import React, { useMemo } from 'react'
import { useTheme } from "@mui/material";
import { FormattedMessage, useIntl } from 'react-intl';
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IMG_FEATURE_LEFT, IMG_FEATURE_RIGHT } from '../../constants';
import { useUIContext } from '../../context/ui';
import { ImageCovered } from '../../components/imageCover/ImageCover'
import useImage from '../../hooks/useImage';


const Service = ({mobile, tablet, user, activeBuilding}) => {
    const theme = useTheme()
    const imageFunc = useImage()
    
    // const handleClickFarms = () => {
    //     // navigate(URL_FARMS)
    //     setAlertObj({
    //         severity: SEVERITY_SUCCESS,
    //         content: intl.formatMessage({id: 'info.farms.coming.soon'}),
    //         duration: null,
    //         okHandler: () => {}
    //     })
    //     setShowAlert(true)
    // }

    // const handleClickCommercials = () => {
    //     // navigate(URL_COMMERCIAL_COMMERCIALS)
    //     navigate(URL_COMMERCIAL_PRODUCTS_2 + '64b73b9173a9328f6f5f9661')
    // }

    // const handleClickCommunity = () => {
    //     if (!user) {
    //         setAlertObj({
    //             severity: SEVERITY_WARNING,
    //             content: intl.formatMessage({id: 'warning.not.login'}),
    //             duration: null,
    //             okHandler: () => {
    //                 navigate(URL_LOGIN)
    //             }
    //         })
    //         setShowAlert(true)
    //         return
    //     } else if (!activeBuilding) {
    //         setAlertObj({
    //             severity: SEVERITY_WARNING,
    //             content: intl.formatMessage({id: 'warning.community.not.set'}),
    //             duration: null,
    //             okHandler: () => {
    //                 navigate(URL_COMMUNITIES)
    //             }
    //         })
    //         setShowAlert(true)
    //         navigate(URL_COMMUNITIES)
    //         return
    //     } else {
    //         navigate(URL_COMMUNITY_ISSUES)
    //     }
    // }

    // const handleClickStrata = () => {
    //     if (!user) {
    //         setAlertObj({
    //             severity: SEVERITY_WARNING,
    //             content: intl.formatMessage({id: 'warning.not.login'}),
    //             duration: null,
    //             okHandler: () => {
    //                 navigate(URL_LOGIN)
    //             }
    //         })
    //         setShowAlert(true)
    //         return
    //     } else if (!activeBuilding) {
    //         setAlertObj({
    //             severity: SEVERITY_WARNING,
    //             content: intl.formatMessage({id: 'warning.community.not.set'}),
    //             duration: null,
    //             okHandler: () => {
    //                 navigate(URL_COMMUNITIES)
    //             }
    //         })
    //         setShowAlert(true)
    //         navigate(URL_COMMUNITIES)
    //         return
    //     } else {
    //         navigate(URL_STRATA_SUMMARY)
    //     }
    // }
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      mt={mobile ? 0 : 5}
      mb={mobile ? 5 : 0}
      width="100vw"
      sx={{ textAlign: "center" }}
    >
      <Typography
        variant="h4"
        pl={10}
        pr={10}
        sx={{
          color: theme.palette.text.primary,
          borderBottom: `3px solid ${theme.palette.grey['700']}`,
        }}
      >
        <FormattedMessage id="home.services" />
      </Typography>
      <Stack width='100%' direction={mobile ? 'column' : 'row'} justifyContent={'center'} alignItems={'center'} spacing={mobile ? 5 : 10} py={3}>
        <Stack justifyContent={'start'} alignItems={'center'} spacing={2}>
          <ImageCovered src={imageFunc({image: IMG_FEATURE_LEFT})} sx={{borderRadius: '50%', width: mobile ? '120px' : '200px', height: mobile ? '120px' : '200px'}} />
          <Typography variant='body1'><FormattedMessage id={'home.feature.left.1'} /></Typography>
          <Typography variant='body1'><FormattedMessage id={'home.feature.left.2'} /></Typography>
          <Typography variant='body1'><FormattedMessage id={'home.feature.left.3'} /></Typography>
        </Stack>
        <Stack justifyContent={'start'} alignItems={'center'} spacing={2}>
          <ImageCovered src={imageFunc({image: IMG_FEATURE_RIGHT})} sx={{borderRadius: '50%', width: mobile ? '120px' : '200px', height: mobile ? '120px' : '200px'}} />
          <Typography variant='body1'><FormattedMessage id={'home.feature.right.1'} /></Typography>
          <Typography variant='body1'><FormattedMessage id={'home.feature.right.2'} /></Typography>
          <Typography variant='body1' sx={{height: '24px'}}>{''}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Service