import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ImageCovered } from '../../pages/commercialCart/CartItem'

const PaymentSelect = ({order, payment, setPayment}) => {
    const intl = useIntl()
    const theme = useTheme()
    const handlePaymentChange = (value) => {
        setPayment(value)
    }
  return (
    <Stack width='100%' display='flex' justifyContent='center' alignItems='center' mt={3}>
        <Typography
              variant="h5"
              color={theme.palette.primary.main}
              fontWeight="bold"
              sx={{
                mr: "1",
                borderBottom: `1px solid ${theme.palette.primary.main}`,
                minWidth: "300px",
                textAlign: "center",
                mb: 2,
              }}
            >
              <FormattedMessage id="commercial.choose.payment" />
        </Typography>
        <FormControl sx={{mb: 3}}>
            {/* <FormLabel id="demo-radio-buttons-group-label"><FormattedMessage id='commercial.choose.payment' /></FormLabel> */}
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={'stripe'}
                value={payment}
                name="radio-buttons-group"
                onChange={(e) => handlePaymentChange(e.target.value)}
            >
                <FormControlLabel value="stripe" control={<Radio />} label={<>
                    <ImageCovered src={process.env.REACT_APP_LOGO_STRIPE} width="120px" height="auto" />
                </>} />
                <FormControlLabel value="emt" control={<Radio />} label={<>
                    <ImageCovered src={process.env.REACT_APP_LOGO_EMT} width="120px" height="auto" />
                </>} />
            </RadioGroup>
        </FormControl>
        {payment === 'emt' && (
            <Box width='90vw' display='flex' flexDirection={'column'} justifyContent='center' alignItems='center' >
                <Stack width={'80%'} display='flex' flexDirection={'column'} justifyContent='center' alignItems='center'>
                    <Box>
                    <Typography variant='body'><FormattedMessage id='commercial.emt.part1' /></Typography>
                    </Box>
                    <Box>
                    <Tooltip title={intl.formatMessage({id: 'tooltip.click.copy'})} enterTouchDelay={0} arrow><Button variant='text' sx={{cursor: 'pointer', color: theme.palette.warning.main}} onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_EMAIL_SUPPORT)}>{process.env.REACT_APP_EMAIL_SUPPORT}</Button></Tooltip>
                    </Box>
                    <Box textAlign={'center'}>
                    <Typography><FormattedMessage id='commercial.emt.part2' /></Typography>
                    </Box>
                    <Box>
                    <Tooltip title={intl.formatMessage({id: 'tooltip.click.copy'})} enterTouchDelay={0} arrow><Button variant='text' sx={{cursor: 'pointer', color: theme.palette.warning.main, fontSize: '1.8rem'}} onClick={() => navigator.clipboard.writeText(order?.orderId?.substr(order?.orderId.length - 4))}>{order?.orderId?.substr(order?.orderId.length - 4)}</Button></Tooltip>
                    </Box>
                
                </Stack>
                
            </Box>
        )}
    </Stack>
  )
}

export default PaymentSelect