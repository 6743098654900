import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Badge,
  Stack,
  Avatar,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { styled, useTheme, alpha } from "@mui/material/styles";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LOCALES } from "../../i18n/locales";
import { FormattedMessage, useIntl } from "react-intl";
import { StyledBadge } from "../../components/styledBadge/StyledBadge";
import useDialogModal from "../../hooks/useDialogModal";
import ContactRequest from "../../components/modals/ContactRequest";
import { Delete, MoreVert, Remove } from "@mui/icons-material";
import {
  CONTACT_ELEMENT_CONTACT,
  CONTACT_ELEMENT_CONVERSATION,
  CONTACT_ELEMENT_REQUEST,
  CONTACT_ELEMENT_SEARCH_RESULT,
  Contact_options,
} from "../../constants";
import { socket } from "../../socket";
import { setConversationViewed, setShowChatroom, setTabSelected } from "../../redux/chatroom";
import { DotsThreeVertical } from "phosphor-react";
import useAvatar from "../../hooks/useAvatar";

const truncateText = (string, n) => {
  return string?.length > n ? `${string?.slice(0, n)}...` : string;
};

const ContactElement = ({
  userId,
  contact,
  locale,
  category = CONTACT_ELEMENT_CONVERSATION,
  showAddContactDialog = null,
  setToUser = null,
  pendingRequest = null,
  conversation = null,
  selectedContact = null,
  setSelectedContact = null,
  showContactRequestDialog = null,
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleContactMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const avatarFunc = useAvatar()
  // console.log('selectedContact', selectedContact)
  const handleStartConversation = () => {
    if (category === CONTACT_ELEMENT_CONTACT) {
      console.log("***selectedContact", selectedContact, contact);
      // if (!selectedContact || selectedContact?.id !== contact?.id) {
        setSelectedContact(contact);
        const param = {
          from: userId,
          to: contact.id,
        };

        if (conversation?.latestMessageTime) {
          param["latestMessageTime"] = conversation?.latestMessageTime;
        }
        // console.log("param", param);
        socket.emit("start_conversation", param, () => {});
      // }
      dispatch(setTabSelected({
        tabSelected: 0
      }))
    }
  };

  const handleViewContact = () => {
    // TODO: Launch a view contact modal dialog
  };

  const handleDeleteContact = async () => {
    if (
      window.confirm(intl.formatMessage({ id: "window.sure.delete" })) === true
    ) {
      const deleteParam = {
        sender: userId,
        recipient: contact?.id,
      };
      // console.log('deleteParam', contact, deleteParam)
      socket.emit("delete_contact", deleteParam, () => {
        alert(intl.formatMessage({ id: "chat.request.sent" }));
      });
    }
  };

  const handleClickMenuItem = (idx) => {
    setAnchorEl(null);
    if (idx === 0) {
      // Start conversation
      handleStartConversation();
    } else if (idx === 1) {
      // View Contact
      handleViewContact();
    } else if (idx === 2) {
      // Delete Contact
      handleDeleteContact();
    }
  };

  const isSelected = useMemo(() => {
    if (category === CONTACT_ELEMENT_REQUEST) {
      return (
        selectedContact &&
        pendingRequest &&
        selectedContact.id === pendingRequest.id
      );
    } else if (category === CONTACT_ELEMENT_CONTACT) {
      // console.log('****** selectedContact', selectedContact, contact)
      return selectedContact && contact && selectedContact.id === contact.id;
    } else if (category === CONTACT_ELEMENT_CONVERSATION) {
      return selectedContact && contact && selectedContact.id === contact.id;
    }
  }, [category, selectedContact, pendingRequest]);

  return (
    <>
      <Box
        onClick={() => {
          if (category === CONTACT_ELEMENT_REQUEST && pendingRequest) {
            setSelectedContact && setSelectedContact(pendingRequest);
            showContactRequestDialog && showContactRequestDialog(true);
          } else if (category === CONTACT_ELEMENT_CONVERSATION) {
            // console.log('******** contact?.conversationId', contact?.conversationId)
            dispatch(
              setConversationViewed({ conversationId: contact?.conversationId })
            );

            dispatch(setShowChatroom({
              showChatroom: true,
            }))
          } else if (category === CONTACT_ELEMENT_CONTACT) {
            setSelectedContact && setSelectedContact(contact)
          }
        }}
        sx={{
          width: "100%",
          borderRadius: 3,
          bgcolor: isSelected
            ? theme.palette.mode === "light"
              ? // ? alpha(theme.palette.primary.main, 0.5)
                theme.palette.primary.lighter
              : theme.palette.primary.main
            : theme.palette.mode === "light"
            ? theme.palette.grey["100"]
            : theme.palette.background.paper,
          "&:hover": {
            cursor: "pointer",
            // border: pendingRequest ? `1px solid ${theme.palette.amber['800']}` : undefined
          },
        }}
        p={2}
      >
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={2} width="100%" onClick={() => handleStartConversation()}>
            <Badge
              className="unread-count"
              color="error"
              badgeContent={contact?.unread}
            >
              {contact?.online ? (
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <Avatar
                    // alt={contact?.firstName}
                    src={
                      avatarFunc({image: contact?.avatar})
                    }
                  />
                </StyledBadge>
              ) : (
                <Avatar
                  // alt={contact?.firstName}
                  src={
                    avatarFunc({image: contact?.avatar})
                  }
                />
              )}
            </Badge>
            <Stack spacing={0.3} width="100%">
              <Stack
                direction="row"
                justifyContent={"space-between"}
                alignItems={"center"}
                width="100%"
              >
                <Typography variant="subtitle2">
                  {locale === LOCALES.ENGLISH
                    ? `${contact?.firstName} ${contact?.lastName}`
                    : `${contact?.lastName} ${contact?.firstName}`}
                </Typography>
                {category === CONTACT_ELEMENT_CONVERSATION &&
                  contact?.lastMessageTime && (
                    <Typography sx={{ fontWeight: 600 }} variant="caption">
                      {contact?.lastMessageTime}
                    </Typography>
                  )}
              </Stack>

              {category === CONTACT_ELEMENT_CONTACT && (
                <Typography
                  variant="caption"
                  color={theme.palette.warning.dark}
                >
                  {contact?.email}
                </Typography>
              )}
              {category !== CONTACT_ELEMENT_CONTACT && (
                <Typography variant="caption">
                  {truncateText(contact?.lastMessage, 20)}
                </Typography>
              )}
            </Stack>
          </Stack>

          {category === CONTACT_ELEMENT_SEARCH_RESULT && (
            <Button
              variant="contained"
              size="small"
              sx={{
                bgcolor: theme.palette.amber["800"],
                "&:hover": {
                  bgcolor: theme.palette.amber["700"],
                },
              }}
              onClick={() => {
                showAddContactDialog && showAddContactDialog();
                setToUser && setToUser(contact);
              }}
            >
              <FormattedMessage id="general.add" />
            </Button>
          )}
          {category === CONTACT_ELEMENT_CONTACT && (
            <>
              <MoreVert
                size={20}
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleContactMenuClick}
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <Stack spacing={1} px={1}>
                  {Contact_options.map((el, idx) => (
                    <MenuItem
                      onClick={() => handleClickMenuItem(idx)}
                      key={idx}
                    >
                      <FormattedMessage id={el.title} />
                    </MenuItem>
                  ))}
                </Stack>
              </Menu>
            </>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default ContactElement;
