import { createSlice } from "@reduxjs/toolkit";

const activitySlice = createSlice({
    name: 'activity',
    initialState: {
        activities: [],
        myActivities: [],
    },
    reducers: {
        setActivities: (state, action) => {
            state.activities = action.payload
        },
        setMyActivities: (state, action) => {
            state.myActivities = action.payload
        },
    }
})

export const {setActivities, setMyActivities} = activitySlice.actions
export default activitySlice.reducer