import { api } from './api'

const cartApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCartByUser: build.query({
            query: ({ id, token }) => ({
                url: `api/cartByUser/${id}`,
                method: "GET",
                //   params: { page, pageSize, sort, search, vendor, location, category, tags },
                headers: { Authorization: `Bearer ${token}` },
            }),
            providesTags: (result) =>
                result
                ? [
                    { type: 'Carts', id: result._id },
                    { type: 'Carts', id: 'LIST' },
                    ]
                : [{ type: 'Carts', id: 'LIST' }],
        }),
        addCart: build.mutation({
            query: ({token, body}) => ({
                url: `api/addCart/`,
                method: 'POST',
                body,
                headers: { Authorization: `Bearer ${token}` },
            }),
            invalidatesTags: ['Carts'],
        }),
        updateCartByUser: build.mutation({
            query: ({id, token, body}) => ({
              url: `api/updateCartByUser/${id}`,
              method: 'PUT',
              body,
              headers: { Authorization: `Bearer ${token}` },
            }),
            invalidatesTags: ['Carts'],
        }),
        payStripe: build.mutation({
            query: ({token, body}) => ({
              url: `api/payStripe`,
              method: 'POST',
              body,
              headers: { Authorization: `Bearer ${token}` },
            }),
            invalidatesTags: ['Carts'],
        }),
    }),
    overrideExisting: false,
})

export const { useGetCartByUserQuery, useAddCartMutation, useUpdateCartByUserMutation, usePayStripeMutation  } = cartApi