import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const FlexBetween = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const FlexCenter = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const FlexStart = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
}));

export const TextLeft = styled(Typography)(() => ({
  flex: 1,
  textAlign: 'left',
}))

export const TextRight = styled(Typography)(() => ({
  flex: 2,
  textAlign: 'right',
}))

