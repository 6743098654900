import { useEffect, useState } from "react";
import * as Yup from "yup";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Link,
  Stack,
  Alert,
  IconButton,
  InputAdornment,
  Button,
  useTheme,
} from "@mui/material";
// components
import FormProvider, { RHFTextField } from "../../components/hook-form";
import { Eye, EyeSlash } from "phosphor-react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  REGEX_PASSWORD,
  SEVERITY_ERROR,
  SEVERITY_SUCCESS,
  SEVERITY_WARNING,
  URL_HOME,
  URL_LOGIN,
} from "../../constants";
import { useResetPasswordMutation } from "../../apis/auth";
import { useUIContext } from "../../context/ui";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/user";
import { setUserToken } from "../../redux/global";
import { useNavigate, useParams } from "react-router-dom";
import { LOCALES } from "../../i18n/locales";
import { socket } from "../../socket";

const NewPasswordForm = () => {
  const intl = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { emailToken } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  const locale =
    useSelector((state) => {
      return state.persisted.global.locale;
    })?.value || LOCALES.ENGLISH;

  const { setShowBackdropCallback, setShowAlert, setAlertObj } = useUIContext();
  const [resetPassword, { isLoading: isUpdating }] = useResetPasswordMutation();

  useEffect(() => {
    setShowBackdropCallback(isUpdating);
  }, [isUpdating]);

  const NewPasswordSchema = Yup.object().shape({
    newPassword: Yup.string().matches(REGEX_PASSWORD, "error.invalid.password"),
    newPasswordConfirmation: Yup.string().oneOf(
      [Yup.ref("newPassword"), null],
      "error.password.match"
    ),
  });

  const defaultValues = {
    newPassword: "",
    newPasswordConfirmation: "",
  };

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(NewPasswordSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isDirty },
  } = methods;

  console.log("errors", errors);
  const onSubmit = async (data) => {
    try {
      let submitObj = {
        token: emailToken,
        password: data.newPassword,
        passwordConfirm: data.newPasswordConfirmation,
      };
      if (
        window.confirm(intl.formatMessage({ id: "window.ready.submit" })) ===
        true
      ) {
        const result = await resetPassword({
          body: submitObj,
        });
        if (result.error) {
          const errorInfo = result.error?.data;
          setShowAlert(true);
          setAlertObj({
            severity: SEVERITY_ERROR,
            content: errorInfo[locale],
            duration: null,
          });
        } else {
          setShowAlert(true);
          setAlertObj({
            severity: SEVERITY_SUCCESS,
            content: intl.formatMessage({ id: "window.operation.successful" }),
            duration: null,
          });
          dispatch(setUser(null));
          dispatch(setUserToken(null));
          // dispatch(setDeliveryPhone(''))
          // dispatch(setDeliveryAddress(''))
          navigate(URL_LOGIN);
          if (socket) {
            socket.emit('end')
          } 
        }
      }
      // submit data to backend
      //   dispatch(LoginUser(data));
    } catch (error) {
      console.error(error);
      reset();
      setAlertObj({
        severity: SEVERITY_WARNING,
        content: error.message,
        duration: null,
      });
      setShowAlert(true);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}

        <RHFTextField
          name="newPassword"
          label={intl.formatMessage({ id: "auth.new.password" })}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <Eye /> : <EyeSlash />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name="newPasswordConfirmation"
          label={intl.formatMessage({ id: "auth.password.confirmation" })}
          type={showPasswordConfirmation ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() =>
                    setShowPasswordConfirmation(!showPasswordConfirmation)
                  }
                  edge="end"
                >
                  {showPasswordConfirmation ? <Eye /> : <EyeSlash />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          disabled={
            !!errors.newPassword || !!errors.newPasswordConfirmation || !isDirty
          }
          sx={{
            bgcolor: theme.palette.primary.main,
            color: (theme) =>
              theme.palette.mode === "light" ? "common.white" : "grey.800",
            "&:hover": {
              bgcolor: theme.palette.primary.main,
              color: (theme) =>
                theme.palette.mode === "light" ? "common.white" : "grey.800",
            },
          }}
        >
          <FormattedMessage id="general.submit" />
        </Button>
      </Stack>
    </FormProvider>
  );
};

export default NewPasswordForm;
