import { Box, Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import { styled } from "@mui/material/styles";
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import IncDec from '../../ui';
import { INCDEC_SIZE_SMALL, NULL_USER, URL_COMMERCIAL_PRODUCT_2 } from '../../constants';
import { addProductToCart, removeProductFromCart } from '../../redux/global';
import { LOCALES } from '../../i18n/locales';
import { useNavigate } from 'react-router-dom';
import useImage from '../../hooks/useImage';

export const ImageCovered = styled('img')(({}) => ({
    objectFit: 'cover',
}))


const CartItem = ({user, cartProduct, mobile, soldouts, themeBg}) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const imageFunc = useImage()
    const location = useSelector((state) => state.persisted.global.location) || process.env.REACT_APP_DEFAULT_MARKET
    const locale = useSelector((state) => state.persisted.global.locale)?.value || LOCALES.ENGLISH;

    const isSoldout = useMemo(() => {
        return soldouts.findIndex((item) => item._id === cartProduct._id) >= 0 
    }, [cartProduct, soldouts])

    const hasAddons = useMemo(() => {
        return cartProduct?.extraItems?.findIndex((item) => item.quantity > 0) >= 0
    }, [cartProduct?.extraItems])
    const [quantity, setQuantity] = useState(cartProduct?.quantity)

    // console.log('cartProduct', cartProduct)
    useEffect(() => {
        // const saleInfoIdx = product?.saleInfo?.findIndex((info) => info.city.toLowerCase() === location.toLowerCase())
        console.log('quantity', quantity, cartProduct?.quantity)
        if (quantity > cartProduct?.quantity) {
            const productInfo = {
                product: {...cartProduct,
                    _id: cartProduct?._id?.indexOf('-') > 0 ? cartProduct?._id?.split('-')[0] : cartProduct?._id,
                },
                extraItems: cartProduct?.sales?.extraItems,
                saleIdx: -1,
                quantity: (quantity - cartProduct?.quantity),
                user: user ? user?._id : NULL_USER,
            }
            console.log('productInfo', productInfo)
            dispatch(addProductToCart(productInfo))
        } else if (quantity < cartProduct?.quantity) {
            dispatch(removeProductFromCart({
                product: {...cartProduct,
                    _id: cartProduct?._id?.indexOf('-') > 0 ? cartProduct?._id?.split('-')[0] : cartProduct?._id,
                },
                extraItems: cartProduct?.extraItems,
                saleInfoIdx: -1,
                quantity: (cartProduct?.quantity - quantity),
                user: user ? user?._id : NULL_USER,
            }))
        }
    }, [quantity])

  return (
    <>
        <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='center' p={3} borderBottom={`1px solid ${theme.palette.grey['300']}`}>
            <Box display='flex' flexDirection={mobile ? 'column' : 'row'} justifyContent='flex-start' alignItems='center'>
                <ImageCovered src={imageFunc({image: cartProduct?.image})} width='120px' height='120px' sx={{border: `5px solid ${themeBg}`, cursor: 'pointer', borderRadius: '10px', mr: mobile ? 0 : 2, mb: mobile? 2 : 0}} onClick={() => navigate(URL_COMMERCIAL_PRODUCT_2 + cartProduct?._id)} />
                <Stack spacing={0.5}>
                    <Box display='flex' justifyContent='flex-start' alignItems='center'>
                        <Typography variant='body2' sx={{mr: 1, color: theme.palette.green['900'] }}><FormattedMessage id='product.name' />: </Typography>
                        <Typography variant='body2'>{cartProduct?.title[locale]}</Typography>
                    </Box>
                    <Box display='flex' justifyContent='flex-start' alignItems='center'>
                        <Typography variant='body2' sx={{mr: 1, color: theme.palette.green['900'] }}><FormattedMessage id='shop.price' />: </Typography>
                        <Typography variant='body2'>${cartProduct?.price.toFixed(2)}</Typography>
                    </Box>
                    {cartProduct?.grade && <Box display='flex' justifyContent='flex-start' alignItems='center'>
                        <Typography variant='body2' sx={{mr: 1, color: theme.palette.green['900']}}><FormattedMessage id='shop.grade' />: </Typography>
                        <Typography variant='body2'>{cartProduct?.grade[locale]}</Typography>
                    </Box>}
                    <Box display='flex' justifyContent='flex-start' alignItems='center'>
                        <Typography variant='body2' sx={{mr: 1, color: theme.palette.green['900']}}><FormattedMessage id='shop.unit' />: </Typography>
                        <Typography variant='body2'>{cartProduct?.unit ? cartProduct?.unit[locale] : ''}</Typography>
                    </Box>
                    {hasAddons && (
                        <>
                        <Box display='flex' justifyContent='flex-start' alignItems='center'>
                            <Typography variant='body2' sx={{mr: 1, color: theme.palette.green['900'] }}
                            ><FormattedMessage id='commercial.addons' />: </Typography>
                        </Box>
                        {cartProduct?.extraItems?.map((item, idx) => item.quantity > 0 && (
                            <Box key={idx} display='flex' justifyContent='flex-start' alignItems='center'>
                                <Typography variant='body2' sx={{mr: 1, }}>
                                    {item.title[locale]} ({item.unit[locale]})
                                </Typography>
                                <Typography variant='body2' sx={{mr: 1, }}>
                                    ${item.price}
                                </Typography>
                                <Typography variant='body2' sx={{mr: 1, }}>
                                    X {item.quantity}
                                </Typography>
                            </Box>
                        ))}
                        </>
                    )}
                    {isSoldout && (
                        <Box display='flex' justifyContent='flex-start' alignItems='center'>
                            <Typography variant='body2' sx={{mr: 1, color: theme.palette.red['800']}}><FormattedMessage id='commercial.sold.out' /> </Typography>
                        </Box>
                    )}
                    <Box display='flex' justifyContent='flex-start' alignItems='center'>
                        <IncDec value={quantity} setValue={setQuantity} size={INCDEC_SIZE_SMALL} buyUnit={cartProduct?.buyUnit} />
                        <Typography variant='h5' ml={3} sx={{color: theme.palette.green['800']}}>${cartProduct.subtotal.toFixed(2)}</Typography>
                    </Box>
                </Stack>
            </Box>
        </Box>
    </>
  )
}

export default CartItem