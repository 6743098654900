import { api } from './api'

const notificationApi = api.injectEndpoints({
  endpoints: (build) => ({
      getNotifications: build.query({
          query: ({}) => ({
            url: "api/notifications",
            method: "GET",
            params: {},
            // headers: { Authorization: `Bearer ${token}` },
          }),
          providesTags: (result) =>
            result?.notifications
            ? [
                ...result.notifications.map(({ _id }) => ({ type: 'Notifications', id: _id })),
                { type: 'Notifications', id: 'LIST' },
              ]
            : [{ type: 'Notifications', id: 'LIST' }],
      }),
    }),
    overrideExisting: false,
})
    
export const { useGetNotificationsQuery } = notificationApi