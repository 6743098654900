import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from "redux-persist";
import storage from "redux-persist/lib/storage";
import globalReducer from './global'
import productReducer from './product'
import communityReducer from './community'
import buildingReducer from './building'
import vendorReducer from './vendor'
import userReducer from './user'
import deliveryReducer from './delivery'
import postReducer from './post'
import activityReducer from './activity'
import notificationReducer from './notification'
import strataReducer from './strata'
import configReducer from './config'
import conversationReducer from './conversation'
import pendingRequestReducer from './pendingRequest'
import chatroomReducer from './chatroom'
import { api } from '../apis/api'

const persistConfig = {
    key: "birtoo-web-v2",
    version: 1,
    storage,
};

const rootReducer = combineReducers({ global: globalReducer, conversation: conversationReducer });

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: {
    persisted: persistedReducer,
    product: productReducer,
    community: communityReducer,
    building: buildingReducer,
    vendor: vendorReducer,
    user: userReducer,
    delivery: deliveryReducer,
    post: postReducer,
    activity: activityReducer,
    notification: notificationReducer,
    strata: strataReducer,
    config: configReducer,
    // conversation: conversationReducer,
    pendingRequest: pendingRequestReducer,
    chatroom: chatroomReducer, 
    [api.reducerPath]: api.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware),
});

export let persistor = persistStore(store);