import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { FlexBetween, FlexStart } from "../flexBetween/FlexBetween";
import { ImageCovered } from "../imageCover/ImageCover";
import { REGEX_JSON } from "../../constants";
import { LOCALES } from "../../i18n/locales";
import useImage from "../../hooks/useImage";

const OrderDetail = ({ open, onClose, order, tablet, mobile }) => {
  const theme = useTheme();
  const intl = useIntl();
  // const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config)?.config;
  const locale = useSelector((state) => state.persisted.global.locale)?.value || LOCALES.ENGLISH;
  const imageFunc = useImage()
  // console.log('mobile', mobile)
  const startTime = useMemo(() => {
    return new Date(order?.communityDelivery?.time);
  }, [order?.communityDelivery]);

  const endTime = useMemo(() => {
    if (order?.communityDelivery && startTime) {
      return new Date(
        startTime.getTime() + order?.communityDelivery.range * 60000
      );
    }
  }, [startTime, order?.communityDelivery]);

  const vendorTitle = useMemo(() => {
    if (order?.vendor) {
      return JSON.parse(order?.vendor?.title)[locale];
    } else {
      return "";
    }
  }, [order?.vendor]);

  return (
    // <Dialog TransitionComponent={slideTransition}
    <Dialog variant="permanent" open={open} fullScreen>
      <DialogTitle
        sx={{
          color: theme.palette.common.white,
          background: theme.palette.primary.main,
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <FormattedMessage id="shop.order.id" />: {order?.orderId}
          <IconButton onClick={onClose} sx={{ color: theme.palette.common.white }}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Stack width="100%" justifyContent="center" alignItems="center">
          <Box width={mobile ? "100%" : tablet ? "60%" : "40%"} mt={3}>
            <Typography variant="h5" sx={{ mb: 3, width: "100%" }}>
              <FormattedMessage id="shop.order.products" />
            </Typography>
            {order?.products?.map((item, idx) => (
              <Box
                key={item?._id + "-" + idx}
                width="100%"
                mb={2}
                pb={2}
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                borderBottom={`1px solid ${theme.palette.teal['600']}`}
              >
                <ImageCovered
                  src={
                    imageFunc({image: item.image})
                  }
                  width="120px"
                  height="120px"
                  sx={{
                    mr: 3,
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.22)",
                  }}
                />
                <Stack width="100%">
                  <FlexStart width="100%">
                    <Typography variant="body2">
                      {item?.name?.match(REGEX_JSON) ? JSON.parse(item.name)[locale] : ''}{" "}
                    </Typography>
                    {item?.level?.match(REGEX_JSON) && JSON.parse(item.level)[locale]?.length > 0 && (
                      <Typography variant="body2">
                        ({item?.level?.match(REGEX_JSON) ? JSON.parse(item.level)[locale] : ''})
                      </Typography>
                    )}
                  </FlexStart>
                  <FlexStart width="100%">
                    <Typography variant="body2" sx={{ mr: 2 }}>
                      <FormattedMessage id="shop.price" />:{" "}
                    </Typography>
                    <Typography variant="body2">{`$${item.price.toFixed(2)} (${
                      item.quantity
                    })`}</Typography>
                  </FlexStart>
                  <FlexStart width="100%">
                    <Typography variant="body2" sx={{ mr: 2 }}>
                      <FormattedMessage id="shop.unit" />:{" "}
                    </Typography>
                    <Typography variant="body2">
                      {item?.unit?.match(REGEX_JSON) ? JSON.parse(item.unit)[locale] : ''}
                    </Typography>
                  </FlexStart>

                  {item?.extraItems?.length > 0 && (
                    <FlexStart width="100%">
                      <Typography
                        variant="body"
                        sx={{ mr: 1, color: theme.palette.green['900'] }}
                      >
                        <FormattedMessage id="commercial.addons" />:{" "}
                      </Typography>
                    </FlexStart>
                  )}
                  {item?.extraItems?.map(
                    (extra, idx) =>
                      extra.quantity > 0 && (
                        <>
                          <FlexStart width="100%">
                            <Typography variant="body" sx={{ mr: 1, ml: 2 }}>
                              {JSON.parse(extra.name)[locale]}
                            </Typography>
                            <Typography variant="body" sx={{ mr: 1 }}>
                              ${extra.price}
                            </Typography>
                            <Typography variant="body" sx={{ mr: 1 }}>
                              X {extra.quantity}
                            </Typography>
                          </FlexStart>
                        </>
                      )
                  )}
                  <FlexStart width="100%">
                    <Typography variant="body2" sx={{ mr: 2 }}>
                      <FormattedMessage id="shop.subtotal" />:{" "}
                    </Typography>
                    <Typography variant="body2">
                      ${item.subtotal.toFixed(2)}
                    </Typography>
                  </FlexStart>
                </Stack>
              </Box>
            ))}
          </Box>
          <Stack width={mobile ? "100%" : tablet ? "60%" : "40%"} mt={3} spacing={2}>
            <Typography variant="h5">
              <FormattedMessage id="shop.order.summary" />:
            </Typography>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.teal['800'],
                  }}
                >
                  <FormattedMessage id="shop.order.id" />:
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  color={theme.palette.warning.main}
                >
                  {order?.orderId}
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                >
                  <FormattedMessage id="commercial.vendor" />:
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2">{vendorTitle}</Typography>
              </Box>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                >
                  <FormattedMessage id="commercial.shipping" />:
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" color={theme.palette.warning.main}>
                  <FormattedMessage id={"commercial." + order.shipping} />
                </Typography>
              </Box>
            </Stack>
            {order?.communityDelivery && (
              <>
                <Stack
                  direction={"column"}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                    >
                      <FormattedMessage id="shop.pickup.location" />:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">
                      {order?.communityDelivery?.address}
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                    >
                      <FormattedMessage id="shop.pickup.time" />:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">
                      {moment(startTime).format("YYYY-MM-DD HH:mm")} -{" "}
                      {moment(endTime).format("YYYY-MM-DD HH:mm")}
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                    >
                      <FormattedMessage id="shop.pickup.details" />:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">
                      {!!order.communityDelivery?.details.match(REGEX_JSON)
                        ? JSON.parse(order.communityDelivery?.details)[locale]
                        : order.communityDelivery?.details}
                    </Typography>
                  </Box>
                </Stack>
              </>
            )}
            {!order?.communityDelivery && (
              <>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                    >
                      <FormattedMessage id="shop.delivery.time" />:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">{order?.deliveryTime}</Typography>
                  </Box>
                </Stack>
                <Stack
                  direction={"column"}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                    >
                      <FormattedMessage id="shop.delivery.address" />:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">{order?.address}</Typography>
                  </Box>
                </Stack>
              </>
            )}
            <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                >
                  <FormattedMessage id="auth.phone" />:
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2">
                  {order?.phone.replace(/^(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}
                </Typography>
              </Box>
            </Stack>
            {order.notes?.length > 0 && (
              <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                <Box>
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.primary.main,
                    }}
                  >
                    <FormattedMessage id="commercial.order.notes" />:
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">{order.notes}</Typography>
                </Box>
              </Stack>
            )}
            <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                >
                  <FormattedMessage id="shop.subtotal" />:
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2">
                  ${order?.subtotal.toFixed(2)}
                </Typography>
              </Box>
            </Stack>
            <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                >
                  <FormattedMessage id="shop.discount" />:
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2">
                  ${order?.discount.toFixed(2)}
                </Typography>
              </Box>
            </Stack>
            {order?.payment === "stripe" && (
              <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems="flex-start"
            >
                <Box>
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.primary.main,
                    }}
                  >
                    <FormattedMessage id="commercial.transaction.fee" />:
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    ${parseFloat(order?.transactionFee).toFixed(2)}
                  </Typography>
                </Box>
              </Stack>
            )}
            <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                >
                  <FormattedMessage id="commercial.service.fee" />:
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2">
                  $
                  {(
                    parseFloat(order?.serviceFee) // +
                    // parseFloat(order?.deliveryFee) +
                    // parseFloat(order?.tax)
                  ).toFixed(2)}
                </Typography>
              </Box>
            </Stack>
            <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                >
                  <FormattedMessage id="commercial.delivery.fee" />:
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2">
                  $
                  {(
                    // parseFloat(config?.defaultServiceFee) +
                    parseFloat(order?.deliveryFee) //+
                    // parseFloat(order?.tax)
                  ).toFixed(2)}
                </Typography>
              </Box>
            </Stack>
            <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                >
                  <FormattedMessage id="shop.tax" />:
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2">
                  ${parseFloat(order?.tax).toFixed(2)}
                </Typography>
              </Box>
            </Stack>
            <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                >
                  <FormattedMessage id="shop.total" />:
                </Typography>
              </Box>
              <Box>
                <Typography variant="h4" sx={{ color: theme.palette.warning.main }}>
                  ${order?.total.toFixed(2)}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default OrderDetail;
