import {
  Box,
  Button,
  Divider,
  Stack,
  Tab,
  Tabs,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import {
  CONFIG_KEY_IMMIGRATE_INFO,
  REGEX_JSON,
  URL_CHATROOM,
} from "../../constants";
import { LOCALES } from "../../i18n/locales";
import { useTheme } from "@emotion/react";
import { ImageCovered } from "../../components/imageCover/ImageCover";
import { ChatBubble, Email } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  ...theme.typography.h4,
  color: theme.palette.text.primary,
  "& > :not(style) ~ :not(style)": {
    marginTop: theme.spacing(2),
  },
}));

const cssAppliedContent = (locale, body) => {
  return locale === LOCALES.ENGLISH
    ? `
      <div class="immigrationInfo" style="width: 100%;">
          <style>
                div.immigrationInfo h4 {
                    font-family: Roboto, arial, sans-serif;
                    font-size: 20px;
                    line-height: 40px;
                    margin-top: 16px;
                }
              div.immigrationInfo p, div.immigrationInfo li {
                  font-family: Roboto, arial, sans-serif;
                  font-size: 18px;
                  line-height: 30px;
              } 
              div.immigrationInfo ul {
                padding-left: 20px;
              }
          </style>
        ${body}
      <div>`
    : `
      <div class="immigrationInfo" style="width: 100%;">
          <style>
            div.immigrationInfo h4 {
                font-family: Montserrat, arial, sans-serif;
                font-size: 20px;
                line-height: 40px;
                margin-top: 16px;
            }
              div.immigrationInfo p, div.immigrationInfo li {
                  font-family: Montserrat, arial, sans-serif;
                  font-size: 18px;
                  line-height: 30px;
              } 

              div.immigrationInfo ul {
                padding-left: 20px;
              }
          </style>
        ${body}
      <div>`;
};

const ImmigrateCanada = () => {
  const theme = useTheme();
  const navigate = useNavigate()
  const [data, setData] = useState(null);
  const config = useSelector((state) => state.config)?.config;
  const locale =
    useSelector((state) => {
      return state.persisted.global.locale;
    })?.value || LOCALES.ENGLISH;
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (config) {
      if (config[CONFIG_KEY_IMMIGRATE_INFO]) {
        const rawData = config[CONFIG_KEY_IMMIGRATE_INFO];
        if (rawData && rawData?.match(REGEX_JSON)) {
          const jsonData = JSON.parse(rawData.replaceAll("&lt;", "<"));
          setData(jsonData);
        }
      }
    }
  }, [config]);
  return (
    <Stack
      width="100%"
      height="100%"
      justifyContent={"start"}
      alignItems={"center"}
      py={5}
      spacing={5}
      // border={`1px solid ${theme.palette.grey["200"]}`}
    >
      <Root>
        <Divider sx={{ color: theme.palette.grey["600"] }}>
          {data?.title[locale]}
        </Divider>
      </Root>
      <Stack justifyContent={"start"} alignItems={"center"} width={"100%"}>
        <ImageCovered
          src={data?.image}
          sx={{ width: "auto", height: "210px" }}
          onClick={() => {}}
        />
      </Stack>
      <Box
        width={mobile ? "100%" : "70%"}
        px={3}
        py={2}
        dangerouslySetInnerHTML={{
          __html: cssAppliedContent(locale, data?.content[locale]),
        }}
      ></Box>
      <Stack
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        spacing={3}
        mb={5}
      >
        <Button
          variant="contained"
          href={`mailto: ${process.env.REACT_APP_EMAIL_SUPPORT}`}
          sx={{
            width: "150px",
            py: 1,
            fontSize: "16px",
            fontWeight: 500,
            borderRadius: 0,
          }}
          startIcon={<Email fontSize="medium" />}
        >
          <FormattedMessage id="home.email.us" />
        </Button>
        <Button
          variant="contained"
          sx={{
            width: "150px",
            py: 1,
            fontSize: "16px",
            fontWeight: 500,
            borderRadius: 0,
          }}
          startIcon={<ChatBubble fontSize="medium" />}
          onClick={() => {
            navigate(URL_CHATROOM)
          }}
        >
          <FormattedMessage id="home.message.us" />
        </Button>
      </Stack>
    </Stack>
  );
};

export default ImmigrateCanada;
