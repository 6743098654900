import React, { useEffect, useMemo } from "react";
import {
  Stack,
  Box,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Divider,
  Avatar,
  useTheme,
  alpha,
  styled,
} from "@mui/material";
import { DotsThreeVertical, DownloadSimple, Image } from "phosphor-react";
import truncateString from "../../utils/truncate";
import Embed from "react-embed";
import moment from "moment";
import { Message_options } from "../../constants";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { setConversationReply } from "../../redux/chatroom";
import { deleteDirectMessage } from "../../redux/conversation";
import { ImageCovered } from "../imageCover/ImageCover";
import { FilePresent, MoreVert } from "@mui/icons-material";

export const ChatBubble = styled(Box)(
    ({ theme, mobile, incoming, bgcolor }) => ({
      display: "inline-block",
      position: "relative",
      width: "auto",
      maxWidth: mobile === "yes" ? "260px" : "320px",
      height: "auto",
      borderRadius: "10px",
      backgroundColor: bgcolor,
      boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.22)",
      "&::after":
        incoming === "yes"
          ? {
              content: '" "',
              position: "absolute",
              width: 0,
              height: 0,
              left: "-20px",
              right: "auto",
              top: "10px",
              bottom: "auto",
              border: "15px solid",
              borderColor: `${bgcolor} ${bgcolor} transparent transparent`,
            }
          : {
              content: '" "',
              position: "absolute",
              width: 0,
              height: 0,
              left: "auto",
              right: "-20px",
              top: "10px",
              bottom: "auto",
              border: "15px solid",
              borderColor: `${bgcolor} transparent transparent ${bgcolor}`,
            },
    })
  );
  
  export const ChatText = styled(Box)(({ theme }) => ({
    width: "100%",
    padding: "5px 10px",
    textAlign: "left",
    lineHeight: "1.5em",
    overflowWrap: "break-word",
  }));