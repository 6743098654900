import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const conversationSlice = createSlice({
  name: "conversation",
  initialState: {
    direct_chats: {},
    // conversations: [],
    // current_conversation: null,
    // current_messages: [],
    group_chats: {},
  },
  reducers: {
    
    updateDirectConversation(state, action) {
      const this_conversation = action.payload.conversation;
      const userId = action.payload.userId;
      const messageDividerSpan = action.payload.span || 60.0;
      const updateMessage = action.payload.updateMessage || false;
      let conversationIdx = -1;
      // const messages_sorted = formatted_messages.sort((a, b) => a.created_at - b.created_at)
      state.direct_chats[userId].conversations = state.direct_chats[
        userId
      ].conversations.map((el, idx) => {
        if (el?._id !== this_conversation._id) {
          return el;
        } else {
          conversationIdx = idx;
          const user = this_conversation.participants.find(
            (elm) => elm._id.toString() !== userId
          );

          const formatted_messages = this_conversation?.messages?.map(
            (elm) => ({
              _id: elm._id,
              type: elm.type,
              subtype: elm.subtype,
              message: elm.text,
              reply: elm.reply,
              file: elm.file,
              incoming: elm.to === userId,
              outgoing: elm.from === userId,
              created_at: elm.created_at,
            })
          );

          const filtered_messages = el.latestMessageTime
            ? formatted_messages?.filter(
                (message) => message.created_at > el.latestMessageTime
              )
            : formatted_messages;

          // console.log("filtered_messages", filtered_messages);

          const filtered_messages_divided = [];
          if (filtered_messages?.length > 0) {
            for (let i = 0; i < filtered_messages.length - 1; i++) {
              filtered_messages_divided.push(filtered_messages[i]);
              const start = moment(filtered_messages[i].created_at);
              const end = moment(filtered_messages[i + 1].created_at);
              const diffCreatedAt = moment
                .duration(end.diff(start))
                .asMinutes();
              if (diffCreatedAt > messageDividerSpan) {
                const dividerMsg = {
                  type: "divider",
                  message: moment(filtered_messages[i + 1].created_at).format(
                    "YYYY-MM-DD HH:mm"
                  ),
                };
                filtered_messages_divided.push(dividerMsg);
              }
            }
            filtered_messages_divided.push(
              filtered_messages[filtered_messages.length - 1]
            );
          }

          const updatedConversation = {
            _id: this_conversation._id,
            userId: user?._id,
            email: user?.email,
            firstName: `${user?.firstName}`,
            lastName: `${user?.lastName}`,
            username: user?.username,
            description: user?.description,
            online: user?.socket_status === "Online",
            avatar: user?.avatar,
            msg:
              formatted_messages?.length > 0
                ? formatted_messages.slice(-1)[0]?.message
                : "",
            messages:
              updateMessage && filtered_messages_divided?.length > 0
                ? el.messages
                  ? el.messages.concat(filtered_messages_divided)
                  : filtered_messages_divided
                : el.messages,
            displayTime: formatted_messages.slice(-1)[0]?.created_at
              ? moment(formatted_messages.slice(-1)[0]?.created_at).format(
                  "YYYY-MM-DD HH:mm"
                )
              : el.displayTime,
            latestMessageTime: updateMessage
              ? (formatted_messages.slice(-1)[0]?.created_at ||
                el.latestMessageTime)
              : el.latestMessageTime,
            unread: filtered_messages?.filter((msg) => msg.incoming === true).length,
            active: this_conversation?.active,
            pinned: this_conversation?.pinned,
          };

          // console.log("updatedConversation", updatedConversation);
          return updatedConversation;
        }
      });

      if (conversationIdx >= 0) {
        state.direct_chats[userId].current_conversation =
          state.direct_chats[userId].conversations[conversationIdx];
      }
    },
    addDirectConversation(state, action) {
      const this_conversation = action.payload.conversation;
      const userId = action.payload.userId;
      const updateMessage = action.payload.updateMessage || false;
      if (!state.direct_chats[userId]) {
        state.direct_chats[userId] = {
          conversations: [],
          current_conversation: null,
        };
      }
      const formatted_messages = this_conversation?.messages?.map((elm) => ({
        _id: elm._id,
        type: elm.type,
        subtype: elm.subtype,
        message: elm.text,
        reply: elm.reply,
        file: elm.file,
        incoming: elm.to === userId,
        outgoing: elm.from === userId,
        created_at: elm.created_at,
      }));

      const user = this_conversation.participants.find(
        (elm) => elm._id.toString() !== userId
      );
      state.direct_chats[userId].conversations = state.direct_chats[
        userId
      ].conversations.filter((el) => el?._id !== this_conversation._id);

      const new_conversation = {
        _id: this_conversation._id,
        userId: user?._id,
        email: user?.email,
        username: user?.username,
        firstName: `${user?.firstName}`,
        lastName: `${user?.lastName}`,
        description: user?.description,
        online: user?.socket_status === "Online",
        avatar: user?.avatar,
        msg:
          formatted_messages?.length > 0
            ? formatted_messages.slice(-1)[0]?.message
            : "",
        messages:
          updateMessage && formatted_messages?.length > 0
            ? formatted_messages
            : [],
        displayTime: moment(
          formatted_messages.slice(-1)[0]?.created_at ||
            this_conversation.updatedAt
        ).format("YYYY-MM-DD HH:mm"),
        latestMessageTime: updateMessage
          ? (formatted_messages.slice(-1)[0]?.created_at ||
            this_conversation.createdAt)
          : this_conversation.createdAt,
        unread: formatted_messages?.filter((msg) => msg.incoming === true)?.length,
        active: this_conversation?.active,
        pinned: this_conversation?.pinned,
      };

      state.direct_chats[userId].conversations.push(new_conversation);
      state.direct_chats[userId].current_conversation = new_conversation;
    },
    setCurrentConversation(state, action) {
      const userId = action.payload.userId;
      const conversation = action.payload.conversation;
      state.direct_chats[userId].current_conversation = conversation;
    },
    resetConversations(state, action) {
      const userId = action.payload.userId;
      if (
        state.direct_chats === undefined ||
        state.direct_chats[userId] === undefined
      ) {
        state.direct_chats = {};
        state.direct_chats[userId] = {
          conversations: [],
          current_conversation: null,
        };
      } else {
        state.direct_chats[userId].conversations = [];
        state.direct_chats[userId].current_conversation = null;
      }
    },
    addDirectMessage(state, action) {
      // state.direct_chats.current_messages.push(action.payload.message);
      const userId = action.payload.userId;
      const message = action.payload.message;
      const unread = action.payload.unread;
      const messageDividerSpan = action.payload.span || 60.0;
      const conversationId = action.payload.conversationId;
      const this_conversation = state.direct_chats[userId].conversations.find(
        (el) => el?._id === conversationId
      );
      // console.log("this_conversation", this_conversation);
      const start = moment(this_conversation?.latestMessageTime);
      const end = moment(message.created_at);
      const diffCreatedAt = moment.duration(end.diff(start)).asMinutes();
      if (diffCreatedAt > messageDividerSpan) {
        const dividerMsg = {
          type: "divider",
          message: moment(message.created_at).format("YYYY-MM-DD HH:mm"),
        };
        this_conversation.messages.push(dividerMsg);
      }

      this_conversation.messages.push(message);
      this_conversation.msg = message.message;
      this_conversation.latestMessageTime = message.created_at;
      if (unread) {
        this_conversation.unread++
      }

      state.direct_chats[userId].current_conversation = this_conversation;
      // if (message.outgoing) {
      //   state.direct_chats[userId].current_conversation = this_conversation;
      // }
    },
    deleteDirectMessage(state, action) {
      const userId = action.payload.userId;
      const conversationId = action.payload.conversationId;
      const messageId = action.payload.messageId;
      
      state.direct_chats[userId].conversations = state.direct_chats[
        userId
      ].conversations.map((el) => {
        if (el?._id !== conversationId) {
          return el;
        } else {
          const messageIdx = el.messages.findIndex((item) => {
            return item._id === messageId;
          });

          let msg = el.msg;
          if (messageIdx === el.messages.length - 1) {
            msg =
              el.messages.length === 1
                ? ""
                : el.messages[el.messages.length - 2].message;
          }
          const updatedConversation = {
            ...el,
            msg: msg,
            messages: [
              ...el.messages.slice(0, messageIdx),
              ...el.messages.slice(messageIdx + 1),
            ],
          };

          state.direct_chats[userId].current_conversation = updatedConversation;
          return updatedConversation
        }
      });
    },
    setMessagesViewed(state, action) {
      const conversationId = action.payload.conversationId;
      const userId = action.payload.userId;
      // const messageDividerSpan = action.payload.span || 60.0;
      state.direct_chats[userId].conversations = state.direct_chats[
        userId
      ].conversations.map((el) => {
        if (el?._id !== conversationId) {
          return el;
        } else {
          const updatedConversation = {
            ...el,
            unread: 0,
          }
          // const user = this_conversation.participants.find(
          //   (elm) => elm._id.toString() !== userId
          // );
          // const filteredMessages = el.latestMessageTime
          //   ? this_conversation?.messages?.filter(
          //       (message) => message.created_at > el.latestMessageTime
          //     )
          //   : this_conversation?.messages;

          // const formatted_messages = filteredMessages?.map((elm) => ({
          //   _id: elm._id,
          //   type: elm.type,
          //   subtype: elm.subtype,
          //   message: elm.text,
          //   reply: elm.reply,
          //   file: elm.file,
          //   incoming: elm.to === userId,
          //   outgoing: elm.from === userId,
          //   created_at: elm.created_at,
          // }));

          // const filtered_messages_divided = [];
          // if (formatted_messages?.length > 0) {
          //   for (let i = 0; i < formatted_messages.length - 1; i++) {
          //     filtered_messages_divided.push(formatted_messages[i]);
          //     // const diffCreatedAt = (formatted_messages[i+1].created_at - formatted_messages[i].created_at) / 1000
          //     const start = moment(formatted_messages[i].created_at);
          //     const end = moment(formatted_messages[i + 1].created_at);
          //     const diffCreatedAt = moment
          //       .duration(end.diff(start))
          //       .asMinutes();
          //     // console.log("setMessagesViewed diffCreatedAt", diffCreatedAt);
          //     if (diffCreatedAt > messageDividerSpan) {
          //       const dividerMsg = {
          //         type: "divider",
          //         message: moment(formatted_messages[i + 1].created_at).format(
          //           "YYYY-MM-DD HH:mm"
          //         ),
          //       };
          //       filtered_messages_divided.push(dividerMsg);
          //     }
          //   }
          //   filtered_messages_divided.push(
          //     formatted_messages[formatted_messages.length - 1]
          //   );
          // }
          // // const messages_sorted = formatted_messages.sort((a, b) => a.created_at - b.created_at)

          // console.log("filtered_messages_divided", filtered_messages_divided);

          // const updatedConversation = {
          //   ...el,
          //   messages:
          //     el.messages?.length > 0
          //       ? el.messages.concat(filtered_messages_divided)
          //       : filtered_messages_divided,
          //   latestMessageTime:
          //     formatted_messages.slice(-1)[0]?.created_at ||
          //     el.latestMessageTime,
          //   unread: 0,
          // };

          // console.log("setMessagesViewed", updatedConversation);
          state.direct_chats[userId].current_conversation = updatedConversation;
          return updatedConversation;
        }
      });
    },
    updateConversationStatus(state, action) {
      const conversationId = action.payload.conversationId;
      const userId = action.payload.userId;
      const status = action.payload.status;
      // const messageDividerSpan = action.payload.span || 60.0;
      state.direct_chats[userId].conversations = state.direct_chats[
        userId
      ].conversations.map((el) => {
        if (el?._id !== conversationId) {
          return el;
        } else {
          const updatedConversation = {
            ...el,
            online: status,
          }
          // state.direct_chats[userId].current_conversation = updatedConversation;
          return updatedConversation;
        }
      });
    },
    clearMessages(state, action) {
      const userId = action.payload.userId;
      const conversationId = action.payload.conversationId;
      const messageStart = action.payload.messageStart;
      state.direct_chats[userId].conversations = state.direct_chats[
        userId
      ].conversations.map((el) => {
        if (el?._id !== conversationId) {
          return el;
        } else {
          const updatedConversation = {
            ...el,
            messages: [],
            msg: "",
            latestMessageTime: messageStart,
          };
          state.direct_chats[userId].current_conversation = updatedConversation;
          return updatedConversation;
        }
      });
    },
    deleteConversation(state, action) {
      const userId = action.payload.userId;
      const conversationId = action.payload.conversationId;
      const messageStart = action.payload.messageStart;
      state.direct_chats[userId].conversations = state.direct_chats[
        userId
      ].conversations.map((el) => {
        if (el?._id !== conversationId) {
          return el;
        } else {
          const updatedConversation = {
            ...el,
            messages: [],
            msg: "",
            latestMessageTime: messageStart,
            active: false,
          };
          state.direct_chats[userId].current_conversation = updatedConversation;
          return updatedConversation;
        }
      });
    },
    pinConversation(state, action) {
      const userId = action.payload.userId;
      const conversationId = action.payload.conversationId;
      state.direct_chats[userId].conversations = state.direct_chats[
        userId
      ].conversations.map((el) => {
        if (el?._id !== conversationId) {
          return el;
        } else {
          const updatedConversation = { ...el, pinned: true };
          state.direct_chats[userId].current_conversation = updatedConversation;
          return updatedConversation;
        }
      });
    },
    unpinConversation(state, action) {
      const userId = action.payload.userId;
      const conversationId = action.payload.conversationId;
      state.direct_chats[userId].conversations = state.direct_chats[
        userId
      ].conversations.map((el) => {
        if (el?._id !== conversationId) {
          return el;
        } else {
          const updatedConversation = { ...el, pinned: false };
          state.direct_chats[userId].current_conversation = updatedConversation;
          return updatedConversation;
        }
      });
    },
  },
});

// Reducer
export const {
  updateDirectConversation,
  addDirectConversation,
  setCurrentConversation,
  resetConversations,
  addDirectMessage,
  deleteDirectMessage,
  setMessagesViewed,
  updateConversationStatus,
  clearMessages,
  deleteConversation,
  pinConversation,
  unpinConversation,
} = conversationSlice.actions;
export default conversationSlice.reducer;
