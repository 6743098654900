import PropTypes from "prop-types";
import { useMemo } from "react";
// @mui
import { CssBaseline } from "@mui/material";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
// hooks
import useSettings from "../../hooks/useSettings.js";
//
import palette from "./palette";
import typography from "./typography";
import breakpoints from "./breakpoints";
import componentsOverride from "./overrides";
import shadows, { customShadows } from "./shadows";

// ----------------------------------------------------------------------
export const Colors = {
    // primary: "#00897B",
    // secondary: "#80CBC4",
    // primary: "#00B0FF",
    // secondary: "#40C4FF",
    primary: "#00695C",
    secondary: "#00897B",
    background: "#E1F5FE",
    success: "#4CAF50",
    info: "#00a2ff",
    danger: "#FF5722",
    warning: "rgb(237, 108, 2)",
    dark: "#0e1b20",
    light: "#aaa",
    muted: "#abafb3",
    border: "#DDDFE1",
    inverse: "#2F3D4A",
    shaft: "#333",
    ///////////////
    // Grays
    ///////////////
    dim_grey: "#696969",
    dove_gray: "#d5d5d5",
    body_bg: "#f3f6f9",
    light_gray: "rgb(230,230,230)",
    ///////////////
    // Solid Color
    ///////////////
    white: "#fff",
    black: "#000",
    ///////////////
    // Other Color
    ///////////////
    purple: "#5f2c3e",
    light_purple: "#d1adcc",
    gray_blue_900: "#263238",
    gray_900: "#212121",
    gray_800: "#424242",
    gray_700: "#616161",
    gray_600: "#757575",
    gray_500: "#9E9E9E",
    gray_400: "#BDBDBD",
    gray_300: "#E0E0E0",
    gray_200: "#EEEEEE",
    gray_100: "#F5F5F5",
    gray_50: "#FAFAFA",
    teal_900: "#004D40",
    teal_800: "#00695C",
    teal_700: "#00796B",
    teal_600: "#00897B",
    teal_500: "#009688",
    teal_400: "#26A69A",
    teal_300: "#4DB6AC",
    teal_200: "#80CBC4",
    teal_100: "#B2DFDB",
    teal_50: "#E0F2F1",
    blue_light_900: "#01579B",
    blue_light_800: "#0277BD",
    blue_light_700: "#0288D1",
    blue_light_600: "#039BE5",
    blue_light_500: "#03A9F4",
    blue_light_400: "#29B6F6",
    blue_light_300: "#4FC3F7",
    blue_light_200: "#81D4FA",
    blue_light_100: "#B3E5FC",
    blue_light_50: "#E1F5FE",
    blue_900: "#0D47A1",
    blue_800: "#1565C0",
    blue_700: "#1976D2",
    blue_600: "#1E88E5",
    blue_500: "#2196F3",
    blue_400: "#42A5F5",
    blue_300: "#64B5F6",
    blue_200: "#90CAF9",
    blue_100: "#BBDEFB",
    blue_50: "#E3F2FD",
    blue_gray_900: "#263238",
    blue_gray_800: "#37474F",
    blue_gray_700: "#455A64",
    blue_gray_600: "#546E7A",
    blue_gray_500: "#607D8B",
    blue_gray_400: "#78909C",
    blue_gray_300: "#90A4AE",
    blue_gray_200: "#B0BEC5",
    blue_gray_100: "#CFD8DC",
    blue_gray_50: "#ECEFF1",
    red_900: "#B71C1C",
    red_800: "#C62828",
    red_700: "#D32F2F",
    red_600: "#E53935",
    red_500: "#F44336",
    red_400: "#EF5350",
    red_300: "#E57373",
    red_200: "#EF9A9A",
    red_100: "#FFCDD2",
    red_50: "#FFEBEE",
    amber_900: "#FF6F00",
    amber_800: "#FF8F00",
    amber_700: "#FFA000",
    amber_600: "#FFB300",
    amber_500: "#FFC107",
    amber_400: "#FFCA28",
    amber_300: "#FFD54F",
    amber_200: "#FFE082",
    amber_100: "#FFECB3",
    amber_50: "#FFF8E1",
    green_900: "#1B5E20",
    green_800: "#2E7D32",
    green_700: "#388E3C",
    green_600: "#43A047",
    green_500: "#4CAF50",
    green_400: "#66BB6A",
    green_300: "#81C784",
    green_200: "#A5D6A7",
    green_100: "#C8E6C9",
    green_50: "#E8F5E9",
    std_900: "#311B92",
    std_800: "#4527A0",
    std_700: "#512DA8",
    std_600: "#5E35B1",
    std_500: "#673AB7",
    std_400: "#7E57C2",
    std_300: "#9575CD",
    std_200: "#B39DDB",
    std_100: "#D1C4E9",
    std_50: "#EDE7F6",
};

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
  const { themeMode, themeDirection } = useSettings();

  const isLight = themeMode === "light";

  const themeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      typography,
      breakpoints,
      shape: { borderRadius: 5 },
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight, themeDirection]
  );

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
