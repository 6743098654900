import React from 'react'
import MenubarDesktop from './MenubarDesktop';

const Menubar = ({unreadMessages}) => {
    return (
        <>
            {/* {(!mobile) &&  */}
                <MenubarDesktop unreadMessages={unreadMessages} />
            {/* } */}
        </>
    )
}

export default Menubar