import { styled } from "@mui/material/styles";
import { Box, Typography, List, IconButton, Button } from "@mui/material";
import { lighten } from 'polished'
import { slideInBottom, slideInRight } from "../../animation";

export const Product = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    '&:hover': {
        cursor: 'pointer'
    },
    [theme.breakpoints.up('md')]: {
        position: 'relative',
    }
}))

export const ProductImage = styled('img')(({type = 'desktop', src, theme, bg}) => ({
    src: `url(${src})`,
    width: type === 'mobile' ? '120px' : '200px',
    height: type === 'mobile' ? '120px' : '200px',
    objectFit: 'cover',
    borderRadius: '10px',
    padding: '5px',
    background: bg,
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.22)',
    [theme.breakpoints.down('md')]: {
        // width: '80%',
        padding: '5px',
    }
}))

export const ProductActionButton = styled(IconButton)(({themecolor, theme}) => ({
    background: theme.palette.common.white,
    margin: 4,
    '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: themecolor,
    }
}))

export const ProductFavButton = styled(ProductActionButton, {
    shouldForwardProp: (prop) => prop !== 'isFav'
})(({ isFav, theme}) => ({
    color: isFav ? theme.palette.primary.main : theme.palette.grey['100'],
    [theme.breakpoints.up('md')]: {
        position: 'absolute',
        right: 15,
        top: 0,
    }
}))

export const ProductAddToCart = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'show'
})(({ show, theme, bgprimary, bgsecondary}) => ({
    width: '120px',
    fontSize: '12px',
    [theme.breakpoints.up('md')]: {
        position: 'absolute',
        bottom: '2%',
        width: '180px',
        padding: '10px 5px',
        animation: show && `${slideInBottom} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`,
    },
    background: bgsecondary,
    opacity: 0.9,
    '&:hover': {
        background: lighten(0.05, bgprimary)
    }
}))

export const ProductMetaWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 4,
}))

export const ProductMetaPriceWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    // overflow: 'hidden', 
    // whiteSpace: 'nowrap', 
    // textOverflow: 'ellipsis'
}))

export const ProductActionsWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'show'
})(({ show, theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: show ? 'visible' : 'none',
        position: 'absolute',
        right: 20,
        top: '10%',
        animation: show && `${slideInRight} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`,
    }
}))