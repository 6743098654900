import {
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import React from "react";
import { CaretLeft } from "phosphor-react";
import { FormattedMessage } from "react-intl";
import { URL_LOGIN } from "../../constants";
import NewPasswordForm from "./NewPasswordForm";

const NewPassword = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Stack
        width={mobile ? "100%" : tablet ? "60%" : "40%"}
        spacing={2}
        sx={{ my: 5, position: "relative" }}
      >
        <Typography variant="h3" paragraph>
          <FormattedMessage id='auth.reset.password' />
        </Typography>
        <Typography sx={{ color: "text.secondary", mb: 5 }}>
          <FormattedMessage id='auth.set.new.password' />
        </Typography>

        {/* New Password Form */}
        <NewPasswordForm />
        <Link
          component={RouterLink}
          to={URL_LOGIN}
          color="inherit"
          variant="subtitle2"
          sx={{
            mt: 3,
            mx: "auto",
            alignItems: "center",
            display: "inline-flex",
          }}
        >
          <CaretLeft />
          <FormattedMessage id='auth.return.signin' />
        </Link>
      </Stack>
    </>
  );
};

export default NewPassword;
