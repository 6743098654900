import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const PRIMARY = {
  lighter: '#cce2f8',
  light: '#66a7ea',
  main: '#1B6DC1',
  dark: '#16579a',
  darker: '#104174',
};
const SECONDARY = {
  lighter: '#C8FACD',
  light: '#5BE584',
  main: '#00695C',
  dark: '#007B55',
  darker: '#005249',
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
};
const WARNING = {
  lighter: '#f7c098',
  light: '#f3a065',
  main: '#D86411',
  dark: '#ad500e',
  darker: '#823c0a',
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
};

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const TEAL = {
    900: "#004D40",
    800: "#00695C",
    700: "#00796B",
    600: "#00897B",
    500: "#009688",
    400: "#26A69A",
    300: "#4DB6AC",
    200: "#80CBC4",
    100: "#B2DFDB",
    50: "#E0F2F1",
}

const BLUE = {
    900: "#0D47A1",
    800: "#1565C0",
    700: "#1976D2",
    600: "#1E88E5",
    500: "#2196F3",
    400: "#42A5F5",
    300: "#64B5F6",
    200: "#90CAF9",
    100: "#BBDEFB",
    50: "#E3F2FD",
}

const RED = {
    900: "#B71C1C",
    800: "#C62828",
    700: "#D32F2F",
    600: "#E53935",
    500: "#F44336",
    400: "#EF5350",
    300: "#E57373",
    200: "#EF9A9A",
    100: "#FFCDD2",
    50: "#FFEBEE",
}

const AMBER = {
    900: "#FF6F00",
    800: "#FF8F00",
    700: "#FFA000",
    600: "#FFB300",
    500: "#FFC107",
    400: "#FFCA28",
    300: "#FFD54F",
    200: "#FFE082",
    100: "#FFECB3",
    50: "#FFF8E1",
}

const GREEN = {
    900: "#1B5E20",
    800: "#2E7D32",
    700: "#388E3C",
    600: "#43A047",
    500: "#4CAF50",
    400: "#66BB6A",
    300: "#81C784",
    200: "#A5D6A7",
    100: "#C8E6C9",
    50: "#E8F5E9",
}

const BLUE_GREY = {
    900: "#263238",
    800: "#37474F",
    700: "#455A64",
    600: "#546E7A",
    500: "#607D8B",
    400: "#78909C",
    300: "#90A4AE",
    200: "#B0BEC5",
    100: "#CFD8DC",
    50: "#ECEFF1",
}

const BLUE_LIGHT = {
    900: "#01579B",
    800: "#0277BD",
    700: "#0288D1",
    600: "#039BE5",
    500: "#03A9F4",
    400: "#29B6F6",
    300: "#4FC3F7",
    200: "#81D4FA",
    100: "#B3E5FC",
    50: "#E1F5FE",
}

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  teal: TEAL,
  red: RED,
  green: GREEN,
  blue: BLUE,
  amber: AMBER,
  blueGrey: BLUE_GREY,
  blueLight: BLUE_LIGHT,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: { primary: '#1B1B1B', secondary: GREY[600], disabled: GREY[500] },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
    background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action },
  },
};




export default palette;
