import {
  Stack,
  Typography,
  Link,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import React from "react";
import SigninForm from "./SigninForm";
import AuthSocial from "./AuthSocial";
import { FormattedMessage } from "react-intl";
import { URL_REGISTER } from "../../constants";

const Signin = () => {
  const theme = useTheme();
  const navigate = useNavigate()
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Stack
        width={mobile ? "100%" : tablet ? "60%" : "40%"}
        spacing={2}
        sx={{ my: 5, position: "relative" }}
      >
        <Typography variant="h4">
          <FormattedMessage id="app.login" />
        </Typography>
        <Stack direction={"row"} spacing={0.5}>
          <Typography variant="body2">
            <FormattedMessage id="auth.new.user" />
          </Typography>
          <Link onClick={() => navigate(URL_REGISTER)} component={Typography} variant="body2" sx={{textTransform: 'capitalize', cursor: 'pointer'}}>
            <FormattedMessage id="auth.create.account" />
          </Link>
        </Stack>
        {/* Login Form */}
        <SigninForm />
        {/* Auth Social */}
        {/* <AuthSocial /> */}
      </Stack>
    </>
  );
};

export default Signin;
