import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useUpdateUserMutation } from "../../apis/auth";
import { setUser } from "../../redux/user";
import { useUIContext } from "../../context/ui";
import { LOCALES } from "../../i18n/locales";
import { useAsyncFn } from "../../hooks/useAsync";
import { getSignedUrl, uploadFileToSignedUrl } from "../../apis/s3";
import { S3_DIRECTORY_USERS, SEVERITY_WARNING } from "../../constants";
import { dataURIToBlob, resizeImage } from "../../utils/resizeImage";
import useImage from "../../hooks/useImage";
import useAvatar from "../../hooks/useAvatar";

const Profile = () => {
  const theme = useTheme();
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { setShowBackdropCallback, setAlertObj, setShowAlert } = useUIContext();
  const locale =
    useSelector((state) => {
      return state.persisted.global.locale;
    })?.value || LOCALES.ENGLISH;
  const user = useSelector((state) => state.user.currentUser)?.data;
  const userToken = useSelector((state) => state.persisted.global.userToken);
  const config = useSelector((state) => state.config)?.config;
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();

  const [referCodeCopied, setReferCodeCopied] = useState(false);
  const [imageUrl, setImageUrl] = useState(user?.avatar);
  const [imageDirty, setImageDirty] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileUploaded, setFileUploaded] = useState("");
  const imageFunc = useImage()
  const avatarFunc = useAvatar()
  useEffect(() => {
    setShowBackdropCallback(isUpdating);
  }, [isUpdating]);

  const showName = useMemo(() => {
    return user
      ? user?.firstName?.length > 0
        ? user?.firstName
        : user?.username
      : "";
  }, [user]);

  const initialValues = useMemo(
    () => ({
      firstName: user && user.firstName ? user.firstName : "",
      lastName: user && user.lastName ? user.lastName : "",
      username: user && user.username ? user.username : "",
      email: user && user.email ? user.email : "",
      description: user && user.description ? user.description : "",
      // phone: user && user.phone ? user.phone : '',
    }),
    [user?.firstName, user?.lastName, user?.username, user?.email]
  );

  const checkoutSchema = yup.object().shape({
    firstName: yup.string().required("error.required"),
    lastName: yup.string().required("error.required"),
    username: yup.string().required("error.required"),
    email: yup.string().email("error.invalid.email").required("error.required"),
    description: yup.string(),
    // phone: yup
    // .string()
    // .matches(REGEX_PHONE, "error.invalid.phone")
    // .required("error.required"),
  });

  const handleFormSubmit = async (values) => {
    let submitObj = {
      username: values.username,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      description: values.description,
      // phone: values.phone,
    };
    // console.log('submitObj', submitObj)
    try {
      if (
        window.confirm(intl.formatMessage({ id: "window.ready.submit" })) ===
        true
      ) {
        const params = {
          id: userToken?._id,
          token: userToken?.token,
          body: submitObj,
        };

        const result = await updateUser(params);
        console.log("*** result", result);
        if (result?.error && result?.error?.status !== 200) {
          window.alert(result?.error?.data?.message[locale]);
        } else if (result?.data?.status === "success") {
          // console.log('result?.data?.message', result?.data?.message)
          window.alert(result?.data?.message[locale]);
          const userInfoWithToken = {
            data: {
              ...result?.data?.data?.user,
              accessToken: userToken?.token,
            },
          };
          // console.log('userInfoWithToken', userInfoWithToken)
          dispatch(setUser(userInfoWithToken));
        }
      }
    } catch (err) {
      window.alert(intl.formatMessage({ id: "window.operation.failed" }));
    }
  };

  useEffect(() => {
    if (selectedImage) {
      // setImageData(selectedImage)
      const newUrl = URL.createObjectURL(selectedImage);
      setImageUrl(newUrl);
      
    } else if (user?.avatar) {
      setImageUrl(
        avatarFunc({image: user?.avatar})
      );
    }
  }, [selectedImage, user?.avatar, avatarFunc]);

  const { execute: getSignedUrlFn, loading: isQueryingSignedUrl } =
    useAsyncFn(getSignedUrl);
  const { execute: uploadFileFn, loading: isUploadingFile } = useAsyncFn(
    uploadFileToSignedUrl
  );

  useEffect(() => {
    setShowBackdropCallback(
      isUpdating || isQueryingSignedUrl || isUploadingFile
    );
  }, [isUpdating, isQueryingSignedUrl, isUploadingFile]);

  const handleSelectImage = (image) => {
    setSelectedImage(image);
    if (image) {
      setImageDirty(true);
    }
  };

  const uploadAvatar = async () => {
    try {
      if (!imageDirty) {
        setAlertObj({
          severity: SEVERITY_WARNING,
          content: intl.formatMessage({ id: "auth.select.image.first" }),
          duration: null,
        });
        setShowAlert(true);
      } else {
        if (
          window.confirm(intl.formatMessage({ id: "window.ready.submit" })) ===
          true
        ) {
          setIsUploading(true);
          let logoUploaded = null;
          if (imageDirty) {
            const resizedImage = await resizeImage(selectedImage);
            const newFileBlob = await dataURIToBlob(resizedImage);
            const newFile = new File([newFileBlob], "image.jpeg", {
              type: newFileBlob.type,
            });
            setSelectedImage(newFile);

            const extension = newFile?.name?.slice(
              newFile?.name?.lastIndexOf(".")
            );
            const signedUrlParam = {
              token: userToken?.token,
              directory: S3_DIRECTORY_USERS,
              key:
                user._id +
                "/" +
                (user?.username.replace(/\s/g, "_") + "_avatar") +
                extension,
              content_type: newFile.type,
            };

            console.log("signedUrlParam", signedUrlParam);
            getSignedUrlFn(signedUrlParam).then((response) => {
              // console.log("response", response);
              const signedUrl = response.data.signedUrl;
              const fileLink = response.data.fileLink;
              console.log("signedUrl", signedUrl);
              console.log("fileLink", fileLink);
              uploadFileFn({
                signedUrl: signedUrl,
                file: newFile,
                contentType: newFile.type,
                onProgress: (progress) => {
                  // const currentProgress = (progress.loaded / progress.total) * 100;
                  // console.log('currentProgress', currentProgress)
                  setUploadProgress(1);
                  // console.log('onProgress...')
                },
                onComplete: () => {
                  // urlList.push(fileLink);
                  setFileUploaded(
                    fileLink.slice(process.env.REACT_APP_S3_BUCKET_URL)
                  );
                  setUploadProgress(0);
                  setImageDirty(false)
                  console.log("onComplete...");
                  // if (onSuccess) {
                  //   onSuccess && onSuccess();
                  // }
                },
              }).finally(() => {});
            });

            //   const response = await s3ClientUsers.uploadFile(
            //     newFile,
            //     user?.username.replace(/\s/g, "_") +
            //       "_avatar_" +
            //       new Date().getTime()
            //   );
            //   logoUploaded = response.key;
          }
        }
      }
    } catch (err) {
      setIsUploading(false);
      console.log("err", err);
      window.alert(intl.formatMessage({ id: "error.upload.failed" }));
    }
  };

  useEffect(async () => {
    if (fileUploaded?.length > 0) {
      const submitObj = {
        avatar: fileUploaded,
      };
      const userInfo = await updateUser({
        id: user?._id,
        token: userToken?.token,
        body: submitObj,
      });

      setIsUploading(false);
      setFileUploaded("");
      if (userInfo.error) {
        window.alert(intl.formatMessage({ id: "window.operation.failed" }));
      } else {
        window.alert(intl.formatMessage({ id: "window.operation.successful" }));
        const userInfoWithToken = {
          data: { ...userInfo?.data?.data?.user, accessToken: userToken?.token },
        };
        console.log("userInfoWithToken", userInfoWithToken);
        dispatch(setUser(userInfoWithToken));
      }
    }
  }, [fileUploaded, dispatch]);

  return (
    <Stack
      width="100%"
      justifyContent="center"
      alignItems={mobile ? "center" : "flex-start"}
      p={3}
      spacing={4}
    >
      {user?.referrer && (
        <Stack
          direction={"column"}
          alignItems={mobile ? "center" : "flex-start"}
          spacing={2}
        >
          <Box>
            <Typography
              variant="h5"
              color={theme.palette.primary.main}
              fontWeight="bold"
            >
              <FormattedMessage id="auth.referrer" />
            </Typography>
          </Box>
          <Stack
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            spacing={2}
          >
            <Typography variant="h6" color={theme.palette.info.main}>
              {locale === LOCALES.ENGLISH
                ? `${user?.referrer?.firstName} ${user?.referrer?.lastName}`
                : `${user?.referrer?.lastName} ${user?.referrer?.firstName}`}
            </Typography>
          </Stack>
        </Stack>
      )}
      {/* {user?.referCode && (
        <Stack
          direction={"column"}
          alignItems={mobile ? "center" : "flex-start"}
          spacing={2}
        >
          <Stack direction={mobile ? 'column' : 'row'} justifyContent={'flex-start'} alignItems={'center'} spacing={mobile ? 1 : 2}>
            <Typography
              variant="h5"
              color={theme.palette.primary.main}
              fontWeight="bold"
            >
              <FormattedMessage id="auth.refer.code" />
            </Typography>
            
          </Stack>

          <Stack
            direction={mobile ? "column" : 'row'}
            justifyContent={"flex-start"}
            alignItems={"center"}
            spacing={2}
          >
            <Tooltip
              title={
                !referCodeCopied ? (
                  <FormattedMessage id="tooltip.click.copy" />
                ) : (
                  <FormattedMessage id="tooltip.copied" />
                )
              }
              enterTouchDelay={0}
              arrow
            >
              <Link
                variant={'inherit'}
                color={theme.palette.info.main}
                sx={{ cursor: "pointer", fontSize: "1.2rem" }}
                onClick={() => {
                  navigator.clipboard.writeText(user?.referCode);
                  setReferCodeCopied(true);
                }}
              >
                {user?.referCode}
              </Link>
            </Tooltip>
            <Button variant='contained' size="small" onClick={() => {
                navigator.clipboard.writeText(user?.referCode);
                setReferCodeCopied(true);
            }} sx={{
                bgcolor: theme.palette.primary.main,
                '&:hover': {
                    bgcolor: theme.palette.primary.main,
                }
            }}>
              <FormattedMessage id="tooltip.click.copy" />
            </Button>
          </Stack>
          <Box width="100%" textAlign={mobile ? "center" : "left"}>
            <Typography variant="h6" color="text.secondary">
              {intl.formatMessage(
                { id: "profile.use.refer.code" },
                { discount: Number(config?.couponReferAmount)?.toFixed(2) }
              )}
            </Typography>
          </Box>
        </Stack>
      )} */}
      <Box>
        <Typography
          variant="h5"
          color={theme.palette.primary.main}
          fontWeight="bold"
        >
          <FormattedMessage id="auth.profile" />
        </Typography>
      </Box>
      <Stack
        direction={mobile ? "column" : "row"}
        justifyContent={"start"}
        alignItems={"center"}
        spacing={3}
      >
        <Box>
          <input
            type="file"
            accept="image/*"
            id={`select-image`}
            style={{ display: "none" }}
            onChange={(e) => handleSelectImage(e.target.files[0])}
          />
          <label htmlFor={`select-image`}>
            <Button
              variant="contained"
              sx={{
                width: "110px",
                height: "110px",
                position: "relative",
                borderRadius: "50%",
              }}
              component="span"
            >
              <Avatar
                src={imageUrl}
                // alt={user?.username}
                sx={{ borderRadius: "50%", width: "120px", height: "120px" }}
              />
            </Button>
          </label>
        </Box>
        <Button
          variant="outlined"
          sx={{
            // mt: 2,
            fontSize: "0.8em",
            textTransform: "capitalize",
          }}
          onClick={() => uploadAvatar()}
        >
          <FormattedMessage id="auth.upload.avatar" />
        </Button>
      </Stack>

      <Box width={mobile ? "100%" : tablet ? "60%" : "40%"}>
        <Formik
          onSubmit={handleFormSubmit}
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
          style={{ border: "1px solid red" }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Stack alignItems="flex-start" width="100%">
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label={intl.formatMessage({ id: "auth.first.name" })}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  name="firstName"
                  error={!!touched.firstName && !!errors.firstName}
                  helperText={
                    touched.firstName &&
                    errors.firstName &&
                    intl.formatMessage({ id: errors.firstName })
                  }
                  sx={{ mb: 3 }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label={intl.formatMessage({ id: "auth.last.name" })}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  name="lastName"
                  error={!!touched.lastName && !!errors.lastName}
                  helperText={
                    touched.lastName &&
                    errors.lastName &&
                    intl.formatMessage({ id: errors.lastName })
                  }
                  sx={{ mb: 3 }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label={intl.formatMessage({ id: "auth.username" })}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.username}
                  name="username"
                  error={!!touched.username && !!errors.username}
                  helperText={
                    touched.username &&
                    errors.username &&
                    intl.formatMessage({ id: errors.username })
                  }
                  sx={{ mb: 3 }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label={intl.formatMessage({ id: "auth.email" })}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={
                    touched.email &&
                    errors.email &&
                    intl.formatMessage({ id: errors.email })
                  }
                  sx={{ mb: 3 }}
                />
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  variant="outlined"
                  type="text"
                  label={intl.formatMessage({ id: "auth.description" })}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  name="description"
                  sx={{ mb: 3 }}
                />
                <Button
                  disabled={
                    !!values.email === false ||
                    !!values.firstName === false ||
                    !!values.lastName === false ||
                    // !!values.username === false ||
                    // !!values.phone === false ||
                    !!errors.email ||
                    !!errors.firstName ||
                    !!errors.lastName ||
                    // !!errors.username ||
                    // !!errors.phone ||
                    isUpdating
                  }
                  sx={{
                    width: "100%",
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    // color: theme.palette.background.alt,
                    pt: 1.5,
                    pb: 1.5,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.light,
                    },
                    "&:disabled": {
                      backgroundColor: theme.palette.grey[300],
                      color: theme.palette.grey[800],
                      cursor: "not-allowed",
                      pointerEvents: "none",
                    },
                  }}
                  type="submit"
                >
                  <FormattedMessage id="general.update" />
                </Button>
              </Stack>
            </form>
          )}
        </Formik>
      </Box>
    </Stack>
  );
};

export default Profile;
