import { Box, Button, Dialog, DialogContent, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, {useEffect, useMemo, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ModalTitle } from '../../styles/modal'
import { FormattedMessage, useIntl } from 'react-intl'
import { GridCloseIcon } from '@mui/x-data-grid'
import { LOCALES } from '../../i18n/locales'
import PaymentSelect from '../paymentSelect/PaymentSelect'
import { useUpdateOrderByIdMutation } from '../../apis/order'
import { useUIContext } from '../../context/ui'
import { usePayStripeMutation } from '../../apis/cart'
import { useNavigate } from 'react-router-dom'
import { EMPTY_CART, NULL_USER, SEVERITY_SUCCESS, URL_ORDERS } from '../../constants';
import StripeCheckout from 'react-stripe-checkout'

const Payment = ({open, onClose, unpaidOrder, tablet, mobile}) => {
    const theme = useTheme()
    const intl = useIntl()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [localSelected, setLocaleSelected] = useState(LOCALES.ENGLISH)
    const locale = useSelector((state) => state.persisted.global.locale)?.value || LOCALES.ENGLISH;
    const carts = useSelector((state) => state.persisted.global.carts)
    const user = useSelector(state => state.user.currentUser)?.data; 
    const userToken = useSelector((state) => state.persisted.global.userToken); 
    const [payment, setPayment] = useState(unpaidOrder?.payment)
    const [stripeToken, setStripeToken] = useState(null);
    const { setShowBackdropCallback, setShowAlert, setAlertObj } = useUIContext()

    const [
        payStripe, 
        { isLoading: isPaying }, 
    ] = usePayStripeMutation() 

    const [
        updateOrderById, 
        { isLoading: isUpdatingOrder }, 
    ] = useUpdateOrderByIdMutation() 

    useEffect(() => {
        setShowBackdropCallback(isPaying || isUpdatingOrder)
    }, [isPaying, isUpdatingOrder])

    const onToken = (token) => {
        setStripeToken(token);
    };

    const stripeLocale = useMemo(() => {
        return locale?.length > 0 ? locale.split('-')[0] : 'en'
    }, [locale])

    const newTotal = useMemo(() => {
        return unpaidOrder.subtotal + unpaidOrder.tax + unpaidOrder.deliveryFee + unpaidOrder.serviceFee
    }, [unpaidOrder])

    const placeOrderWithEmt = async () => {
        const submitObj = {
            payment: 'emt',
            status: 'unpaid',
            noEmail: 'true',
        }          

        const orderInfo = await updateOrderById({
            id: unpaidOrder?._id,
            token: userToken?.token,
            body: submitObj,
        })

        if (orderInfo.error) {
            window.alert(intl.formatMessage({id: 'shop.order.update.failed'}))
        } else {
            // dispatch(setCarts(user ? {...carts, [user?._id]: EMPTY_CART} : {...carts, [NULL_USER]: EMPTY_CART}))
            // dispatch(setUnpaidOrder(null))
            // navigate(URL_ORDERS)
            // showOrderConfirmationDialog()
            // setAlertObj({
            //     severity: SEVERITY_SUCCESS,
            //     content: intl.formatMessage({id: 'shop.order.success'}),
            //     duration: null,
            // })
            // setShowAlert(true)
        }

        onClose()
    }

    useEffect(() => {
        const makeRequest = async () => {
          try {
            const res = await payStripe({
                token: userToken?.token,
                body: {
                    tokenId: stripeToken.id,
                    amount: parseInt((Number(unpaidOrder?.total)) * 100),
                    client: process.env.REACT_APP_CURRENT_CLIENT,
                }
            });

            // console.log('res', res)
            if (res.error) {
                window.alert(intl.formatMessage({id: 'shop.payment.failed'}))
            } else {
                const submitObj = {
                    status: 'paid',
                    payment: 'stripe',
                    currrentStatus: unpaidOrder?.status,
                    // noEmail: 'true',
                }          

                const orderInfo = await updateOrderById({
                    id: unpaidOrder?._id,
                    token: userToken?.token,
                    body: submitObj,
                })

                if (orderInfo.error) {
                    window.alert(intl.formatMessage({id: 'shop.order.update.failed'}))
                } else {
                    // dispatch(setCarts(user ? {...carts, [user?._id]: EMPTY_CART} : {...carts, [NULL_USER]: EMPTY_CART}))
                    // dispatch(setUnpaidOrder(null))
                    // navigate(URL_ORDERS)
                    // showOrderConfirmationDialog()
                    setAlertObj({
                        severity: SEVERITY_SUCCESS,
                        content: intl.formatMessage({id: 'shop.payment.success'}),
                        duration: null,
                    })
                    setShowAlert(true)
                }

                onClose()
            }
            
          } catch {
          }
        };

        if (unpaidOrder?.total > 0 && payment === 'stripe' && stripeToken) {
            makeRequest()
        }
    }, [stripeToken]);


  return (
    <Dialog 
        // TransitionComponent={slideTransition} 
        variant='permanent'
        open={open} 
        fullWidth
        maxWidth="sm">
        <ModalTitle sx={{backgroundColor: theme.palette.primary.main}}>
            <Box display='flex' 
                alignItems='center' 
                justifyContent='space-between'
                fontFamily={["Martel Sans", "PT Sans", 'Fira Sans', "adobe-caslon-pro", "Roboto", "cursive"].join(",")}>
                <FormattedMessage id='commercial.payment' />
                <IconButton sx={{color: theme.palette.common.white}} onClick={onClose}>
                    <GridCloseIcon />
                </IconButton>
            </Box>
        </ModalTitle>
        <DialogContent>
            <Stack width='100%' display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Box width={mobile ? '100%' : '75%'} mt={3}>
                <PaymentSelect order={unpaidOrder} payment={payment} setPayment={setPayment} />
                </Box>
                {payment === 'emt' && (
                    <Button variant='contained' sx={{backgroundColor: theme.palette.primary.main}} onClick={() => placeOrderWithEmt()}>
                        <FormattedMessage id='app.ok' />
                    </Button>
                )}
                {payment === 'stripe' && (<StripeCheckout
                    name="BIRTOO"
                    // image="https://test-birtoo.s3-ca-central-1.amazonaws.com/vendors/Honest_Fresh_Produce_logo.jpeg"
                    currency='CAD'
                    locale={stripeLocale}
                    email={user?.email}
                    alipay={false}
                    bitcoin={false}
                    billingAddress
                    allowRememberMe={true}
                    // shippingAddress
                    description={`Your order total is $${newTotal?.toFixed(2)}`}
                    amount={newTotal * 100}
                    token={onToken}
                    stripeKey={process.env.REACT_APP_STRIPE}
                >
                <Button variant='contained' sx={{backgroundColor: theme.palette.primary.main}}>
                    <FormattedMessage id='app.ok' />
                </Button>
                </StripeCheckout>)}
            </Stack>
        </DialogContent>
    </Dialog>
  )
}

export default Payment