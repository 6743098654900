import React, { useEffect, useState } from "react";
import { REGEX_JSON } from "../../constants";
import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { ImageCovered } from "../../components/imageCover/ImageCover";
import useImage from "../../hooks/useImage";

const Universities = ({ data, mobile, locale }) => {
  const theme = useTheme();
  const imageFunc = useImage()
  const [universities, setUniversities] = useState([]);

  useEffect(() => {
    if (data && data?.match(REGEX_JSON)) {
      const jsonData = JSON.parse(data);
      setUniversities(jsonData);
    }
  }, [data]);

//   console.log("Universities", universities);
  return (
    <Grid
      container
      //   spacing={{md: 1}}
      // columns={{xs: 4, sm: 8, md: 12}}
      justifyContent="center"
      width={mobile ? "100%" : "80%"}
      p={3}
    >
      {universities?.map((university) => (
        <Grid item xs={12} md={6} lg={4} key={university.title}>
          <Stack
            justifyContent={"start"}
            alignItems={"center"}
            spacing={3}
            px={2}
            py={5}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: "20px", fontWeight: 600, textAlign: "center" }}
            >
              {university?.title}
            </Typography>
            <Stack width="100%" alignItems={"center"}>
              <ImageCovered
                src={imageFunc({image: university?.logo})}
                sx={{ width: "auto", height: "150px" }}
                onClick={() => {}}
              />
            </Stack>
            <Stack width="100%" alignItems={"center"} px={3}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "18px",
                  textAlign: "left",
                  color: theme.palette.grey["800"],
                  lineHeight: 1.8,
                }}
              >
                {university?.description[locale]}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};

export default Universities;
