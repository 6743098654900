import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ModalButton, ModalTitle } from "../../styles/modal";
import { FormattedMessage, useIntl } from "react-intl";
import { socket } from "../../socket";

const AddContact = ({ open, onClose, fromUser, toUser }) => {
  const theme = useTheme();
  const intl = useIntl();
  const [greeting, setGreeting] = useState("");

  const handleAddContact = async () => {
    console.log("fromUser", fromUser);
    console.log("toUser", toUser);
    const request = { to: toUser.id, from: fromUser._id, greeting: greeting };
    
    socket.emit(
      "friend_request",
      request,
      () => {
        alert(intl.formatMessage({ id: "chat.request.sent" }));
      }
    );
    onClose()
  };

  return (
    <Dialog
      // TransitionComponent={slideTransition}
      variant="permanent"
      open={open}
      fullWidth
      maxWidth="xs"
    >
      <ModalTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          fontFamily={[
            "Martel Sans",
            "PT Sans",
            "Fira Sans",
            "adobe-caslon-pro",
            "Roboto",
            "cursive",
          ].join(",")}
        >
          <FormattedMessage id="chat.add.contact" />
          <IconButton
            sx={{ color: theme.palette.common.white }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </ModalTitle>
      <DialogContent>
        <Stack
          width="100%"
          justifyContent={"start"}
          alignItems={"center"}
          spacing={2}
          p={2}
        >
          <TextField
            variant="standard"
            multiline={true}
            rows={3}
            sx={{
              width: "100%",
              borderBottom: `1px solid ${theme.palette.grey["200"]}`,
            }}
            value={greeting}
            onChange={(e) => setGreeting(e.target.value)}
            placeholder={intl.formatMessage({
              id: "chat.add.contact.placeholder",
            })}
            InputProps={{ disableUnderline: true }}
          />
          <ModalButton
            variant="contained"
            disabled={greeting?.length === 0}
            onClick={() => handleAddContact()}
          >
            <FormattedMessage id="general.submit" />
          </ModalButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AddContact;
