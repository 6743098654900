import { createRef, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import Timeline from "./Timeline";
import ReplyMsg from "./ReplyMsg";
import MediaMsg from "./MediaMsg";
import DocMsg from "./DocMsg";
import LinkMsg from "./LinkMsg";
import TextMsg from "./TextMsg";
import useAvatar from "../../hooks/useAvatar";

export const Conversation = ({ user, mobile, menu, current_conversation }) => {
  const dispatch = useDispatch();

  const { room_id, conversation_location } = useSelector(
    (state) => state.chatroom
  );
  const config = useSelector((state) => state.config)?.config;

  const [messages, setMessages] = useState([]);
  const [searchedMessageId, setSearchedMessageId] = useState(-1);
  const [messageLoadIdx, setMessageLoadIdx] = useState(1);
  const messagesEachLoad = config["defaultMessagesEachLoad"]
    ? parseInt(config["defaultMessagesEachLoad"])
    : 5;

  const ref = useRef();

  const avatarFunc = useAvatar()

  useEffect(() => {
    const handleScroll = (e) => {
      // console.log("scroll e", e?.target?.scrollTop, messageLoadIdx);
      if (e?.target?.scrollTop === 0) {
        setMessageLoadIdx((prev) => prev + 1);
      }
    };

    ref.current?.addEventListener("scroll", handleScroll);
    return () => ref.current?.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    ref.current.scrollTop = ref.current.scrollHeight;
  }, [messages?.length])

  useEffect(() => {
    // console.log("conversation_location", conversation_location);
    if (
      conversation_location &&
      conversation_location?.conversationId?.length > 0 &&
      conversation_location?.conversationId === current_conversation?._id &&
      conversation_location?.messageIndex >= 0
    ) {
      let idx = current_conversation?.messages?.length - 1;
      let loadIdx = 0;
      while (idx >= conversation_location?.messageIndex) {
        idx = idx - messagesEachLoad;
        loadIdx++;
      }

      setMessageLoadIdx(loadIdx);
      setSearchedMessageId(
        current_conversation?.messages[conversation_location?.messageIndex]._id
      );
      // setSearchedMessage(current_conversation?.messages[conversation_location?.messageIndex])
    }
  }, [conversation_location]);

  useEffect(() => {
    // console.log('current_conversation', current_conversation, messageLoadIdx)
    // let element = document.getElementById("conversation_container");
    // element.scrollTop = element.scrollHeight;
    // console.log('element', element)
    setMessages(
      current_conversation?.messages?.length > messagesEachLoad * messageLoadIdx
        ? current_conversation?.messages
        ?.slice(
          current_conversation?.messages?.length -
            messageLoadIdx * messagesEachLoad
        )
        : current_conversation?.messages
    );
  }, [current_conversation, messageLoadIdx]);

  
  useEffect(() => {
    let element = document.getElementById(searchedMessageId);
    if (element) {
      element?.scrollIntoView({
        behavior: "instant",
        inline: "nearest",
        block: "nearest",
      });
    }
  }, [messages, searchedMessageId]);

  return (
    <Box
      ref={ref}
      p={mobile ? 1 : 3}
      height="100%"
      maxHeight={"600px"}
      sx={{ overflow: "auto" }}
    >
      <Stack spacing={3}>
        {messages?.map((el, idx) => {
          switch (el.type) {
            case "divider":
              return (
                // Timeline
                <Timeline el={el} key={idx} mobile={mobile ? "yes" : "no"} />
              );
            case "Reply":
              return (
                //  ReplyMessage
                <ReplyMsg
                  el={el}
                  avatarFunc={avatarFunc}
                  searched={el._id === searchedMessageId}
                  menu={menu}
                  mobile={mobile ? "yes" : "no"}
                  user={el.incoming ? current_conversation : user}
                  conversation={current_conversation}
                  key={idx}
                />
              );
            case "Msg":
              switch (el.subtype) {
                case "Media":
                  return (
                    // Media Message
                    <MediaMsg
                      el={el}
                      avatarFunc={avatarFunc}
                      searched={el._id === searchedMessageId}
                      menu={menu}
                      key={idx}
                      mobile={mobile ? "yes" : "no"}
                      user={el.incoming ? current_conversation : user}
                      conversation={current_conversation}
                    />
                  );

                case "Doc":
                  return (
                    // Doc Message
                    <DocMsg
                      el={el}
                      avatarFunc={avatarFunc}
                      searched={el._id === searchedMessageId}
                      menu={menu}
                      key={idx}
                      mobile={mobile ? "yes" : "no"}
                      user={el.incoming ? current_conversation : user}
                      conversation={current_conversation}
                    />
                  );
                case "Link":
                  return (
                    //  Link Message
                    <LinkMsg
                      el={el}
                      avatarFunc={avatarFunc}
                      searched={el._id === searchedMessageId}
                      menu={menu}
                      key={idx}
                      mobile={mobile ? "yes" : "no"}
                      user={el.incoming ? current_conversation : user}
                      conversation={current_conversation}
                    />
                  );
                default:
                  return (
                    // Text Message
                    <TextMsg
                      el={el}
                      avatarFunc={avatarFunc}
                      searched={el._id === searchedMessageId}
                      menu={menu}
                      mobile={mobile ? "yes" : "no"}
                      user={el.incoming ? current_conversation : user}
                      conversation={current_conversation}
                      key={idx}
                    />
                  );
              }
            default:
              return <Box key={idx}></Box>;
          }
        })}
      </Stack>
    </Box>
  );
};
