import React from "react";
import { Stack, Typography, Avatar, useTheme } from "@mui/material";
import moment from "moment";
import { ChatBubble, ChatText } from "./ChatBubble";
import MessageOption from "./MessageOption";

const TextBubble = ({ el, mobile, searched }) => {
  const theme = useTheme();
  return (
    <ChatBubble
      mobile={mobile}
      incoming={el.incoming ? "yes" : "no"}
      bgcolor={!searched ? (el.incoming ? "lightyellow" : theme.palette.green["100"]) : theme.palette.amber['900']}
    >
      <ChatText>
        <Typography
          variant="body2"
          sx={{ width: "100%" }}
          // color={el.incoming ? theme.palette.text : "#fff"}
          color={theme.palette.text}
        >
          {el.message}
        </Typography>
      </ChatText>
      <Stack
        direction={"row"}
        justifyContent={"start"}
        alignItems={"center"}
        px={"10px"}
      >
        <Typography
          variant="caption"
          // color={el.incoming ? theme.palette.text : "#fff"}
          color={theme.palette.grey["600"]}
        >
          {moment(new Date(el.created_at)).format("HH:mm")}
        </Typography>
      </Stack>
    </ChatBubble>
  );
};

const TextMsg = ({ el, avatarFunc, searched, menu, user, conversation, mobile }) => {
  // console.log('TextMsg', el?._id, searched)
  return (
    <div id={el._id}>
      {el.incoming && (
        <Stack
          width={"100%"}
          direction="row"
          justifyContent={el.incoming ? "start" : "end"}
          spacing={3}
        >
          <Avatar
            // alt={user?.firstName}
            src={
              avatarFunc({image: user?.avatar})
            }
          />
          <Stack
            width={"100%"}
            direction="row"
            justifyContent={el.incoming ? "start" : "end"}
            spacing={1}
          >
            <TextBubble el={el} mobile={mobile} searched={searched} />
            {menu && (
              <MessageOption
                incoming={el.incoming}
                message={el}
                conversation={conversation}
                user={user}
              />
            )}
          </Stack>
        </Stack>
      )}
      {!el.incoming && (
        <Stack
          width={"100%"}
          direction="row"
          justifyContent={el.incoming ? "start" : "end"}
          spacing={3}
        >
          <Stack
            width={"100%"}
            direction="row"
            justifyContent={el.incoming ? "start" : "end"}
            spacing={1}
          >
            {menu && (
              <MessageOption
                incoming={el.incoming}
                message={el}
                conversation={conversation}
                user={user}
              />
            )}
            <TextBubble el={el} mobile={mobile} searched={searched} />
          </Stack>
          <Avatar
            // alt={user?.firstName}
            src={
              avatarFunc({image: user?.avatar})
            }
          />
        </Stack>
      )}
    </div>
  );
};

export default TextMsg;
