import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment, Button, useTheme } from "@mui/material";
// import { LoadingButton } from "@mui/lab";
// components
import FormProvider, { RHFTextField } from "../../components/hook-form";
import { Eye, EyeSlash } from "phosphor-react";
// import { LoginUser } from "../../redux/slices/auth";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useUIContext } from "../../context/ui";
import { useLoginMutation, useSendOTPMutation } from "../../apis/auth";
import { NULL_USER, REGEX_PASSWORD, SEVERITY_ERROR, SEVERITY_SUCCESS, URL_FORGOT_PASSWORD, URL_UPDATE_PROFILE, URL_VERIFY_OTP } from "../../constants";
import { LOCALES } from "../../i18n/locales";
import { setOtpUser, setUser } from "../../redux/user";
import { setCarts, setUserToken } from "../../redux/global";

const SigninForm = () => {
    const theme = useTheme()
    const intl = useIntl()
    const locale = useSelector((state) => {
        return state.persisted.global.locale
    } )?.value || LOCALES.ENGLISH;;
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const carts = useSelector((state) => state.persisted.global.carts)
    const activeBuilding = useSelector(state => state.persisted.global.activeBuilding)
    const { setShowBackdropCallback, setShowAlert, setAlertObj } = useUIContext()

    const [
        login, 
        { isLoading: isUpdating }, 
      ] = useLoginMutation() 

      const [
        sendOTP, 
        { isLoading: isSendingOTP }, 
      ] = useSendOTPMutation() 

    useEffect(() => {
        setShowBackdropCallback(isUpdating || isSendingOTP)
    }, [isUpdating, isSendingOTP])

    const [showPassword, setShowPassword] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup.string().required('error.required').email('error.invalid.email'),
        password: Yup.string().required("error.required").matches(REGEX_PASSWORD, "error.invalid.password"),
    });
  
    const defaultValues = {
      email: "",
      password: "",
    };
  
    const methods = useForm({
      mode: "onChange",
      resolver: yupResolver(LoginSchema),
      defaultValues,
    });
  
    const {
      reset,
      setError,
      handleSubmit,
      formState: { errors, isDirty, isValid },
    } = methods;
  
    const onSubmit = async (data) => {
      try {
        const credential = {
            username: data.email,
            password: data.password,
            userType: 'user',
        }

        const result = await login(credential)
        // console.log('login result', result?.error?.data?.data?.userId)
        if (result?.error) {
          // const errorInfo = result.error?.data
          setShowAlert(true)
          setAlertObj({
            severity: SEVERITY_ERROR,
            content: result?.error?.data?.message[locale],
            // content: errorInfo[locale],
            duration: null,
          })
          if (result?.error?.status === 405) {
            const userId = result?.error?.data?.data?.userId
            await sendOTP({userId})
            dispatch(setOtpUser(data.email))
            navigate(URL_VERIFY_OTP)
          }
        } else {
          setShowAlert(true)
          setAlertObj({
            severity: SEVERITY_SUCCESS,
            content: result?.data?.message[locale],
            duration: null,
          })
          console.log('result', result)
          // dispatch(setUser(result))
          dispatch(setUserToken({
            _id: result?.data?.data?.userId,
            token: result?.data?.data?.token,
          }))
          if (carts[result?.data?._id]?.products?.length === 0 && carts[NULL_USER]?.products?.length > 0) {
            dispatch(setCarts({...carts, 
              [result?.data?._id]: carts[NULL_USER]}))
          }

          navigate(URL_UPDATE_PROFILE)
        }
        // const result = await login(credential)
        // console.log(data);
        // submit data to backend
      //   dispatch(LoginUser(data));
      } catch (error) {
        console.error(error);
        reset();
        setError("afterSubmit", {
          ...error,
          message: error.message,
        });
      }
    };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}

        <RHFTextField name="email" label={intl.formatMessage({id: 'auth.email'})} />

        <RHFTextField
          name="password"
          label={intl.formatMessage({id: 'auth.password'})}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <Eye /> : <EyeSlash />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack alignItems="flex-end" sx={{ my: 2 }}>
        <Link
          component={RouterLink}
          to={URL_FORGOT_PASSWORD}
          variant="overline"
          color="inherit"
          underline="always"
          
        >
          <FormattedMessage id='auth.forgot.password' />?
        </Link>
      </Stack>
      <Button
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        disabled={!isDirty || !isValid}
        sx={{
          bgcolor: theme.palette.primary.main,
          color: (theme) =>
            theme.palette.mode === "light" ? "common.white" : "grey.800",
          "&:hover": {
            bgcolor: theme.palette.primary.main,
            color: (theme) =>
              theme.palette.mode === "light" ? "common.white" : "grey.800",
          },
        }}
      >
        <FormattedMessage id="app.login" />
      </Button>
    </FormProvider>
  )
}

export default SigninForm