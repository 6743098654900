import {
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import SearchField from "../../components/searchField/SearchField";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setConversationLocation, setShowChatroom } from "../../redux/chatroom";
import { useIntl } from "react-intl";

const SearchedMessages = ({ mobile, current_conversation }) => {
  const theme = useTheme();
  const dispatch = useDispatch()
  const intl = useIntl()
  const [searchText, setSearchText] = useState("");
  const [searchInput, setSearchInput] = useState("");
  // console.log('current_conversation?.messages', current_conversation?.messages)
  const filteredMessages = useMemo(() => {
    return current_conversation?.messages?.filter((item) => {
      return (
        searchInput?.length > 0 &&
        item?.message?.toLowerCase()?.indexOf(searchInput?.toLowerCase()) >= 0
      );
    });
  }, [current_conversation?.messages, searchInput]);

  return (
    <>
      <Stack width="100%" justifyContent={"start"} alignItems={"center"} p={2}>
        <Stack
          width="100%"
          direction={"row"}
          justifyContent={"start"}
          alignItems={"center"}
          px={1}
        >
          <SearchField
            setSearchText={setSearchText}
            setInput={setSearchInput}
            mobile={mobile}
            width={"100%"}
            label={intl.formatMessage({id: 'general.search.messages'})}
          />
        </Stack>
        <Stack
          width="100%"
          //   justifyContent={"start"}
          //   alignItems={"center"}
          spacing={2}
          pt={2}
        >
          <List width="100%">
            {filteredMessages?.map((message, idx) => (
              <ListItemButton
                key={idx}
                sx={{
                  width: "100%",
                  '&:hover': {
                    bgcolor: theme.palette.primary.lighter
                  }
                }}
                onClick={() => {
                    dispatch(setConversationLocation({
                        conversationId: current_conversation._id,
                        messageIdx: current_conversation?.messages?.findIndex((item) => item.message === message?.message),
                    }))
                    if (mobile) {
                      dispatch(setShowChatroom({
                        showChatroom: true
                      }))
                    }
                }}
              >
                <Stack
                  width="100%"
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant="body">{message?.message}</Typography>
                  <Typography variant="body">
                    {moment(message?.created_at).format("HH:mm")}
                  </Typography>
                </Stack>
              </ListItemButton>
            ))}
          </List>
        </Stack>
      </Stack>
    </>
  );
};

export default SearchedMessages;
