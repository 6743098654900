import { createSlice } from "@reduxjs/toolkit";

const buildingSlice = createSlice({
    name: 'building',
    initialState: {
        buildings: [],
        selected: null,
    },
    reducers: {
        setBuildings: (state, action) => {
            state.buildings = action.payload
        },
        setSelectedBuilding: (state, action) => {
            state.selected = action.payload
        }
    }
})

export const {setBuildings, setSelectedBuilding} = buildingSlice.actions
export default buildingSlice.reducer