import React from "react";
import {
  Stack,
} from "@mui/material";
import Signin from "../../sections/auth/Signin";
import ForgotPassword from "../../sections/auth/ForgotPassword";


const ForgotPasswordPage = () => {
  return (
    <>
    <Stack alignItems={'center'} width='100%' height='100%' p={3}>
        <ForgotPassword />
    </Stack>
    </>
  )
}

export default ForgotPasswordPage