import { useEffect, useState } from "react";
import * as Yup from "yup";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormattedMessage, useIntl } from "react-intl";
import { useTheme } from "@mui/material";
import {
  Link,
  Stack,
  Alert,
  IconButton,
  InputAdornment,
  Button,
} from "@mui/material";
// components
import FormProvider, { RHFTextField } from "../../components/hook-form";
import { useSelector } from "react-redux";
import { LOCALES } from "../../i18n/locales";
import { useUIContext } from "../../context/ui";
import { useForgotPasswordMutation } from "../../apis/auth";
import { SEVERITY_ERROR, SEVERITY_SUCCESS } from "../../constants";
import { useNavigate } from "react-router-dom";

const ForgotPasswordForm = () => {
  const theme = useTheme();
  const intl = useIntl();
  const navigate = useNavigate();
  const locale =
    useSelector((state) => {
      return state.persisted.global.locale;
    })?.value || LOCALES.ENGLISH;
  const { setShowBackdropCallback, setShowAlert, setAlertObj } = useUIContext();
  const [forgotPassword, { isLoading: isUpdating }] =
    useForgotPasswordMutation();

  useEffect(() => {
    setShowBackdropCallback(isUpdating);
  }, [isUpdating]);

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email("error.invalid.email").required("error.required"),
  });

  const defaultValues = {
    email: "",
  };

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(ForgotPasswordSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isDirty },
  } = methods;

  // console.log('errors', errors, isDirty)

  const onSubmit = async (data) => {
    try {
      const submitObj = {
        email: data.email,
      };
      const result = await forgotPassword(submitObj);
      if (result.error) {
        const errorInfo = result.error?.data;
        setShowAlert(true);
        setAlertObj({
          severity: SEVERITY_ERROR,
          content: errorInfo[locale],
          duration: null,
        });
      } else {
        setShowAlert(true);
        setAlertObj({
          severity: SEVERITY_SUCCESS,
          content: intl.formatMessage({
            id: "auth.reset.password.link.success",
          }),
          duration: null,
        });
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
      reset();
      // setError("afterSubmit", {
      //   ...error,
      //   message: error.message,
      // });
      setShowAlert(true);
      setAlertObj({
        severity: SEVERITY_ERROR,
        content: error.message,
        duration: null,
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}

        <RHFTextField
          name="email"
          label={intl.formatMessage({ id: "auth.email" })}
        />
        <Button
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          disabled={!!errors?.email || !isDirty}
          sx={{
            bgcolor: theme.palette.primary.main,
            color: (theme) =>
              theme.palette.mode === "light" ? "common.white" : "grey.800",
            "&:hover": {
              bgcolor: theme.palette.primary.main,
              color: (theme) =>
                theme.palette.mode === "light" ? "common.white" : "grey.800",
            },
          }}
        >
          <FormattedMessage id="general.send.request" />
        </Button>
      </Stack>
    </FormProvider>
  );
};

export default ForgotPasswordForm;
