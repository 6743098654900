import React from "react";
import { Stack, Typography, Avatar, useTheme, alpha } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { ImageCovered } from "../imageCover/ImageCover";
import { FilePresent } from "@mui/icons-material";
import { ChatBubble, ChatText } from "./ChatBubble";
import MessageOption from "./MessageOption";
import useImage from "../../hooks/useImage";

const ReplyBubble = ({ el, mobile, searched }) => {
  const theme = useTheme();
  const imageFunc = useImage();
  return (
    <ChatBubble
      mobile={mobile}
      incoming={el.incoming ? "yes" : "no"}
      bgcolor={
        !searched
          ? el.incoming
            ? "lightyellow"
            : theme.palette.green["100"]
          : theme.palette.amber["900"]
      }
    >
      <Stack
        px={1.5}
        py={1.5}
        spacing={1}
        width="100%"
        sx={{
          backgroundColor: !searched
            ? el.incoming
              ? "lightyellow"
              : theme.palette.green["100"]
            : theme.palette.amber["900"],
          borderRadius: 1.5,
        }}
      >
        <Stack
          p={2}
          direction="column"
          spacing={3}
          alignItems="center"
          width="100%"
          sx={{
            backgroundColor: alpha(theme.palette.background.paper, 1),

            borderRadius: 1,
          }}
        >
          <Typography variant="body2" color={theme.palette.text}>
            {el.message}
          </Typography>
        </Stack>
        <Stack
          // direction={"row"}
          justifyContent={"start"}
          alignItems={"center"}
          spacing={1}
          width="100%"
          height="auto"
        >
          <Stack
            width="100%"
            direction={"row"}
            justifyContent={"start"}
            alignItems={"center"}
          >
            <Typography variant="body2" color={theme.palette.text}>
              <FormattedMessage id="general.reply" />:
            </Typography>
          </Stack>
          <Stack
            width="100%"
            direction={"row"}
            justifyContent={"start"}
            alignItems={"center"}
            sx={{overflowWrap: "break-word",wordBreak: "break-all"}}
          >
            {(el.subtype === "Text" || el.type === "Reply") && (
              <Typography variant="body2" color={theme.palette.text}>
                {el.reply}
              </Typography>
            )}
            {el.type !== "Reply" && el.subtype === "Media" && (
              <ImageCovered
                src={imageFunc({ image: el.file })}
                alt={el.reply}
                style={{ maxHeight: 80 }}
              />
            )}
            {el.type !== "Reply" && el.subtype === "Doc" && (
              <Stack
                direction={"row"}
                justifyContent={"start"}
                alignItems={"center"}
                spacing={0.5}
                height="80px"
              >
                <FilePresent />
                <Typography variant="body">
                  {el.file.split("/").pop()}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </ChatBubble>
  );
};

const ReplyMsg = ({
  el,
  avatarFunc,
  searched,
  menu,
  user,
  conversation,
  mobile,
}) => {
  return (
    <div id={el._id}>
      {el.incoming && (
        <Stack
          width={"100%"}
          direction="row"
          justifyContent={el.incoming ? "start" : "end"}
          spacing={3}
        >
          <Avatar
            // alt={user?.firstName}
            src={avatarFunc({ image: user?.avatar })}
          />
          <Stack
            width={"100%"}
            direction="row"
            justifyContent={el.incoming ? "start" : "end"}
            spacing={1}
          >
            <ReplyBubble el={el} mobile={mobile} searched={searched} />
            {menu && (
              <MessageOption
                incoming={el.incoming}
                message={el}
                conversation={conversation}
                user={user}
              />
            )}
          </Stack>
        </Stack>
      )}
      {!el.incoming && (
        <Stack
          width={"100%"}
          direction="row"
          justifyContent={el.incoming ? "start" : "end"}
          spacing={3}
        >
          <Stack
            width={"100%"}
            direction="row"
            justifyContent={el.incoming ? "start" : "end"}
            spacing={1}
          >
            {menu && (
              <MessageOption
                incoming={el.incoming}
                message={el}
                conversation={conversation}
                user={user}
              />
            )}
            <ReplyBubble el={el} mobile={mobile} searched={searched} />
          </Stack>
          <Avatar
            // alt={user?.firstName}
            src={avatarFunc({ image: user?.avatar })}
          />
        </Stack>
      )}
    </div>
  );
};

export default ReplyMsg;
