export const SLIDES = [
    // {
    //     id: 1,
    //     image: '/assets/images/carousels/006.jpg',
    //     title: 'slider.01',
    //     url: '/commercial/commercials',
    // }, {
    //     id: 2,
    //     image: '/assets/images/carousels/007.jpg',
    //     title: 'slider.02',
    //     url: '/community/requests'
    // }, {
    //     id: 3,
    //     image: '/assets/images/carousels/004.jpg',
    //     title: 'slider.03',
    //     url: '/strata/summary',
    // }, 
    // {
    //     id: 1,
    //     image: '/assets/images/carousels/007.jpg',
    //     title: 'slider.title.01',
    //     description: 'slider.description.01',
    //     url: '/farms',
    //     button: 'slider.shop.now',
    //     state: {
    //         tag: 'organic'
    //     },
    //     layout: 'right-left',
    // }, 
    {
        id: 1,
        // image: 'https://test-birtoo.s3.ca-central-1.amazonaws.com/011-min.jpeg',
        image: 'https://test-birtoo.s3.ca-central-1.amazonaws.com/012-min.jpg',
        title: 'slider.title.02',
        description: 'slider.description.02',
        // url: '/commercial/commercials',
        url: '/commercial/commercials/products/64b73b9173a9328f6f5f9661',
        button: 'slider.shop.now',
        state: {
            tag: 'organic'
        },
        layout: 'right-left',
    }, 
    {
        id: 2,
        // image: 'https://test-birtoo.s3.ca-central-1.amazonaws.com/011-min.jpeg',
        image: 'https://test-birtoo.s3.ca-central-1.amazonaws.com/012-min.jpg',
        title: 'slider.title.02',
        description: 'slider.description.02',
        // url: '/commercial/commercials',
        url: '/commercial/commercials/products/64b73b9173a9328f6f5f9661',
        button: 'slider.shop.now',
        state: {
            tag: 'organic'
        },
        layout: 'right-left',
    }, 
    {
        id: 3,
        image: 'https://test-birtoo.s3.ca-central-1.amazonaws.com/005-min.jpeg',
        title: 'slider.title.03',
        description: 'slider.description.03',
        url: '/community/issues',
        button: 'slider.learn.more',
        state: {},
        layout: 'left-right',
    },
    // {
    //     id: 3,
    //     image: '/assets/images/carousels/009.jpg',
    //     title: 'slider.title.01',
    //     description: 'slider.description.01',
    //     url: '/commercial/commercials',
    //     button: 'slider.shop.now',
    //     state: {
    //         tag: 'new'
    //     },
    //     layout: 'right-left',
    // }, 
    
    
]