import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { REGEX_PASSWORD, SEVERITY_ERROR, SEVERITY_SUCCESS, URL_LOGIN, URL_VERIFY_OTP } from '../../constants'
import FormProvider, { RHFTextField } from "../../components/hook-form";
import {
  Alert,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  useTheme,
} from "@mui/material";
import { Eye, EyeSlash } from "phosphor-react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useUIContext } from "../../context/ui";
import { useRegisterMutation } from "../../apis/auth";
import { LOCALES } from "../../i18n/locales";
import { useNavigate } from "react-router-dom";
import { setOtpUser } from "../../redux/user";

const SignupForm = () => {
    const intl = useIntl()
    const theme = useTheme()
    const navigate = useNavigate()
    const locale = useSelector((state) => state.persisted.global.locale)?.value || LOCALES.ENGLISH;
  const dispatch = useDispatch()
  const { setShowBackdropCallback, setShowAlert, setAlertObj } = useUIContext()
  
  const [
    register, 
    { isLoading: isUpdating }, 
  ] = useRegisterMutation() 

  useEffect(() => {
    setShowBackdropCallback(isUpdating)
  }, [isUpdating])

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

  const SignupSchema = Yup.object().shape({
    firstName: Yup.string().required("error.required"),
    lastName: Yup.string().required("error.required"),
    // username: yup.string().required("error.required"),
    email: Yup.string().email("error.invalid.email").required("error.required"),
    password: Yup.string().matches(REGEX_PASSWORD, "error.invalid.password"),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'error.password.match'),
    referrer: Yup.string(),
  });

  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    referrer: "",
  };

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(SignupSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const submitObj = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        password: data.password,
        role: 'resident',
        referrer: data.referrer,
        client: process.env.REACT_APP_CURRENT_CLIENT,
      }

      console.log(submitObj);
      const result = await register(submitObj)
      console.log('*** register result', result)
      if (result?.error) {
        setShowAlert(true)
        setAlertObj({
          severity: SEVERITY_ERROR,
          // content: intl.formatMessage({id: 'auth.register.failed'}) + (result.error?.data[locale] ? (intl.formatMessage({id: 'error.error'}) + result.error?.data[locale]) : ''),
          content: result?.error?.data?.message[locale],
          duration: null
        })
      } else {
        setShowAlert(true)
        setAlertObj({
          severity: SEVERITY_SUCCESS,
          content: result?.data?.message[locale],
          duration: null
        })
        console.log('**** data.email',data.email)
        dispatch(setOtpUser(data.email))
        navigate(URL_VERIFY_OTP)
        
      }
      // submit data to backend
      //   dispatch(LoginUser(data));
    } catch (error) {
      console.error(error);
      reset();
      // setError("afterSubmit", {
      //   ...error,
      //   message: error.message,
      // });
      setShowAlert(true)
      setAlertObj({
        severity: SEVERITY_ERROR,
        content: error.message,
        duration: null
      })
    }
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <RHFTextField name="firstName" label={`${intl.formatMessage({id: 'auth.first.name'})}*`} />
          <RHFTextField name="lastName" label={`${intl.formatMessage({id: 'auth.last.name'})}*`} />
        </Stack>
        <RHFTextField name="email" label={`${intl.formatMessage({id: 'auth.email'})}*`} />
        <RHFTextField
          name="password"
          label={`${intl.formatMessage({id: 'auth.password'})}*`}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <Eye /> : <EyeSlash />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="passwordConfirmation"
          label={`${intl.formatMessage({id: 'auth.password.confirmation'})}*`}
          type={showPasswordConfirmation ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                  edge="end"
                >
                  {showPasswordConfirmation ? <Eye /> : <EyeSlash />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField name="referrer" label={`${intl.formatMessage({id: 'auth.refer.code'})} (${intl.formatMessage({id: 'general.optional'})})`} />

        <Button
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          disabled={!isDirty || !isValid}
          sx={{
            textTransform: 'capitalize', 
            bgcolor: theme.palette.primary.main,
            color: (theme) =>
              theme.palette.mode === "light" ? "common.white" : "grey.800",
            "&:hover": {
              bgcolor: theme.palette.primary.main,
              color: (theme) =>
                theme.palette.mode === "light" ? "common.white" : "grey.800",
            },
          }}
        >
          <FormattedMessage id='auth.create.account2' />
        </Button>
      </Stack>
    </FormProvider>
  );
};

export default SignupForm;
