import { styled } from "@mui/material/styles";
import { Box, Typography, List, Button, ListItemButton, DialogTitle } from "@mui/material";

export const ModalTitle = styled(DialogTitle)(({theme}) => ({
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
}))

export const ModalSelectList = styled(List)(({theme}) => ({
    width: '100%',
    overflow: 'auto',
    maxHeight: 300,
    '& ul': { padding: 0 },
    marginTop: '20px',
    border: `1px solid ${theme.palette.grey['200']}`,
    '&& .Mui-selected, && .Mui-selected:hover': {
        backgroundColor: theme.palette.teal['50'],
        '&, & .MuiListItemIcon-root': {
        color: theme.palette.teal['500'],
        },
    },
}))

export const ModalListItemButton = styled(ListItemButton)(({theme}) => ({
    cursor: 'pointer',
    color: theme.palette.blueGrey['500'],
}))

export const ModalButton = styled(Button)(({theme}) => ({
    marginTop: '20px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
    }
}))


