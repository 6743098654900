import React, { useMemo } from "react";
import { Box, Grid, Typography, Stack, Link, useTheme } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { ImageCovered } from "../imageCover/ImageCover";
import { useNavigate } from "react-router-dom";
import {
  LOGO_HEADER_CN,
  LOGO_HEADER_EN,
  URL_UPDATE_PROFILE,
} from "../../constants";
import { URL_CONTACT_US } from "../../constants";
import useImage from "../../hooks/useImage";

const Footer2 = ({ mobile, tablet, locale }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const imageFunc = useImage()
  const cardType = useMemo(() => {
    return mobile ? "mobile" : "desktop";
  }, [mobile]);
  return (
    <Stack
      width="100%"
      height="100%"
      py={5}
      backgroundColor={theme.palette.grey["900"]}
      justifyContent={"start"}
      alignItems={"center"}
      spacing={2}
    >
      <Stack
        maxWidth={"600px"}
        justifyContent={"start"}
        alignItems={"center"}
        spacing={3}
        p={3}
      >
        <Typography variant="h6" color={theme.palette.common.white}>
          <FormattedMessage id="footer.about.us" />
        </Typography>
        <Box p={2} backgroundColor="#FFF">
          <ImageCovered
            src={
              locale === "en-US"
              ? LOGO_HEADER_EN
              : LOGO_HEADER_CN
            }
            // height="80px"
            height={mobile? '60px' : '80px'}
          />
        </Box>

        <Typography
          variant="body2"
          color={theme.palette.common.white}
          sx={{ textAlign: "center" }}
        >
          <FormattedMessage id="footer.intro" />
        </Typography>
      </Stack>
      <Typography
        variant="body2"
        sx={{
          marginBottom: "1em",
          marginTop: "1.5em",
          fontSize: "0.9em",
          color: theme.palette.warning.main,
          textAlign: "center",
        }}
      >
        Copyright © 2023 Birtoo Technology Inc. All rights reserved.
      </Typography>
    </Stack>
  );
};

export default Footer2;
