import { createSlice } from "@reduxjs/toolkit";
import md5 from "md5";
import { LOCALES } from "../i18n/locales";
import { SHIPPING_PICKUP } from "../constants";

const initialState = {
  locale: {
    value: LOCALES.ENGLISH,
    saved: false,
  },
  userToken: null,
  location: process.env.REACT_APP_DEFAULT_MARKET,
  lastNotification: null,
  activeBuilding: null,
  activeVendor: null,
  activeVendors: null,
  vendorDeliveries: {},
  carts: {},
  unpaidOrder: {
    id: '',
    user: '',
    vendor: '',
    shipping: SHIPPING_PICKUP,
    address: '',
    subtotal: 0,
    discount: 0,
    tax: 0,
    total: 0,
    products: [],
  },
  deliveryPhone: '',
  deliveryAddress: '',
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setLocale: (state, action) => {
        state.locale = action.payload
    },
    setUserToken: (state, action) => {
        state.userToken = action.payload
    },
    setLocation: (state, action) => {
      state.location = action.payload
    },
    setLastNotification: (state, action) => {
      state.lastNotification = action.payload
    },
    setActiveBuilding: (state, action) => {
      state.activeBuilding = action.payload
    },
    setActiveVendor: (state, action) => {
      state.activeVendor = action.payload
    },
    setActiveVendors: (state, action) => {
      state.activeVendors = action.payload
    },
    setVendorDeliveries: (state, action) => {
      state.vendorDeliveries = action.payload
    },
    // addDelivery: (state, action) => {
    //   // state.deliveries[action.payload.vendor] = action.payload.delivery
    //   state.deliveries = {
    //     ...state.deliveries,
    //     `${action.payload.vendor}`: action.payload.delivery
    //   }
    // },
    setCarts: (state, action) => {
      state.carts = action.payload
    },
    addCart: (state, action) => {
      const newCart = action.payload
      state.carts[newCart.user] = newCart
    },
    removeCart: (state, action) => {
      const user = action.payload?.user
      delete state.carts[user]
    },
    addProductToCart: (state, action) => {
      const myCart = state.carts[action.payload.user]
      let product = {...action.payload.product}
      // const taxRate = product?.category?.tax || product?.tax ||  0
      const saleInfo = action.payload.saleIdx >= 0 ? product.sales[action.payload.saleIdx] : product.saleInfo
      const quantity = action.payload.quantity
      const price = saleInfo.special < saleInfo.price ? saleInfo.special : saleInfo.price
      
      const extraItems = action.payload.extraItems

      const extraItemsTotal = extraItems?.length > 0 ? extraItems.reduce((acc, val) => {
          return acc + val.price * val.quantity
      }, 0) : 0
      const subtotal = (price + extraItemsTotal) * quantity
      // const tax = subtotal * taxRate
      // const total = subtotal + tax
      const discount = saleInfo.special < saleInfo.price ? (saleInfo.price - saleInfo.special) * quantity : 0
      
      const cartProductId = product._id + '-' + saleInfo._id + (extraItems?.length > 0 ? ('-' + md5(JSON.stringify(extraItems))) : '')
      // 64b819569be730eb1173d226-6578b54cd784628c45ad35bf
      const foundIdx = myCart.products.findIndex((item) => item.id === cartProductId)
      // console.log('foundIdx', foundIdx)
      const productObj = foundIdx >= 0 ? {
        ...myCart.products[foundIdx],
        saleInfo, 
        price,
        quantity: myCart.products[foundIdx].quantity + quantity,
        discount: myCart.products[foundIdx].discount + discount,
        subtotal: myCart.products[foundIdx].subtotal + subtotal,
      } : {
        ...action.payload.product,
        id: cartProductId,
        saleInfo, 
        saleIdx: action.payload.saleIdx,
        price,
        quantity,
        subtotal,
        discount,
        extraItems,
      }

      // console.log('productObj', productObj)
      let newCart = {...myCart, 
        vendor: action.payload.vendor || myCart.vendor,
        subtotal: myCart.subtotal + subtotal,
        quantity: myCart.quantity + quantity,
        discount: myCart.discount + discount,
        products: foundIdx >= 0 ? [...myCart.products.slice(0, foundIdx), productObj, ...myCart.products.slice(foundIdx+1, (myCart.products.length))] : [...myCart.products, productObj],
        tags: action.payload.tags ? action.payload.tags : myCart.tags,
      }
      state.carts = {...state.carts,
        [action.payload.user]: newCart
      }
    },
    removeProductFromCart: (state, action) => {
      const myCart = state.carts[action.payload.user]
      let product = {...action.payload.product}
      const saleInfo = action.payload.saleIdx >= 0 ? product.saleInfo[action.payload.saleIdx] : product.saleInfo
      const quantity = action.payload.quantity
      const price = saleInfo.special < saleInfo.price ? saleInfo.special : saleInfo.price
      const extraItems = action.payload.extraItems
      const extraItemsTotal = extraItems?.length > 0 ? extraItems.reduce((acc, val) => {
          return acc + val.price * val.quantity
      }, 0) : 0
      const subtotal = (price + extraItemsTotal) * quantity // price * quantity + extraItemsTotal
      const discount = saleInfo.special < saleInfo.price ? (saleInfo.price - saleInfo.special) * quantity : 0
      
      const cartProductId = product._id + '-' + saleInfo._id + (extraItems?.length > 0 ? ('-' + md5(JSON.stringify(extraItems))) : '')
      const foundIdx = myCart.products.findIndex((item) => item.id === cartProductId)
      if (foundIdx >= 0) {
        const productObj = {
          ...myCart.products[foundIdx],
          quantity: myCart.products[foundIdx].quantity - quantity,
          discount: myCart.products[foundIdx].discount - discount,
          subtotal: myCart.products[foundIdx].subtotal - subtotal,
        }

        let newCart = {...myCart, 
          subtotal: myCart.subtotal - subtotal,
          quantity: myCart.quantity - quantity,
          discount: myCart.discount - discount,
          vendor: (myCart.quantity - quantity) === 0 ? '' : myCart.vendor,
          products: productObj.quantity === 0 ? [...myCart.products.slice(0, foundIdx), ...myCart.products.slice(foundIdx+1, (myCart.products.length))] : [...myCart.products.slice(0, foundIdx), productObj, ...myCart.products.slice(foundIdx+1, (myCart.products.length))],
        }

        state.carts = {...state.carts,
          [action.payload.user]: newCart
        }
      }
    },
    setUnpaidOrder: (state, action) => {
      state.unpaidOrder = action.payload
    },
    setDeliveryPhone: (state, action) => {
      state.deliveryPhone = action.payload
    },
    setDeliveryAddress: (state, action) => {
      state.deliveryAddress = action.payload
    },
  },
});

export const { setLocale, setUserToken, setLocation, setLastNotification, setActiveBuilding, setActiveVendor, setActiveVendors, setCarts, addCart, removeCart, addProductToCart, removeProductFromCart, setUnpaidOrder, setVendorDeliveries, setDeliveryPhone, setDeliveryAddress } = globalSlice.actions;

export default globalSlice.reducer;
