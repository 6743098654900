import React, { useEffect, useMemo, useState } from "react";
import PublicSharpIcon from "@mui/icons-material/PublicSharp";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import { FormattedMessage, useIntl } from "react-intl";
import { setLocale, setUserToken } from "../../redux/global";
import { TITLES } from "../../i18n/titles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useUIContext } from "../../context/ui";
import { StyledBadge } from "../../components/styledBadge/StyledBadge";

import {
  LOCALE_ITEMS,
  LOGO_HEADER_CN,
  LOGO_HEADER_EN,
  SEVERITY_WARNING,
  URL_HOME,
  URL_LOGIN,
  URL_NOTIFICATIONS,
  URL_REQUESTS,
  URL_UPDATE_PROFILE,
} from "../../constants";
import { setUser } from "../../redux/user";
import { LOCALES } from "../../i18n/locales";
import { socket } from "../../socket";
import { ImageCovered } from "../imageCover/ImageCover";
import { CaretDown } from "phosphor-react";
import moment from "moment";
import Menubar from "../menubar/Menubar";
import useAvatar from "../../hooks/useAvatar";

const TopbarDesktop = ({
  user,
  userToken,
  unreadMessages,
  unreadNotifications,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const locale =
    useSelector((state) => state.persisted.global.locale)?.value ||
    LOCALES.ENGLISH;
  const location = useSelector((state) => state.persisted.global.location);
  const carts = useSelector((state) => state.persisted.global.carts);
  const unreadMessageCount = useSelector(
    (state) => state.post.unreadMessageCount
  );

  const avatarFunc = useAvatar();
  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const [anchorElLocale, setAnchorElLocale] = useState(null);

  const showName = useMemo(() => {
    return user
      ? user?.firstName?.length > 0
        ? user?.firstName
        : user?.username
      : "";
  }, [user]);

  // const [cart, setCart] = useState(null);
  // useEffect(() => {
  //   if (carts) {
  //     if (!user && carts[NULL_USER]) {
  //       setCart(carts[NULL_USER]);
  //     } else if (user && carts[user?._id]) {
  //       setCart(carts[user?._id]);
  //     }
  //   }
  // }, [carts, user]);

  const loggedIn = useMemo(() => {
    return (
      user && userToken?.token
      // &&
      // (user?.firstName !== process.env.REACT_APP_GUEST_PREFIX ||
      //   user?.lastName !== process.env.REACT_APP_GUEST_PREFIX)
    );
  }, [user]);

  const isProfileMenuOpen = Boolean(anchorElProfile);
  const isLocaleMenuOpen = Boolean(anchorElLocale);
  const handleUsernameClick = (event) =>
    setAnchorElProfile(event.currentTarget);
  const handleProfileMenuClose = () => setAnchorElProfile(null);
  const handleLocaleClick = (event) => setAnchorElLocale(event.currentTarget);
  const handleLocaleMenuClose = () => setAnchorElLocale(null);

  const [langTitle, setLangTitle] = useState(LOCALES.ENGLISH);
  const { setAlertObj, setShowAlert } = useUIContext();
  useEffect(() => {
    setLangTitle(TITLES[locale]);
  }, [locale]);

  const handleProfile = () => {
    setAnchorElProfile(null);
    navigate(URL_UPDATE_PROFILE);
  };

  const handleLocaleSelect = (value) => {
    setAnchorElLocale(null);
    dispatch(
      setLocale({
        value: value,
        saved: true,
      })
    );
  };

  const handleLogout = () => {
    if (
      window.confirm(intl.formatMessage({ id: "window.sure.logout" })) === true
    ) {
      console.log('logout user_id', user?._id)
      if (socket) {
        socket.emit("end", {
          user_id: user?._id,
        });
      }

      dispatch(setUser(null));
      dispatch(setUserToken(null));
      // dispatch(setDeliveryPhone(''))
      // dispatch(setDeliveryAddress(''))
      navigate(URL_HOME);
      setAnchorElProfile(null);
      
    }
  };

  const handleNotificationsClick = () => {
    navigate(URL_NOTIFICATIONS);
  };

  const handleUnreadMessageCountClick = () => {
    if (unreadMessageCount > 0) {
      navigate(URL_REQUESTS);
    } else {
      setAlertObj({
        severity: SEVERITY_WARNING,
        content: intl.formatMessage({ id: "general.not.available" }),
        duration: null,
      });
      setShowAlert(true);
    }
  };

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          // backgroundColor: theme.palette.primary.main,
          backgroundColor: "#FFF",
          zIndex: 999,
        }}
        py={2}
        px={10}
      >
        <ImageCovered
          src={locale === LOCALES.ENGLISH ? LOGO_HEADER_EN : LOGO_HEADER_CN}
          alt={"LOGO"}
          // style={{ maxHeight: 60 }}
          onClick={() => navigate(URL_HOME)}
          sx={{
            mb: -1,
            maxHeight: 60,
            color: theme.palette.common.white,
            "&:hover": {
              cursor: "pointer",
            },
          }}
        />
        <Stack
          direction={"row"}
          justifyContent={"start"}
          alignItems={"center"}
          spacing={2}
        >
          <Stack
            direction={"row"}
            justifyContent={"start"}
            alignItems={"center"}
            spacing={1}
          >
            {!loggedIn && (
              <Button
                variant="text"
                case="uppercase"
                onClick={() => {
                  navigate(URL_LOGIN);
                }}
                sx={{ py: 1 }}
              >
                <Typography
                  variant="string"
                  color={theme.palette.text.primary}
                  sx={{ fontSize: "16px", fontWeight: 400 }}
                >
                  <FormattedMessage id="app.login" /> /{" "}
                  <FormattedMessage id="app.register" />
                </Typography>
              </Button>
            )}
            {loggedIn && (
              <Stack direction={"row"} alignItems={"center"}>
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  variant="dot"
                >
                  <Avatar
                    src={avatarFunc({ image: user?.avatar })}
                    // alt={showName}
                    sx={{
                      mr: 0.5,
                      borderRadius: "50%",
                      width: "48px",
                      height: "48px",
                    }}
                  />
                </StyledBadge>

                <Button
                  variant="text"
                  case="lowercase"
                  onClick={(e) => handleUsernameClick(e)}
                >
                  <Typography
                    variant="body2"
                    color={theme.palette.text.primary}
                    sx={{ fontSize: "16px", fontWeight: 400 }}
                  >
                    <FormattedMessage id="general.hello" />, {showName}
                  </Typography>
                </Button>
                <Menu
                  anchorEl={anchorElProfile}
                  open={isProfileMenuOpen}
                  onClose={handleProfileMenuClose}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                  <MenuItem onClick={handleProfile}>
                    <FormattedMessage id="auth.profile" />
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <FormattedMessage id="auth.logout" />
                  </MenuItem>
                </Menu>
              </Stack>
            )}
          </Stack>

          <Divider
            orientation="vertical"
            flexItem
            sx={{ backgroundColor: theme.palette.grey["400"] }}
          />
          <Button
            variant="text"
            sx={{
              color: theme.palette.text.primary,
              py: 1,
              px: 1.5,
              fontSize: "16px",
              fontWeight: 400,
            }}
            onClick={(e) => handleLocaleClick(e)}
            startIcon={<PublicSharpIcon fontSize="medium" />}
            endIcon={<CaretDown fontSize={"medium"} />}
          >
            <Typography variant="body2">{langTitle}</Typography>
          </Button>
          <Menu
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            TransitionComponent={Fade}
            id="locale-positioned-menu"
            aria-labelledby="locale-positioned-button"
            anchorEl={anchorElLocale}
            open={isLocaleMenuOpen}
            onClose={handleLocaleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              "& .MuiMenu-paper": {
                border: `1px solid ${theme.palette.grey["400"]}`,
              },
            }}
          >
            <Box py={0.5}>
              <Stack spacing={1} minWidth={"120px"}>
                {LOCALE_ITEMS.map((el, idx) => (
                  <MenuItem
                    key={idx}
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => handleLocaleSelect(el.value)}
                  >
                    {el.title}
                  </MenuItem>
                ))}
              </Stack>
            </Box>
          </Menu>
          <Tooltip
            title={intl.formatMessage({ id: "general.notifications" })}
            enterTouchDelay={0}
            arrow
          >
            <IconButton
              onClick={() => {
                handleNotificationsClick();
              }}
            >
              <Badge badgeContent={unreadNotifications} color="error">
                <NotificationsIcon
                  fontSize={"large"}
                  sx={{ color: theme.palette.primary.main }}
                />
              </Badge>
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
      <Menubar unreadMessages={unreadMessages} />
    </>
  );
};

export default TopbarDesktop;
