import React from "react";
import { Stack, Typography, Avatar, useTheme } from "@mui/material";
import { ChatBubble, ChatText } from "./ChatBubble";
import MessageOption from "./MessageOption";
import useImage from "../../hooks/useImage";

const ImageBubble = ({ el, mobile, searched }) => {
  const theme = useTheme();
  const imageFunc = useImage()
  return (
    <ChatBubble
      mobile={mobile}
      incoming={el.incoming ? "yes" : "no"}
      bgcolor={!searched ? (el.incoming ? "lightyellow" : theme.palette.green["100"]) : theme.palette.amber['900']}
    >
      <Stack
        width={"100%"}
        px={1.5}
        py={1.5}
        spacing={1}
        sx={{
          backgroundColor: (!searched ? (el.incoming ? "lightyellow" : theme.palette.green["100"]) : theme.palette.amber['900']),
          borderRadius: 1.5,
        }}
      >
        <Stack spacing={1}>
          <img
            src={
              imageFunc({image: el.file})
            }
            alt={el.message}
            style={{ maxHeight: 210, borderRadius: "10px" }}
          />
          <Typography variant="body2" color={theme.palette.text}>
            {el.message}
          </Typography>
        </Stack>
      </Stack>
    </ChatBubble>
  );
};

const MediaMsg = ({ el, avatarFunc, searched, menu, user, conversation, mobile }) => {
  return (
    <div id={el._id}>
      {el.incoming && (
        <Stack
          width={"100%"}
          direction="row"
          justifyContent={el.incoming ? "start" : "end"}
          spacing={3}
        >
          <Avatar
            // alt={user?.firstName}
            src={
              avatarFunc({image: user?.avatar})
            }
          />
          <Stack
            width={"100%"}
            direction="row"
            justifyContent={el.incoming ? "start" : "end"}
            spacing={1}
          >
            <ImageBubble el={el} mobile={mobile} searched={searched} />
            {menu && (
              <MessageOption
                incoming={el.incoming}
                message={el}
                conversation={conversation}
                user={user}
              />
            )}
          </Stack>
        </Stack>
      )}
      {!el.incoming && (
        <Stack
          width={"100%"}
          direction="row"
          justifyContent={el.incoming ? "start" : "end"}
          spacing={3}
        >
          <Stack
            width={"100%"}
            direction="row"
            justifyContent={el.incoming ? "start" : "end"}
            spacing={1}
          >
            {menu && (
              <MessageOption
                incoming={el.incoming}
                message={el}
                conversation={conversation}
                user={user}
              />
            )}
            <ImageBubble el={el} mobile={mobile} searched={searched} />
          </Stack>
          <Avatar
            // alt={user?.firstName}
            src={
              avatarFunc({image: user?.avatar})
            }
          />
        </Stack>
      )}
    </div>
  );
};

export default MediaMsg;
