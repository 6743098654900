import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ChevronRightOutlined,
} from "@mui/icons-material";
import {
  getSidebarItemsProfile,
} from "../../data/menu";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
  setUserToken,
} from "../../redux/global";
import { useUpdateUserMutation } from "../../apis/auth";
import {
  SIDEBAR_PROFILE,
  URL_HOME,
} from "../../constants";
import { setUser } from "../../redux/user";
import { socket } from "../../socket";
import useAvatar from "../../hooks/useAvatar";

const Sidebar = ({
  isSidebarOpen,
  sidebarType,
  mobile,
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState(pathname);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileUploaded, setFileUploaded] = useState('')
  
  const user = useSelector((state) => state.user.currentUser)?.data;
  const userToken = useSelector((state) => state.persisted.global.userToken);
  const config = useSelector((state) => state.config)?.config;
  // const carts = useSelector((state) => state.persisted.global.carts);
  // const activeBuilding = useSelector(
  //   (state) => state.persisted.global.activeBuilding
  // );
  // const unreadMessageCount = useSelector(
  //   (state) => state.post.unreadMessageCount
  // );
  const [cart, setCart] = useState(null);

  const [postsExpanded, setPostsExpanded] = React.useState(false);
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();

  const avatarFunc = useAvatar()
  // const isSupport = useMemo(() => {
  //   return config['supportTeam'] && user && config['supportTeam'].indexOf(user.email) >= 0
  // }, [user, config])

  // useEffect(() => {
  //   if (carts) {
  //     if (!user && carts[NULL_USER]) {
  //       setCart(carts[NULL_USER]);
  //     } else if (user && carts[user?._id]) {
  //       setCart(carts[user?._id]);
  //     }
  //   }
  // }, [carts, user]);
  // const buildingImage = useMemo(() => {
  //   const images = activeBuilding?.building?.images;
  //   return images && images?.length > 0
  //     ? process.env.REACT_APP_S3_BUCKET_URL + images[0]
  //     : null;
  // }, [activeBuilding?.building?.images]);

  
  const isProfile = useMemo(() => {
    return sidebarType === SIDEBAR_PROFILE;
  }, [sidebarType]);

  useEffect(() => {
    if (pathname) {
      setActive(pathname);
      const postMenu = getSidebarItemsProfile(mobile).find(
        (item) => item.text === "auth.posts"
      );
      if (postMenu) {
        setPostsExpanded(
          postMenu.children.findIndex(
            (item) => pathname.indexOf(item.link) >= 0
          ) >= 0
        );
      }
    }
  }, [pathname]);

  const showName = useMemo(() => {
    return (user ? (user?.firstName?.length > 0 ? user?.firstName : user?.username) : '')
  }, [user])

  const sidebarItems = useMemo(() => {
    if (sidebarType === SIDEBAR_PROFILE) {
      return getSidebarItemsProfile(mobile);
    } 
    // else if (sidebarType === SIDEBAR_STRATA) {
    //   return SIDEBAR_ITEMS_STRATA;
    // } else if (sidebarType === SIDEBAR_COMMUNITY) {
    //   return SIDEBAR_ITEMS_COMMUNITY;
    // } else if (sidebarType === SIDEBAR_COMMERCIAL) {
    //   return SIDEBAR_ITEMS_COMMERCIAL;
    // }
    return [];
  }, [sidebarType]);

  
  // console.log('user', user)
  return (
    <>
      {isSidebarOpen && (
        <Stack width={"100%"} height="100%" py={5}>
          {isProfile && (
            <Stack
              width="100%"
              justifyContent="flex-start"
              alignItems="center"
              borderBottom={`1px solid ${theme.palette.blueGrey["100"]}`}
              spacing={2}
              pb={3}
            >
              <Avatar
                src={
                  avatarFunc({image: user?.avatar})
                }
                // alt={user?.username}
                sx={{
                  borderRadius: "50%",
                  mt: 2,
                  width: "65px",
                  height: "65px",
                }}
              />
              <Typography variant="body2" fontSize="1.2em">
                <FormattedMessage id="profile.hello" /> {showName}
              </Typography>
              {user?.referrer && (<Typography variant="body2" fontSize={'1.1rem'} color={'text.primary'}>
                <FormattedMessage id="auth.referrer" /> {user?.referrer?.firstName}
              </Typography>)}
            </Stack>
          )}
          {/* {!isProfile && (
            <Stack
              width="100%"
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              borderBottom={`1px solid ${theme.palette.blueGrey["100"]}`}
              mb={2}
            >
              {activeBuilding && (
                <>
                  <ImageCovered
                    src={buildingImage}
                    alt={activeBuilding?.building?.name}
                    height="100px"
                    sx={{
                      mt: 2,
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 10px 20px 0 rgba(0, 0, 0, 0.22)",
                      mb: 2,
                    }}
                  />
                  <Stack width="90%" textAlign={"center"}>
                    <Typography
                      variant="body2"
                      mt={1}
                      color={theme.palette.primary.main}
                    >
                      {activeBuilding?.building?.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      mt={1}
                      color={theme.palette.grey["600"]}
                    >
                      {activeBuilding?.building?.address}
                    </Typography>
                    <Typography
                      variant="body2"
                      mt={1}
                      mb={3}
                      color={theme.palette.warning.main}
                    >
                      {activeBuilding?.community?.name}
                    </Typography>
                    {user?.buildings?.length > 0 && (
                      <Button
                        variant="contained"
                        sx={{ mt: 1, mb: 3, ml: 3, mr: 3 }}
                        onClick={() => navigate(URL_COMMUNITIES)}
                      >
                        <FormattedMessage id="community.switch.community" />
                      </Button>
                    )}
                  </Stack>
                </>
              )}
              {!activeBuilding && user && (
                <>
                  <Avatar
                    src={imageUrl}
                    alt={user?.username}
                    sx={{
                      borderRadius: "50%",
                      mt: 2,
                      width: "65px",
                      height: "65px",
                    }}
                  />
                  <Typography variant="body2" fontSize="1.2em" mt={2}>
                    <FormattedMessage id="profile.hello" /> {user?.firstName}
                  </Typography>
                </>
              )}
            </Stack>
          )} */}

          <Box width="100%" height="100%" mt={3} mb={3}>
            <List>
              {sidebarItems.map(({ text, icon, link, children }) => {
                // if (text === 'auth.chats' && isSupport === false) {
                //   return <Box key={text}></Box>
                // }

                if (!icon) {
                  return (
                    <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                      <FormattedMessage id={text} />
                    </Typography>
                  );
                }

                return (
                  <Box key={text}>
                    <ListItem
                      disablePadding
                      sx={{
                        mt: text.indexOf("logout") >= 0 ? 5 : 0,
                      }}
                    >
                      <ListItemButton
                        sx={{ py: 2 }}
                        selected={active.indexOf(link) >= 0}
                        onClick={() => {
                          if (children && children.length > 0) {
                            setPostsExpanded(!postsExpanded);
                          } else if (
                            link.indexOf("logout") >= 0 &&
                            window.confirm(
                              intl.formatMessage({ id: "window.sure.logout" })
                            ) === true
                          ) {
                            dispatch(setUser(null));
                            dispatch(setUserToken(null));
                            // dispatch(setDeliveryPhone(''))
                            // dispatch(setDeliveryAddress(''))
                            navigate(URL_HOME);
                            if (socket) {
                              socket.emit('end')
                            } 
                          } else {
                            navigate(link ? link : URL_HOME);
                            setActive(link);
                          }
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ml: "2rem",
                            color:
                              text.indexOf("logout") >= 0
                                ? active.indexOf(link) >= 0
                                  ? theme.palette.red["900"]
                                  : theme.palette.red["600"]
                                : active.indexOf(link) >= 0
                                ? theme.palette.primary.main
                                : theme.palette.primary.light,
                          }}
                        >
                          {icon}
                        </ListItemIcon>
                        {/* {text.indexOf("cart") >= 0 && (
                          <Badge badgeContent={cart?.quantity} color="warning">
                            <ListItemText>
                              <Typography variant="body2" sx={{ mr: 1 }}>
                                <FormattedMessage id={text} />
                              </Typography>
                            </ListItemText>
                          </Badge>
                        )} */}
                        {/* {text.indexOf("cart") < 0 &&
                          text.indexOf("request") >= 0 && (
                            <Badge
                              badgeContent={unreadMessageCount}
                              color="error"
                            >
                              <ListItemText>
                                <Typography variant="body2" sx={{ mr: 1 }}>
                                  <FormattedMessage id={text} />
                                </Typography>
                              </ListItemText>
                            </Badge>
                          )} */}
                        {text.indexOf("cart") < 0 &&
                          text.indexOf("request") < 0 && (
                            <ListItemText>
                              <Typography variant="body2" sx={{ mr: 1 }}>
                                <FormattedMessage id={text} />
                              </Typography>
                            </ListItemText>
                          )}
                        {(!children || children.length === 0) &&
                          active.indexOf(link) >= 0 && (
                            <ChevronRightOutlined sx={{ ml: "auto" }} />
                          )}
                        {/* {children &&
                          children.length > 0 &&
                          (postsExpanded ? <ExpandLess /> : <ExpandMore />)} */}
                      </ListItemButton>
                      {/* {text.indexOf('cart') >= 0 && <Button></Button>} */}
                    </ListItem>
                    {/* {postsExpanded && children?.length > 0 && (
                      <Collapse in={postsExpanded} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {children.map((child) => (
                            <ListItemButton
                              key={child.text}
                              selected={active.indexOf(child?.link) >= 0}
                              sx={{ pl: 4 }}
                              onClick={() => {
                                navigate(child?.link);
                              }}
                            >
                              <ListItemIcon
                                sx={{
                                  ml: "2rem",
                                  color:
                                    active.indexOf(child?.link) >= 0
                                      ? theme.palette.teal["800"]
                                      : theme.palette.teal["500"],
                                }}
                              >
                                {child.icon}
                              </ListItemIcon>
                              {child?.text.indexOf("requests") >= 0 && (
                                <Badge
                                  badgeContent={unreadMessageCount}
                                  color="error"
                                >
                                  <ListItemText
                                    sx={{
                                      color:
                                        active.indexOf(child?.link) >= 0
                                          ? theme.palette.blueGrey["900"]
                                          : theme.palette.blueGrey["600"],
                                    }}
                                  >
                                    <FormattedMessage id={child.text} />
                                  </ListItemText>
                                </Badge>
                              )}
                              {child?.text.indexOf("requests") < 0 && (
                                <ListItemText
                                  sx={{
                                    color:
                                      active.indexOf(child?.link) >= 0
                                        ? theme.palette.blueGrey["900"]
                                        : theme.palette.blueGrey["600"],
                                  }}
                                >
                                  <FormattedMessage id={child.text} />
                                </ListItemText>
                              )}
                            </ListItemButton>
                          ))}
                        </List>
                      </Collapse>
                    )} */}
                  </Box>
                );
              })}
            </List>
          </Box>
        </Stack>
      )}
    </>
  );
};

export default Sidebar;
