import React from 'react';
import CarouselWrapper from 'react-material-ui-carousel'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTheme } from '@mui/material'
import { SLIDES } from '../../data/carousel'
import { CAROUSEL_SLIDE_INTERVAL, CAROUSEL_SLIDE_DURATION } from '../../constants'
import Item from './Item'

const Carousel = ({slides = SLIDES, tablet, mobile, locale}) => {
    const theme = useTheme()
    // const type = useMemo(() => {
    //     return mobile ? 'mobile' : (tablet ? 'tablet' : 'desktop')
    // }, [tablet, mobile])

    return (
        <CarouselWrapper 
            navButtonsAlwaysVisible
            indicators={false}
            interval={CAROUSEL_SLIDE_INTERVAL}
            duration={CAROUSEL_SLIDE_DURATION}
            swipe={false}
            animation='fade'
            navButtonsProps={{
                style: {
                    backgroundColor: theme.palette.blueGrey['700'],
                    border: '2px solid white',
                    cursor: 'pointer',
                    opacity: '0.5',
                    fontWeight: 'bold',
                }
            }}
            NextIcon={<ArrowForwardIosIcon />}
            PrevIcon={<ArrowBackIosIcon />}
            sx={{
                width: '100%',
                maxWidth: '100vw',
                borderBottom: `1px solid ${theme.palette.grey['100']}`
            }}
        >
            {
                slides.map( (item) => <Item key={item.url} item={item} tablet={tablet} mobile={mobile} locale={locale} /> )
            }
        </CarouselWrapper>
    )
}

export default Carousel