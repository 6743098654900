import { createSlice } from "@reduxjs/toolkit";
import moment from 'moment';
// const user_id = window.localStorage.getItem("user_id");

const pendingRequestSlice = createSlice({
  name: "pendingRequest",
  initialState: {
    pendingRequests: [],
  },
  reducers: {
    addRequest(state, action) {
      if (!state.pendingRequests) {
        state.pendingRequests = []
      }
      state.pendingRequests = [...state.pendingRequests, action.payload.pendingRequest]
    },
    removeRequest(state, action) {
      console.log('state.pendingRequests 1', state.pendingRequests)
      const requestIndex = state.pendingRequests.findIndex((item) => action.payload.id === item._id || action.payload.id === item.id)
      if (requestIndex >= 0) {
        state.pendingRequests = [...state.pendingRequests.slice(0, requestIndex), ...state.pendingRequests.slice(requestIndex+1)]
      }
      console.log('state.pendingRequests 2', state.pendingRequests)
    },
    setRequests(state, action) {
      state.pendingRequests = action.payload.pendingRequests
    },
  },
});

// Reducer
export const {addRequest, removeRequest, setRequests } = pendingRequestSlice.actions
export default pendingRequestSlice.reducer;

