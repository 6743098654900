import React, { useMemo } from "react";
import { Stack, Typography, IconButton, Avatar, useTheme } from "@mui/material";
import { DownloadSimple, Image } from "phosphor-react";
import { ChatBubble, ChatText } from "./ChatBubble";
import MessageOption from "./MessageOption";
import useImage from "../../hooks/useImage";

const DocBubble = ({ el, mobile, searched }) => {
  const theme = useTheme();
  const imageFunc = useImage()
  const fileUrl = useMemo(() => {
    return imageFunc({image: el.file})
  }, [el.file, imageFunc]);

  const fileName = useMemo(() => {
    return el.file.split("/").pop();
  }, [el.file]);
  return (
    <ChatBubble
      mobile={mobile}
      incoming={el.incoming ? "yes" : "no"}
      bgcolor={!searched ? (el.incoming ? "lightyellow" : theme.palette.green["100"]) : theme.palette.amber['900']}
    >
      <Stack
        width={"100%"}
        px={1.5}
        py={1.5}
        spacing={1}
        sx={{
          backgroundColor: (!searched ? (el.incoming ? "lightyellow" : theme.palette.green["100"]) : theme.palette.amber['900']),
          borderRadius: 1.5,
        }}
      >
        <Stack spacing={2}>
          <Stack
            p={2}
            width={"100%"}
            direction="row"
            spacing={1}
            justifyContent={"space-between"}
            alignItems="center"
            sx={{
              backgroundColor: theme.palette.background.paper,
              borderRadius: 1,
            }}
          >
            <Image size={48} />
            <Typography
              variant="caption"
              sx={{
                width: "100%",
                overflow: "hidden",
                // whiteSpace: "nowrap",
                overflowWrap: "break-word",
                flexGrow: 1,
              }}
            >
              {fileName}
            </Typography>
            <IconButton onClick={() => window.open(fileUrl, "_blank")}>
              <DownloadSimple />
            </IconButton>
          </Stack>
          <Typography variant="body2" color={theme.palette.text}>
            {el.message}
          </Typography>
        </Stack>
      </Stack>
    </ChatBubble>
  );
};

const DocMsg = ({ el, avatarFunc, searched, menu, user, conversation, mobile }) => {
  return (
    <div id={el._id}>
      {el.incoming && (
        <Stack
          width={"100%"}
          direction="row"
          justifyContent={el.incoming ? "start" : "end"}
          spacing={3}
        >
          <Avatar
            // alt={user?.firstName}
            src={
              avatarFunc({image: user?.avatar})
            }
          />
          <Stack
            width={"100%"}
            direction="row"
            justifyContent={el.incoming ? "start" : "end"}
            spacing={1}
          >
            <DocBubble el={el} mobile={mobile} searched={searched} />
            {menu && (
              <MessageOption
                incoming={el.incoming}
                message={el}
                conversation={conversation}
                user={user}
              />
            )}
          </Stack>
        </Stack>
      )}
      {!el.incoming && (
        <Stack
          width={"100%"}
          direction="row"
          justifyContent={el.incoming ? "start" : "end"}
          spacing={3}
        >
          <Stack
            width={"100%"}
            direction="row"
            justifyContent={el.incoming ? "start" : "end"}
            spacing={1}
          >
            {menu && (
              <MessageOption
                incoming={el.incoming}
                message={el}
                conversation={conversation}
                user={user}
              />
            )}
            <DocBubble el={el} mobile={mobile} searched={searched} />
          </Stack>
          <Avatar
            // alt={user?.firstName}
            src={
              avatarFunc({image: user?.avatar})
            }
          />
        </Stack>
      )}
    </div>
  );
};

export default DocMsg;
