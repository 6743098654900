import { createSlice } from "@reduxjs/toolkit";

const chatroomSlice = createSlice({
  name: "chatroom",
  initialState: {
    chat_type: null,
    room_id: null,
    conversation_viewed: null,
    conversation_location: {
      conversationId: '',
      messageIndex: -1,
    },
    conversation_reply: {
      conversationId: '',
      message: null,
    },
    conversation_uploads: {
      conversationId: '',
      files: []
    },
    conversation_uploads_reset: false,
    tab_selected: 0,
    show_chatroom: false,
    show_search: false,
  },
  reducers: {
    selectConversation(state, action) {
      state.chat_type = "individual";
      state.room_id = action.payload.room_id;
    },
    setConversationViewed(state, action) {
      // console.log('action.payload.conversationId', action.payload.conversationId)
      state.conversation_viewed = action.payload.conversationId;
    },
    setConversationLocation(state, action) {
      state.conversation_location = {
        conversationId: action.payload.conversationId,
        messageIndex: action.payload.messageIdx,
      }
    },
    setConversationReply(state, action) {
      state.conversation_reply = {
        conversationId: action.payload.conversationId,
        message: action.payload.message,
      }
    },
    setConversationUploads(state, action) {
      state.conversation_uploads = {
        conversationId: action.payload.conversationId,
        files: action.payload.files,
      }
      // if (action.payload.files?.length === 0) {
      //   state.conversation_uploads_reset = true
      // }
    },
    setTabSelected(state, action) {
      state.tab_selected = action.payload.tabSelected;
    },
    setShowChatroom(state, action) {
      state.show_chatroom = action.payload.showChatroom;
      if (action.payload.showChatroom) {
        state.show_search = false
      }
    },
    setShowSearchPane(state, action) {
      state.show_search = action.payload.showSearch;
      if (action.payload.showSearch) {
        state.show_chatroom = false
      }
    },
  },
});

export const { selectConversation, setConversationViewed, setConversationLocation, setConversationReply, setConversationUploads, setTabSelected, setShowChatroom, setShowSearchPane } = chatroomSlice.actions;
export default chatroomSlice.reducer;
