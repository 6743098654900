import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ChatSidebar from "../../sections/chat/ChatSidebar";
import ChatHeader from "../../components/chat/ChatHeader";
import ChatFooter from "../../components/chat/ChatFooter";
import { useDispatch, useSelector } from "react-redux";
import ChatRoom from "../../sections/chat/ChatRoom";
import { LOCALES } from "../../i18n/locales";
import { useGetConversationsQuery } from "../../apis/conversation";
import { useUIContext } from "../../context/ui";
import {
  addDirectConversation,
  setMessagesViewed,
  updateDirectConversation,
} from "../../redux/conversation";
import { setConversationViewed } from "../../redux/chatroom";

const ChatPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const user = useSelector((state) => state.user.currentUser)?.data;
  const userToken = useSelector((state) => state.persisted.global.userToken);
  const locale =
    useSelector((state) => state.persisted.global.locale)?.value ||
    LOCALES.ENGLISH;
  const config = useSelector((state) => state.config)?.config;
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { room_id, conversation_viewed } = useSelector(
    (state) => state.chatroom
  );
  const directChats = useSelector(
    (state) => state.persisted.conversation.direct_chats
  );

  const current_conversation = useMemo(() => {
    return directChats && user?._id?.length > 0
      ? directChats[user?._id]?.current_conversation
      : null;
  }, [directChats, user?._id]);

  const conversations = useMemo(() => {
    return directChats && user?._id?.length > 0
      ? directChats[user?._id]?.conversations
      : [];
  }, [directChats, user?._id]);

  const { setShowBackdropCallback } = useUIContext();
  const {
    data: dataConversations,
    isLoading: isLoadingConversations,
    isFetchingConversations,
  } = useGetConversationsQuery(
    { userId: user?._id, token: userToken.token },
    { skip: !user?._id || !userToken }
  );

  useEffect(() => {
    setShowBackdropCallback(isLoadingConversations | isFetchingConversations);
  }, [isLoadingConversations, isFetchingConversations]);

  useEffect(() => {
    if (
      dataConversations?.status === "success" &&
      dataConversations?.data?.conversations?.length > 0 &&
      initialLoading === true
    ) {
      // Update redux conversations
      const latestConversations = dataConversations?.data?.conversations;
      latestConversations?.map((conversation) => {
        const existing_conversation = conversations
          ? conversations.find((item) => item?._id === conversation._id)
          : null;
        if (existing_conversation) {
          // update direct conversation

          dispatch(
            updateDirectConversation({
              userId: user?._id,
              conversation: conversation,
              updateMessage: true,
              span: config["defaultMessageDividerSpan"]
                ? parseFloat(config["defaultMessageDividerSpan"])
                : undefined,
            })
          );
        } else {
          // add direct conversation
          dispatch(
            addDirectConversation({
              userId: user?._id,
              conversation: conversation,
              updateMessage: true,
            })
          );
        }

        setInitialLoading(false);
      });
    }

    if (initialLoading === false && conversations?.length > 0) {
      const filtered = conversations?.filter((item) => item.active === true);
      if (filtered?.length > 0) {
        dispatch(
          setConversationViewed({
            conversationId: filtered[0]._id,
          })
        );
      }
    }
  }, [dataConversations, dispatch, initialLoading]);

  useEffect(() => {
    if (conversation_viewed?.length > 0) {
      // if (
      //   dataConversations?.status === "success" &&
      //   dataConversations?.data?.conversations?.length > 0
      // ) {
      //   const latestConversations = dataConversations?.data?.conversations;
      //   const conversationFound = latestConversations?.find(
      //     (item) => item._id === conversation_viewed
      //   );
      //   console.log("conversationFound", conversationFound);
      //   if (conversationFound) {
          dispatch(
            setMessagesViewed({
              conversationId: conversation_viewed,
              userId: user?._id,
            })
          );
      //   }
      // }
    }
  }, [conversation_viewed, dataConversations]);

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"start"}
        alignItems={"center"}
        width="100%"
        m={-5}
        height="100%"
        minHeight={"650px"}
      >
        <Stack width="30%" minWidth="320px" height={"100%"}>
          <ChatSidebar
            user={user}
            userToken={userToken}
            mobile={false}
            locale={locale}
            current_conversation={current_conversation}
            conversations={conversations}
          />
        </Stack>
        <Stack flexGrow={1} height={"100%"}>
          <ChatRoom
            user={user}
            userToken={userToken}
            mobile={mobile}
            current_conversation={current_conversation}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default ChatPage;
