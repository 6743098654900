import { Search } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import { useIntl } from "react-intl";

const SearchField = ({ setSearchText, setInput = null, width = null, label = null }) => {
  const intl = useIntl();
  const [searchInput, setSearchInput] = useState("");
  return (
    <>
      <TextField
        label={label || intl.formatMessage({ id: "app.search" })}
        sx={{ width: width || undefined, minWidth: 200 }}
        onChange={(e) => {
          setSearchInput(e.target.value)
          setInput && setInput(e.target.value)
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setSearchText(searchInput?.length > 0 ? searchInput : "");
          }
        }}
        value={searchInput}
        variant="standard"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setSearchText(searchInput);
                }}
              >
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default SearchField;
