import { api } from "./api";

const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    register: build.mutation({
      query: (body) => ({
        url: `api/register`,
        method: "POST",
        body,
      }),
    }),
    sendOTP: build.mutation({
      query: (body) => ({
        url: `api/sendOTP`,
        method: "POST",
        body,
      }),
    }),
    verifyOTP: build.mutation({
      query: (body) => ({
        url: `api/verifyOTP`,
        method: "POST",
        body,
      }),
    }),
    addGuest: build.mutation({
      query: (body) => ({
        url: `auth/addGuest`,
        method: "POST",
        body,
      }),
    }),
    login: build.mutation({
      query: (body) => ({
        url: `api/login`,
        method: "POST",
        body,
      }),
    }),
    getUsersCount: build.query({
      query: ({ id, token }) => {
        return {
          url: `api/usersCount/`,
          method: "GET",
          // params: { page, pageSize, sort, search },
          // headers: { token: `Bearer ${token}` },
        };
      },
      providesTags: ["Users"],
    }),
    getUserById: build.query({
      query: ({ id, token }) => {
        return {
          url: `api/userById/${id}`,
          method: "GET",
          // params: { page, pageSize, sort, search },
          headers: { Authorization: `Bearer ${token}` },
        };
      },
      providesTags: (result) => [{ type: "Users", id: result?._id }],
    }),
    getContacts: build.query({
      query: ({ limit, search, token }) => {
        return {
          url: `api/contacts`,
          method: "GET",
          params: { limit, search },
          headers: { Authorization: `Bearer ${token}` },
        };
      },
    }),
    ping: build.mutation({
      query: (token) => ({
        url: `auth/ping`,
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    verifyEmail: build.mutation({
      query: (body) => ({
        url: `auth/verifyEmail`,
        method: "POST",
        body,
      }),
    }),
    forgotPassword: build.mutation({
      query: (body) => ({
        // url: `auth/resetPassword`,
        url: `api/forgotPassword`,
        method: "POST",
        body,
      }),
    }),
    resetPassword: build.mutation({
      query: ({ body }) => ({
        url: `api/resetPassword`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Users"],
    }),
    updateUser: build.mutation({
      query: ({ id, token, body }) => ({
        url: `api/userById/${id}`,
        method: "PUT",
        headers: { Authorization: `Bearer ${token}` },
        body,
      }),
      invalidatesTags: ["Users"],
    }),
    updateUserPassword: build.mutation({
      query: ({ id, token, body }) => ({
        url: `api/userPasswordById/${id}`,
        method: "PUT",
        headers: { Authorization: `Bearer ${token}` },
        body,
      }),
      invalidatesTags: ["Users"],
    }),
    addUserBuilding: build.mutation({
      query: ({ id, token, body }) => ({
        url: `api/addUserBuildingById/${id}`,
        method: "PUT",
        headers: { Authorization: `Bearer ${token}` },
        body,
      }),
      invalidatesTags: ["Users"],
    }),
    removeUserBuilding: build.mutation({
      query: ({ id, token, body }) => ({
        url: `api/removeUserBuildingById/${id}`,
        method: "PUT",
        headers: { Authorization: `Bearer ${token}` },
        body,
      }),
      invalidatesTags: ["Users"],
    }),
    updateUserBuilding: build.mutation({
      query: ({ id, token, body }) => ({
        url: `api/updateUserBuildingById/${id}`,
        method: "PUT",
        headers: { Authorization: `Bearer ${token}` },
        body,
      }),
      invalidatesTags: ["Users"],
    }),
    addUserAddress: build.mutation({
      query: ({ id, token, body }) => ({
        url: `api/addUserAddressById/${id}`,
        method: "PUT",
        headers: { Authorization: `Bearer ${token}` },
        body,
      }),
      invalidatesTags: ["Users"],
    }),
    removeUserAddress: build.mutation({
      query: ({ id, token, body }) => ({
        url: `api/removeUserAddressById/${id}`,
        method: "PUT",
        headers: { Authorization: `Bearer ${token}` },
        body,
      }),
      invalidatesTags: ["Users"],
    }),
    updateUserAddress: build.mutation({
      query: ({ id, token, body }) => ({
        url: `api/updateUserAddressById/${id}`,
        method: "PUT",
        headers: { Authorization: `Bearer ${token}` },
        body,
      }),
      invalidatesTags: ["Users"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useRegisterMutation,
  useVerifyOTPMutation,
  useAddGuestMutation,
  useLoginMutation,
  useSendOTPMutation,
  useGetUserByIdQuery,
  useVerifyEmailMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useUpdateUserMutation,
  useUpdateUserPasswordMutation,
  useAddUserBuildingMutation,
  useRemoveUserBuildingMutation,
  useUpdateUserBuildingMutation,
  useAddUserAddressMutation,
  useUpdateUserAddressMutation,
  useRemoveUserAddressMutation,
  usePingMutation,
  useGetUsersCountQuery,
  useGetContactsQuery,
} = authApi;
