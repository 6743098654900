import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { CONFIG_KEY_SETTLEDOWN, REGEX_JSON, URL_CHATROOM } from "../../constants";
import { LOCALES } from "../../i18n/locales";
import { useTheme } from "@emotion/react";
import { ImageCovered } from "../../components/imageCover/ImageCover";
import { ChatBubble, Email } from "@mui/icons-material";
import { Star } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import useImage from "../../hooks/useImage";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  ...theme.typography.h4,
  color: theme.palette.text.primary,
  "& > :not(style) ~ :not(style)": {
    marginTop: theme.spacing(2),
  },
}));

const Settledown = () => {
  const theme = useTheme();
  const navigate = useNavigate()
  const [data, setData] = useState(null);
  const config = useSelector((state) => state.config)?.config;
  const locale =
    useSelector((state) => {
      return state.persisted.global.locale;
    })?.value || LOCALES.ENGLISH;
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const imageFunc = useImage()
  useEffect(() => {
    if (config) {
      if (config[CONFIG_KEY_SETTLEDOWN]) {
        const rawData = config[CONFIG_KEY_SETTLEDOWN];
        if (rawData && rawData?.match(REGEX_JSON)) {
          const jsonData = JSON.parse(rawData);
          // console.log('jsonData', jsonData.services[1].items)
          setData(jsonData);
        }
      }
    }
  }, [config]);
  return (
    <Stack
      width="100%"
      height="100%"
      justifyContent={"start"}
      alignItems={"center"}
      py={5}
      // border={`1px solid ${theme.palette.grey["200"]}`}
    >
      <Box width={mobile ? "100%" : "80%"} mt={5}>
        <Root>
          <Divider sx={{ color: theme.palette.grey["600"] }}>
            {data?.title[locale]}
          </Divider>
        </Root>
      </Box>

      <Stack
        width={mobile ? "100%" : "75%"}
        justifyContent={"start"}
        alignItems={"center"}
        spacing={3}
      >
        {data?.services?.length > 0 && (
          <Grid
            container
            //   spacing={{md: 1}}
            // columns={{xs: 4, sm: 8, md: 12}}
            justifyContent="center"
            width={"100%"}
            p={3}
          >
            {data?.services?.map((service) => (
              <Grid item xs={12} md={6} lg={4} key={service.title["en-US"]}>
                <Stack
                  justifyContent={"start"}
                  alignItems={"center"}
                  spacing={3}
                  px={2}
                  py={5}
                >
                  <Stack width="100%" alignItems={"center"}>
                    <ImageCovered
                      src={
                        imageFunc({image: service?.image})
                      }
                      sx={{
                        width: "auto",
                        height: "180px",
                        borderRadius: "50%",
                      }}
                      onClick={() => {}}
                    />
                  </Stack>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "20px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    {service?.title[locale]}
                  </Typography>
                  <Stack
                    width={"100%"}
                    justifyContent={"start"}
                    alignItems={"center"}
                  >
                    <List sx={{ width: "100%" }}>
                      {service?.items[locale]?.map((item, idx) => (
                        <ListItem key={idx} sx={{ width: "100%" }}>
                          <ListItemIcon>
                            <Star />
                          </ListItemIcon>
                          <ListItemText>
                            <Typography variant="body1">{item}</Typography>
                          </ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        )}
        <Typography
          variant="h5"
          fontFamily="Montserrat, arial, sans-serif"
          textAlign={"center"}
          color={theme.palette.amber["900"]}
        >
          <FormattedMessage id="service.extra" />
        </Typography>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={3}
          mb={5}
          py={2}
        >
          <Button
            variant="contained"
            href={`mailto: ${process.env.REACT_APP_EMAIL_SUPPORT}`}
            sx={{
              width: "150px",
              py: 1,
              fontSize: "16px",
              fontWeight: 500,
              borderRadius: 0,
            }}
            startIcon={<Email fontSize="medium" />}
          >
            <FormattedMessage id="home.email.us" />
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "150px",
              py: 1,
              fontSize: "16px",
              fontWeight: 500,
              borderRadius: 0,
            }}
            startIcon={<ChatBubble fontSize="medium" />}
            onClick={() => {
              navigate(URL_CHATROOM)
            }}
          >
            <FormattedMessage id="home.message.us" />
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Settledown;
