import axios from "axios";
import { API_S3_BUCKET_OBJECT, API_S3_BUCKET_OBJECTS, API_S3_SIGNED_URL } from "../constants";
import { apiCall } from "../utils/makeRequest";

export const getSignedUrl = ({ token, directory, key, content_type }) => {
  console.log("token", token);
  return apiCall(API_S3_SIGNED_URL, {
    method: "POST",
    data: {
      directory,
      key,
      content_type,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getBucketObjects = ({ token, delimiter, prefix }) => {
  console.log("token", token);
  return prefix?.length > 0 ? apiCall(API_S3_BUCKET_OBJECTS, {
    method: "GET",
    params: { delimiter, prefix },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }) : new Promise((resolve, reject) => {
    resolve([])
  });
};

export const uploadFileToSignedUrl = ({
  signedUrl,
  file,
  contentType,
  onProgress,
  onComplete,
}) => {
  return axios.put(signedUrl, file, {
    onUploadProgress: onProgress,
    headers: {
      "Content-Type": contentType,
    },
  }).then((response) => {
      onComplete(response);
    })
    .catch((err) => {
      console.log('err', err);
    });
};

export const deleteBucketObject = ({ token, key }) => {
  console.log("token", token);
  return apiCall(API_S3_BUCKET_OBJECT, {
    method: "DELETE",
    params: { key },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
