import { api } from "./api";

const productApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProducts: build.query({
      query: ({
        page,
        pageSize,
        sort,
        search,
        vendor,
        location,
        category,
      }) => ({
        url: "api/products",
        method: "GET",
        params: { page, pageSize, sort, search, vendor, location, category },
        // headers: { token: `Bearer ${token}` },
      }),
      providesTags: (result) =>
        result?.products
          ? [
              ...result.products.map(({ _id }) => ({
                type: "Products",
                id: _id,
              })),
              { type: "Products", id: "LIST" },
            ]
          : [{ type: "Products", id: "LIST" }],
    }),
    getSoldoutProducts: build.query({
      query: ({ page, pageSize, vendor, location }) => ({
        url: "api/productsSoldout",
        method: "GET",
        params: { page, pageSize, vendor, location },
        // headers: { token: `Bearer ${token}` },
      }),
    }),
    getUnavailableProducts: build.query({
      query: ({ productIds, vendor, location }) => ({
        url: "api/productsUnavailable",
        method: "GET",
        params: { productIds, vendor, location },
        // headers: { token: `Bearer ${token}` },
      }),
    }),
    getProductById: build.query({
      query: ({ id }) => {
        return {
          url: `api/productById/${id}`,
          method: "GET",
          // headers: { Authorization: `Bearer ${token}` },
        };
      },
      providesTags: (result) => [{ type: "Products", id: result?._id }],
    }),
    updateProductRatingById: build.mutation({
      query: ({id, token, body}) => ({
        url: `api/productRatingById/${id}`,
        method: 'PUT',
        body,
        headers: { Authorization: `Bearer ${token}` },
      }),
      invalidatesTags: ['Products'],
  }),
  }),

  overrideExisting: false,
});

export const {
  useGetProductsQuery,
  useGetSoldoutProductsQuery,
  useGetUnavailableProductsQuery,
  useGetProductByIdQuery,
  useUpdateProductRatingByIdMutation,
} = productApi;
