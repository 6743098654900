import { createSlice } from "@reduxjs/toolkit";

const configSlice = createSlice({
    name: 'config',
    initialState: {
        config: {}
    },
    reducers: {
        setConfig: (state, action) => {
            state.config = action.payload
        },
    }
})

export const {setConfig} = configSlice.actions
export default configSlice.reducer