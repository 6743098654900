import { createSlice } from "@reduxjs/toolkit";

const postSlice = createSlice({
    name: 'post',
    initialState: {
        issues: [],
        myIssues: [],
        requests: [],
        myRequests: [],
        messages: [],
        unreadMessageCount: 0,
    },
    reducers: {
        setIssues: (state, action) => {
            state.issues = action.payload
        },
        setMyIssues: (state, action) => {
            state.myIssues = action.payload
        },
        setRequests: (state, action) => {
            state.requests = action.payload
        },
        setMyRequests: (state, action) => {
            state.myRequests = action.payload
        },
        setMessages: (state, action) => {
            state.messages = action.payload
        },
        setUnreadMessageCount: (state, action) => {
            state.unreadMessageCount = action.payload
        },
    }
})

export const {setIssues, setMyIssues, setRequests, setMyRequests, setMessages, setUnreadMessageCount} = postSlice.actions
export default postSlice.reducer