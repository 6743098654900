import {
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { REGEX_JSON } from "../../constants";
import { LOCALES } from "../../i18n/locales";
import { ImageCovered } from "../../components/imageCover/ImageCover";
import useImage from "../../hooks/useImage";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  ...theme.typography.h4,
  color: theme.palette.text.primary,
  "& > :not(style) ~ :not(style)": {
    marginTop: theme.spacing(2),
  },
}));

const cssAppliedContent = (locale, body) => {
  return locale === LOCALES.ENGLISH
    ? `
    <div class="introBoards">
        <style>
            div.introBoards p, div.introBoards li {
                font-family: Roboto, arial, sans-serif;
                font-size: 18px;
                line-height: 30px;
            } 
        </style>
      ${body}
    <div>`
    : `
    <div class="introBoards">
        <style>
            div.introBoards p, div.introBoards li {
                font-family: Montserrat, arial, sans-serif;
                font-size: 18px;
                line-height: 30px;
            } 
        </style>
      ${body}
    <div>`;
};

const cssAppliedContent2 = (locale, body) => {
  return locale === LOCALES.ENGLISH
    ? `
      <div class="boardFeature">
          <style>
            div.boardFeature p, div.boardFeature li {
                  font-family: Roboto, arial, sans-serif;
                  font-size: 18px;
                  line-height: 32px;
                    color: #454F5B;
              }
              div.boardFeature p {
                text-align: center;
              }
              div.boardFeature ul {
                padding-left: 20px
              }
          </style>
        ${body}
      <div>`
    : `
      <div class="boardFeature">
          <style>
          div.boardFeature  p, div.boardFeature  li {
                font-family: Montserrat, arial, sans-serif;
                font-size: 18px;
                line-height: 32px;
                color: #454F5B;
            }
            div.boardFeature  p {
                text-align: center;
            }
            div.boardFeature  ul {
                padding-left: 20px;
            }
          </style>
        ${body}
      <div>`;
};

const Secondary = ({
  dataIntroSchoolBoards,
  dataSchoolBoards,
  dataSchools,
  mobile,
  locale,
}) => {
  const theme = useTheme();
  const [introSchoolBoards, setIntroSchoolBoards] = useState(null);
  const [schoolBoards, setSchoolBoards] = useState(null);
  const [schools, setSchools] = useState(null);
  const imageFunc = useImage()
  useEffect(() => {
    if (dataIntroSchoolBoards && dataIntroSchoolBoards?.match(REGEX_JSON)) {
      const jsonData = JSON.parse(
        dataIntroSchoolBoards.replaceAll("&lt;", "<")
      );
      setIntroSchoolBoards(jsonData);
    }
  }, [dataIntroSchoolBoards]);

  useEffect(() => {
    // console.log("dataSchoolBoards", dataSchoolBoards);
    if (dataSchoolBoards && dataSchoolBoards?.match(REGEX_JSON)) {
      const jsonData = JSON.parse(dataSchoolBoards.replaceAll("&lt;", "<"));
      setSchoolBoards(jsonData);
    }
  }, [dataSchoolBoards]);

  useEffect(() => {
    // console.log("dataSchools", dataSchools);
    if (dataSchools && dataSchools?.match(REGEX_JSON)) {
      const jsonData = JSON.parse(dataSchools.replaceAll("&lt;", "<"));
      setSchools(jsonData);
    }
  }, [dataSchools]);

  return (
    <Stack
      width={mobile ? "100%" : "80%"}
      justifyContent={"start"}
      alignItems={"center"}
      spacing={3}
      py={5}
    >
      <Stack
        width="80%"
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Root>
          <Divider sx={{ color: theme.palette.grey["600"] }}>
            {introSchoolBoards?.title[locale]}
          </Divider>
        </Root>
      </Stack>
      {introSchoolBoards?.image?.length > 0 && (
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          py={1}
        >
          <ImageCovered
            src={imageFunc({image: introSchoolBoards?.image})}
            width={"360px"}
            height={"auto"}
          />
        </Stack>
      )}
      <Box
          width={mobile ? "100%" : "70%"}
          px={5}
          py={2}
          dangerouslySetInnerHTML={{
            __html: cssAppliedContent(
              locale,
              introSchoolBoards?.content[locale]
            ),
          }}
        ></Box>

      <Stack
        width="80%"
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        mt={6}
      >
        <Root>
          <Divider sx={{ color: theme.palette.grey["600"] }}>
            {schoolBoards?.title[locale]}
          </Divider>
        </Root>
      </Stack>
      <Grid container justifyContent="center" width={"100%"} p={3}>
        {schoolBoards?.boards?.map((board) => (
          <Grid item xs={12} md={6} lg={4} key={board.title["en-US"]}>
            <Stack
              justifyContent={"start"}
              alignItems={"center"}
              spacing={3}
              // px={2}
              py={3}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: "20px", fontWeight: 600, textAlign: "center" }}
              >
                {board?.title[locale]}
              </Typography>
              <Stack width="100%" alignItems={"center"}>
                <ImageCovered
                  src={imageFunc({image: board?.image})}
                  sx={{ width: "auto", height: "240px", borderRadius: "50%" }}
                  onClick={() => {}}
                />
              </Stack>
              <Stack width="100%" alignItems={"center"} px={3}>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "18px",
                    textAlign: "left",
                    color: theme.palette.grey["800"],
                    lineHeight: 1.8,
                  }}
                >
                  {board?.description[locale]}
                </Typography>
              </Stack>
                <Box
                  width={"100%"}
                  px={3}
                  // py={2}
                  dangerouslySetInnerHTML={{
                    __html: cssAppliedContent2(locale, board?.features[locale]),
                  }}
                ></Box>
            </Stack>
          </Grid>
        ))}
      </Grid>
      <Stack
        width="80%"
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        mt={6}
      >
        <Root>
          <Divider sx={{ color: theme.palette.grey["600"] }}>
            {schools?.title[locale]}
          </Divider>
        </Root>
      </Stack>
      <Grid container justifyContent="center" width={"100%"} p={3}>
        {schools?.schools?.map((school) => (
          <Grid item xs={12} md={6} lg={4} key={school.title}>
            <Stack
              justifyContent={"start"}
              alignItems={"center"}
              spacing={3}
              // px={2}
              py={3}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: "20px", fontWeight: 600, textAlign: "center" }}
              >
                {school?.title}
              </Typography>
              <Stack width="100%" alignItems={"center"}>
                <ImageCovered
                  src={imageFunc({image: school?.image})}
                  sx={{ width: "auto", height: "180px",}}
                  onClick={() => {}}
                />
              </Stack>
              <Stack width="100%" alignItems={"center"} px={3}>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "18px",
                    textAlign: "left",
                    color: theme.palette.grey["800"],
                    lineHeight: 1.8,
                  }}
                >
                  {school?.description[locale]}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default Secondary;
