import { styled } from "@mui/material/styles";
import { Box, Typography, Button, List, IconButton, ListItemText, ListItemButton } from "@mui/material";

export const DrawerCloseButton = styled(IconButton)(({theme}) => ({
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`
}))

export const DrawerList = styled(List)(({theme}) => ({
    width: '100%',
    // borderBottom: `1px solid ${theme.palette.grey['50']}`,
    '&& .Mui-selected, && .Mui-selected:hover': {
        backgroundColor: theme.palette.grey['200'],
    },
}))

export const DrawerItemButton = styled(ListItemButton)(() => ({
    fontFamily: 'Urbanist',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textTransform: 'capitalize',
    cursor: 'pointer',
    fontWeight: 400,
    padding: '15px 20px',
}))