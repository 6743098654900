import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: 'user',
    initialState: {
        currentUser: null,
        otpUser: null,
        usersCount: 0,
    },
    reducers: {
        setUser: (state, action) => {
            state.currentUser = action.payload
        },
        setOtpUser: (state, action) => {
            state.otpUser = action.payload
        },
        setUsersCount: (state, action) => {
            state.usersCount = action.payload
        },
    }
})

export const {setUser, setOtpUser, setUsersCount} = userSlice.actions
export default userSlice.reducer