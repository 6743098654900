import { Box, Button, Dialog, DialogContent, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, {useState} from 'react'
import { useSelector } from 'react-redux'
import { ModalTitle } from '../../styles/modal'
import { FormattedMessage } from 'react-intl'
import { GridCloseIcon } from '@mui/x-data-grid'
import { LOCALES } from '../../i18n/locales'

const Notification = ({open, onClose, notification}) => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('md'))
    const [localSelected, setLocaleSelected] = useState(LOCALES.ENGLISH)
    const locale = useSelector((state) => state.persisted.global.locale)?.value || LOCALES.ENGLISH;
  return (
    <Dialog 
        // TransitionComponent={slideTransition} 
        variant='permanent'
        open={open} 
        fullWidth
        maxWidth="sm">
        <ModalTitle sx={{backgroundColor: theme.palette.primary.main}}>
            <Box display='flex' 
                alignItems='center' 
                justifyContent='space-between'
                fontFamily={["Martel Sans", "PT Sans", 'Fira Sans', "adobe-caslon-pro", "Roboto", "cursive"].join(",")}>
                <FormattedMessage id='general.notifications' />
                <IconButton sx={{color: theme.palette.common.white}} onClick={onClose}>
                    <GridCloseIcon />
                </IconButton>
            </Box>
        </ModalTitle>
        <DialogContent>
            <Stack width='100%' display={'flex'} justifyContent={'center'} alignItems={'center'} p={3}>
                <Typography variant='h6' mb={2} color={theme.palette.warning.main} sx={{textAlign: 'center'}}>
                    {notification?.title[locale]}
                </Typography>
                <Typography variant='body2' color='text.secondary' sx={{textAlign: 'center', mb: 3, }}>
                    {notification?.details[locale]}
                </Typography>
                <Button variant='contained' sx={{backgroundColor: theme.palette.primary.main}} onClick={() => onClose()}>
                    <FormattedMessage id='app.ok' />
                </Button>
            </Stack>
        </DialogContent>
    </Dialog>
  )
}

export default Notification