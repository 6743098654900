
// URLs
export const URL_HOME = '/'
export const URL_LOGIN = '/login'
export const URL_REGISTER = '/register'
export const URL_VERIFY_OTP = '/verify-otp'
export const URL_FORGOT_PASSWORD = '/forgot-password'
export const URL_NEW_PASSWORD = '/new-password'
export const URL_VERIFY_EMAIL = '/verify-email/:emailToken'
export const URL_VERIFY_EMAIL_2 = '/verify-email'
export const URL_UPDATE_PROFILE = '/profile/update-profile'
export const URL_CHANGE_PASSWORD = '/profile/change-password'
export const URL_COMMUNITIES = '/profile/communities'
export const URL_COUPONS = '/profile/coupons'
export const URL_ADD_COMMUNITY = '/profile/add-community'
export const URL_UPDATE_COMMUNITY = '/profile/update-community/:buildingIndex'
export const URL_UPDATE_COMMUNITY_2 = '/profile/update-community/'
export const URL_ORDERS = '/profile/orders'
export const URL_CHATBOX = '/profile/chatbox'
export const URL_CHATROOM = '/chatroom'
export const URL_POSTS = '/profile/posts'
export const URL_ISSUES = '/profile/issues'
export const URL_REQUESTS = '/profile/requests'
export const URL_ACTIVITIES = '/profile/activities'
export const URL_MESSAGES = '/profile/messages'
export const URL_RESET_PASSWORD = '/reset-password/:emailToken'
export const URL_RESET_PASSWORD_2 = '/reset-password'
export const URL_FARMS = '/farms'
export const URL_FARMS_PRODUCTS = '/farms/products/:id'
export const URL_FARMS_PRODUCTS_2 = '/farms/products/'
export const URL_FARMS_DELIVERIES = '/farms/deliveries/:vendorId'
export const URL_FARMS_DELIVERIES_2 = '/farms/deliveries/'
export const URL_FARMS_VENDORS = '/farms/vendor/:id'
export const URL_FARMS_VENDORS_2 = '/farms/vendor/'
export const URL_COMMERCIAL_COMMERCIALS = '/commercial/commercials'
export const URL_COMMERCIAL_DELIVERIES = '/commercial/commercials/deliveries/:vendorId'
export const URL_COMMERCIAL_DELIVERIES_2 = '/commercial/commercials/deliveries/'
export const URL_COMMERCIAL_VENDORS = '/commercial/commercials/vendor/:id'
export const URL_COMMERCIAL_VENDORS_2 = '/commercial/commercials/vendor/'
export const URL_COMMERCIAL_PRODUCTS = '/commercial/commercials/products/:id'
export const URL_COMMERCIAL_PRODUCTS_2 = '/commercial/commercials/products/'
export const URL_COMMERCIAL_PRODUCT = '/commercial/product/:id'
export const URL_COMMERCIAL_PRODUCT_2 = '/commercial/product/'
export const URL_COMMERCIAL_CART = '/cart'
export const URL_COMMERCIAL_CHECKOUT = '/checkout'
export const URL_COMMERCIAL_RESTAURANTS = '/commercial/restaurants'
export const URL_COMMERCIAL_COUPONS = '/commercial/coupons'
export const URL_COMMERCIAL_NEWSLETTERS = '/commercial/newsletters'
export const URL_STRATA_SUMMARY = '/strata/summary'
export const URL_STRATA_CONTACTS = '/strata/contacts'
export const URL_STRATA_NOTIFICATIONS = '/strata/notifications'
export const URL_STRATA_DOCUMENTS = '/strata/documents'
export const URL_STRATA_ISSUES = '/strata/issues'
export const URL_COMMUNITY_ISSUES = '/community/issues'
export const URL_COMMUNITY_REQUESTS = '/community/requests'
export const URL_COMMUNITY_ADS = '/community/market'
export const URL_COMMUNITY_ACTIVITIES = '/community/activities'
export const URL_CONTACT_US = '/contactus'
export const URL_CONTACT = '/contact'
export const URL_NOTIFICATIONS = '/notifications'
export const URL_FAQS = '/faqs'
export const URL_RENTAL_MAIN = '/rental/main'
export const URL_REALTY_MAIN = '/realty/main'
export const URL_FRIENDS = '/friends'
export const URL_STUDY_BC = '/abroad/studyBC'
export const URL_IMMIGRATE = '/abroad/immigration'
export const URL_SETTLEDOWN = '/settlement'
export const URL_DOMESTIC_MAIN = '/domestic'

export const LOGO_HEADER_EN = '/assets/images/logo-003_en.jpg'
export const LOGO_HEADER_CN = '/assets/images/logo-003_cn.jpg'

export const REGEX_PASSWORD = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/
export const REGEX_PHONE =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
export const REGEX_URL = /^((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
export const REGEX_JSON = /^((\[[^\}]+)?\{s*[^\}\{]{3,}?:.*\}([^\{]+\])?)$/


// Alert severity
export const SEVERITY_SUCCESS = 'success'
export const SEVERITY_ERROR = 'error'
export const SEVERITY_INFO = 'info'
export const SEVERITY_WARNING = 'warning'

// Button size
export const INCDEC_SIZE_LARGE = 1
export const INCDEC_SIZE_SMALL = 2

// Carousel
export const CAROUSEL_SLIDE_INTERVAL = 6000
export const CAROUSEL_SLIDE_DURATION = 1000

// Home Service Feature Images
export const IMG_FEATURE_LEFT = 'dev/homeCarousels/service-feature-consulting-n95VMLxqM2I1705463936859.jpg'
export const IMG_FEATURE_RIGHT = 'dev/homeCarousels/service-feature-finance-Dc2SRspMak41705463936876.jpg'
export const IMG_PROCESS_LEFT = 'https://img1.wsimg.com/isteam/stock/11253/:/rs=w:900,h:600,cg:true,m/cr=w:900,h:600'

// Sidebar Type
export const SIDEBAR_NONE = 0
export const SIDEBAR_PROFILE = 1
export const SIDEBAR_STRATA = 2
export const SIDEBAR_COMMUNITY = 3
export const SIDEBAR_COMMERCIAL = 4

export const POST_IMAGE_LIMIT = 3

export const TEXTAREA_MAX_LENGTH = 350

export const CONFIG_KEY_HOME_CAROUSELS = 'homeCarousels'
export const CONFIG_KEY_UNIVERSITIES = 'universities'
export const CONFIG_KEY_INTRO_SCHOOL_BOARDS = 'introSchoolBoards'
export const CONFIG_KEY_SCHOOL_BOARDS = 'schoolBoards'
export const CONFIG_KEY_SCHOOLS = 'schools'
export const CONFIG_KEY_SCHOOL_APPLICATION = 'schoolApplication'
export const CONFIG_KEY_IMMIGRATE_INFO = 'immigrateInfo'
export const CONFIG_KEY_SETTLEDOWN = 'settledown'
export const CONFIG_KEY_DOMESTIC = 'lifeServices'

export const EMPTY_JSON_INTL = {
  'en-US': '',
  'zh-CN': '',
  'zh-TW': '',
}

export const EMOJI_CATEGORIES = [
  {
    category: 'suggested',
    name: 'Recently Used'
  },
  {
    category: 'smileys_people',
    name: 'Faces...'
  }
];

export const CONTACT_CATEGORIES = [
  // {
  //   key: 'strata',
  //   value: 'app.strata'
  // }, 
  {
    key: 'commercial',
    value: 'app.commercial'
  },
  {
    key: 'refund',
    value: 'general.refund'
  },
  {
    key: 'community',
    value: 'app.community'
  },
  {
    key: 'investment',
    value: 'footer.investments'
  },
  // {
  //   key: 'complain',
  //   value: 'general.complain'
  // },
  
  {
    key: 'other',
    value: 'general.other'
  },
]

export const USER_ROLES = [
  {
    key: 'resident',
    value: 'role.resident'
  }, 
  {
    key: 'landlord',
    value: 'role.landlord'
  }, 
  {
    key: 'strataManager',
    value: 'role.strata.manager'
  }, 
  {
    key: 'buildingManager',
    value: 'role.building.manager'
  }, 
  {
    key: 'concierge',
    value: 'role.strata.concierge'
  }, 
  {
    key: 'councilor',
    value: 'role.councilor'
  }, 
  {
    key: 'myOffice',
    value: 'role.my.office'
  }
]

export const USER_ROLES_OBJ = {
  'resident': 'role.resident',
  'landlord': 'role.landlord',
  'strataManager': 'role.strata.manager',
  'buildingManager': 'role.building.manager',
  'concierge': 'role.strata.concierge',
  'councilor': 'role.councilor',
  'myOffice': 'role.my.office',
}

export const EMPTY_CART = {
  vendor: '',
  notes: '',
  shipping: 'pickup',
  subtotal: 0,
  discount: 0,
  quantity: 0,
  products: [],
  tags: '',
  user: 'null',
}

export const NULL_USER = 'null'

export const SHIPPING_DELIVERY = 'delivery'
export const SHIPPING_PICKUP = 'pickup'

export const CONTACT_ELEMENT_CONTACT = 'contact'
export const CONTACT_ELEMENT_REQUEST = 'request'
export const CONTACT_ELEMENT_CONVERSATION = 'conversation'
export const CONTACT_ELEMENT_SEARCH_RESULT = 'searchResult'

export const Message_options = [
  {
    title: "chat.reply.message",
    incoming: true,
  },
  {
    title: "chat.delete.message",
    incoming: false,
  },
];

export const Contact_options = [
  {
    title: 'chat.send.message'
  },
  {
    title: 'chat.view.contact'
  },
  {
    title: 'chat.delete.contact'
  },
]

export const API_S3_SIGNED_URL = '/api/s3/signedUrl'
export const API_S3_BUCKET_OBJECTS = '/api/s3/bucketObjects'
export const API_S3_BUCKET_OBJECT = '/api/s3/bucketObject'

export const S3_DIRECTORY_USERS = 'users'
export const S3_DIRECTORY_CHATROOM = 'chatrooms'

export const S3_ATTACH_IMAGE = 'Image'
export const S3_ATTACH_DOC = 'Doc'

export const LOCALE_ITEMS = [
  {
    title: 'English',
    value: 'en-US',
  },
  {
    title: '简体中文',
    value: 'zh-CN',
  },
  {
    title: '繁體中文',
    value: 'zh-TW',
  },
]
