import { createSlice } from "@reduxjs/toolkit";

const vendorSlice = createSlice({
    name: 'vendor',
    initialState: {
        vendors: [],
        vendorsTotal: 0,
        vendorCategories: [],
        selected: null,
    },
    reducers: {
        setVendors: (state, action) => {
            state.vendors = action.payload
        },
        setSelectedVendor: (state, action) => {
            state.selected = action.payload
        },
        setVendorCategories: (state, action) => {
            state.vendorCategories = action.payload
        },
        setVendorsTotal: (state, action) => {
            state.vendorsTotal = action.payload
        },
    }
})

export const {setVendors, setSelectedVendor, setVendorCategories, setVendorsTotal} = vendorSlice.actions
export default vendorSlice.reducer