import React, {useEffect, useState} from 'react'
import { styled } from "@mui/material/styles";
import { FormattedMessage } from 'react-intl'
import ReactMapGL, { Marker, Popup } from "react-map-gl"
import RoomIcon from '@mui/icons-material/Room';
import { useSelector } from 'react-redux';
import { Box, Typography, useTheme } from '@mui/material';
import useImage from '../../hooks/useImage';

export const PopupWrapper = styled(Box)(({ type }) => ({
    width: '250px',
    // height: '250px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    zIndex: 999,
}))

export const PopupImage = styled('img')(({ type }) => ({
    width: '120px',
    height: '120px',
    objectFit: 'cover',
    borderRadius: '10px',
    marginBottom: '15px',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.22)',
}))

export const PopupTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.blueGrey['900'],
    fontSize: '1em'
}))

export const PopupText = styled(Typography)(({ theme }) => ({
    color: theme.palette.blue['900'],
    fontSize: '0.9em'
}))

const Mapbox = ({coords, width, height, selectedBuilding, setSelectedBuilding}) => {
    const theme = useTheme()
    const [currentPlaceId, setCurrentPlaceId] = useState(null);
    const buildings = useSelector((state) => state.building.buildings)
    const [viewport, setViewport] = useState({
        width: width || '100%',
        height: height || '500px',
        latitude: 49.26527,
        longitude: -123.00524,
        zoom: 16,
    });

    const imageFunc = useImage()

    useEffect(() => {
        if (selectedBuilding) {
            setCurrentPlaceId(selectedBuilding?._id);
            setViewport({
                ...viewport,
                latitude: Number(selectedBuilding?.lat) || 49.26527,
                longitude: Number(selectedBuilding?.long) || -123.00524,
            })
        }
    }, [selectedBuilding])

    useEffect(() => {
        if (coords && coords?.length > 0) {
            setViewport({
                ...viewport,
                latitude: Number(coords[0]?.lat) || 49.26527,
                longitude: Number(coords[0]?.long) || -123.00524,
            })
        }
    }, [coords])

    const handleMarkerClick = (id, lat, long) => {
        setCurrentPlaceId(id);
        setViewport({ ...viewport, latitude: Number(lat), longitude: Number(long) });
        // setSelectedBuilding && setSelectedBuilding(id)
    };

    const handleBuildingClick = (building) => {
        setCurrentPlaceId(building?._id);
        setViewport({ ...viewport, latitude: Number(building?.lat), longitude: Number(building?.long) });
        setSelectedBuilding && setSelectedBuilding(building)
    }

  return (
    <>
        <ReactMapGL {...viewport} 
            width={width || '100%'}
            height={height || '500px'}
            transitionDuration="200"
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX}
            mapStyle="mapbox://styles/mapbox/streets-v11"
            onViewportChange={(nextViewport) => setViewport(nextViewport)} 
        >
            {buildings?.map((building) => (
                <div key={building._id}>
                    <Marker
                        latitude={Number(building.lat)}
                        longitude={Number(building.long)}
                        offsetLeft={-1.5 * viewport.zoom}
                        offsetTop={-2 * viewport.zoom}
                        className='marker'
                        // key={building.id}
                    >
                        <RoomIcon sx={{
                                fontSize: 2 * viewport.zoom,
                                color: currentPlaceId === building._id ? theme.palette.warning.main : theme.palette.info.main,
                                cursor: "pointer",
                            }}
                            onClick={() => handleBuildingClick(building)}
                        />
                    </Marker>
                    {building._id === currentPlaceId && (
                        <Popup
                            // key={building.id}
                            latitude={Number(building.lat)}
                            longitude={Number(building.long)}
                            closeButton={true}
                            closeOnClick={true}
                            onClose={() => setCurrentPlaceId(null)}
                            anchor="left"
                            className='popup'
                        >
                            <PopupWrapper>
                                <PopupImage src={imageFunc({image: building.images[0]})} alt='Building Image' />
                                <PopupTitle variant='body2'>{building.name}</PopupTitle>
                                <PopupText variant='body2'>{building.address}</PopupText>
                                <PopupText variant='body2'>
                                    <FormattedMessage id="home.members" />:&nbsp;&nbsp;{building.members.length}
                                </PopupText>
                            </PopupWrapper>
                        </Popup>
                    )}
                </div>
            ))}
            {coords?.length > 0 && coords.map((coord) => (
                <div key={coord.id}>
                    <Marker
                        latitude={coord.lat}
                        longitude={coord.long}
                        offsetLeft={-1.5 * viewport.zoom}
                        offsetTop={-2 * viewport.zoom}
                        className='marker'
                        // key={coord.id}
                    >
                        <RoomIcon sx={{
                                fontSize: 2 * viewport.zoom,
                                color: currentPlaceId === coord.id ? theme.palette.primary.main : theme.palette.error.main,
                                cursor: "pointer",
                            }}
                            onClick={() => handleMarkerClick(coord.id, coord.lat, coord.long)}
                        />
                    </Marker>
                    {coord.id === currentPlaceId && (
                        <Popup
                            // key={coord.id}
                            latitude={coord.lat}
                            longitude={coord.long}
                            closeButton={true}
                            closeOnClick={true}
                            onClose={() => setCurrentPlaceId(null)}
                            anchor="left"
                            className='popup'
                        >
                            <PopupWrapper>
                                <PopupTitle variant='body2' sx={{marginBottom: '10px'}}><FormattedMessage id='community.new.home' /></PopupTitle>
                                <PopupText variant='body2'>{coord.addr}</PopupText>
                            </PopupWrapper>
                        </Popup>
                    )}
                </div>
            ))}
        </ReactMapGL>
    </>
  )
}

export default Mapbox