import React from "react";
import {
  Stack,
  Typography,
  Divider,
  useTheme,
} from "@mui/material";

const Timeline = ({ el }) => {
  const theme = useTheme();
  return (
    <Stack
      width={"100%"}
      direction="row"
      alignItems={"center"}
      justifyContent="space-between"
      py={3}
    >
      <Divider width="35%" />
      <Typography variant="caption" sx={{ color: theme.palette.text }}>
        {el.message}
      </Typography>
      <Divider width="35%" />
    </Stack>
  );
};

export default Timeline;
