import React, { useMemo } from "react";
import { Stack, Box, Avatar, useTheme } from "@mui/material";
import Embed from "react-embed";
import { ChatBubble, ChatText } from "./ChatBubble";
import MessageOption from "./MessageOption";

const LinkBubble = ({ el, mobile, searched }) => {
  const theme = useTheme();
  const rawUrl = useMemo(() => {
    if (el.message?.length > 0) {
      if (el.message?.indexOf('<a href="') < 0) {
        return el.message;
      } else {
        const startIdx = '<a href="'.length;
        const endIdx = el.message?.indexOf('" target=');
        return el.message.slice(startIdx, endIdx);
      }
    }
  }, [el?.message]);
  return (
    <ChatBubble
      mobile={mobile}
      incoming={el.incoming ? "yes" : "no"}
      bgcolor={!searched ? (el.incoming ? "lightyellow" : theme.palette.green["100"]) : theme.palette.amber['900']}
    >
      <Stack
        width={"100%"}
        px={1.5}
        py={1.5}
        spacing={1}
        sx={{
          backgroundColor: (!searched ? (el.incoming ? "lightyellow" : theme.palette.green["100"]) : theme.palette.amber['900']),
          borderRadius: 1.5,
        }}
      >
        <Stack spacing={2} width={"100%"}>
          <Stack
            p={2}
            direction="column"
            spacing={3}
            alignItems="start"
            sx={{
              backgroundColor: theme.palette.background.paper,
              borderRadius: 1,
            }}
            width={"100%"}
          >
            <Stack direction={"column"} spacing={2}>
              <Embed width="90%" isDark url={rawUrl} />
            </Stack>
          </Stack>
          <Box width={"100%"}>
            <div
              style={{
                width: "100%",
                height: "auto",
                overflowWrap: "break-word",
              }}
              dangerouslySetInnerHTML={{ __html: el.message }}
            ></div>
          </Box>

          {/* </Typography> */}
        </Stack>
      </Stack>
    </ChatBubble>
  );
};

const LinkMsg = ({ el, avatarFunc, searched, menu, user, conversation, mobile }) => {
  return (
    <div id={el.id}>
      {el.incoming && (
        <Stack
          width={"100%"}
          direction="row"
          justifyContent={el.incoming ? "start" : "end"}
          spacing={3}
        >
          <Avatar
            // alt={user?.firstName}
            src={
              avatarFunc({image: user?.avatar})
            }
          />
          <Stack
            width={"100%"}
            direction="row"
            justifyContent={el.incoming ? "start" : "end"}
            spacing={1}
          >
            <LinkBubble el={el} mobile={mobile} searched={searched} />
            {menu && (
              <MessageOption
                incoming={el.incoming}
                message={el}
                conversation={conversation}
                user={user}
              />
            )}
          </Stack>
        </Stack>
      )}
      {!el.incoming && (
        <Stack
          width={"100%"}
          direction="row"
          justifyContent={el.incoming ? "start" : "end"}
          spacing={3}
        >
          <Stack
            width={"100%"}
            direction="row"
            justifyContent={el.incoming ? "start" : "end"}
            spacing={1}
          >
            {menu && (
              <MessageOption
                incoming={el.incoming}
                message={el}
                conversation={conversation}
                user={user}
              />
            )}
            <LinkBubble el={el} mobile={mobile} searched={searched} />
          </Stack>
          <Avatar
            // alt={user?.firstName}
            src={
              avatarFunc({image: user?.avatar})
            }
          />
        </Stack>
      )}
    </div>
  );
};

export default LinkMsg;
