import React, {useState} from 'react'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Slide, Typography, useMediaQuery, useTheme, Button, List, ListItem, ListItemText, ListItemButton, Fade } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@mui/system'
import { FormattedMessage } from 'react-intl'
import { LOCALES } from '../../i18n/locales'
import { setLocale } from '../../redux/global'
import { useEffect } from 'react'
import { ModalTitle, ModalButton, ModalSelectList, ModalListItemButton } from '../../styles/modal'


const slideTransition = (props) => {
    return <Slide direction='down' {...props} />
}

const defaultTransition = (props) => {
  return <Fade {...props} />
}

const LanguageSelect = ({ open, onClose}) => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('md'))
    const [localSelected, setLocaleSelected] = useState(LOCALES.ENGLISH)
    const locale = useSelector((state) => state.persisted.global.locale)?.value || LOCALES.ENGLISH;
    const dispatch = useDispatch()

    useEffect(() => {
      setLocaleSelected(locale)
    }, [locale])

    const onLocaleSelect = (value) => {
      setLocaleSelected(value)
    }

    const onLocaleSubmit = () => {
      dispatch(setLocale({
        value: localSelected,
        saved: true
      }))
      onClose()
    }
  return (
    <Dialog 
        // TransitionComponent={slideTransition} 
        variant='permanent'
        open={open} 
        fullWidth
        maxWidth="xs">
        <ModalTitle>
            <Box display='flex' 
                alignItems='center' 
                justifyContent='space-between'
                fontFamily={["Martel Sans", "PT Sans", 'Fira Sans', "adobe-caslon-pro", "Roboto", "cursive"].join(",")} pb={1}>
                <FormattedMessage id='modal.lang.title' />
                <IconButton sx={{color: theme.palette.common.white}} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </ModalTitle>
        <DialogContent>
          <Stack>
            <ModalSelectList>
              <ModalListItemButton selected={localSelected === 'en-US'} onClick={() => onLocaleSelect('en-US')}>
                <ListItemText primary="English" />
                { localSelected === 'en-US' && <CheckIcon /> }
              </ModalListItemButton>
              <ModalListItemButton selected={localSelected === 'zh-CN'} onClick={() => onLocaleSelect('zh-CN')}>
                <ListItemText primary="简体中文" />
                { localSelected === 'zh-CN' && <CheckIcon /> }
              </ModalListItemButton>
              <ModalListItemButton selected={localSelected === 'zh-TW'} onClick={() => onLocaleSelect('zh-TW')}>
                <ListItemText primary="繁體中文" />
                { localSelected === 'zh-TW' && <CheckIcon /> }
              </ModalListItemButton>
            </ModalSelectList>
            <ModalButton variant='contained' onClick={onLocaleSubmit}>
                <FormattedMessage id='modal.ok' />
            </ModalButton>
          </Stack>
        </DialogContent>
    </Dialog>
  )
}

export default LanguageSelect
