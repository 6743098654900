import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { FormattedMessage, useIntl } from "react-intl";
import { StyledBadge } from "../styledBadge/StyledBadge";
import { LOCALES } from "../../i18n/locales";
import { ModalTitle } from "../../styles/modal";
import { socket } from "../../socket";
import { SEVERITY_ERROR, SEVERITY_SUCCESS } from "../../constants";
import { useUIContext } from "../../context/ui";
import useAvatar from "../../hooks/useAvatar";

const ContactRequest = ({ open, onClose, request, locale }) => {
  const theme = useTheme();
  const intl = useIntl();
  const [avatar, setAvatar] = useState(null);
  const [socketStatus, setSocketStatus] = useState("Offline");
  const [fullName, setFullName] = useState("");
  const { setAlertObj, setShowAlert } = useUIContext();
  const avatarFunc = useAvatar();
  useEffect(() => {
    if (request) {
      setAvatar(avatarFunc({image: request?.sender?.avatar}));
      setSocketStatus(request?.sender?.socketStatus);
      setFullName(
        locale === LOCALES.ENGLISH
          ? `${request?.sender?.firstName} ${request?.sender?.lastName}`
          : `${request?.sender?.lastName} ${request?.sender?.firstName}`
      );
    }
  }, [request, avatarFunc]);

  const handleAcceptContact = async () => {
    socket.emit("accept_request", request, () => {
      setShowAlert(true);
      setAlertObj({
        severity: SEVERITY_SUCCESS,
        content: intl.formatMessage({ id: "chat.request.accepted" }),
        duration: null,
      });
    });
    onClose();
  };

  const handleRejectContact = async () => {
    socket.emit("reject_request", request, () => {
      setShowAlert(true);
      setAlertObj({
        severity: SEVERITY_ERROR,
        content: intl.formatMessage({ id: "chat.request.rejected" }),
        duration: null,
      });
    });
    onClose();
  };
  return (
    <Dialog
      // TransitionComponent={slideTransition}
      variant="permanent"
      open={open}
      fullWidth
      maxWidth="xs"
    >
      <ModalTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          fontFamily={[
            "Martel Sans",
            "PT Sans",
            "Fira Sans",
            "adobe-caslon-pro",
            "Roboto",
            "cursive",
          ].join(",")}
        >
          <FormattedMessage id="chat.incoming.request" />
          <IconButton
            sx={{ color: theme.palette.common.white }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </ModalTitle>
      <DialogContent>
        <Stack
          width="100%"
          justifyContent={"start"}
          alignItems={"center"}
          spacing={2}
          p={2}
        >
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent={"start"}
            alignItems={"center"}
            spacing={2}
          >
            {socketStatus === "Online" ? (
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar
                  alt={request?.sender?.firstName}
                  src={
                    // request?.sender?.avatar
                    avatarFunc({image: request?.sender?.avatar})
                  }
                />
              </StyledBadge>
            ) : (
              <Avatar
                alt={request?.sender?.firstName}
                src={
                  // request?.sender?.avatar
                  avatarFunc({image: request?.sender?.avatar})
                }
              />
            )}
            <Typography variant="h6" color={theme.palette.primary.main}>
              {intl.formatMessage({ id: "general.from" })}&nbsp;{fullName}
            </Typography>
          </Stack>

          <Box
            width="100%"
            minHeight={"120px"}
            border={`1px solid ${theme.palette.grey["200"]}`}
            p={2}
          >
            <Typography variant="body" color={theme.palette.grey["800"]}>
              {request?.sender?.lastMessage}
            </Typography>
          </Box>
          <Stack
            width="100%"
            direction={"row"}
            p={2}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Button
              variant="contained"
              sx={{
                width: "150px",
                bgcolor: theme.palette.primary.main,
                "&:hover": {
                  bgcolor: theme.palette.primary.light,
                },
              }}
              onClick={() => handleAcceptContact()}
            >
              <FormattedMessage id="general.accept" />
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "150px",
                bgcolor: theme.palette.red["800"],
                "&:hover": {
                  bgcolor: theme.palette.red["700"],
                },
              }}
              onClick={() => handleRejectContact()}
            >
              <FormattedMessage id="general.reject" />
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ContactRequest;
