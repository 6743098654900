import { Stack } from '@mui/material'
import React from 'react'
import NewPassword from '../../sections/auth/NewPassword'

const NewPasswordPage = () => {
  return (
    <>
    <Stack alignItems={'center'} width='100%' height='100%' p={3}>
        <NewPassword />
    </Stack>
    </>
  )
}

export default NewPasswordPage