import { api } from './api'

const buildingApi = api.injectEndpoints({
  endpoints: (build) => ({
      getBuildings: build.query({
          query: ({ page, pageSize, sort, search }) => ({
            url: "api/buildings",
            method: "GET",
            params: { page, pageSize, sort, search },
            // headers: { token: `Bearer ${token}` },
          }),
          providesTags: (result) =>
            result?.buildings
            ? [
                ...result.buildings.map(({ _id }) => ({ type: 'Buildings', id: _id })),
                { type: 'Buildings', id: 'LIST' },
              ]
            : [{ type: 'Buildings', id: 'LIST' }],
      }),
    }),
    overrideExisting: false,
})

export const { useGetBuildingsQuery } = buildingApi