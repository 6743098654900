import { api } from './api'

const orderApi = api.injectEndpoints({
    endpoints: (build) => ({
        getOrdersByUserId: build.query({
            query: ({ id, page, pageSize, sort, vendor, client, token }) => ({
                url: `api/ordersByUserId/${id}`,
                method: "GET",
                params: { page, pageSize, sort, vendor, client },
                headers: { Authorization: `Bearer ${token}` },
            }),
            providesTags: (result) =>
            result?.orders
                ? [
                    ...result.orders.map(({ _id }) => ({ type: 'Orders', id: _id })),
                    { type: 'Orders', id: 'LIST' },
                    ]
                : [{ type: 'Orders', id: 'LIST' }],
            }),
        getOrderById: build.query({
            query: ({id, token}) => {
                return {
                url: `api/orderById/${id}`,
                method: "GET",
                headers: { Authorization: `Bearer ${token}` },
                }},
                providesTags: (result) =>
                    [{ type: 'Orders', id: result?._id }]
                }),
        addOrder: build.mutation({
            query: ({token, body}) => ({
                url: `api/addOrder/`,
                method: 'POST',
                body,
                headers: { Authorization: `Bearer ${token}` },
            }),
            invalidatesTags: ['Orders'],
        }),
        updateOrderById: build.mutation({
            query: ({id, token, body}) => ({
              url: `api/orderById/${id}`,
              method: 'PUT',
              body,
              headers: { Authorization: `Bearer ${token}` },
            }),
            invalidatesTags: ['Orders'],
        }),
        updateOrderStatusById: build.mutation({
            query: ({id, token, body}) => ({
              url: `api/orderStatusById/${id}`,
              method: 'PUT',
              body,
              headers: { Authorization: `Bearer ${token}` },
            }),
            invalidatesTags: ['Orders'],
        }),
    }),
    overrideExisting: false,
})

export const { useGetOrdersByUserIdQuery, useGetOrderByIdQuery, useAddOrderMutation, useUpdateOrderByIdMutation, useUpdateOrderStatusByIdMutation } = orderApi