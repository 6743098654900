import {
  Box,
  Container,
  Fab,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Topbar from "../components/topbar/Topbar";
import Sidebar from "../components/sidebar/Sidebar";
import Footer from "../components/footer/Footer";
import Menubar from "../components/menubar/Menubar";
import useAlert from "../hooks/useAlert";
import useBackdrop from "../hooks/useBackdrop";
import { useUIContext } from "../context/ui";
import {
  SIDEBAR_COMMUNITY,
  SIDEBAR_NONE,
  SIDEBAR_PROFILE,
  SIDEBAR_STRATA,
  URL_CHATROOM,
} from "../constants";
import { ArrowUpward, VerticalAlignTop } from "@mui/icons-material";
import Footer2 from "../components/footer/Footer2";
import { useSelector } from "react-redux";
import { LOCALES } from "../i18n/locales";
const Layout = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  // console.log('location', location?.pathname)
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [sidebarType, setSidebarType] = useState(SIDEBAR_NONE);
  const locale = useSelector((state) => {
    return state.persisted.global.locale
  } )?.value || LOCALES.ENGLISH;
  const [AlertComponent] = useAlert();
  const [BackdropComponent] = useBackdrop();
  const { isLoading } = useUIContext();
  useEffect(() => {
    // setShowBackdropCallback(isLoading)
    if (pathname.indexOf("profile") >= 0) {
      setSidebarType(SIDEBAR_PROFILE);
    } else if (pathname.indexOf("strata") >= 0) {
      setSidebarType(SIDEBAR_STRATA);
    } else if (pathname.indexOf("community") >= 0) {
      setSidebarType(SIDEBAR_COMMUNITY);
    } else {
      setSidebarType(SIDEBAR_NONE);
    }

    if (
      sidebarType === SIDEBAR_PROFILE ||
      sidebarType === SIDEBAR_STRATA ||
      sidebarType === SIDEBAR_COMMUNITY
    ) {
      setIsSidebarOpen(true);
    } else {
      setIsSidebarOpen(false);
    }
  }, [isLoading, pathname, sidebarType]);
  return (
    <Container
      disableGutters
      maxWidth="xxl"
      sx={{
        background: theme.palette.background.paper,
        width: "100vw",
        // height: '100vh',
        margin: "0px",
        padding: "0px",
        overflowX: "clip",
        position: "relative",
        // border:'3px solid red'
      }}
    >
      <Box
        width="100%"
        sx={{
          position: "-webkit-sticky" /* Safari */,
          position: "sticky",
          top: "0",
          zIndex: 999,
        }}
      >
        <Topbar tablet={tablet} mobile={mobile} />
      </Box>
      <Stack width="100%" height="100%">
        <Stack width="100%" height="100%" direction={"row"}>
          {!mobile && !tablet && isSidebarOpen && (
            <Box
              width="300px"
              minWidth={"300px"}
              sx={{ boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)" }}
            >
              <Sidebar
                mobile={mobile || tablet}
                drawerWidth="300px"
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                sidebarType={sidebarType}
              />
            </Box>
          )}
          <Box flexGrow={1} p={(!mobile && !tablet && isSidebarOpen) ? 5 : 0}>
            <Outlet />
          </Box>
        </Stack>
        {pathname?.indexOf(URL_CHATROOM) < 0 && (<Footer2 mobile={mobile} tablet={tablet} locale={locale} sx={{ zIndex: 200 }} />)}
        <Fab
          sx={{
            position: "fixed",
            left: "5%",
            bottom: "10%",
            zIndex: 1000,
            backgroundColor: theme.palette.error.main,
            color: theme.palette.common.white,
            "&:hover": {
              backgroundColor: theme.palette.amber['900'],
              outline: `2px solid ${theme.palette.grey['100']}`,
            },
          }}
          size="large"
          aria-label="add"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          <VerticalAlignTop />
        </Fab>
      </Stack>
      <AlertComponent />
      <BackdropComponent />
    </Container>
  );
};

export default Layout;
