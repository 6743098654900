import { createSlice } from "@reduxjs/toolkit";

const communitySlice = createSlice({
    name: 'community',
    initialState: {
        communities: [],
        selected: null,
    },
    reducers: {
        setCommunities: (state, action) => {
            state.communities = action.payload
        },
        setSelectedCommunity: (state, action) => {
            state.selected = action.payload
        }
    }
})

export const {setCommunities, setSelectedCommunity} = communitySlice.actions
export default communitySlice.reducer