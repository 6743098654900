import { api } from './api'

const communityApi = api.injectEndpoints({
  endpoints: (build) => ({
      getCommunities: build.query({
          query: ({ page, pageSize, sort, search }) => ({
            url: "api/communities",
            method: "GET",
            params: { page, pageSize, sort, search },
            // headers: { token: `Bearer ${token}` },
          }),
          providesTags: (result) =>
            result?.communities
            ? [
                ...result.communities.map(({ _id }) => ({ type: 'Communities', id: _id })),
                { type: 'Communities', id: 'LIST' },
              ]
            : [{ type: 'Communities', id: 'LIST' }],
      }),
    }),
    overrideExisting: false,
})

export const { useGetCommunitiesQuery } = communityApi