import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useVerifyEmailMutation } from "../../apis/auth";
import useBackdrop from "../../hooks/useBackdrop";
import useAlert from "../../hooks/useAlert";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useUIContext } from "../../context/ui";
import { URL_HOME, URL_LOGIN } from "../../constants";
import VerifyForm from "../../sections/auth/VerifyForm";
import { useSelector } from "react-redux";

const VerifyOTP = () => {
  const intl = useIntl();
  const [email, setEmail] = useState('')
  const otpUser = useSelector(state => state.user.otpUser)
  
  useEffect(() => {
    if (otpUser) {
      setEmail(otpUser)
    }
  }, [otpUser])
  return (
    <>
      <Stack
        width="100%"
        minHeight="400px"
        justifyContent="start"
        alignItems="center"
        mt={5}
        spacing={2}
      >
        <Typography variant="h4"><FormattedMessage id='auth.verify.email' /></Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography variant="h6">
            {intl.formatMessage(
                { id: "auth.verify.email.sent" },
                { email: email }
              )}
          </Typography>
        </Stack>
        <VerifyForm email={email} />
      </Stack>
    </>
  );
};

export default VerifyOTP;
