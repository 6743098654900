import { useTheme } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { Divider, Stack, Typography } from "@mui/material";
import SearchField from "../../components/searchField/SearchField";
import ContactElement from "./ContactElement";
import { FormattedMessage } from "react-intl";
import { CONTACT_ELEMENT_CONVERSATION } from "../../constants";
import { useDispatch, useSelector } from "react-redux";

const Conversations = ({
  user,
  userToken,
  mobile,
  locale,
  current_conversation,
  conversations,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [pinnedContacts, setPinnedContacts] = useState([]);
  const [unpinnedContacts, setUnpinnedContacts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [selectedContact, setSelectedContact] = useState(null);
  const { room_id, conversation_viewed } = useSelector(
    (state) => state.chatroom
  );

  useEffect(() => {
    // console.log('searchInput', message?.message)
    const filteredConversations =
      conversations?.length > 0
        ? conversations?.filter(
            (item) =>
              item.active === true &&
              (item?.firstName
                ?.toLowerCase()
                ?.indexOf(searchInput?.toLowerCase()) >= 0 ||
                item?.lastName
                  ?.toLowerCase()
                  ?.indexOf(searchInput?.toLowerCase()) >= 0 ||
                item?.email
                  ?.toLowerCase()
                  ?.indexOf(searchInput?.toLowerCase()) >= 0 ||
                item?.username
                  ?.toLowerCase()
                  ?.indexOf(searchInput?.toLowerCase()) >= 0 ||
                item?.description
                  ?.toLowerCase()
                  ?.indexOf(searchInput?.toLowerCase()) >= 0 ||
                item?.msg?.toLowerCase()?.indexOf(searchInput?.toLowerCase()) >=
                  0 ||
                item?.messages?.findIndex(
                  (message) =>
                    message?.message?.toLowerCase()?.indexOf(searchInput?.toLowerCase()) >=
                    0
                ) >= 0)
          )
        : [];
    // console.log('conversations', conversations)
    // console.log('filteredConversations', filteredConversations)

    // Update conversation contacts
    const pinnedContactList = [];
    filteredConversations
      ?.filter((item) => {
        return item.pinned === true;
      })
      ?.map((conversation) => {
        pinnedContactList.push({
          id: conversation.userId,
          conversationId: conversation._id,
          firstName: conversation.firstName,
          lastName: conversation.lastName,
          email: conversation.email,
          avatar: conversation.avatar,
          online: conversation.online,
          lastMessage: conversation?.msg,
          lastMessageTime: conversation?.displayTime,
          unread: conversation?.unread,
        });
      });
    setPinnedContacts(pinnedContactList);

    const unpinnedContactList = [];
    filteredConversations
      ?.filter((item) => {
        return item.pinned === false;
      })
      ?.map((conversation) => {
        unpinnedContactList.push({
          id: conversation.userId,
          conversationId: conversation._id,
          firstName: conversation.firstName,
          lastName: conversation.lastName,
          email: conversation.email,
          avatar: conversation.avatar,
          online: conversation.online,
          lastMessage: conversation?.msg,
          lastMessageTime: conversation?.displayTime,
          unread: conversation?.unread,
        });
      });
    setUnpinnedContacts(unpinnedContactList);
  }, [conversations, searchInput]);

  useEffect(() => {
    let contactToSelect = null
    if (pinnedContacts?.length > 0) {
      if (!current_conversation) {
        contactToSelect = pinnedContacts[0];
      } else if (current_conversation) {
        contactToSelect = 
          pinnedContacts.find((item) => {
            return item.id === current_conversation.userId;
          })
      }
    }

    if (!contactToSelect && unpinnedContacts?.length > 0) {
      if (!current_conversation) {
        contactToSelect = unpinnedContacts[0];
      } else {
        contactToSelect = 
        unpinnedContacts.find((item) => {
            return item.id === current_conversation.userId;
          })
      }
    }

    setSelectedContact(contactToSelect)
  }, [pinnedContacts, unpinnedContacts, current_conversation]);

  return (
    <>
      <Stack width="100%" justifyContent={"start"} alignItems={"center"} p={2}>
        <Stack
          width="100%"
          direction={"row"}
          justifyContent={"start"}
          alignItems={"center"}
          px={1}
        >
          <SearchField
            setSearchText={setSearchText}
            setInput={setSearchInput}
            mobile={mobile}
            width={"100%"}
          />
        </Stack>
        {pinnedContacts?.length > 0 && (
          <>
          <Stack
            width="100%"
            justifyContent={"start"}
            alignItems={"center"}
            spacing={0.5}
            pt={2}
          >
            <Typography variant="subtitle2" sx={{ color: "#676667" }}>
              <FormattedMessage id="chat.pinned.conversation" />
            </Typography>
            {pinnedContacts?.map((contact, idx) => (
              <ContactElement
                key={contact.id || idx}
                userId={user?._id}
                category={CONTACT_ELEMENT_CONVERSATION}
                contact={contact}
                locale={locale}
                selectedContact={selectedContact}
                setSelectedContact={setSelectedContact}
              />
            ))}
            
          </Stack>
          <Divider width='100%' sx={{mt: 2}} px={1} orientation="horizontal" />
          </>
        )}
        {unpinnedContacts?.length > 0 && (
          <Stack
            width="100%"
            justifyContent={"start"}
            alignItems={"center"}
            spacing={0.5}
            pt={2}
          >
            {unpinnedContacts?.map((contact, idx) => (
              <ContactElement
                key={contact.id || idx}
                userId={user?._id}
                category={CONTACT_ELEMENT_CONVERSATION}
                contact={contact}
                locale={locale}
                selectedContact={selectedContact}
                setSelectedContact={setSelectedContact}
              />
            ))}
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default Conversations;
