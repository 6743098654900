import { api } from './api'

const friendRequestApi = api.injectEndpoints({
  endpoints: (build) => ({
      getPendingRequestsByUserId: build.query({
          query: ({ userId, token }) => ({
            url: `api/pendingRequestsByUserId/${userId}`,
            method: "GET",
            headers: { Authorization: `Bearer ${token}` },
          }),
          providesTags: (result) =>
            result?.requests
            ? [
                ...result.requests.map(({ _id }) => ({ type: 'FriendRequests', id: _id })),
                { type: 'FriendRequests', id: 'LIST' },
              ]
            : [{ type: 'FriendRequests', id: 'LIST' }],
      }),
    }),
    overrideExisting: false,
})
    
export const { useGetPendingRequestsByUserIdQuery } = friendRequestApi