import { createSlice } from "@reduxjs/toolkit";

const strataSlice = createSlice({
    name: 'strata',
    initialState: {
        notifications: [],
        documents: null,
    },
    reducers: {
        setNotifications: (state, action) => {
            state.notifications = action.payload
        },
        setDocuments: (state, action) => {
            state.documents = action.payload
        },
    }
})

export const {setNotifications, setDocuments} = strataSlice.actions
export default strataSlice.reducer