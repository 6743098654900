import React, { useMemo } from "react";
import {
  Paper,
  Button,
  Stack,
  Box,
  useTheme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  CarouselButton,
  CarouselDescription,
  CarouselHeader,
  CarouselImage,
  CarouselImageSimple,
  CarouselImageWrapper,
  CarouselTextContainer,
  CarouselTextWrapper,
  CarouselWrapper,
  CarouselWrapperSimple,
} from "../../styles/carousel";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { REGEX_JSON } from "../../constants";

const Item = ({ item, tablet, mobile, locale }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const type = useMemo(() => {
    return mobile ? "mobile" : tablet ? "tablet" : "desktop";
  }, [tablet, mobile]);

  const title = useMemo(() => {
    return item.title?.match(REGEX_JSON)
      ? JSON.parse(item.title)[locale]
      : item.title;
  }, [locale, item]);

  const description = useMemo(() => {
    return item.description?.match(REGEX_JSON)
      ? JSON.parse(item.description)[locale]
      : item.description;
  }, [locale, item]);

  const buttonInfo = useMemo(() => {
    return item.button?.match(REGEX_JSON)
      ? JSON.parse(item.button)
      : item.button;
  }, [item]);

  return (
    <>
      {item.layout?.length > 0 && (
        <Paper
          sx={{
            width: "100%",
            height: mobile ? "740px" : "650px",
            backgroundColor: theme.palette.background.paper,
            position: "relative",
          }}
          elevation={0}
        >
          <Stack
            direction={mobile ? "column" : "row"}
            width={"100%"}
            height="100%"
            p={0}
          >
            {item.layout?.indexOf("left-right") >= 0 && mobile === false && (
              <>
                <Box height="100%" sx={{ flex: 2 }}>
                  <CarouselImage src={item.url} alt="slider" />
                </Box>

                <Stack
                  sx={{ flex: 1 }}
                  justifyContent={"center"}
                  alignItems={"left"}
                  p={4}
                  mt={2}
                  spacing={4}
                >
                  <Typography
                    variant={"h4"}
                    sx={{
                      color: theme.palette.text.primary,
                      textAlign: "left",
                      fontWeight: 600,
                      fontFamily: "Montserrat, arial, sans-serif",
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      color: theme.palette.text.primary,
                      textAlign: "left",
                      fontWeight: 400,
                      fontFamily: "Montserrat, arial, sans-serif",
                    }}
                  >
                    {description}
                  </Typography>
                  {buttonInfo?.title && (
                    <Button
                      variant="outlined"
                      sx={{
                        color: theme.palette.text.primary,
                        border: `1px solid ${theme.palette.grey["700"]}`,
                        width: "120px",
                        p: 1,
                      }}
                      onClick={() => navigate(buttonInfo?.target)}
                    >
                      <FormattedMessage id={buttonInfo?.title} />
                    </Button>
                  )}
                </Stack>
              </>
            )}

            {item.layout?.indexOf("right-left") >= 0 && mobile === false && (
              <>
                <Stack
                  sx={{ flex: 1 }}
                  justifyContent={"center"}
                  alignItems={"left"}
                  p={4}
                  mt={2}
                  spacing={3}
                >
                  <Typography
                    variant={"h4"}
                    sx={{
                      color: theme.palette.text.primary,
                      textAlign: "left",
                      fontWeight: 600,
                      fontFamily: "Montserrat, arial, sans-serif",
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      color: theme.palette.text.primary,
                      textAlign: "left",
                      fontWeight: 400,
                      fontFamily: "Montserrat, arial, sans-serif",
                    }}
                  >
                    {description}
                  </Typography>
                  {buttonInfo && (
                    <Button
                      sx={{
                        color: theme.palette.text.primary,
                        border: `1px solid ${theme.palette.grey["700"]}`,
                        width: "120px",
                        p: 1,
                      }}
                      onClick={() =>
                        navigate(buttonInfo.target, { state: item.state })
                      }
                    >
                      <FormattedMessage id={buttonInfo.title} />
                    </Button>
                  )}
                </Stack>
                <Box variant="outlined" height="100%" flex={mobile ? 1 : 2}>
                  <CarouselImage src={item.url} alt="slider" />
                </Box>
              </>
            )}

            {item.layout?.indexOf("top-bottom") >= 0 && mobile === true && (
              <>
                <Box sx={{ height: '280px' }}>
                  <CarouselImage imgHeight={'280px'} src={item.url} alt="slider" />
                </Box>

                <Stack
                  sx={{ flex: 1 }}
                  justifyContent={"start"}
                  alignItems={"center"}
                  p={4}
                  mt={2}
                  spacing={4}
                >
                  <Typography
                    variant={"h4"}
                    sx={{
                      color: theme.palette.text.primary,
                      textAlign: "left",
                      fontWeight: 600,
                      fontFamily: "Montserrat, arial, sans-serif",
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      color: theme.palette.text.primary,
                      textAlign: "left",
                      fontWeight: 400,
                      fontFamily: "Montserrat, arial, sans-serif",
                    }}
                  >
                    {description}
                  </Typography>
                  {buttonInfo?.title && (
                    <Button
                      variant="outlined"
                      sx={{
                        color: theme.palette.text.primary,
                        border: `1px solid ${theme.palette.grey["700"]}`,
                        width: "120px",
                        p: 1,
                      }}
                      onClick={() => navigate(buttonInfo?.target)}
                    >
                      <FormattedMessage id={buttonInfo?.title} />
                    </Button>
                  )}
                </Stack>
              </>
            )}
          </Stack>
        </Paper>
      )}

      {item.layout === "none" && (
        <Paper
          sx={{
            width: "100%",
            height: "620px",
            backgroundColor: theme.palette.background.paper,
            position: "relative",
          }}
          elevation={0}
        >
          <CarouselImage src={item.url} alt="slider" />
        </Paper>
      )}
    </>
  );
};

export default Item;
