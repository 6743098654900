import {
  Badge,
  Box,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Contacts from "./Contacts";
import Conversations from "./Conversations";
import SearchedUsers from "./SearchedUsers";
import AddContact from "../../components/modals/AddContact";
import useDialogModal from "../../hooks/useDialogModal";
import { useDispatch, useSelector } from "react-redux";
import { setTabSelected } from "../../redux/chatroom";
import SearchedMessages from "./SearchedMessages";

const ChatSidebar = ({
  user,
  userToken,
  mobile,
  locale,
  current_conversation,
  conversations,
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  const { tab_selected } = useSelector((state) => state.chatroom);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const pendingRequests = useSelector(
    (state) => state.pendingRequest.pendingRequests
  );

  useEffect(() => {
    console.log("***** pendingRequests", pendingRequests);
  }, [pendingRequests]);

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    dispatch(
      setTabSelected({
        tabSelected: newValue,
      })
    );
  };

  useEffect(() => {
    setValue(tab_selected);
  }, [tab_selected]);

  return (
    <>
      <Stack
        width="100%"
        height="100%"
        justifyContent={"start"}
        alignItems={"center"}
        border={`1px solid ${theme.palette.grey["200"]}`}
      >
        <Box width="100%" sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            variant="fullWidth"
            onChange={handleChange}
            aria-label="secondary tabs example"
            sx={{ border: "1px solid greeen" }}
          >
            <Tab
              label={
                <Badge badgeContent={0} color="primary">
                  <FormattedMessage id="chat.conversations" />
                </Badge>
              }
            />
            <Tab
              label={
                <Badge badgeContent={pendingRequests?.length} color="error">
                  <FormattedMessage id="chat.contacts" />
                </Badge>
              }
            />
            {!mobile && <Tab label={<FormattedMessage id="app.search" />} />}
          </Tabs>
        </Box>

        <Stack
          justifyContent={"start"}
          alignItems={"center"}
          width="100%"
          height="100%"
        >
          {value === 0 && (
            <Conversations
              user={user}
              userToken={userToken}
              mobile={mobile}
              locale={locale}
              current_conversation={current_conversation}
              conversations={conversations}
            />
          )}
          {value === 1 && (
            <Contacts
              user={user}
              userToken={userToken}
              mobile={mobile}
              locale={locale}
              pendingRequests={pendingRequests}
              current_conversation={current_conversation}
              conversations={conversations}
            />
          )}
          {value === 2 && (
            <>
              <SearchedMessages
                mobile={mobile}
                current_conversation={current_conversation}
              />
            </>
            // <SearchedUsers
            //   userToken={userToken}
            //   mobile={mobile}
            //   user={user}
            //   locale={locale}
            // />
          )}
        </Stack>
        {/* <Stack
          width="100%"
          direction={"row"}
          justifyContent={"start"}
          alignItems={"center"}
        >
          <Typography variant="h6" color={theme.palette.primary.main} p={1}>
            <FormattedMessage id='auth.my.friends' />
          </Typography>
        </Stack>
        <Stack
          width="100%"
          direction={"row"}
          justifyContent={"start"}
          alignItems={"center"}
          px={1}
        >
          <SearchField setSearchText={setSearchText} mobile={mobile} width={'100%'} />
        </Stack> */}
      </Stack>
    </>
  );
};

export default ChatSidebar;
