import { createSlice } from "@reduxjs/toolkit";

const deliverySlice = createSlice({
    name: 'delivery',
    initialState: {
        deliveries: null,
        selected: null,
    },
    reducers: {
        setDeliveries: (state, action) => {
            state.deliveries = action.payload
        },
        setSelectedDelivery: (state, action) => {
            state.selected = action.payload
        }
    }
})

export const {setDeliveries, setSelectedDelivery} = deliverySlice.actions
export default deliverySlice.reducer