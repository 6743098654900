import { api } from './api'

const deliveryApi = api.injectEndpoints({
  endpoints: (build) => ({
      getDeliveries: build.query({
          query: ({ page, pageSize, sort, vendor, search, token }) => ({
            url: "api/deliveries",
            method: "GET",
            params: { page, pageSize, sort, vendor, search },
            headers: { Authorization: `Bearer ${token}` },
          }),
          providesTags: (result) => result?.vendors
          ? [
              ...result.vendors.map(({ _id }) => ({ type: 'Deliveries', id: _id })),
              { type: 'Deliveries', id: 'LIST' },
            ]
          : [{ type: 'Deliveries', id: 'LIST' }],
          
      }),
    }),
    overrideExisting: false,
})

export const { useGetDeliveriesQuery } = deliveryApi