import React, { useEffect, useMemo, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { MENU_TOPBAR } from "../../data/menu";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { useUIContext } from "../../context/ui";
import {
  SEVERITY_WARNING,
  URL_CHATROOM,
  URL_COMMUNITIES,
  URL_LOGIN,
} from "../../constants";
import { useSelector } from "react-redux";
import {
  Badge,
  Box,
  Collapse,
  Fade,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { CaretDown, CaretUp } from "phosphor-react";
import { ImageCovered } from "../imageCover/ImageCover";

const MenubarDesktop = ({unreadMessages}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const intl = useIntl();
  const { pathname } = useLocation();
  const [selectedMenuItem, setSelectedMenuItem] = useState(MENU_TOPBAR[0]);
  const [menuList, setMenuList] = useState([]);

  const { setShowSearchBox } = useUIContext();
  const user = useSelector((state) => state.user.currentUser)?.data;
  // const activeBuilding = useSelector(
  //   (state) => state.persisted.global.activeBuilding
  // );

  // const directChats = useSelector(
  //   (state) => state.persisted.conversation.direct_chats
  // );

  // const conversations = useMemo(() => {
  //   return directChats && user?._id?.length > 0
  //     ? directChats[user?._id]?.conversations
  //     : [];
  // }, [directChats, user?._id]);

  // const unreadMessages = useMemo(() => {
  //   if (conversations?.length > 0) {
  //     let total = 0;
  //     conversations?.forEach((item) => {
  //       total += item.unread;
  //     });
  //     return total;
  //   } else {
  //     return 0;
  //   }
  // }, [conversations]);

  // console.log("unreadMessages", conversations, unreadMessages);

  useEffect(() => {
    if (MENU_TOPBAR) {
      setMenuList([
        ...MENU_TOPBAR.map((item) => {
          return {
            ...item,
            expanded: item.children?.length > 0 ? false : undefined,
            anchorEl: null,
          };
        }),
      ]);
    }
  }, [MENU_TOPBAR]);

  const handleMenuItem = (evt, item) => {
    if (item.url === "search") {
      setShowSearchBox(true);
    } else if (item.children?.length > 0) {
      setMenuList([
        ...menuList.map((item) => {
          return {
            ...item,
            expanded: item.children?.length > 0 ? !item.expanded : undefined,
            anchorEl: evt.currentTarget,
          };
        }),
      ]);
    } else {
      setSelectedMenuItem(item);
      navigate(item.url);
    }
  };

  const handleMenuClose = (item) => {
    setMenuList([
      ...menuList.map((el) => {
        if (el.title !== item.title) {
          return el;
        } else {
          return { ...el, anchorEl: null, expanded: !el.expanded };
        }
      }),
    ]);
  };

  useEffect(() => {
    if (pathname === "/") {
      setSelectedMenuItem(MENU_TOPBAR[0]);
    } else if (pathname.indexOf("profile") >= 0) {
      setSelectedMenuItem(null);
    } else if (pathname.indexOf("login") < 0) {
      MENU_TOPBAR.forEach((item) => {
        if (item.url !== "/" && pathname.indexOf(item.category) >= 0) {
          setSelectedMenuItem(item);
        }
      });
    }
  }, [pathname]);

  return (
    <Stack
      direction={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      backgroundColor={theme.palette.primary.main}
      // borderBottom={`1px solid ${theme.palette.grey["300"]}`}
      minHeight={60}
    >
      <List
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {menuList.map((item, idx) => (
          <Box key={idx}>
            <ListItemButton
              selected={selectedMenuItem?.id === item?.id}
              key={item.id}
              onClick={(e) => handleMenuItem(e, item)}
              sx={{
                display: "flex",
                position: "relative",
                justifyContent: "center",
                px: 3,
                color:
                  selectedMenuItem?.id === item.id
                    ? theme.palette.warning.light
                    : "#FFF",
              }}
            >
              {item.url !== URL_CHATROOM && (
                <ListItemText sx={{ mr: 0.5 }}>
                  <Typography variant="body1" fontWeight={600}>
                    <FormattedMessage id={item.title} />
                    {item.title.indexOf("search") > 0 && (
                      <SearchIcon
                        sx={{
                          marginLeft: "5px",
                          fontSize: "16px",
                        }}
                      />
                    )}
                  </Typography>
                </ListItemText>
              )}

              {item.url === URL_CHATROOM && (
                <Badge badgeContent={unreadMessages} color="error">
                  <ListItemText sx={{ mr: 0.5 }}>
                    <Typography variant="body1" fontWeight={600}>
                      <FormattedMessage id={item.title} />
                      {item.title.indexOf("search") > 0 && (
                        <SearchIcon
                          sx={{
                            marginLeft: "5px",
                            fontSize: "16px",
                          }}
                        />
                      )}
                    </Typography>
                  </ListItemText>
                </Badge>
              )}

              {unreadMessages === 0 && item?.badge?.length > 0 && (
                <ImageCovered
                  src={item?.badge}
                  sx={{
                    width: "60px",
                    height: "auto",
                    position: "absolute",
                    right: -20,
                    bottom: 20,
                  }}
                />
              )}

              {item.children?.length > 0 && item?.expanded === true && (
                <CaretUp fontSize={"large"} />
              )}
              {item.children?.length > 0 && item?.expanded === false && (
                <CaretDown fontSize={"large"} />
              )}
            </ListItemButton>
            {item.children?.length > 0 && (
              <Menu
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                TransitionComponent={Fade}
                id={`positioned-menu-${item.title}`}
                aria-labelledby={`positioned-button-${item.title}`}
                anchorEl={item.anchorEl}
                open={Boolean(item.anchorEl)}
                onClose={() => handleMenuClose(item)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  "& .MuiMenu-paper": {
                    border: `1px solid ${theme.palette.grey["400"]}`,
                  },
                }}
              >
                <Box py={0.5}>
                  <Stack spacing={1} minWidth={"120px"}>
                    {item?.children?.map((el, idx) => (
                      <MenuItem
                        key={idx}
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          if (el?.url?.length > 0) {
                            navigate(el?.url);
                          }
                          setSelectedMenuItem(item);
                          handleMenuClose(item);
                        }}
                      >
                        <FormattedMessage id={el.title} />
                      </MenuItem>
                    ))}
                  </Stack>
                </Box>
              </Menu>
            )}
          </Box>
        ))}
      </List>
    </Stack>
  );
};

export default MenubarDesktop;
