import {
  Box,
  Button,
  Fab,
  Fade,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  Camera,
  File,
  Image,
  LinkSimple,
  PaperPlaneTilt,
  Smiley,
  Sticker,
  User,
} from "phosphor-react";
import { useTheme, styled } from "@mui/material/styles";
import React, { useEffect, useMemo, useRef, useState } from "react";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
//   import { socket } from "../../socket";
import { useDispatch, useSelector } from "react-redux";
import { AttachFile } from "@mui/icons-material";
import { FormattedMessage, useIntl } from "react-intl";
import { socket } from "../../socket";
import {
  setConversationReply,
  setConversationUploads,
} from "../../redux/chatroom";
import { useAsyncFn } from "../../hooks/useAsync";
import {
  getSignedUrl,
  getBucketObjects,
  uploadFileToSignedUrl,
  deleteBucketObject,
} from "../../apis/s3";
import { resizeUploadImage } from "../../utils/resizeImage";
import {
  S3_ATTACH_DOC,
  S3_ATTACH_IMAGE,
  S3_DIRECTORY_CHATROOM,
} from "../../constants";
import { useUIContext } from "../../context/ui";
const Attach_actions = [
  {
    icon: <Camera size={24} />,
    title: "chat.upload.image",
  },
  {
    icon: <File size={24} />,
    title: "chat.upload.document",
  },
];

const ChatInput = ({
  openPicker,
  setOpenPicker,
  setValue,
  value,
  inputRef,
  handleSendTextMessage,
  handleSelectFiles,
  isChatMode
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  // const [fileList, setFileList] = useState([])
  const uploadImagesRef = useRef();
  const uploadDocsRef = useRef();

  const handleAttachIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAttachMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TextField
        inputRef={inputRef}
        multiline={false}
        disabled={isChatMode === false}
        // rows={1}
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        onKeyDown={async (e) => {
          if (e.key === "Enter") {
            handleSendTextMessage && (await handleSendTextMessage());
          }
        }}
        fullWidth
        variant="standard"
        sx={{
          "& fieldset": { border: `1px solid ${theme.palette.grey["300"]}` },
        }}
        placeholder={intl.formatMessage({ id: "chat.write.message" })}
        InputProps={{
          style: {fontSize: '1.2rem'},
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                onClick={(e) => {
                  handleAttachIconClick(e);
                }}
              >
                <AttachFile />
              </IconButton>
              <input
                ref={uploadImagesRef}
                type="file"
                multiple={false}
                accept={"image/*"}
                style={{ display: "none" }}
                onChange={(e) =>
                  handleSelectFiles(e.target.files, S3_ATTACH_IMAGE)
                }
                onClick={(e) => {
                  const element = e.target
                  element.value = ''
                }}
              />
              <input
                ref={uploadDocsRef}
                type="file"
                multiple={false}
                accept={"application/*,text/*"}
                style={{ display: "none" }}
                onChange={(e) =>
                  handleSelectFiles(e.target.files, S3_ATTACH_DOC)
                }
                onClick={(e) => {
                  const element = e.target
                  element.value = ''
                }}
              />
              <Menu
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                TransitionComponent={Fade}
                id="profile-positioned-menu"
                aria-labelledby="profile-positioned-button"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleAttachMenuClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Stack spacing={1}>
                  {Attach_actions.map((el, idx) => (
                    <Box key={idx}>
                      <MenuItem onClick={handleAttachMenuClose}>
                        <Stack
                          onClick={() => {
                            if (idx === 0) {
                              uploadImagesRef.current &&
                                uploadImagesRef.current.click();
                            } else if (idx === 1) {
                              uploadDocsRef.current &&
                                uploadDocsRef.current.click();
                            }
                          }}
                          sx={{ width: 100 }}
                          direction="row"
                          alignItems={"center"}
                          justifyContent="start"
                          spacing={2}
                        >
                          {el.icon}
                          <Typography variant="body2" lineHeight={1}>
                            <FormattedMessage id={el.title} />
                          </Typography>
                        </Stack>{" "}
                      </MenuItem>
                    </Box>
                  ))}
                </Stack>
              </Menu>
            </InputAdornment>
          ),
          endAdornment: (
            <Stack sx={{ position: "relative" }}>
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setOpenPicker(!openPicker);
                  }}
                >
                  <Smiley />
                </IconButton>
              </InputAdornment>
            </Stack>
          ),
        }}
      ></TextField>
    </>
  );
};

function linkify(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(
    urlRegex,
    (url) => `<a href="${url}" target="_blank">${url}</a>`
  );
}

function containsUrl(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return urlRegex.test(text);
}

const ChatFooter = ({ user, userToken, current_conversation }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { setShowBackdropCallback } = useUIContext();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const { sideBar, room_id } = useSelector((state) => state.app);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [openPicker, setOpenPicker] = React.useState(false);
  const [value, setValue] = useState("");
  const [fileList, setFileList] = useState([]);
  const [filesType, setFilesType] = useState(S3_ATTACH_IMAGE);
  const inputRef = useRef(null);

  const { conversation_reply, conversation_uploads } = useSelector((state) => state.chatroom);

  const { tab_selected } = useSelector((state) => state.chatroom);

  const { execute: getSignedUrlFn, loading: isQueryingSignedUrl } =
    useAsyncFn(getSignedUrl);
  const { execute: uploadFileFn, loading: isUploadingFile } = useAsyncFn(
    uploadFileToSignedUrl
  );
  const { execute: deleteFileFn, loading: isDeletingFile } =
    useAsyncFn(deleteBucketObject);

  const handleSelectFiles = async (files, type) => {
    setFilesType(type);
    console.log("evt", files);
    // setFileNumber(files.length);
    const uploadFileList = [];

    Array.from(files)?.forEach((file) => {
      let uploadFileObj = {
        data: file,
        url: null,
        width: 0,
        height: 0,
      };

      if (file.type.indexOf("image") >= 0) {
        const img = document.createElement("img");
        uploadFileObj.url = URL.createObjectURL(file);

        img.onload = function handleLoad() {
          uploadFileObj.width = img.width;
          uploadFileObj.height = img.height;
          // URL.revokeObjectURL(uploadFileObj.url);
        };

        img.src = uploadFileObj.url;
      }

      uploadFileList.push(uploadFileObj);
    });

    console.log('uploadFileList', uploadFileList)
    setFileList(uploadFileList);
  };

  useEffect(() => {
    if (fileList?.length > 0) {
      const filesData = [
        ...fileList?.map((item) => {
          return {
            url: item.url || item.data.name,
            width: item.width,
            height: item.height,
            type: item.data.type,
          };
        }),
      ];

      dispatch(
        setConversationUploads({
          conversationId: current_conversation?._id,
          files: filesData,
        })
      );
    }
  }, [fileList]);

  const handleUpload = async ({ onSuccess }) => {
    let resizedList = [];
    let urlList = [];
    // let uploadFileList = [...fileList];
    if (filesType === S3_ATTACH_IMAGE) {
      for (let i = 0; i < fileList.length; i++) {
        const uploadFileObj = fileList[i];
        await resizeUploadImage(uploadFileObj, resizedList);
      }
    } else {
      resizedList = fileList
    }
    
    for (let i = 0; i < resizedList.length; i++) {
      const uploadFileObj = resizedList[i];
      const signedUrlParam = {
        token: userToken?.token,
        directory: S3_DIRECTORY_CHATROOM,
        key: current_conversation._id + "/" + uploadFileObj.data.name,
        content_type: uploadFileObj.data.type,
      };
      console.log("signedUrlParam", signedUrlParam);
      getSignedUrlFn(signedUrlParam).then((response) => {
        // console.log("response", response);
        const signedUrl = response.data.signedUrl;
        const fileLink = response.data.fileLink;
        console.log("signedUrl", signedUrl);
        console.log("fileLink", fileLink);
        uploadFileFn({
          signedUrl: signedUrl,
          file: uploadFileObj.data,
          contentType: uploadFileObj.data.type,
          onProgress: (progress) => {
            // const currentProgress = (progress.loaded / progress.total) * 100;
            // console.log('currentProgress', currentProgress)
            setUploadProgress(i + 1);
            // console.log('onProgress...')
          },
          onComplete: () => {
            urlList.push(fileLink);
            setUploadProgress(0);
            console.log("onComplete...");
            if (onSuccess) {
              onSuccess(urlList);
            }
          },
        }).finally(() => {});
      });
    }

    // setUrls(urlList);
    // setUploadFiles(uploadFileList);
  };

  function handleEmojiClick(emoji) {
    setOpenPicker(!openPicker);
    const input = inputRef.current;

    if (input) {
      const selectionStart = input.selectionStart;
      const selectionEnd = input.selectionEnd;
      // console.log("selectionStart", selectionStart, selectionEnd, value.length);
      setValue(
        value.substring(0, selectionStart) +
          emoji +
          value.substring(selectionEnd)
      );

      // Move the cursor to the end of the inserted emoji
      input.selectionStart = input.selectionEnd = selectionEnd + 2;
      // console.log('input.selectionStart', input.selectionStart)
      input.focus()
    }
  }

  const handleSendTextMessage = async () => {
    if (socket) {
      let msgObj = {
        message: linkify(value),
        conversation_id: current_conversation._id,
        from: user?._id,
        to: current_conversation.userId,
      };
      if (conversation_reply?.message) {
        msgObj.type = "Reply";
        msgObj.reply = conversation_reply?.message?.message;
        msgObj.file = conversation_reply?.message?.file;
        if (conversation_reply?.message?.subtype === 'Media' || conversation_reply?.message?.subtype === 'Doc') {
          msgObj.subtype = conversation_reply?.message?.subtype
        } else {
          msgObj.subtype = 'Text'
        }
      } else {
        msgObj.type = "Msg";
        if (fileList?.length > 0) {
          msgObj.subtype = filesType === S3_ATTACH_IMAGE ? "Media" : "Doc";
        } else if (containsUrl(value)) {
          msgObj.subtype = "Link";
        } else {
          msgObj.subtype = "Text";
        }
      }

      

      console.log("msgObj", msgObj);
      // if the current message is reply or it is normal text or link message, send the message directly, otherwise upload the files first.
      if (
        msgObj.type === "Reply" ||
        (msgObj.subtype !== "Media" && msgObj.subtype !== "Doc")
      ) {
        socket.emit("text_message", msgObj);
      } else {
        await handleUpload({
          onSuccess: (urlList) => {
            // socket.emit("text_message", msgObj);
            msgObj.file = urlList[0].slice(process.env.REACT_APP_S3_BUCKET_URL.length)
            console.log("uploaded", urlList, msgObj);
            socket.emit("text_message", msgObj);
          },
        });
      }

      dispatch(
        setConversationReply({
          conversationId: null,
          message: null,
        })
      );

      dispatch(
        setConversationUploads({
          conversationId: null,
          files: [],
        })
      );
    }
    setValue("");
  };

  useEffect(() => {
    setShowBackdropCallback(uploadProgress > 0);
  }, [uploadProgress]);

  useEffect(() => {
    if (conversation_uploads?.files?.length === 0) {
      setFileList([])
    }
  }, [conversation_uploads?.files])

  return (
    <Box
      width={"100%"}
      // height={'100%'}
      height='64px'
      sx={{
        position: "relative",
        backgroundColor: "transparent !important",
      }}
    >
      <Box
        px={mobile ? 1 : 2}
        // py={1}
        width={"100%"}
        height={'100%'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor:
            theme.palette.mode === "light"
              ? "#F8FAFF"
              : theme.palette.background,
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Stack direction="row" alignItems={"center"} spacing={mobile ? 1 : 3}>
          <Stack sx={{ width: "85%", }}>
            <Box
              style={{
                zIndex: 10,
                position: "absolute",
                display: openPicker ? "inline" : "none",
                bottom: 81,
                right: mobile ? 0 : 100,
              }}
            >
              <Picker
                theme={theme.palette.mode}
                data={data}
                onEmojiSelect={(emoji) => {
                  handleEmojiClick(emoji.native);
                }}
              />
            </Box>
            {/* Chat Input */}
            <ChatInput
              inputRef={inputRef}
              value={value}
              setValue={setValue}
              openPicker={openPicker}
              setOpenPicker={setOpenPicker}
              handleSendTextMessage={handleSendTextMessage}
              handleSelectFiles={handleSelectFiles}
              isChatMode={tab_selected === 0}
            />
          </Stack>
          <Box
            sx={{
              // height: 48,
              // width: 48,
              flexGrow: 1,
              // backgroundColor: theme.palette.primary.main,
              borderRadius: 1.5,
            }}
          >
            <Stack
              sx={{ height: "100%" }}
              alignItems={"center"}
              justifyContent="center"
            >
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  bgcolor: theme.palette.blueLight["800"],
                  "&:hover": {
                    bgcolor: theme.palette.blueLight["700"],
                  },
                }}
                disabled={(value?.length === 0 && fileList?.length === 0) || tab_selected > 0}
                onClick={async () => await handleSendTextMessage()}
              >
                <FormattedMessage id="general.send" />
              </Button>
              {/* <IconButton
                  onClick={() => {
                    socket.emit("text_message", {
                      message: linkify(value),
                      conversation_id: room_id,
                      from: user_id,
                      to: current_conversation.user_id,
                      type: containsUrl(value) ? "Link" : "Text",
                    });
                  }}
                >
                  <PaperPlaneTilt color="#ffffff" />
                </IconButton> */}
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default ChatFooter;
