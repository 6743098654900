import { Stack, Typography } from '@mui/material'
import React from 'react'
import Signup from '../../sections/auth/Signup'

const Register = () => {
  return (
    <>
    <Stack alignItems={'center'} width='100%' height='100%' p={3}>
        <Signup />
    </Stack>
    </>
  )
}

export default Register