import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { SEVERITY_SUCCESS } from "../../constants";

export const UIContext = createContext({});
export const useUIContext = () => useContext(UIContext);

export const UIProvider = ({ children }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [showSearchBox, setShowSearchBox] = useState(false);
    const [searchText, setSearchText] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [alertObj, setAlertObj] = useState({
        severity: SEVERITY_SUCCESS,
        content: '',
        duration: null,
    })
    const [showBackdrop, setShowBackdrop] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingExtras, setIsLoadingExtras] = useState(false)

    const setShowBackdropCallback = useCallback((show) => {
        setShowBackdrop(show)
    }, [setShowBackdrop])

    const value = {
        drawerOpen,
        setDrawerOpen,
        showSearchBox, 
        setShowSearchBox,
        searchText,
        setSearchText,
        showAlert,
        setShowAlert,
        alertObj,
        setAlertObj,
        // setShowBackdrop,
        showBackdrop,
        setShowBackdropCallback,
        isLoading,
        setIsLoading,
        isLoadingExtras,
        setIsLoadingExtras,
    };

    return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}