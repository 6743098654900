import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
};

export default function RHFTextField({ name, helperText, ...other }) {
  const { control } = useFormContext();
  const intl = useIntl()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: {ref, ...field}, fieldState: { invalid, error } }) => (
        <TextField
          {...field}
          inputRef={ref}
          fullWidth
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          error={!!error}
          helperText={error ? intl.formatMessage({id: error?.message}) : helperText}
          {...other}
        />
      )}
    />
  );
}