import Resizer from "react-image-file-resizer";

export const resizeImage = (imageFile, width = 1000, height = 1000, quality = 75) => {
    return new Promise((resolve) => {
       Resizer.imageFileResizer(
         imageFile,
         width,
         height,
         "JPEG",
         quality,
         0,
         (uri) => {
            resolve(uri);
          },
          "base64"
        );
    });
}

export const dataURIToBlob = async (dataURI) => {
    const base64Response = await fetch(dataURI);
    const blob = await base64Response.blob();
    return blob
};

export const resizeUploadImage = async (uploadFile, resizedList) => {
  const fileNameParts = uploadFile.data.name.split(".");
  if (uploadFile.data.type.indexOf("image") >= 0) {
    let width = uploadFile.width;
    let height = uploadFile.height;
    const maxWidth = process.env.REACT_APP_S3_IMAGE_WIDTH || 1000;
    if (width > maxWidth) {
      let ratio = maxWidth / width;
      width = maxWidth;
      height = Math.floor(uploadFile.height * ratio);
    }

    const resizedImage = await resizeImage(uploadFile.data, width, height);
    const newFileBlob = await dataURIToBlob(resizedImage);
    const newFile = new File(
      [newFileBlob],
      // fileNameParts[0] + new Date().getTime() + "." + fileNameParts[1],
      fileNameParts[0] + "." + fileNameParts[1],
      {
        type: newFileBlob.type,
      }
    );
    const resizedImageObj = {
      data: newFile,
      width: parseInt(width),
      height: parseInt(height),
    };
    // console.log('resizedImageObj', resizedImageObj, width)
    resizedList && resizedList.push(resizedImageObj);
  } else {
    resizedList &&
      resizedList.push({
        ...uploadFile,
        data: {
          ...uploadFile.data,
          name:
            fileNameParts[0] + new Date().getTime() + "." + fileNameParts[1],
        },
      });
  }
};