import { useCallback } from "react";
import moment from 'moment'

export default function useAvatar(dependencies=[]) {
    const callback = useCallback(({image}) => {
        if (image === undefined || image?.length === 0) {
            return undefined
        } else if (image?.indexOf('http') === 0) {
            return image
        } else {
            return process.env.REACT_APP_S3_BUCKET_URL + image + '?m=' + moment().seconds()
        }
    }, dependencies)

    return callback
}