import {
  Person,
  LockReset,
  ShoppingCartCheckout,
  Article,
  Logout,
  NotificationImportant,
  DocumentScanner,
  BugReport,
  DirectionsRun,
  Sell,
  Celebration,
  Apartment,
  DinnerDining,
  Restaurant,
  PriceChange,
  Email,
  ShoppingCart,
  RequestQuote,
  Call,
  ChatBubble,
} from "@mui/icons-material";
import {
  URL_CHANGE_PASSWORD,
  URL_CHATBOX,
  URL_CHATROOM,
  URL_COMMUNITIES,
  URL_COUPONS,
  URL_DOMESTIC_MAIN,
  URL_FRIENDS,
  URL_HOME,
  URL_IMMIGRATE,
  URL_ORDERS,
  URL_REALTY_MAIN,
  URL_SETTLEDOWN,
  URL_STUDY_BC,
  URL_UPDATE_PROFILE,
} from "../constants";

export const MENU_TOPBAR = [
  {
    id: 1,
    title: "app.home",
    url: URL_HOME,
    category: "home",
    children: [],
  },
  {
    id: 2,
    title: "app.abroad",
    category: "abroad",
    children: [
      {
        title: "app.study",
        url: URL_STUDY_BC,
        category: "abroad",
        children: [],
      },
      {
        title: "app.immigration",
        url: URL_IMMIGRATE,
        category: "abroad",
        children: [],
      },
    ],
  },
  {
    id: 3,
    title: "app.settledown",
    url: URL_SETTLEDOWN,
    category: "settledown",
    children: [],
  },
  {
    id: 4,
    title: "app.domestic.service",
    url: URL_DOMESTIC_MAIN,
    category: "domestic",
    children: [],
  },
  {
    id: 5,
    title: "home.message.us",
    url: URL_CHATROOM,
    badge: '/assets/images/badge_new.png',
    category: "chat",
    children: [],
  },
  // {
  //   id: 4,
  //   title: 'app.find.home',
  //   url: URL_REALTY_MAIN,
  //   category: 'homeRental',
  //   children: [],
  // },
  // {
  //   id: 5,
  //   title: 'app.commercial',
  //   url: '/commercial/commercials',
  //   category: 'commercial',
  //   children: [],
  // },
];

// Sidbars
export const getSidebarItemsProfile = (mobile) => {
  return [
    {
      text: "auth.profile",
      icon: <Person />,
      link: URL_UPDATE_PROFILE,
    },
    {
      text: "auth.change.password",
      icon: <LockReset />,
      link: URL_CHANGE_PASSWORD,
    },
    // {
    //   text: "auth.resident.community",
    //   icon: <Apartment />,
    //   link: URL_COMMUNITIES,
    // },
    // {
    //   text: "auth.my.coupons",
    //   icon: <PriceChange />,
    //   link: URL_COUPONS,
    // },
    {
      text: "auth.orders",
      icon: <ShoppingCartCheckout />,
      link: URL_ORDERS,
    },
    // {
    //   text: "auth.chats",
    //   icon: <ChatBubble />,
    //   link: mobile ? URL_FRIENDS : URL_CHATBOX,
    // },
    // {
    //   text: "auth.posts",
    //   icon: <Article />,
    //   link: null,
    //   children: [
    //     {
    //       text: "auth.issues",
    //       icon: <BugReport />,
    //       link: '/profile/issues',
    //     },
    //     {
    //       text: "auth.requests",
    //       icon: <DirectionsRun />,
    //       link: '/profile/requests',
    //     },
    //     {
    //       text: "auth.activities",
    //       icon: <Celebration />,
    //       link: '/profile/activities',
    //     },
    //   ]
    // },
    {
      text: "auth.logout",
      icon: <Logout />,
      link: "logout",
    },
  ];
};

export const SIDEBAR_ITEMS_STRATA = [
  {
    text: "strata.summary",
    icon: <Apartment />,
    link: "/strata/summary",
  },
  {
    text: "strata.contacts",
    icon: <Call />,
    link: "/strata/contacts",
  },
  {
    text: "strata.notifications",
    icon: <NotificationImportant />,
    link: "/strata/notifications",
  },
  // {
  //   text: "strata.documents",
  //   icon: <Article />,
  //   link: '/strata/documents',
  // },
];

export const SIDEBAR_ITEMS_COMMUNITY = [
  {
    text: "community.events",
    icon: <Celebration />,
    link: "/community/activities",
  },
  {
    text: "community.requests",
    icon: <RequestQuote />,
    link: "/community/requests",
  },
  {
    text: "community.issues",
    icon: <BugReport />,
    link: "/community/issues",
  },

  // {
  //   text: "community.market",
  //   icon: <Sell />,
  //   link: '/community/market',
  // },
];

export const SIDEBAR_ITEMS_COMMERCIAL = [
  {
    text: "commercial.commercials",
    icon: <DinnerDining />,
    link: "/commercial/commercials",
  },
  // {
  //   text: "commercial.restaurants",
  //   icon: <Restaurant />,
  //   link: '/commercial/restaurants',
  // },
  // {
  //   text: "commercial.coupons",
  //   icon: <PriceChange />,
  //   link: '/commercial/coupons',
  // },
  // {
  //   text: "commercial.newsletters",
  //   icon: <Email />,
  //   link: '/commercial/newsletters',
  // },
  {
    text: "shop.my.cart",
    icon: <ShoppingCart />,
    link: "/commercial/cart",
  },
];
