// import { Snackbar, MuiAlert } from '@mui/material'
import React, { useCallback, useMemo, useState } from 'react'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Box, Button, Slide, SnackbarContent, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useUIContext } from '../context/ui';
import { SEVERITY_ERROR, SEVERITY_INFO, SEVERITY_SUCCESS, SEVERITY_WARNING } from '../constants';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={2} ref={ref} variant="filled" {...props} />;
});

function TransitionComponent(props) {
    return <Slide {...props} direction="down" />;
}

export default function useAlert() {
    const theme = useTheme()
    const { alertObj, showAlert, setShowAlert } = useUIContext()
    const closeAlert = () => {
        setShowAlert(false)
    }

    const bgColor = useMemo(() => {
        let color = theme.palette.primary.main
        
        if (alertObj.severity === SEVERITY_INFO) {
            color = theme.palette.info.main
        } else if (alertObj.severity === SEVERITY_SUCCESS) {
            color = theme.palette.success.main
        } else if (alertObj.severity === SEVERITY_WARNING) {
            color = theme.palette.warning.main
        } else if (alertObj.severity === SEVERITY_ERROR) {
            color = theme.palette.error.main
        }
        // console.log('alertObj.severity', color)
        return color
    }, [alertObj])

    const action = (
        <Button sx={{color: theme.palette.common.white}} size="small" onClick={() => {
            if (alertObj.okHandler) {
                alertObj.okHandler()
            }
            closeAlert()}
        }>
          <FormattedMessage id='app.ok' />
        </Button>
    );

    // console.log('duration', alertObj.duration)
    const AlertComponent = useCallback(() => {
        return (
            <Snackbar open={showAlert} 
                TransitionComponent={TransitionComponent}
                sx={{ height: "100%"}}
                autoHideDuration={alertObj.duration ? Number(alertObj.duration) : null} 
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                onClose={() => closeAlert()}>
                     {/* <Alert onClose={() => closeAlert()} severity={alertObj.severity} sx={{ width: '100%' }}>
                        {alertObj.content}
                        </Alert> */}
                    <SnackbarContent sx={{
                        backgroundColor: bgColor,
                        fontSize: '1rem'
                    }} message={alertObj.content} action={action} />
                
            </Snackbar>
        )
    }, [showAlert, alertObj?.duration, alertObj?.content, alertObj?.severity])

    return [AlertComponent]
}
