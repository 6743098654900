import { Stack, Tab, Tabs, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { CONFIG_KEY_INTRO_SCHOOL_BOARDS, CONFIG_KEY_SCHOOLS, CONFIG_KEY_SCHOOL_APPLICATION, CONFIG_KEY_SCHOOL_BOARDS, CONFIG_KEY_UNIVERSITIES } from "../../constants";
import Universities from "./Universities";
import { LOCALES } from "../../i18n/locales";
import { useTheme } from "@emotion/react";
import Secondary from "./Secondary";
import Procedure from "./Procedure";

const StudyBc = () => {
  const theme = useTheme()
  const [value, setValue] = useState("1");
  const [dataUniversities, setDataUniversities] = useState([])
  const [dataIntroSchoolBoards, setDataIntroSchoolBoards] = useState(null)
  const [dataSchoolBoards, setDataSchoolBoards] = useState(null)
  const [dataSchools, setDataSchools] = useState(null)
  const [dataSchoolApplication, setDataSchoolApplication] = useState(null)
  const config = useSelector((state) => state.config)?.config;
  const locale = useSelector((state) => {
    return state.persisted.global.locale
  } )?.value || LOCALES.ENGLISH;
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (config ) {
      if (config[CONFIG_KEY_UNIVERSITIES]) {
        setDataUniversities(config[CONFIG_KEY_UNIVERSITIES])
      }
      if (config[CONFIG_KEY_INTRO_SCHOOL_BOARDS]) {
        setDataIntroSchoolBoards(config[CONFIG_KEY_INTRO_SCHOOL_BOARDS])
      }

      if (config[CONFIG_KEY_SCHOOL_BOARDS]) {
        setDataSchoolBoards(config[CONFIG_KEY_SCHOOL_BOARDS])
      }

      if (config[CONFIG_KEY_SCHOOLS]) {
        setDataSchools(config[CONFIG_KEY_SCHOOLS])
      }

      if (config[CONFIG_KEY_SCHOOL_APPLICATION]) {
        setDataSchoolApplication(config[CONFIG_KEY_SCHOOL_APPLICATION])
      }
    }
  }, [config])
  return (
    <Stack
      width="100%"
      height="100%"
      justifyContent={"start"}
      alignItems={"center"}
      py={5}
      // border={`1px solid ${theme.palette.grey["200"]}`}
    >
      <Tabs
        value={value}
        variant="fullWidth"
        onChange={handleChange}
        aria-label="secondary tabs example"
        // sx={{ border: "1px solid green" }}
      >
        <Tab
          value="1"
          sx={{ width: mobile ? "80px" : "200px", fontSize: "18px", fontWeight: 600 }}
          label={<FormattedMessage id="study.tab.left" />}
        />
        <Tab
          value="2"
          sx={{ width: mobile ? "80px" : "200px", fontSize: "18px", fontWeight: 600 }}
          label={<FormattedMessage id="study.tab.center" />}
        />

        <Tab
          value="3"
          sx={{ width: mobile ? "80px" : "200px", fontSize: "18px", fontWeight: 600 }}
          label={<FormattedMessage id="study.tab.right" />}
        />
      </Tabs>
      <Stack
          justifyContent={"start"}
          alignItems={"center"}
          width="100%"
          height="100%"
        >
          {value === '1' && (<Secondary dataIntroSchoolBoards={dataIntroSchoolBoards} dataSchoolBoards={dataSchoolBoards} dataSchools={dataSchools} mobile={mobile} locale={locale} />)}
          {value === '2' && (<Universities data={dataUniversities} mobile={mobile} locale={locale} />)}
          {value === '3' && (<Procedure data={dataSchoolApplication} mobile={mobile} locale={locale} />)}
      </Stack>
    </Stack>
  );
};

export default StudyBc;
