import {
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { URL_LOGIN } from "../../constants";
import SignupForm from "./SignupForm";

const Signup = () => {
  const theme = useTheme();
  const intl = useIntl();
  const navigate = useNavigate();
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Stack
        width={mobile ? "100%" : tablet ? "60%" : "40%"}
        spacing={2}
        sx={{ my: 5, position: "relative" }}
      >
        <Typography variant="h4">
          <FormattedMessage id={"app.register2"} />
        </Typography>
        <Stack direction={"row"} spacing={0.5}>
          <Typography>
            <FormattedMessage id="auth.already.have.account" />
          </Typography>
          <Link component={RouterLink} to={URL_LOGIN} variant="subtitle2">
            <FormattedMessage id="app.login" />
          </Link>
        </Stack>
        {/* Register Form */}
        <SignupForm />
        <Typography
          component={"div"}
          sx={{
            color: "text.secondary",
            mt: 3,
            typography: "caption",
            textAlign: "center",
          }}
        >
          {intl.formatMessage({ id: "auth.by.signup" })}
          <Link
            underline="always"
            color={theme.palette.secondary.main}
            sx={{cursor: 'pointer'}}
            onClick={() =>
              window.open(process.env.REACT_APP_URL_TERMS_CONDITIONS, "_blank")
            }
          >
            <FormattedMessage id="footer.terms.conditions" />
          </Link>
          {intl.formatMessage({ id: "general.and" })}
          <Link
            underline="always"
            color={theme.palette.secondary.main}
            sx={{cursor: 'pointer'}}
            onClick={() =>
              window.open(process.env.REACT_APP_URL_TERMS_CONDITIONS, "_blank")
            }
          >
            <FormattedMessage id="footer.privacy.policy" />
          </Link>
          .
        </Typography>
      </Stack>
    </>
  );
};

export default Signup;
