import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useVerifyEmailMutation } from '../../apis/auth'
import useBackdrop from '../../hooks/useBackdrop'
import useAlert from '../../hooks/useAlert'
import { useIntl } from 'react-intl'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { useUIContext } from '../../context/ui'
import { URL_HOME, URL_LOGIN } from '../../constants'

const VerifyEmail = () => {
  const { emailToken } = useParams()
  const theme = useTheme()
  const intl = useIntl()
  const navigate = useNavigate()
  const [text, setText ] = useState(intl.formatMessage({id: 'auth.verifying.email'}))
  // const [BackdropComponent, showBackdrop, closeBackdrop] = useBackdrop()
  const { setShowBackdropCallback } = useUIContext()
  const [
    verifyEmail, 
    { isLoading: isUpdating }, 
  ] = useVerifyEmailMutation() 

  useEffect(() => {
    setShowBackdropCallback(isUpdating)
  }, [isUpdating])

  useEffect(async () => {
    if (emailToken) {
      const submitObj = {
        emailToken: emailToken
      }

      try {
        const result = await verifyEmail(submitObj)
        if (result.error) {
          setText(intl.formatMessage({id: 'auth.verifying.email.failed'}))
        } else {
          setText(intl.formatMessage({id: 'auth.verifying.email.success'}))
          setTimeout(() => {
            navigate(URL_LOGIN)
          }, 3000)
        }
      } catch (err) {
        setText(intl.formatMessage({id: 'auth.verifying.email.failed'}))
      } 
    }
  }, [emailToken])
  return (
    <>
      <Stack width='100%' minHeight='400px' display='flex' justifyContent='flex-start' alignItems='center'>
        <Box mt={10}>
            <Typography variant='h4'
                color={theme.palette.primary.main}
                fontWeight="bold">
                    {text}
            </Typography>
        </Box>
      </Stack>
    </>
  )
}

export default VerifyEmail