import {
  Box,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
  styled,
  Badge,
  useMediaQuery,
  Avatar,
  IconButton,
  Divider,
  Fade,
} from "@mui/material";
import { CaretDown, MagnifyingGlass } from "phosphor-react";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import SearchField from "../searchField/SearchField";
import { useIntl } from "react-intl";
import { socket } from "../../socket";
import { Search } from "@mui/icons-material";
import { setShowSearchPane } from "../../redux/chatroom";
import useAvatar from "../../hooks/useAvatar";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const Conversation_Menu = [
  // {
  //   title: "Contact info",
  // },
  // {
  //   title: "Mute notifications",
  // },
  {
    title: "chat.clear.messages",
  },
  {
    title: "chat.delete.conversation",
  },
  {
    title: "chat.pin.conversation",
  },
  {
    title: "chat.unpin.conversation",
  },
];

const ChatHeader = ({ user, current_conversation }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const intl = useIntl();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const avatarFunc = useAvatar()
  const [conversationMenuAnchorEl, setConversationMenuAnchorEl] =
    useState(null);
  const openConversationMenu = Boolean(conversationMenuAnchorEl);
  const handleClickConversationMenu = (event) => {
    setConversationMenuAnchorEl(event.currentTarget);
  };
  const handleCloseConversationMenu = (idx) => {
    setConversationMenuAnchorEl(null);
    if (idx === 0) {
      // Delete messages
      if (current_conversation.messages?.length > 0 && socket) {
        const clearParam = {
          conversationId: current_conversation._id,
          messageStart: current_conversation.latestMessageTime,
        };

        console.log("clearParam", clearParam);
        socket.emit("clear_messages", clearParam);
      }
    } else if (idx === 1) {
      // Delete conversation
      const deleteParam = {
        conversationId: current_conversation._id,
        messageStart: current_conversation.latestMessageTime,
      };
      socket.emit("delete_conversation", deleteParam);
    } else if (idx === 2) {
      // Pin/Unpin conversation
      if (current_conversation?.pinned) {
        const unpinParam = {
          conversationId: current_conversation._id,
        };
        socket.emit("unpin_conversation", unpinParam);
      } else {
        const pinParam = {
          conversationId: current_conversation._id,
        };
        socket.emit("pin_conversation", pinParam);
      }
    } 
  };

  const conversationMenu = useMemo(() => {
    return [
      {
        title: "chat.clear.messages",
      },
      {
        title: "chat.delete.conversation",
      },
      {
        title:
          current_conversation?.pinned === true
            ? "chat.unpin.conversation"
            : "chat.pin.conversation",
      },
    ];
  }, [current_conversation]);

  return (
    <>
      <Box
        p={2}
        width={"100%"}
        sx={{
          backgroundColor:
            theme.palette.mode === "light"
              ? "#F8FAFF"
              : theme.palette.background,
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Stack
          alignItems={"center"}
          direction={"row"}
          sx={{ width: "100%", height: "100%" }}
          justifyContent="space-between"
        >
          {current_conversation && (
            <Stack spacing={2} direction="row">
              <Box>
                {current_conversation?.online ? (
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    variant="dot"
                  >
                    <Avatar
                      // alt={current_conversation?.firstName}
                      src={
                        avatarFunc({image: current_conversation?.avatar})
                      }
                    />
                  </StyledBadge>
                ) : (
                  <Avatar
                    // alt={current_conversation?.firstName}
                    src={
                      avatarFunc({image: current_conversation?.avatar})
                    }
                  />
                )}
              </Box>
              <Stack spacing={0.2}>
                <Typography variant="subtitle2">
                  {current_conversation?.firstName}
                </Typography>
                <Typography variant="caption">
                  {current_conversation?.online
                    ? intl.formatMessage({ id: "chat.online" })
                    : intl.formatMessage({ id: "chat.offline" })}
                </Typography>
              </Stack>
            </Stack>
          )}
          {!current_conversation && <Box></Box>}
          <Stack direction={"row"} justifyContent={'end'} alignItems="center" spacing={mobile ? 1 : 3}>
            {/* {!mobile && (
              <SearchField setSearchText={setSearchText} mobile={mobile} />
            )}
            <Divider orientation="vertical" flexItem /> */}
            {mobile && (
              <IconButton onClick={() => {
                dispatch(setShowSearchPane({
                  showSearch: true,
                }))
              }}>
                <Search />
              </IconButton>
            )}
            <IconButton
              id="conversation-positioned-button"
              aria-controls={
                openConversationMenu
                  ? "conversation-positioned-menu"
                  : undefined
              }
              aria-haspopup="true"
              aria-expanded={openConversationMenu ? "true" : undefined}
              onClick={handleClickConversationMenu}
            >
              <CaretDown />
            </IconButton>
            <Menu
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              TransitionComponent={Fade}
              id="conversation-positioned-menu"
              aria-labelledby="conversation-positioned-button"
              anchorEl={conversationMenuAnchorEl}
              open={openConversationMenu}
              onClose={handleCloseConversationMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Box p={1}>
                <Stack spacing={1}>
                  {conversationMenu.map((el, idx) => (
                    <MenuItem
                      key={idx}
                      onClick={() => handleCloseConversationMenu(idx)}
                    >
                      <Stack
                        sx={{ minWidth: 100 }}
                        direction="row"
                        alignItems={"center"}
                        justifyContent="space-between"
                      >
                        <span>{intl.formatMessage({ id: el.title })}</span>
                      </Stack>{" "}
                    </MenuItem>
                  ))}
                </Stack>
              </Box>
            </Menu>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default ChatHeader;
