import { api } from './api'

const configApi = api.injectEndpoints({
  endpoints: (build) => ({
      getConfig: build.query({
          query: () => ({
            url: "api/config",
            method: "GET",
          }),
          providesTags: 'Configs'
      }),
      getConfigs: build.query({
        query: () => ({
          url: "api/configs",
          method: "GET",
        }),
        providesTags: 'ConfigsAll'
    }),
    }),
    overrideExisting: false,
})
    
export const { useGetConfigQuery, useGetConfigsQuery } = configApi