import { Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useUIContext } from "../../context/ui";
import SearchField from "../../components/searchField/SearchField";
import ContactElement from "./ContactElement";
import { FormattedMessage } from "react-intl";
import { useGetPendingRequestsByUserIdQuery } from "../../apis/friendRequest";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setRequests } from "../../redux/pendingRequest";
import useDialogModal from "../../hooks/useDialogModal";
import ContactRequest from "../../components/modals/ContactRequest";
import { CONTACT_ELEMENT_CONTACT, CONTACT_ELEMENT_REQUEST, CONTACT_ELEMENT_SEARCH_RESULT } from "../../constants";
import AddContact from "../../components/modals/AddContact";
import { useGetContactsQuery } from "../../apis/auth";
import useAvatar from "../../hooks/useAvatar";

const Contacts = ({ user, userToken, mobile, locale, pendingRequests, current_conversation }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [contacts, setContacts] = useState([]);
  
  const [searchText, setSearchText] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [selectedRequest, setSelectedRequest] = useState(null)
  const [selectedContact, setSelectedContact] = useState(null)
  const [searchedUsers, setSearchedUsers] = useState([])
  const [toUser, setToUser] = useState(null);
  const [filteredRequests, setFilteredRequests] = useState([])
  const { setShowBackdropCallback } = useUIContext();

  const avatarFunc = useAvatar()
  const {
    data: dataSearchedUsers,
    isLoading: isLoadingSearchedUsers,
    isFetchingSearchedUsers,
  } = useGetContactsQuery(
    {
      limit: 10,
      search: searchText,
      token: userToken?.token,
    },
    { skip: searchText === "" }
  );

  useEffect(() => {
    if (searchText?.length === 0) {
      setSearchedUsers([])
    }
  }, [searchText])

  useEffect(() => {
    setShowBackdropCallback(
      isLoadingSearchedUsers || isFetchingSearchedUsers
    );
  }, [isLoadingSearchedUsers, isFetchingSearchedUsers]);

  useEffect(() => {
    // console.log('dataSearchedUsers?.data?.users', dataSearchedUsers?.data?.users)
    const searchedUserList = [];
    if (dataSearchedUsers?.status === 'success' && dataSearchedUsers?.data?.users?.length > 0) {
        dataSearchedUsers?.data?.users?.filter((item) => {
          return user?.friends?.findIndex((friend) => friend._id === item._id) < 0
        })?.map((item) => {
          searchedUserList.push({
            id: item._id,
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
            avatar: avatarFunc({image: item.avatar}),
            socketStatus: item.socket_status,
            lastMessage: null,
            lastMessageTime: null,
            unread: 0,
          });
        });
    }

    setSearchedUsers(searchedUserList);
  }, [dataSearchedUsers, avatarFunc]);

  useEffect(() => {
    console.log('***** pendingRequests', pendingRequests)
    if (
      pendingRequests?.length > 0
    ) {
      const requests = pendingRequests?.filter((item) => {
        return (
          item?.sender?.firstName
            ?.toLowerCase()
            ?.indexOf(searchInput?.toLowerCase()) >= 0 ||
          item?.sender?.lastName
            ?.toLowerCase()
            ?.indexOf(searchInput?.toLowerCase()) >= 0 ||
          item?.sender?.email
            ?.toLowerCase()
            ?.indexOf(searchInput?.toLowerCase()) >= 0 ||
          item?.sender?.username
            ?.toLowerCase()
            ?.indexOf(searchInput?.toLowerCase()) >= 0 ||
          item?.sender?.description
            ?.toLowerCase()
            ?.indexOf(searchInput?.toLowerCase()) >= 0
        );
      });
      setFilteredRequests(requests)
    } else {
      setFilteredRequests([])
    }
  }, [pendingRequests, searchInput]);

  useEffect(() => {
    const contactList = [];
    if (user?.friends?.length > 0) {
      setSearchedUsers([...searchedUsers?.filter((item) => {
        return user.friends.findIndex((friend) => friend._id === item.id) < 0
      })])
      const friends = user?.friends?.filter((item) => {
        return (
          item?.firstName?.toLowerCase()?.indexOf(searchInput?.toLowerCase()) >=
            0 ||
          item?.lastName?.toLowerCase()?.indexOf(searchInput?.toLowerCase()) >= 0 ||
          item?.email?.toLowerCase()?.indexOf(searchInput?.toLowerCase()) >= 0 ||
          item?.username?.toLowerCase()?.indexOf(searchInput?.toLowerCase()) >= 0 ||
          item?.description?.toLowerCase()?.indexOf(searchInput?.toLowerCase()) >= 0
        );
      });
      if (friends?.length > 0) {
        friends?.map((friend) => {
          contactList.push({
            id: friend._id,
            firstName: friend.firstName,
            lastName: friend.lastName,
            email: friend.email,
            avatar: avatarFunc({image: friend.avatar}),
            socketStatus: friend.socket_status,
            online: friend.socket_status === 'Online',
            description: friend.description,
            lastMessage: null,
            lastMessageTime: null,
            unread: 0,
          });
        });
      }
    }

    setContacts(contactList);
  }, [user?.friends, searchInput, avatarFunc]);

  useEffect(() => {
    if (contacts?.length > 0) {
      if (!current_conversation) {
        setSelectedContact(contacts[0])
      } else if (current_conversation) {
        setSelectedContact(contacts.find((item) => {
          return item.id === current_conversation.userId
        }))
      }
    }
    
  }, [contacts, current_conversation])

  const [ContactRequestDialog, showContactRequestDialog] =
    useDialogModal(ContactRequest);
  const [AddContactDialog, showAddContactDialog] = useDialogModal(AddContact);
    
  return (
    <>
      <Stack width="100%" justifyContent={"start"} alignItems={"center"} p={2}>
        <Stack
          width="100%"
          direction={"row"}
          justifyContent={"start"}
          alignItems={"center"}
          px={1}
        >
          <SearchField
            setSearchText={setSearchText}
            setInput={setSearchInput}
            mobile={mobile}
            width={"100%"}
          />
        </Stack>
        <Stack
          width="100%"
          justifyContent={"start"}
          alignItems={"center"}
          spacing={0.5}
          pt={2}
        >
          {searchedUsers?.length > 0 && (
            <>
              <Typography variant="subtitle2" sx={{ color: "#676667" }}>
                <FormattedMessage id="general.search.result" />
              </Typography>
              {searchedUsers?.map((contact) => (
                <ContactElement key={contact.id} userId={user?._id} category={CONTACT_ELEMENT_SEARCH_RESULT} contact={contact} locale={locale} showAddContactDialog={showAddContactDialog} setToUser={setToUser} />
              ))}
            </>
          )}

          {filteredRequests?.length > 0 && (
            <>
              <Typography variant="subtitle2" sx={{ color: "#676667" }}>
                <FormattedMessage id="chat.friend.requests" />
              </Typography>
              {filteredRequests?.map((request) => (
                <ContactElement
                  key={request.id}
                  userId={user?._id} 
                  category={CONTACT_ELEMENT_REQUEST}
                  contact={request?.sender}
                  locale={locale}
                  pendingRequest={request}
                  selectedContact={selectedRequest}
                  setSelectedContact={setSelectedRequest}
                  showContactRequestDialog={showContactRequestDialog}
                />
              ))}
            </>
          )}
          {contacts?.length > 0 && (
            <>
              <Typography variant="subtitle2" sx={{ color: "#676667" }}>
                <FormattedMessage id="chat.contacts" />
              </Typography>
              {contacts?.map((contact) => {
                // const conversation = conversations?.find((item) => item.userId === contact.id) || null
                return (
                <ContactElement
                  key={contact.id}
                  userId={user?._id} 
                  category={CONTACT_ELEMENT_CONTACT}
                  contact={contact}
                  selectedContact={selectedContact}
                  setSelectedContact={setSelectedContact}
                  conversation={current_conversation}
                  locale={locale}
                />
              )})}
            </>
          )}

          {pendingRequests?.length === 0 && contacts?.length === 0 && searchedUsers?.lenght === 0 && (
            <Typography variant="h6" color={theme.palette.primary.main}>
              <FormattedMessage id="chat.no.contacts" />
            </Typography>
          )}
        </Stack>
      </Stack>
      <ContactRequestDialog request={selectedRequest} locale={locale} />
      <AddContactDialog fromUser={user} toUser={toUser} />
    </>
  );
};

export default Contacts;
