import { Box, Stack, useTheme } from "@mui/material";
import React, { useMemo, useRef } from "react";
import ChatHeader from "../../components/chat/ChatHeader";
import ChatFooter from "../../components/chat/ChatFooter";
import { Conversation } from "../../components/chat/Conversation";
import { useSelector } from "react-redux";
import ReplySection from "./ReplySection";
import UploadSection from "./UploadSection";

const ChatRoom = ({ user, userToken, mobile, current_conversation }) => {
  const theme = useTheme();
  const messageListRef = useRef(null);

  const { conversation_reply, conversation_uploads } = useSelector((state) => state.chatroom);

  return (
    <>
      <Stack
        width={mobile ? "96%" : '100%'}
        height={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
        // border='1px solid green'
      >
        <ChatHeader user={user} current_conversation={current_conversation} />
        <Box
          id="conversation_container"
          ref={messageListRef}
          width={"100%"}
          height={"80%"}
          // minHeight={"400px"}
          // maxHeight={"500px"}
          flexGrow={1}
          sx={{
            position: "relative",
            flexGrow: 1,

            backgroundColor:
              theme.palette.mode === "light"
                ? "#F0F4FA"
                : theme.palette.background,

            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Conversation
            menu={true}
            mobile={mobile}
            user={user}
            current_conversation={current_conversation}
          />
        </Box>
          {conversation_reply?.message && (
            <ReplySection conversation={current_conversation} message={conversation_reply?.message} />
          )}
          {conversation_uploads?.files?.length > 0 && (
            <UploadSection conversation={current_conversation} files={conversation_uploads?.files} />
          )}
        <ChatFooter user={user} userToken={userToken} current_conversation={current_conversation} />
      </Stack>
    </>
  );
};

export default ChatRoom;
