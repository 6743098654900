import { Box, Button, IconButton, Paper, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { DataGrid } from "@mui/x-data-grid";
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useGetOrdersByUserIdQuery } from '../../apis/order';
import OrderDetail from '../../components/modals/OrderDetail'
import moment from 'moment'
import useDialogModal from '../../hooks/useDialogModal';
import { useUIContext } from '../../context/ui';
import Payment from '../../components/modals/Payment';

const MyOrders = () => {
    const theme = useTheme();
    const intl = useIntl()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [selectedRow, setSelectedRow] = useState(null)
    const [sort, setSort] = useState({});
    const tablet = useMediaQuery(theme.breakpoints.down('md'))
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))
    const { setIsLoadingExtras } = useUIContext()
    const user = useSelector((state) => state.user.currentUser)?.data; 
    const userToken = useSelector((state) => state.persisted.global.userToken); 
    const [OrderDetailDialog, showOrderDetailDialog] = useDialogModal(OrderDetail)
    const [PaymentDialog, showPaymentDialog] = useDialogModal(Payment)
    const { data: dataOrders, isLoading: isLoadingOrders, isFetching: isFetchingOrders } = useGetOrdersByUserIdQuery({
        id: userToken?._id, 
        page: 0,
        pageSize: 20,
        sort: JSON.stringify({
          field: 'createdAt',
          sort: 'desc',
        }),
        // vendor: process.env.REACT_APP_DEFAULT_VENDOR,
        client: process.env.REACT_APP_CURRENT_CLIENT,
        token: userToken?.token,
    });

    useEffect(() => {
      setIsLoadingExtras(isLoadingOrders || isFetchingOrders)
    }, [isLoadingOrders, isFetchingOrders])

      const columns = [
        {
          field: "orderId",
          headerName: (<Typography variant='body2'>{intl.formatMessage({id: 'shop.order.id'})}</Typography>),
          flex: 0.7,
          renderCell: (params) => {
            return (<Link onClick={() => {
                setSelectedRow(params.row)
                showOrderDetailDialog()
            }}><Typography variant='body2'>{params.value}</Typography></Link>)
          }
        },
        {
          field: "total",
          headerName: (<Typography variant='body2'>{intl.formatMessage({id: 'shop.total'})}</Typography>),
          flex: 0.5,
          renderCell: (params) => {
            return <Typography variant='body2'>{'$' + params.value.toFixed(2)}</Typography>
          }
        },
        {
          field: "status",
          headerName: (<Typography variant='body2'>{intl.formatMessage({id: 'general.status'})}</Typography>),
          flex: 0.75,
          renderCell: (params) => {
            return (params.value === 'unpaid' ? <Link onClick={() => {
                setSelectedRow(params.row)
                showPaymentDialog()
            }}><Typography variant='body2'>{intl.formatMessage({id: 'shop.status.' + params.value})}</Typography></Link> : <Typography variant='body2'>{intl.formatMessage({id: 'shop.status.' + params.value})}</Typography>)
          }
        },
        {
          field: "payment",
          headerName: (<Typography variant='body2'>{intl.formatMessage({id: 'commercial.payment'})}</Typography>),
          flex: 0.75,
          renderCell: (params) => {
            return params.value === 'emt' ? 
            (<Typography variant='body2'>
              {'eTransfer'}
            </Typography>)
            : (
              <Typography variant='body2'>
                {intl.formatMessage({id: 'commercial.payment.credit'})}
              </Typography>
            )
          }
        },
        {
          field: "shipping",
          headerName: (<Typography variant='body2'>{intl.formatMessage({id: 'commercial.shipping'})}</Typography>),
          flex: 1,
          renderCell: (params) => {
            return <Typography variant='body2'>{intl.formatMessage({id: 'commercial.' + params.value})}</Typography>
          }
        },
        {
            field: "createdAt",
            headerName: (<Typography variant='body2'>{intl.formatMessage({id: 'general.date'})}</Typography>),
            flex: 1,
            renderCell: (params) => {
                return <Typography variant='body2'>{moment(params.value).format('YYYY-MM-DD HH:mm')}</Typography>;
            }
        },
      ];

  return (
    <>
    <Stack width='100%' justifyContent='center' alignItems={mobile ? 'center' : 'flex-start'} p={3}>
            <Box mb={2} >
                <Typography variant='h5'
                    color={theme.palette.primary.main}
                    fontWeight="bold">
                        <FormattedMessage id='profile.my.orders' />
                </Typography>
            </Box>
            <Box width={mobile ? '100%' : '80%'} maxWidth={mobile ? '340px' : '1000px'} mb={3}>
                <Typography variant='h6'
                    color={theme.palette.warning.dark}
                    fontWeight="bold"
                    fontSize='1.2rem'>
                        <FormattedMessage id='general.not.available' />
                </Typography>
            </Box>
            {/* <Box width={mobile ? '100%' : '80%'} maxWidth={mobile ? '340px' : '1000px'} mb={3}>
                <Typography variant='h6'
                    color={theme.palette.warning.dark}
                    fontWeight="bold"
                    fontSize='1.2rem'>
                        <FormattedMessage id='commercial.orders.contact' />
                </Typography>
            </Box>
            <Box width={mobile ? '100%' : '80%'} maxWidth={mobile ? '340px' : '1000px'} overflow='auto'>
              <Box minWidth='600px'>
              <DataGrid
                    // showCellVerticalBorder={true}
                    // showColumnVerticalBorder={true}
                    sx={{border: `1px solid ${theme.palette.grey['300']}`}}
                    loading={isLoadingOrders}
                    getRowId={(row) => row._id}
                    rows={(dataOrders && dataOrders.orders) || []}
                    rowHeight={120}
                    columns={columns}
                    rowCount={(dataOrders && dataOrders.total) || 0}
                    rowsPerPageOptions={[20, 50, 100]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode="server"
                    sortingMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    onSortModelChange={(newSortModel) => setSort(...newSortModel)}
                />
              </Box>
              
            </Box> */}
        </Stack>
        <OrderDetailDialog order={selectedRow} tablet={tablet} mobile={mobile} />
        <PaymentDialog unpaidOrder={selectedRow} tablet={tablet} mobile={mobile} />
    </>
  )
}

export default MyOrders