import React, { useEffect, useMemo, useState } from "react";
import TopbarMobile from "./TopbarMobile";
import TopbarDesktop from "./TopbarDesktop";
import useDialogModal from "../../hooks/useDialogModal";
import LanguageSelect from "../modals/LanguageSelect";
import {
  EMPTY_CART,
  NULL_USER,
  SEVERITY_SUCCESS,
  SEVERITY_WARNING,
  URL_COMMUNITIES,
  URL_HOME,
  URL_LOGIN,
  URL_RESET_PASSWORD_2,
  URL_UPDATE_PROFILE,
  URL_VERIFY_EMAIL_2,
} from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetUserByIdQuery,
  useGetUsersCountQuery,
  usePingMutation,
} from "../../apis/auth";
import {
  removeCart,
  setActiveBuilding,
  setCarts,
  setDeliveryAddress,
  setDeliveryPhone,
  setLastNotification,
  setUnpaidOrder,
  setUserToken,
} from "../../redux/global";
import Notification from "../modals/Notification";
import {
  useGetVendorCategoriesQuery,
  useGetVendorsQuery,
} from "../../apis/vendor";
import {
  setVendorCategories,
  setVendors,
  setVendorsTotal,
} from "../../redux/vendor";
import { useGetCommunitiesQuery } from "../../apis/community";
import { useGetBuildingsQuery } from "../../apis/building";
import { useGetDeliveriesQuery } from "../../apis/delivery";
import { useGetNotificationsQuery } from "../../apis/notification";
import { useGetUnreadMessageCountQuery } from "../../apis/post";
import { useGetConfigsQuery } from "../../apis/config";
import { useUIContext } from "../../context/ui";
import { setCommunities } from "../../redux/community";
import { setBuildings } from "../../redux/building";
import { setDeliveries } from "../../redux/delivery";
import { setUnreadMessageCount } from "../../redux/post";
import { setNotifications } from "../../redux/notification";
import { setConfig } from "../../redux/config";
import { setUser, setUsersCount } from "../../redux/user";
import { Box, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { setLocale } from "../../redux/global";
import { LOCALES } from "../../i18n/locales";
import { socket, connectSocket } from "../../socket";
import ContactRequest from "../modals/ContactRequest";
import {
  addDirectConversation,
  addDirectMessage,
  clearMessages,
  deleteConversation,
  pinConversation,
  resetConversations,
  unpinConversation,
  updateConversationStatus,
  updateDirectConversation,
} from "../../redux/conversation";
import {
  selectConversation,
  setConversationViewed,
  setTabSelected,
} from "../../redux/chatroom";
import {
  addRequest,
  removeRequest,
  setRequests,
} from "../../redux/pendingRequest";
import { useGetPendingRequestsByUserIdQuery } from "../../apis/friendRequest";
import moment from "moment";
import { useGetConversationsQuery } from "../../apis/conversation";
import useAvatar from "../../hooks/useAvatar";
import useImage from "../../hooks/useImage";

const WeixinTipContainer = styled("div")(() => ({
  display: "block",
  position: "fixed",
  right: 0,
  top: "4px",
  alignItems: "center",
}));

const WeixinTip = styled("div")(({ theme }) => ({
  background: theme.palette.error.main, //'#40b2a8',
  zIndex: 2000,
  padding: "8px",
  borderRadius: "8px",
  marginRight: "8px",
}));

const WeixinTipTitle = styled("p")(() => ({
  textAlign: "center",
  fontSize: "14px",
  color: "#fff",
}));

const WeixinTipContent = styled("p")(() => ({
  textAlign: "center",
  fontSize: "14px",
  color: "#fff",
}));

const WeixinTipTriangle = styled("div")(({ theme }) => ({
  width: 0,
  height: 0,
  borderWidth: "0 6px 12px",
  borderStyle: "solid",
  borderColor: "transparent transparent " + theme.palette.error.main,
  /*透明 透明  灰*/
  marginLeft: "114px",
}));

const Topbar = ({ tablet, mobile }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { pathname } = useLocation();

  const user = useSelector((state) => state.user.currentUser)?.data;

  const userToken = useSelector((state) => state.persisted.global.userToken);
  const localeSaved =
    useSelector((state) => state.persisted.global.locale)?.saved || false;
  const locale =
    useSelector((state) => state.persisted.global.locale)?.value ||
    LOCALES.ENGLISH;
  const notifications = useSelector(
    (state) => state.notification.notifications
  );
  const lastNotification = useSelector(
    (state) => state.persisted.global.lastNotification
  );
  const carts = useSelector((state) => state.persisted.global.carts);
  // const buildings = useSelector((state) => state.building.buildings);
  // const activeBuilding = useSelector(
  //   (state) => state.persisted.global.activeBuilding
  // );
  // const activeVendor = useSelector(
  //   (state) => state.persisted.global.activeVendor
  // );

  const directChats = useSelector(
    (state) => state.persisted.conversation.direct_chats
  );

  const config = useSelector((state) => state.config)?.config;

  const avatarFunc = useAvatar();
  const imageFunc = useImage();
  const conversations = useMemo(() => {
    return directChats && user?._id?.length > 0
      ? directChats[user?._id]?.conversations
      : [];
  }, [directChats, user?._id]);

  const current_conversation = useMemo(() => {
    return directChats && user?._id?.length > 0
      ? directChats[user?._id]?.current_conversation
      : null;
  }, [directChats, user?._id]);

  useEffect(() => {
    console.log("*** conversations", conversations);
  }, [conversations]);

  const [popped, setPopped] = useState(false);
  const [cart, setCart] = useState(null);
  const [isWeixin, setIsWeixin] = useState(false);
  const [isQQ, setIsQQ] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);

  useEffect(() => {
    let ua = navigator.userAgent.toLowerCase();
    let languages = navigator.languages;

    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      //微信中打开网页
      setIsWeixin(true);
      dispatch(
        setLocale({
          value: LOCALES.CHINESE_SIMPLIFIED,
          saved: false,
        })
      );
    } else if (ua.match(/QQ/i) == "qq") {
      //qq里打开网页
      setIsQQ(true);
    }

    if (/android/i.test(ua)) {
      setIsAndroid(true);
    } else if (/iPad|iPhone|iPod/.test(ua)) {
      setIsIOS(true);
    }

    if (languages.indexOf("zh-CN") >= 0 && !localeSaved) {
      dispatch(
        setLocale({
          value: LOCALES.CHINESE_SIMPLIFIED,
          saved: false,
        })
      );
    } else if (languages.indexOf("zh-TW") >= 0 && !localeSaved) {
      dispatch(
        setLocale({
          value: LOCALES.CHINESE_TRADITIONAL,
          saved: false,
        })
      );
    } else {
      dispatch(
        setLocale({
          value: LOCALES.CHINESE_SIMPLIFIED,
          saved: false,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (user) {
      console.log("user", user);
      // dispatch(resetConversations({ userId: user?._id }));
    }
  }, [user]);

  // useEffect(() => {
  //   if (isWeixin) {
  //     dispatch(setLocale(LOCALES.CHINESE_SIMPLIFIED))
  //   }
  // }, [isWeixin])

  useEffect(() => {
    if (carts) {
      if (!user && carts[NULL_USER]) {
        setCart(carts[NULL_USER]);
      } else if (user && carts[user?._id]) {
        setCart(carts[user?._id]);
      }
    }
  }, [carts, user]);

  const {
    isLoading,
    setIsLoading,
    isLoadingExtras,
    setShowBackdropCallback,
    setShowAlert,
    setAlertObj,
  } = useUIContext();

  const [LanguageSelectDialog, showLanguageSelectDialog] =
    useDialogModal(LanguageSelect);
  const [NotificationDialog, showNotificationDialog] =
    useDialogModal(Notification);

  const notificationsAfter = useMemo(() => {
    if (lastNotification) {
      return lastNotification;
    } else {
      let afterDate = new Date();
      afterDate.setDate(
        afterDate.getDate() - process.env.REACT_APP_NOTIFICATION_DAYS_BACK
      );
      return afterDate.getTime();
    }
  }, [lastNotification]);

  const {
    data: dataUser,
    isLoading: isLoadingUser,
    isFetching: isFetchingUser,
  } = useGetUserByIdQuery({
    id: userToken?._id,
    token: userToken?.token,
  });

  const {
    data: dataConversations,
    isLoading: isLoadingConversations,
    isFetchingConversations,
  } = useGetConversationsQuery(
    { userId: user?._id, token: userToken?.token },
    { skip: !user?._id || !userToken }
  );

  // console.log('****** dataUser', userToken, dataUser)

  // const {
  //   data: dataCommunities,
  //   isLoading: isLoadingCommunities,
  //   isFetching: isFetchingCommunities,
  // } = useGetCommunitiesQuery({
  //   page: 0,
  //   pageSize: process.env.REACT_APP_DEFAULT_COMMUNITIES_LOAD,
  //   sort: JSON.stringify({}),
  //   search: "",
  // });

  // const {
  //   data: dataBuildings,
  //   isLoading: isLoadingBuildings,
  //   isFetching: isFetchingBuildings,
  // } = useGetBuildingsQuery({
  //   page: 0,
  //   pageSize: process.env.REACT_APP_DEFAULT_BUILDINGS_LOAD,
  //   sort: JSON.stringify({}),
  //   search: "",
  // });

  // const {
  //   data: dataUsersCount,
  //   isLoading: isLoadingUsersCount,
  //   isFetching: isFetchingUsersCount,
  // } = useGetUsersCountQuery({});

  // const {
  //   data: dataVendorCategories,
  //   isLoading: isLoadingVendorCategories,
  //   isFetching: isFetchingVendorCategories,
  // } = useGetVendorCategoriesQuery({
  //   page: 0,
  //   pageSize: process.env.REACT_APP_DEFAULT_PRODUCTS_LOAD,
  //   sort: JSON.stringify({}),
  //   search: "",
  // });

  // const {
  //   data: dataDeliveries,
  //   isLoading: isLoadingDeliveries,
  //   isFetchingDeliveries,
  // } = useGetDeliveriesQuery({
  //   page: 0,
  //   pageSize: process.env.REACT_APP_DEFAULT_BUILDINGS_LOAD,
  //   sort: JSON.stringify({}),
  //   vendor: activeVendor,
  //   search: "",
  // });

  const {
    data: dataNotifications,
    isLoading: isLoadingNotifications,
    isFetching: isFetchingNotifications,
  } = useGetNotificationsQuery({});

  // const {
  //   data: dataUnreadMessageCount,
  //   isLoading: isLoadingUnreadMessageCount,
  //   isFetching: isFetchingUnreadMessageCount,
  // } = useGetUnreadMessageCountQuery({
  //   user: user?._id,
  //   token: userToken?.token,
  // }, { skip: !user?._id || !userToken });

  const {
    data: dataPendingContacts,
    isLoading: isLoadingPendingContacts,
    isFetchingPendingContacts,
  } = useGetPendingRequestsByUserIdQuery(
    {
      userId: user?._id,
      token: userToken?.token,
    },
    { skip: !user?._id || !userToken }
  );

  // const { data: dataConfig, isLoading: isLoadingConfig, isFetchingConfig } = useGetConfigQuery();

  const {
    data: dataConfigs,
    isLoading: isLoadingConfigs,
    isFetchingConfigs,
  } = useGetConfigsQuery();

  const tags = useMemo(() => {
    if (pathname?.indexOf("farm") >= 0) {
      return "farm";
    } else if (pathname?.indexOf("commercial") >= 0) {
      return "commercial";
    } else if (cart?.tags?.indexOf("farm") >= 0) {
      return "farm";
    } else {
      return "commercial";
    }
  }, [pathname, cart]);

  // console.log('tags', pathname, cart?.tags, tags)
  // const {
  //   data: dataVendors,
  //   isLoading: isLoadingVendors,
  //   isFetching: isFetchingVendors,
  // } = useGetVendorsQuery({
  //   page: 0,
  //   pageSize: process.env.REACT_APP_DEFAULT_PRODUCTS_LOAD,
  //   sort: JSON.stringify({}),
  //   tag: tags,
  //   search: "",
  // });

  useEffect(() => {
    setIsLoading(
      // isLoadingCommunities ||
      //   isFetchingCommunities ||
      //   isLoadingBuildings ||
      //   isFetchingBuildings ||
      //   isLoadingUsersCount ||
      //   isFetchingUsersCount ||
      //   isLoadingVendorCategories ||
      //   isFetchingVendorCategories ||
      //   isLoadingDeliveries ||
      //   isFetchingDeliveries ||
      isLoadingNotifications ||
        isFetchingNotifications ||
        isLoadingConversations ||
        isFetchingConversations ||
        isLoadingConfigs ||
        isFetchingConfigs ||
        isLoadingUser ||
        isFetchingUser
      // isLoadingVendors ||
      // isFetchingVendors
    );
  }, [
    // isLoadingCommunities,
    // isFetchingCommunities,
    // isLoadingBuildings,
    // isLoadingUsersCount,
    // isFetchingUsersCount,
    // isFetchingBuildings,
    // isLoadingVendorCategories,
    // isFetchingVendorCategories,
    // isLoadingDeliveries,
    // isFetchingDeliveries,
    isLoadingNotifications,
    isFetchingNotifications,
    isLoadingConversations,
    isFetchingConversations,
    isLoadingConfigs,
    isFetchingConfigs,
    isLoadingUser,
    isFetchingUser,
    // isLoadingVendors,
    // isFetchingVendors,
  ]);

  // useEffect(() => {
  //   if (isLoadingVendors || isFetchingVendors) {
  //     dispatch(setVendors([]));
  //   }
  // }, [isLoadingVendors, isFetchingVendors]);

  // useEffect(() => {
  //   if (dataVendors?.vendors) {
  //     const processedVendors = dataVendors?.vendors?.map((item) => {
  //       return {
  //         ...item,
  //         title: JSON.parse(item.title),
  //         description: JSON.parse(item.description),
  //         category: {
  //           ...item.category,
  //           title: JSON.parse(item.category.title),
  //           details:
  //             item?.category?.details?.length > 0
  //               ? JSON.parse(item.category.details)
  //               : "",
  //         },
  //       };
  //     });

  //     dispatch(setVendors(processedVendors));
  //   }

  //   dispatch(setVendorsTotal(dataVendors?.total2));
  // }, [dataVendors?.vendors, dispatch]);

  // useEffect(() => {
  //   dispatch(
  //     setVendorCategories(
  //       dataVendorCategories?.categories?.map((item) => {
  //         return {
  //           ...item,
  //           title: JSON.parse(item.title),
  //           details: item?.details?.length > 0 ? JSON.parse(item.details) : "",
  //         };
  //       })
  //     )
  //   );
  // }, [dataVendorCategories?.categories]);

  // useEffect(() => {
  //   dispatch(setCommunities(dataCommunities?.communities));
  // }, [dataCommunities?.communities, dispatch]);

  // useEffect(() => {
  //   dispatch(setBuildings(dataBuildings?.buildings));
  // }, [dataBuildings?.buildings, dispatch]);

  // useEffect(() => {
  //   if (dataDeliveries?.deliveries?.length > 0) {
  //     const updatedDeliveries = [
  //       ...dataDeliveries?.deliveries.map((item) => {
  //         return { ...item, details: JSON.parse(item.details) };
  //       }),
  //     ];
  //     dispatch(setDeliveries(updatedDeliveries));
  //   } else {
  //     dispatch(setDeliveries(null));
  //   }
  // }, [dataDeliveries?.deliveries, dispatch]);

  // useEffect(() => {
  //   if (dataUnreadMessageCount && dataUnreadMessageCount?.total?.length > 0) {
  //     const unreadCount = dataUnreadMessageCount?.total?.reduce((acc, val) => {
  //       return acc + val.quantity;
  //     }, 0);
  //     dispatch(setUnreadMessageCount(unreadCount));
  //   }
  // }, [dataUnreadMessageCount, dispatch]);

  useEffect(() => {
    if (dataConfigs && dataConfigs.status === "success") {
      let configObj = {};
      // console.log('dataConfigs.data.configs', dataConfigs.data.configs)
      dataConfigs.data.configs.map((item) => {
        configObj[item.key] = item.value;
      });
      dispatch(setConfig(configObj));
    }
  }, [dataConfigs, dispatch]);

  useEffect(async () => {
    // console.log('0 handeshake dataUser ...', dataUser)
    if (dataUser && dataUser.status === "success") {
      dispatch(
        setUser({
          data: {
            ...dataUser?.data?.user,
            avatar: avatarFunc({ image: dataUser?.data?.user?.avatar }),
            accessToken: userToken?.token,
            // socket_id: socket.id,
            socket_status: "Online",
          },
        })
      );

      // console.log('1 handeshake ...', socket?.connected)
      if (!socket?.connected) {
        // console.log('2 handeshake ...', dataUser?.data?.user?._id)
        connectSocket(dataUser?.data?.user?._id);
        // broadcast online
      }
    } else {
      dispatch(setUser(null));
    }
  }, [dataUser, dispatch, avatarFunc]);

  useEffect(() => {
    // console.log('aaa', socket)
    if (socket?.connected && user) {
      const socketParam = {
        userId: user?._id,
        status: "Online",
      };
      // console.log('bbb', socketParam)
      socket.emit("broadcast_friends", socketParam);
    } else {
      console.log("socket is not available");
    }
  }, [socket?.connected, user]);

  useEffect(() => {
    if (
      dataConversations?.status === "success" &&
      dataConversations?.data?.conversations?.length > 0
    ) {
      // Update redux conversations
      const latestConversations = dataConversations?.data?.conversations;
      latestConversations?.map((conversation) => {
        const existing_conversation = conversations
          ? conversations.find((item) => item?._id === conversation._id)
          : null;
        if (existing_conversation) {
          // update direct conversation

          dispatch(
            updateDirectConversation({
              userId: user?._id,
              conversation: conversation,
              updateMessage: true,
              span: config["defaultMessageDividerSpan"]
                ? parseFloat(config["defaultMessageDividerSpan"])
                : undefined,
            })
          );
        } else {
          // add direct conversation
          dispatch(
            addDirectConversation({
              userId: user?._id,
              conversation: conversation,
              updateMessage: true,
            })
          );
        }
      });
    }
  }, [dataConversations, dispatch]);

  useEffect(() => {
    if (socket) {
      socket.on("new_friend_request", (data) => {
        // console.log("new_friend_request", data);
        const request = {
          sender: {
            ...data?.data?.sender,
            avatar: avatarFunc({ image: data?.data?.sender?.avatar }),
          },
          recipient: {
            ...data?.data?.recipient,
            avatar: avatarFunc({ image: data?.data?.recipient?.avatar }),
          },
          id: data?.data?.id,
        };
        dispatch(
          addRequest({
            pendingRequest: request,
          })
        );
        // setContactRequest(data?.data)
        setAlertObj({
          severity: theme.palette.amber['900'],
          content: data?.message[locale],
          duration: null,
        });
        setShowAlert(true);
      });

      socket.on("request_accepted", (data) => {
        console.log("request_accepted data", data);
        if (user?._id === data?.data?.sender?.id) {
          const friendIndex = user?.friends?.findIndex(
            (item) => item._id === data?.data?.recipient?.id
          );
          if (friendIndex < 0) {
            dispatch(
              setUser({
                data: {
                  ...user,
                  friends: [
                    ...user.friends,
                    {
                      ...data?.data?.recipient,
                      _id: data?.data?.recipient?.id,
                    },
                  ],
                },
              })
            );
          }
        } else if (user?._id === data?.data?.recipient?.id) {
          const friendIndex = user?.friends?.findIndex(
            (item) => item._id === data?.data?.sender?.id
          );
          if (friendIndex < 0) {
            dispatch(
              setUser({
                data: {
                  ...user,
                  friends: [
                    ...user.friends,
                    { ...data?.data?.sender, _id: data?.data?.sender?.id },
                  ],
                },
              })
            );
          }
          dispatch(
            removeRequest({
              id: data?.data?.id,
            })
          );
        }

        setAlertObj({
          severity: SEVERITY_WARNING,
          content: data?.message[locale],
          duration: null,
        });
        setShowAlert(true);
      });

      socket.on("request_sent", (data) => {
        setAlertObj({
          severity: SEVERITY_SUCCESS,
          content: data?.message[locale],
          duration: null,
        });
        setShowAlert(true);
      });

      socket.on("friend_deleted_1", (data) => {
        console.log("friend_deleted_1 data", data?.data?.id);
        console.log("friend_deleted_1 user", user?.friends);
        const friendIdx = user?.friends?.findIndex(
          (item) => item._id === data?.data?.id
        );
        console.log("friend_deleted_1 friendIdx", friendIdx);
        if (friendIdx >= 0) {
          dispatch(
            setUser({
              data: {
                ...user,
                friends: [
                  ...user?.friends.slice(0, friendIdx),
                  ...user?.friends.slice(friendIdx + 1),
                ],
              },
            })
          );
        }

        setAlertObj({
          severity: SEVERITY_WARNING,
          content: data?.message[locale],
          duration: null,
        });
        setShowAlert(true);
      });

      socket.on("friend_deleted_2", (data) => {
        console.log("friend_deleted_2 data", data?.data?.id);
        console.log("friend_deleted_2 user", user?.friends);
        const friendIdx = user?.friends?.findIndex(
          (item) => item._id === data?.data?.id
        );
        console.log("friend_deleted_2 friendIdx", friendIdx);
        if (friendIdx >= 0) {
          dispatch(
            setUser({
              data: {
                ...user,
                friends: [
                  ...user?.friends.slice(0, friendIdx),
                  ...user?.friends.slice(friendIdx + 1),
                ],
              },
            })
          );
        }
      });

      socket.on("request_rejected_1", (data) => {
        console.log("request_rejected_1 data", data);
        setAlertObj({
          severity: SEVERITY_WARNING,
          content: data?.message[locale],
          duration: null,
        });
        setShowAlert(true);
      });

      socket.on("request_rejected_2", (data) => {
        console.log("request_rejected_2 data", data?.data?.id);
        // console.log('request_rejected_2 request', pendingRequests)
        dispatch(
          removeRequest({
            id: data?.data?.id,
          })
        );
        setAlertObj({
          severity: SEVERITY_WARNING,
          content: data?.message[locale],
          duration: null,
        });
        setShowAlert(true);
      });

      socket.on("start_chat", (data) => {
        console.log("start_chat", data);

        // add / update to conversation list
        const existing_conversation = conversations
          ? conversations.find((item) => item?._id === data._id)
          : null;

        if (existing_conversation) {
          // update direct conversation
          dispatch(
            updateDirectConversation({
              userId: user?._id,
              conversation: data,
              span: config["defaultMessageDividerSpan"]
                ? parseFloat(config["defaultMessageDividerSpan"])
                : undefined,
            })
          );
        } else {
          // add direct conversation
          dispatch(
            addDirectConversation({
              userId: user?._id,
              conversation: data,
            })
          );
        }
        dispatch(selectConversation({ room_id: data._id }));
      });

      socket.on("new_message", (data) => {
        const message = data.message;
        const conversation_id = data.conversation_id;
        
        const unread =
          message.to === user?._id &&
          (pathname?.indexOf("chatroom") < 0 ||
            current_conversation?._id !== conversation_id);
        console.log("new_message", data, message.to === user?._id, pathname, current_conversation?._id, conversation_id);
        // check if msg we got is from currently selected conversation
        const msgData = {
          _id: message._id,
          type: message.type,
          subtype: message.subtype,
          message: message.text,
          reply: message.reply,
          file: message.file,
          incoming: message.to === user?._id,
          outgoing: message.from === user?._id,
          created_at: message.created_at,
        };
        console.log("msgData", msgData);
        dispatch(
          addDirectMessage({
            userId: user?._id,
            message: msgData,
            unread: unread,
            span: config["defaultMessageDividerSpan"]
              ? parseFloat(config["defaultMessageDividerSpan"])
              : undefined,
            conversationId: conversation_id,
          })
        );
      });

      socket.on("messages_cleared", (data) => {
        console.log("messages_cleared", data);
        const clearMessagesParam = {
          userId: user?._id,
          conversationId: data.data.id,
          messageStart: data.data.messageStart,
        };
        console.log("clearMessagesParam", clearMessagesParam);
        dispatch(clearMessages(clearMessagesParam));
        setAlertObj({
          severity: SEVERITY_SUCCESS,
          content: data?.message[locale],
          duration: null,
        });
        setShowAlert(true);
      });

      socket.on("conversation_deleted", (data) => {
        // console.log("conversation_deleted", data, directChats);
        const nextAvailableConversation = conversations?.find(
          (item) => item?._id !== data.data.id && item.active === true
        );
        // console.log('*** nextAvailableConversation', conversations, nextAvailableConversation)
        if (nextAvailableConversation) {
          dispatch(
            setConversationViewed({
              conversationId: nextAvailableConversation._id,
            })
          );
        }

        const deleteConversationParam = {
          userId: user?._id,
          conversationId: data.data.id,
          messageStart: data.data.messageStart,
        };
        console.log("deleteConversationParam", deleteConversationParam);
        dispatch(deleteConversation(deleteConversationParam));

        setAlertObj({
          severity: SEVERITY_SUCCESS,
          content: data?.message[locale],
          duration: null,
        });
        setShowAlert(true);
      });

      socket.on("conversation_pinned", (data) => {
        console.log("conversation_pinned", data);
        const pinConversationParam = {
          userId: user?._id,
          conversationId: data.data.id,
        };
        console.log("pinConversationParam", pinConversationParam);
        dispatch(pinConversation(pinConversationParam));
        setAlertObj({
          severity: SEVERITY_SUCCESS,
          content: data?.message[locale],
          duration: null,
        });
        setShowAlert(true);
      });

      socket.on("conversation_unpinned", (data) => {
        console.log("conversation_unpinned", data);
        const unpinConversationParam = {
          userId: user?._id,
          conversationId: data.data.id,
        };
        console.log("unpinConversationParam", unpinConversationParam);
        dispatch(unpinConversation(unpinConversationParam));
        setAlertObj({
          severity: SEVERITY_SUCCESS,
          content: data?.message[locale],
          duration: null,
        });
        setShowAlert(true);
      });

      socket.on("status_change", (data) => {
        const conversation = conversations?.find(
          (item) => item.userId === data.userId
        );
        // console.log('conversation', conversation)
        if (conversation) {
          const params = {
            conversationId: conversation._id,
            userId: user?._id,
            status: data.status === "Online",
          };
          // console.log('status_change params', params)
          dispatch(updateConversationStatus(params));
        }
      });
    }

    return () => {
      if (socket) {
        socket?.off("new_friend_request");
        socket?.off("request_accepted");
        socket?.off("request_sent");
        socket?.off("friend_deleted_1");
        socket?.off("friend_deleted_2");
        socket?.off("request_rejected_1");
        socket?.off("request_rejected_2");
        socket?.off("start_chat");
        socket?.off("new_message");
        socket?.off("messages_cleared");
        socket?.off("conversation_deleted");
        socket?.off("conversation_pinned");
        socket?.off("conversation_unpinned");
        socket?.off("status_change");
      }
    };
  }, [socket, conversations, user, pathname, avatarFunc]);
  // useEffect(() => {
  //   if (contactRequest) {
  //     showContactRequestDialog();
  //   }
  // }, [contactRequest]);

  useEffect(() => {
    if (dataNotifications?.data?.notifications?.length > 0) {
      let mappedNotifications = [
        ...dataNotifications?.data?.notifications.map((notification) => {
          return {
            ...notification,
            title: JSON.parse(notification.title),
            details: JSON.parse(notification.details),
          };
        }),
      ];

      // console.log('mappedNotifications', mappedNotifications)
      dispatch(setNotifications(mappedNotifications));
      // dispatch(
      //   setLastNotification(
      //     // new Date(mappedNotifications[0].createdAt).getTime()
      //     0
      //   )
      // );
    }
  }, [dataNotifications?.data?.notifications, dispatch]);

  useEffect(() => {
    if (
      dataPendingContacts?.status === "success" &&
      dataPendingContacts?.data?.requests?.length > 0
    ) {
      const requestList = [];
      dataPendingContacts?.data?.requests?.map((request) => {
        requestList.push({
          sender: {
            id: request?.sender?._id,
            firstName: request?.sender?.firstName,
            lastName: request?.sender?.lastName,
            email: request?.sender?.email,
            avatar: avatarFunc({ image: request?.sender?.avatar }),
            socketStatus: request?.sender?.socket_status,
            lastMessage: request?.greeting,
            lastMessageTime: moment(request?.createdAt).format(
              "YYYY-MM-DD HH:mm"
            ),
            unread: 0,
          },
          recipient: {
            id: request?.recipient?._id,
            firstName: request?.recipient?.firstName,
            lastName: request?.recipient?.lastName,
            email: request?.recipient?.email,
            avatar: avatarFunc({ image: request?.recipient?.avatar }),
            socketStatus: request?.recipient?.socket_status,
            lastMessage: request?.greeting,
            lastMessageTime: moment(request?.createdAt).format(
              "YYYY-MM-DD HH:mm"
            ),
            unread: 0,
          },
          id: request?._id,
        });
      });

      console.log("**** requestList", requestList);
      dispatch(
        setRequests({
          pendingRequests: requestList,
        })
      );
    }
  }, [dataPendingContacts, avatarFunc]);

  useEffect(() => {
    document.title = "BIRTOO";
    // dispatch(removeCart('651c60a62379dc4f5a747147'))
    // console.log('carts', carts)
    // dispatch(setCarts(null))
    // dispatch(setUser(null))
    // dispatch(setUserToken(null))
    // dispatch(setUnpaidOrder(null))
  }, []);

  // useEffect(() => {
  //   if (buildings?.length > 0 && user?.buildings?.length > 0) {
  //     if (!activeBuilding || !activeBuilding?.building) {
  //       const active = {
  //         ...user?.buildings[0],
  //         building: buildings.find(
  //           (item) => item._id === user?.buildings[0].building._id
  //         ),
  //         userId: user?._id,
  //       };
  //       dispatch(setActiveBuilding(active));
  //     } else if (activeBuilding?.userId !== user?._id) {
  //       dispatch(setActiveBuilding(null));
  //     } else {
  //       const activeUserBuilding = user?.buildings?.filter(
  //         (item) => item?.building?._id === activeBuilding?.building?._id
  //       )[0];
  //       const active = {
  //         ...activeUserBuilding,
  //         userId: user?._id,
  //       };
  //       dispatch(setActiveBuilding(active));
  //     }
  //   }
  // }, [user, buildings, dispatch]);

  // useEffect(() => {
  //   if (dataUsersCount?.total) {
  //     dispatch(setUsersCount(Number(dataUsersCount?.total)));
  //   }
  // }, [dataUsersCount, dispatch]);

  const notification = useMemo(() => {
    return notifications?.length > 0 ? notifications[0] : null;
  }, [notifications]);

  useEffect(() => {
    if (
      notifications?.length > 0 &&
      pathname === "/" &&
      popped === false &&
      new Date(notifications[0].due) > new Date()
    ) {
      setPopped(true);
      showNotificationDialog();
    }
  }, [notifications]);

  useEffect(() => {
    setShowBackdropCallback(isLoading || isLoadingExtras);
  }, [isLoading, isLoadingExtras]);

  const [ping, { isLoading: isUpdating }] = usePingMutation();

  const loggedIn = useMemo(() => {
    return (
      user && userToken?.token
      // &&
      // (user?.firstName !== process.env.REACT_APP_GUEST_PREFIX ||
      //   user?.lastName !== process.env.REACT_APP_GUEST_PREFIX)
    );
  }, [user, userToken]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const unreadNotifications = useMemo(() => {
    if (notifications?.length > 0) {
      console.log(
        "lastNotification",
        moment(new Date(lastNotification)).format("YYYY-MM-DD HH:mm")
      );
      const unread = notifications.filter(
        (item) => new Date(item.createdAt).getTime() > lastNotification
      );
      return unread?.length;
    }

    return 0;
  }, [notifications, lastNotification]);

  const unreadMessages = useMemo(() => {
    if (conversations?.length > 0) {
      let total = 0;
      conversations?.forEach((item) => {
        total += item.unread;
      });
      return total;
    } else {
      return 0;
    }
  }, [conversations]);

  const pendingRequests = useSelector(
    (state) => state.pendingRequest.pendingRequests
  );

  return (
    <>
      <Box width="100%" height="auto">
        {!tablet && !mobile ? (
          <TopbarDesktop
            user={user}
            userToken={userToken}
            unreadMessages={unreadMessages + pendingRequests?.length}
            unreadNotifications={unreadNotifications}
          />
        ) : (
          <TopbarMobile
            user={user}
            tablet={tablet}
            mobile={mobile}
            unreadMessages={unreadMessages + pendingRequests?.length}
            unreadNotifications={unreadNotifications}
            showLanguageSelectDialog={showLanguageSelectDialog}
          />
        )}
        <LanguageSelectDialog />
        <NotificationDialog notification={notification} />
      </Box>
      {/* {isWeixin && isAndroid && (
        <WeixinTipContainer>
          <WeixinTipTriangle>
            <span></span>
          </WeixinTipTriangle>
          <WeixinTip>
            <WeixinTipTitle>请点击右上角</WeixinTipTitle>
            <WeixinTipContent>选择"浏览器中打开"</WeixinTipContent>
          </WeixinTip>
        </WeixinTipContainer>
      )} */}
    </>
  );
};

export default Topbar;
