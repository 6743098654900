import React from "react";
import {
  Stack,
  Box,
  Menu,
  MenuItem,
} from "@mui/material";
import { Message_options } from "../../constants";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { setConversationReply } from "../../redux/chatroom";
import { deleteDirectMessage } from "../../redux/conversation";
import { MoreVert } from "@mui/icons-material";

const MessageOption = ({ incoming, message, conversation, user }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (idx) => {
      setAnchorEl(null);
      if (idx === 0) {
        // Reply message
        dispatch(
          setConversationReply({
            conversationId: conversation._id,
            message: message,
          })
        );
      } else if (idx === 1) {
        // Delete message
        dispatch(
          deleteDirectMessage({
            userId: user?._id,
            conversationId: conversation._id,
            messageId: message._id,
          })
        );
      }
    };
    return (
      <Box py={1}>
        <MoreVert
          fontSize="medium"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <Stack spacing={1} px={1}>
            {Message_options.map((el, idx) => {
              if (el.incoming === message.incoming) {
                return (
                  <MenuItem onClick={() => handleClose(idx)} key={idx}>
                    <FormattedMessage id={el.title} />
                  </MenuItem>
                );
              }
            })}
          </Stack>
        </Menu>
      </Box>
    );
  };

export default MessageOption