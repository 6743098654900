import { useCallback } from "react";

export default function useImage(dependencies=[]) {
    const callback = useCallback(({image}) => {
        if (image === undefined || image?.length === 0) {
            return undefined
        } else if (image?.indexOf('http') === 0) {
            return image
        } else {
            return process.env.REACT_APP_S3_BUCKET_URL + image
        }
    }, dependencies)

    return callback
}