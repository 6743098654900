import axios from "axios";

export const apiCall = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

export const makeRequest = (url, options) => {
    return new Promise((resolve, reject) => {
      apiCall(url, options).then((response) => {
        resolve(response?.data)
      }).catch((error) => {
        reject(error?.response?.data ?? "Error")
      })
    })
};
