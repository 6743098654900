import { Backdrop, CircularProgress } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useUIContext } from '../context/ui'
import { useTheme } from '@emotion/react'

export default function useBackdrop() {
    const theme = useTheme()
    const { showBackdrop, setShowBackdropCallback } = useUIContext()
    
    const openBackdrop = useCallback(() => {
        setShowBackdropCallback(true)
    }, [])

    const closeBackdrop = useCallback(() => {
        setShowBackdropCallback(false)
    }, [])

    // console.log('showBackdrop', showBackdrop)
    const BackdropComponent = useCallback(() => {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme.zIndex.drawer > theme.zIndex.modal) ? (theme.zIndex.drawer + 1) : (theme.zIndex.modal + 1) }}
                open={!!showBackdrop}
                // onClick={closeBackdrop}
                >
                <CircularProgress color="inherit" sx={{fontSize: '1.5em'}} />
            </Backdrop>
        )
    }, [showBackdrop])

    return [BackdropComponent]
}
