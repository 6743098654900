import { useEffect, useState } from "react";
import * as Yup from "yup";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Button,
  useTheme,
} from "@mui/material";
// components
import FormProvider, { RHFTextField } from "../../components/hook-form";
import { Eye, EyeSlash } from "phosphor-react";
import RHFCodes from "../../components/hook-form/RHFCodes";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useVerifyOTPMutation } from "../../apis/auth";
import { useUIContext } from "../../context/ui";
import { setOtpUser } from "../../redux/user";
import { SEVERITY_ERROR, SEVERITY_SUCCESS, URL_LOGIN } from "../../constants";
import { LOCALES } from "../../i18n/locales";
import { useNavigate } from "react-router-dom";
// import { VerifyEmail } from "../../redux/slices/auth";

// ----------------------------------------------------------------------

export default function VerifyForm({email}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [codeLength, setCodeLength] = useState(0)
  const { setShowBackdropCallback, setShowAlert, setAlertObj } = useUIContext();
  const locale = useSelector((state) => state.persisted.global.locale)?.value || LOCALES.ENGLISH;
  const [verifyOTP, { isLoading: isUpdating }] = useVerifyOTPMutation();

  useEffect(() => {
    setShowBackdropCallback(isUpdating);
  }, [isUpdating]);

  //   const { email } = useSelector((state) => state.auth);
  const VerifyCodeSchema = Yup.object().shape({
    code1: Yup.string().required("Code is required"),
    code2: Yup.string().required("Code is required"),
    code3: Yup.string().required("Code is required"),
    code4: Yup.string().required("Code is required"),
    code5: Yup.string().required("Code is required"),
    code6: Yup.string().required("Code is required"),
  });

  const defaultValues = {
    code1: "",
    code2: "",
    code3: "",
    code4: "",
    code5: "",
    code6: "",
  };

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(VerifyCodeSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    formState: { isSubmitting, errors },
  } = methods;

  let value1 = watch('code1')
  let value2 = watch('code2')
  let value3 = watch('code3')
  let value4 = watch('code4')
  let value5 = watch('code5')
  let value6 = watch('code6')

  useEffect(() => {
    const otp = `${value1}${value2}${value3}${value4}${value5}${value6}`
    setCodeLength(otp?.length)
  }, [value1,value2,value3,value4,value5,value6])

  const onSubmit = async (data) => {
    try {
      //   Send API Request
      //   dispatch(
      //     VerifyEmail({
      //       email,
      //       otp: `${data.code1}${data.code2}${data.code3}${data.code4}${data.code5}${data.code6}`,
      //     })
      //   );
      console.log("data", data);
      const otp = `${data.code1}${data.code2}${data.code3}${data.code4}${data.code5}${data.code6}`
      const submitObj = {
        email: email,
        otp: otp
      }

      const result = await verifyOTP(submitObj)
        if (result?.error) {
          // const errorInfo = result.error?.data
          setShowAlert(true)
          setAlertObj({
            severity: SEVERITY_ERROR,
            content: result?.error?.data?.message[locale],
            // content: errorInfo[locale],
            duration: null,
          })
        } else {
          setShowAlert(true)
          setAlertObj({
            severity: SEVERITY_SUCCESS,
            content: result?.data?.message[locale],
            duration: null,
          })
          console.log('result', result)
          dispatch(setOtpUser(null))
          navigate(URL_LOGIN)
        }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFCodes
          keyName="code"
          inputs={["code1", "code2", "code3", "code4", "code5", "code6"]}
        />

        <Button
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={email?.length === 0 || codeLength < 6}
          sx={{
            bgcolor: theme.palette.primary.main,
            color: (theme) =>
              theme.palette.mode === "light" ? "common.white" : "grey.800",
            "&:hover": {
              bgcolor: theme.palette.primary.main,
              color: (theme) =>
                theme.palette.mode === "light" ? "common.white" : "grey.800",
            },
          }}
        >
          <FormattedMessage id="general.verify" />
        </Button>
      </Stack>
    </FormProvider>
  );
}
