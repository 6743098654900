import React, { useEffect, useState } from "react";
import { REGEX_JSON } from "../../constants";
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { ImageCovered } from "../../components/imageCover/ImageCover";
import { LOCALES } from "../../i18n/locales";
import { ChatBubble, Email } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import useImage from "../../hooks/useImage";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  ...theme.typography.h4,
  color: theme.palette.text.primary,
  "& > :not(style) ~ :not(style)": {
    marginTop: theme.spacing(2),
  },
}));

const cssAppliedContent = (locale, body) => {
  return locale === LOCALES.ENGLISH
    ? `
      <div class="schoolApplication" style="width: 100%;">
          <style>
                div.schoolApplication h4 {
                    font-family: Roboto, arial, sans-serif;
                    font-size: 20px;
                    line-height: 40px;
                    margin-top: 16px;
                }
              div.schoolApplication p, div.schoolApplication li {
                  font-family: Roboto, arial, sans-serif;
                  font-size: 18px;
                  line-height: 30px;
              } 
              div.schoolApplication ul {
                padding-left: 20px;
              }
          </style>
        ${body}
      <div>`
    : `
      <div class="schoolApplication" style="width: 100%;">
          <style>
            div.schoolApplication h4 {
                font-family: Montserrat, arial, sans-serif;
                font-size: 20px;
                line-height: 40px;
                margin-top: 16px;
            }
              div.schoolApplication p, div.schoolApplication li {
                  font-family: Montserrat, arial, sans-serif;
                  font-size: 18px;
                  line-height: 30px;
              } 

              div.schoolApplication ul {
                padding-left: 20px;
              }
          </style>
        ${body}
      <div>`;
};

const Procedure = ({ data, mobile, locale }) => {
  const theme = useTheme();
  const [schoolApplication, setSchoolApplication] = useState(null);
  const imageFunc = useImage()
  useEffect(() => {
    if (data && data?.match(REGEX_JSON)) {
      const jsonData = JSON.parse(data.replaceAll("&lt;", "<"));
      setSchoolApplication(jsonData);
    }
  }, [data]);

  return (
    <Stack
      width={mobile ? "100%" : "80%"}
      justifyContent={"start"}
      alignItems={"center"}
      spacing={3}
      py={5}
    >
      <Stack
        width="80%"
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Root>
          <Divider sx={{ color: theme.palette.grey["600"] }}>
            {schoolApplication?.title[locale]}
          </Divider>
        </Root>
      </Stack>
      <Stack
        width={'100%'}
        direction={'column'}
        justifyContent={"start"}
        alignItems={"center"}
        spacing={1}
      >
        <Stack justifyContent={"start"} alignItems={"center"} width={'100%'}>
          <ImageCovered
            src={imageFunc({image: schoolApplication?.image})}
            sx={{ width: "auto", height: "210px" }}
            onClick={() => {}}
          />
        </Stack>
        <Box
          width={mobile ? '100%' : '70%'}
          px={3}
          py={2}
          dangerouslySetInnerHTML={{
            __html: cssAppliedContent(
              locale,
              schoolApplication?.details[locale]
            ),
          }}
        ></Box>
      </Stack>
      <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={3}
          mb={5}
        >
          <Button
            variant="contained"
            href={`mailto: ${process.env.REACT_APP_EMAIL_SUPPORT}`}
            sx={{ width: "150px", py: 1, fontSize: "16px", fontWeight: 500, borderRadius: 0, }}
            startIcon={<Email fontSize="medium" />}
          >
            <FormattedMessage id='home.email.us' />
          </Button>
          <Button
            variant="contained"
            sx={{ width: "150px", py: 1, fontSize: "16px", fontWeight: 500, borderRadius: 0,  }}
            startIcon={<ChatBubble fontSize="medium" />}
          >
            <FormattedMessage id='home.message.us' />
          </Button>
        </Stack>
    </Stack>
  );
};

export default Procedure;
