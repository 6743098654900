import { Close, FilePresent } from "@mui/icons-material";
import { useTheme, Stack, Typography, IconButton } from "@mui/material";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { setConversationReply } from "../../redux/chatroom";
import { ImageCovered } from "../../components/imageCover/ImageCover";
import useImage from "../../hooks/useImage";
import truncateString from "../../utils/truncate";

const ReplySection = ({ conversation, message }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fileName = useMemo(() => {
    console.log('*** message', message)
    return message.file?.length > 0 ? message.file.split("/").pop() : '';
  }, [message.file]);
  const imageFunc = useImage()
  // console.log('message', message)
  return (
    <Stack
      width="100%"
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      px={2}
      py={1}
      bgcolor={theme.palette.amber["100"]}
      borderRadius={"10px 10px 0 0"}
    >
      <Stack
        direction={"row"}
        justifyContent={"start"}
        alignItems={"center"}
        spacing={2}
      >
        <Typography variant="body">{conversation?.firstName}:</Typography>
        {message.type === "Reply" && (
          <Typography variant="body">{truncateString(message.reply, 30)}</Typography>
        )}
        {message.type !== "Reply" && (message.subtype === "Text" || message.subtype === 'Link') && (
          <Typography variant="body">{truncateString(message.message, 30)}</Typography>
        )}
        {message.type !== "Reply" && message.subtype === "Media" && (
          <ImageCovered
            src={imageFunc({image: message.file})}
            alt={message.message}
            style={{ maxHeight: 80 }}
          />
        )}
        {message.type !== "Reply" && message.subtype === "Doc" && (
          <Stack
            direction={"row"}
            justifyContent={"start"}
            alignItems={"center"}
            spacing={0.5}
            height="80px"
          >
            <FilePresent />
            <Typography variant="body">{fileName}</Typography>
          </Stack>
        )}
      </Stack>
      <IconButton
        onClick={() => {
          dispatch(
            setConversationReply({
              conversationId: null,
              message: null,
            })
          );
        }}
      >
        <Close />
      </IconButton>
    </Stack>
  );
};

export default ReplySection;
