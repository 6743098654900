import { Box, Button, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CarouselWrapper = styled(Paper)(({type, theme}) => ({
    width: '100%',
    height: '600px',
    display: 'flex',
    flexDirection: (type === 'mobile') ? 'column' : 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
}))

export const CarouselImageWrapper = styled(Box)(({type}) => ({
    height: '100%',
    flex: (type === 'mobile') ? 1 : 2,
    // flex: 1,
    // width: '60%',
}))

export const CarouselWrapperSimple = styled(Paper)(() => ({
    width: '500px',
    padding: '0px',
    position: 'relative',
}))


export const CarouselImage = styled('img')(({imgHeight}) => ({
    width: '100%',
    height: imgHeight || '100%',
    objectFit: 'cover',
}))

// Carousel Image
export const CarouselImageSimple = styled('img')(({}) => ({
    width: '100%',
    height: '600px',
    objectFit: 'cover',
}))

export const CarouselTextWrapper = styled(Box)(({type}) => ({
    flex: (type === 'mobile') ? 2 : 1,
    mt: 2,
    // flex: 1,
    // width: '40%',
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
}))

export const CarouselHeader = styled(Typography)(({theme}) => ({
    color: theme.palette.grey['700'],
    marginBottom: '15px',
}))

export const CarouselDescription = styled(Typography)(({theme}) => ({
    fontSize: '18px',
    color: theme.palette.grey['700'],
    
}))

export const CarouselButton = styled(Button)(({theme}) => ({
    color: theme.palette.grey['700'],
    border: `1px solid ${theme.palette.grey['700']}`,
    borderRadius: '5px',
    width: '150px',
    padding: "5px 5px",
    marginTop: '20px',
    fontSize: '16px',
    cursor: 'pointer',
}))

