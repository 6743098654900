import React, { useMemo } from "react";
import { Box, useTheme } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IMG_FEATURE_LEFT,
  IMG_FEATURE_RIGHT,
  IMG_PROCESS_LEFT,
} from "../../constants";
import { useUIContext } from "../../context/ui";
import { ImageCovered } from "../../components/imageCover/ImageCover";

const Steps = ({ mobile, tablet, user }) => {
  const theme = useTheme();
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      mt={mobile ? 0 : 5}
      mb={5}
      width="100%"
      sx={{ textAlign: "center" }}
    >
      <Typography
        variant="h4"
        pl={10}
        pr={10}
        sx={{
          color: theme.palette.text.primary,
          borderBottom: `3px solid ${theme.palette.grey["700"]}`,
        }}
      >
        <FormattedMessage id="home.service.process" />
      </Typography>
      <Stack
        width="100%"
        direction={mobile ? "column" : "row"}
        justifyContent={"center"}
        alignItems={"center"}
        spacing={5}
        py={3}
      >
        <Stack
          width={mobile ? "100%" : "40%"}
          direction={"row"}
          justifyContent={mobile ? "center" : "end"}
        >
          <ImageCovered
            src={IMG_PROCESS_LEFT}
            sx={{
              width: "320px",
              height: "auto",
            }}
          />
        </Stack>
        <Stack
          flexGrow={1}
          direction={"row"}
          justifyContent={"start"}
          alignItems={"center"}
          spacing={2}
        >
          <Stack
            width="100%"
            maxWidth={"500px"}
            justifyContent={"start"}
            alignItems={"center"}
            spacing={3}
            p={3}
          >
            <Stack
              direction={"row"}
              width="100%"
              justifyContent={"start"}
              alignContent={"center"}
            >
              <Typography variant="h6" sx={{textAlign: 'left', fontWeight: 400}}>
                <FormattedMessage id={"home.process.step1"} />
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              width="100%"
              justifyContent={"start"}
              alignContent={"center"}
            >
              <Typography variant="h6" sx={{textAlign: 'left', fontWeight: 400}}>
                <FormattedMessage id={"home.process.step2"} />
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              width="100%"
              justifyContent={"start"}
              alignContent={"center"}
            >
              <Typography variant="h6" sx={{textAlign: 'left', fontWeight: 400}}>
                <FormattedMessage id={"home.process.step3"} />
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              width="100%"
              justifyContent={"start"}
              alignContent={"center"}
            >
              <Typography variant="h6" sx={{textAlign: 'left', fontWeight: 400}}>
                <FormattedMessage id={"home.process.step4"} />
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Steps;
