import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { LOCALES } from "../../i18n/locales";
import { ImageCovered } from "../commercialCart/CartItem";
import { setLastNotification } from "../../redux/global";
import useImage from "../../hooks/useImage";

const Notifications = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useSelector((state) => state.user.currentUser)?.data;
  const notifications = useSelector(
    (state) => state.notification.notifications
  );
  const locale =
    useSelector((state) => state.persisted.global.locale)?.value ||
    LOCALES.ENGLISH;
  const imageFunc = useImage()
  
  useEffect(() => {
    if (notifications?.length > 0) {
      dispatch(
        setLastNotification(new Date(notifications[0].createdAt).getTime())
      );
    }
  }, [notifications]);

  return (
    <Stack
      width="100%"
      justifyContent="center"
      alignItems={"center"}
      //   my={5}
      p={3}
    >
      <Box
        mb={3}
        width={mobile ? "100%" : "60%"}
        display="flex"
        justifyContent="center"
        alignItems={"center"}
        sx={{ borderBottom: `1px solid ${theme.palette.grey["200"]}` }}
      >
        <Typography
          variant="h5"
          color={theme.palette.primary.main}
          fontWeight="bold"
        >
          <FormattedMessage id="general.notifications" />
        </Typography>
      </Box>
      <Stack
        width={mobile ? "100%" : "60%"}
        display="flex"
        justifyContent="center"
        alignItems={"center"}
      >
        {notifications?.map((notification) => (
          <Accordion
            key={notification?._id}
            sx={{
              width: "100%",
              mb: 3,
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.20), 0 10px 20px 0 rgba(0, 0, 0, 0.15)",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ mb: 2 }}
            >
              <Stack width="100%">
                <Stack direction="row" space={2}>
                  <Typography
                    variant="h6"
                    sx={{ color: theme.palette.primary.main, mb: 2 }}
                  >
                    {notification?.title[locale]}
                  </Typography>
                  <Typography>
                    <CheckIcon
                      sx={{
                        color: theme.palette.error.main,
                        fontWeight: "bold",
                      }}
                    />
                  </Typography>
                </Stack>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.text.primary }}
                >
                  {moment(notification?.createdAt).format("YYYY-MM-DD HH:mm")}
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Stack
                width="100%"
                justifyContent={"start"}
                alignItems={mobile ? "center" : "start"}
                spacing={2}
              >
                {notification?.image?.length > 0 && (
                  <ImageCovered
                    src={
                      imageFunc({image: notification?.image})
                    }
                    width="400px"
                    height="auto"
                  />
                )}
                <Typography
                  variant="body2"
                  width={"100%"}
                  sx={{ textAlign: "left" }}
                >
                  {notification.details[locale]}
                </Typography>
              </Stack>
            </AccordionDetails>
          </Accordion>
        ))}
        {notifications?.length === 0 && (
          <Typography variant="h5">
            <FormattedMessage id="general.not.available" />
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default Notifications;
