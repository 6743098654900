import { api } from './api'

const conversationApi = api.injectEndpoints({
  endpoints: (build) => ({
      getConversations: build.query({
          query: ({ userId, token }) => ({
            url: `api/conversations/${userId}`,
            method: "GET",
            headers: { Authorization: `Bearer ${token}` },
          }),
          providesTags: (result) =>
            result?.Conversations
            ? [
                ...result.Conversations.map(({ _id }) => ({ type: 'Conversations', id: _id })),
                { type: 'Conversations', id: 'LIST' },
              ]
            : [{ type: 'Conversations', id: 'LIST' }],
      }),
    }),
    overrideExisting: false,
})
    
export const { useGetConversationsQuery } = conversationApi