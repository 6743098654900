import { api } from './api'

const vendorApi = api.injectEndpoints({
  endpoints: (build) => ({
      getVendors: build.query({
          query: ({ page, pageSize, sort, tag, search, token }) => ({
            url: "api/vendors",
            method: "GET",
            params: { page, pageSize, sort, tag, search },
            headers: { Authorization: `Bearer ${token}` },
          }),
          providesTags: (result) => result?.vendors
          ? [
              ...result.vendors.map(({ _id }) => ({ type: 'Vendors', id: _id })),
              { type: 'Vendors', id: 'LIST' },
            ]
          : [{ type: 'Vendors', id: 'LIST' }],
          
      }),
      getVendorCategories: build.query({
        query: ({ page, pageSize, sort, search, token }) => ({
          url: "api/vendorCategories",
          method: "GET",
          params: { page, pageSize, sort, search },
          headers: { Authorization: `Bearer ${token}` },
        }),
        providesTags: (result) =>
          result?.categories
          ? [
              ...result.categories.map(({ _id }) => ({ type: 'VendorCategories', id: _id })),
              { type: 'VendorCategories', id: 'LIST' },
            ]
          : [{ type: 'VendorCategories', id: 'LIST' }],
      }),
    }),
    overrideExisting: false,
})

export const { useGetVendorsQuery, useGetVendorCategoriesQuery } = vendorApi