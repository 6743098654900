import { Box, Button, Container, IconButton, InputAdornment, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUpdateUserPasswordMutation } from '../../apis/auth';
import { REGEX_PASSWORD, REGEX_PHONE, URL_LOGIN } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { setDeliveryAddress, setDeliveryPhone, setUserToken } from "../../redux/global";
import { useUIContext } from '../../context/ui';
import { setUser } from '../../redux/user';
import { LOCALES } from '../../i18n/locales';
import { Eye, EyeSlash } from 'phosphor-react';
import { socket } from '../../socket';

const ChangePassword = () => {
  const theme = useTheme();
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const tablet = useMediaQuery(theme.breakpoints.down('md'))
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [showPasswordCurrent, setShowPasswordCurrent] = useState(false)
  const [showPasswordNew, setShowPasswordNew] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)

  const locale = useSelector((state) => {
    return state.persisted.global.locale
  } )?.value || LOCALES.ENGLISH;
  const user = useSelector(state => state.user.currentUser)?.data
  const userToken = useSelector((state) => state.persisted.global.userToken); 
  const { showBackdrop, setShowBackdropCallback } = useUIContext()

  const [
      updateUserPassword, 
      { isLoading: isUpdating }, 
    ] = useUpdateUserPasswordMutation() 

  useEffect(() => {
    setShowBackdropCallback(isUpdating)
  }, [isUpdating])

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
  }

  const checkoutSchema = yup.object().shape({
    oldPassword: yup.string().matches(REGEX_PASSWORD, "error.invalid.password"),
    newPassword: yup.string().matches(REGEX_PASSWORD, "error.invalid.password"),
    newPasswordConfirmation: yup.string()
      .oneOf([yup.ref('newPassword'), null], 'error.password.match'),
  });

  const handleFormSubmit = async (values) => {
      let submitObj = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      }
      // console.log('submitObj', submitObj)
      try {
        if (window.confirm(intl.formatMessage({id: 'window.ready.submit'})) === true) {
          const params = {
              id: user?._id,
              token: userToken?.token,
              body: submitObj,
            }
          const result = await updateUserPassword({
            id: user?._id,
            token: userToken?.token,
            body: submitObj,
          })
          
          if (result.error && result?.error?.status !== 200) {
            window.alert(result?.error?.data?.message[locale])
          } else if (result?.data?.status === 'success') {
            window.alert(result?.data?.message[locale])
            dispatch(setUser(null))
            dispatch(setUserToken(null))
            navigate(URL_LOGIN)
            if (socket) {
              socket.emit('end')
            } 
          }
        }
      } catch (err) {
          window.alert(intl.formatMessage({id: 'window.operation.failed'}))
      }
    }

  return (
          <Stack width='100%' justifyContent='center' alignItems={mobile ? 'center' : 'flex-start'} p={3}>
                <Box mb={3}>
                    <Typography variant='h5'
                        color={theme.palette.primary.main}
                        fontWeight="bold">
                            <FormattedMessage id='auth.change.password' />
                    </Typography>
                </Box>
                <Box width={mobile ? '100%' : (tablet ? '60%' : '40%')}>
                    <Formik
                        onSubmit={handleFormSubmit}
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={checkoutSchema}
                        style={{border: '1px solid red'}}
                    >
                        {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        }) => (
                        <form onSubmit={handleSubmit}>
                            <Stack alignItems='flex-start' width='100%'>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    // type="password"
                                    label={intl.formatMessage({id: 'auth.old.password'})}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.firstName}
                                    name="oldPassword"
                                    error={!!touched.oldPassword && !!errors.oldPassword}
                                    helperText={touched.oldPassword && errors.oldPassword && intl.formatMessage({id: errors.oldPassword}) }
                                    sx={{mb: 3}}
                                    type={showPasswordCurrent ? "text" : "password"}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            onClick={() => setShowPasswordCurrent(!showPasswordCurrent)}
                                            edge="end"
                                          >
                                            {showPasswordCurrent ? <Eye /> : <EyeSlash />}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    // type="password"
                                    label={intl.formatMessage({id: 'auth.new.password'})}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.newPassword}
                                    name="newPassword"
                                    error={!!touched.newPassword && !!errors.newPassword}
                                    helperText={touched.newPassword && errors.newPassword && intl.formatMessage({id: errors.newPassword})}
                                    sx={{mb: 3}}
                                    type={showPasswordNew ? "text" : "password"}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            onClick={() => setShowPasswordNew(!showPasswordNew)}
                                            edge="end"
                                          >
                                            {showPasswordNew ? <Eye /> : <EyeSlash />}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label={intl.formatMessage({id: 'auth.password.confirmation'})}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.newPasswordConfirmation}
                                    name="newPasswordConfirmation"
                                    error={!!touched.newPasswordConfirmation && !!errors.newPasswordConfirmation}
                                    helperText={touched.newPasswordConfirmation && errors.newPasswordConfirmation && intl.formatMessage({id: errors.newPasswordConfirmation})}
                                    sx={{mb: 3}}
                                    type={showPasswordConfirm ? "text" : "password"}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                                            edge="end"
                                          >
                                            {showPasswordConfirm ? <Eye /> : <EyeSlash />}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                />
                                <Button
                                    disabled={
                                        !!values.oldPassword === false || 
                                        !!values.newPassword === false || 
                                        !!values.newPasswordConfirmation === false || 
                                        !!errors.oldPassword || 
                                        !!errors.newPassword || 
                                        !!errors.newPasswordConfirmation || 
                                        isUpdating }
                                    sx={{
                                        width: '100%',
                                        backgroundColor: theme.palette.primary.main,
                                        color: theme.palette.common.white,
                                        // color: theme.palette.background.alt,
                                        pt: 1.5,
                                        pb: 1.5,
                                        '&:hover': {
                                            backgroundColor: theme.palette.teal['600'],
                                        },
                                        "&:disabled": {
                                            backgroundColor: theme.palette.grey[300],
                                            color: theme.palette.grey[800],
                                            cursor: 'not-allowed',
                                            pointerEvents: 'none',
                                        }
                                }} type="submit"><FormattedMessage id='general.update' /></Button>
                            </Stack>
                        </form>
                        )}
                    </Formik>
                </Box>
    </Stack>
  )
}

export default ChangePassword