// import {
//   ThemeProvider,
// } from "@mui/material";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import {IntlProvider} from 'react-intl';
import { useEffect, useMemo, useState } from "react";
import theme from "./styles/theme";
import { UIProvider, useUIContext } from "./context/ui";
import Home from "./pages/home/Home";
import { messages } from './i18n/messages';
import { useDispatch, useSelector } from "react-redux";
import Profile from "./pages/profile/Profile";
import ChangePassword from "./pages/changePassword/ChangePassword";
import { addCart, setCart, setCarts, setLocation, setUnpaidOrder } from './redux/global';
import Layout from "./pages/Layout";
import VerifyEmail from "./pages/verifyEmail/VerifyEmail";
import Login from "./pages/login/Login";
import { EMPTY_CART, NULL_USER, URL_CHANGE_PASSWORD, URL_CHATBOX, URL_CHATROOM, URL_DOMESTIC_MAIN, URL_FORGOT_PASSWORD, URL_FRIENDS, URL_HOME, URL_IMMIGRATE, URL_LOGIN, URL_NOTIFICATIONS, URL_ORDERS, URL_POSTS, URL_REALTY_MAIN, URL_REGISTER, URL_RENTAL_MAIN, URL_RESET_PASSWORD, URL_SETTLEDOWN, URL_STUDY_BC, URL_UPDATE_PROFILE, URL_VERIFY_EMAIL, URL_VERIFY_OTP } from "./constants";
import Notifications from "./pages/notifications/Notifications";
import MyOrders from "./pages/myOrders/MyOrders";
import ThemeProvider from "./styles/theme";
import { LOCALES } from "./i18n/locales";
import Register from "./pages/register/Register";
import NewPassword from "./sections/auth/NewPassword";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import NewPasswordPage from "./pages/newPassword/NewPassword";
import MyCoupons from "./pages/myCoupons/MyCoupons";
import DomesticMain from "./pages/domesticMain/DomesticMain";
import RentalMain from "./pages/rentalMain/RentalMain";
import VerifyOTP from "./pages/verifyOTP/VerifyOTP";
import RealtyMain from "./pages/realtyMain/RealtyMain";
import ProductDetails from "./pages/productDetails/ProductDetails";
import Friends from "./pages/friends/Friends";
import ChatPage from "./pages/chatPage/ChatPage";
import StudyBc from "./pages/studyBc/StudyBc";
import ImmigrateCanada from "./pages/immigrateCanada/ImmigrateCanada";
import Settledown from "./pages/settledown/Settledown";
import ChatRoomPage from "./pages/chatRoom/ChatRoomPage";

function App() {
  const dispatch = useDispatch()
  const locale = useSelector((state) => {
    return state.persisted.global.locale
  } )?.value || LOCALES.ENGLISH;
  const [loggedIn, setLoggedIn] = useState(true)
  const user = useSelector(state => state.user.currentUser)
  const userToken = useSelector((state) => state.persisted.global.userToken); 
  const location = useSelector((state) => state.persisted.global.location)
  const carts = useSelector((state) => state.persisted.global.carts)
  
  useEffect(() => {
    setLoggedIn(user && userToken?.token)
  }, [userToken, user])

  useEffect(() => {
    if (!location) {
      dispatch(setLocation(process.env.REACT_APP_DEFAULT_MARKET))
    }

    let newCarts = carts || {}
    if (!newCarts[NULL_USER]) {
      newCarts = {...carts, [`${NULL_USER}`]: EMPTY_CART}
    }

    if (userToken?._id && !newCarts[userToken?._id]) {
      newCarts = {...carts, 
        [userToken?._id]: {
          ...newCarts[NULL_USER], 
          user: userToken?._id
        }
      }
    }

    dispatch(setCarts(newCarts))

    // dispatch(setUnpaidOrder(null))
  }, [location, carts, dispatch, userToken])
    
  // console.log('user', user?.data)
  // console.log('dataUser', dataUser)
  return (
      <ThemeProvider>
        <IntlProvider 
          messages={messages[locale]}
          locale={locale}
          defaultLocale={locale}>
            <UIProvider>
              <Router>
                <Routes>
                  <Route element={<Layout />}>
                    <Route exact path={URL_HOME} element={<Home />} />
                    {/* <Route path={URL_LOGIN} element={user ? <Navigate to='/' /> : <Login />} /> */}
                    <Route path={URL_LOGIN} element={!loggedIn ? <Login /> : <Navigate replace to={URL_UPDATE_PROFILE} />} />
                    <Route path={URL_REGISTER} element={<Register />} />
                    <Route path={URL_VERIFY_OTP} element={<VerifyOTP />} />
                    <Route path={URL_FORGOT_PASSWORD} element={<ForgotPassword />} />
                    <Route path={URL_VERIFY_EMAIL} element={<VerifyEmail />} />
                    <Route path={URL_UPDATE_PROFILE} element={loggedIn ? <Profile /> : <Navigate replace to={URL_LOGIN} />} />
                    <Route path={URL_CHANGE_PASSWORD} element={loggedIn ? <ChangePassword /> : <Navigate replace to={URL_LOGIN} />} />
                    <Route path={URL_STUDY_BC} element={<StudyBc />} />
                    <Route path={URL_IMMIGRATE} element={<ImmigrateCanada />} />
                    <Route path={URL_SETTLEDOWN} element={<Settledown />} />
                    <Route path={URL_DOMESTIC_MAIN} element={<DomesticMain />} />
                    <Route path={URL_RENTAL_MAIN} element={<RentalMain />} />
                    <Route path={URL_REALTY_MAIN} element={<RealtyMain />} />
                    <Route path={URL_FRIENDS} element={<Friends />} />
                    <Route path={URL_CHATBOX} element={<ChatPage />} />
                    <Route path={URL_CHATROOM} element={loggedIn ? <ChatRoomPage /> : <Navigate replace to={URL_LOGIN} />} />
                    {/* <Route path={URL_COMMUNITIES} element={loggedIn ? <MyCommunities /> : <Navigate to={URL_LOGIN} />} /> */}
                    {/* <Route path={URL_ADD_COMMUNITY} element={loggedIn ? <UpdateCommunity /> : <Navigate to={URL_LOGIN} />} /> */}
                    {/* <Route path={URL_UPDATE_COMMUNITY} element={loggedIn ? <UpdateCommunity /> : <Navigate to={URL_LOGIN} />} /> */}
                    {/* <Route path={URL_COUPONS} element={loggedIn ? <MyCoupons /> : <Navigate to={URL_LOGIN} />} /> */}
                    <Route path={URL_ORDERS} element={loggedIn ? <MyOrders /> : <Navigate replace to={URL_LOGIN} />} />
                    {/* <Route path={URL_POSTS} element={loggedIn ? <Posts /> : <Navigate to={URL_LOGIN} />} /> */}
                    {/* <Route path={URL_ISSUES} element={loggedIn ? <MyIssues /> : <Navigate to={URL_LOGIN} />} /> */}
                    {/* <Route path={URL_REQUESTS} element={loggedIn ? <MyRequests /> : <Navigate to={URL_LOGIN} />} /> */}
                    {/* <Route path={URL_ACTIVITIES} element={loggedIn ? <MyActivities /> : <Navigate to={URL_LOGIN} />} /> */}
                    {/* <Route path={URL_MESSAGES} element={loggedIn ? <MyMessages /> : <Navigate to={URL_LOGIN} />} /> */}
                    <Route path={URL_VERIFY_EMAIL} element={<VerifyEmail />} />
                    <Route path={URL_RESET_PASSWORD} element={<NewPasswordPage />} />
                    {/* <Route path={URL_FARMS} element={<CommercialCommercials farms={true} />} /> */}
                    {/* <Route path={URL_FARMS_PRODUCTS} element={<CommercialProducts />} /> */}
                    {/* <Route path={URL_FARMS_DELIVERIES} element={<CommercialDeliveries farms={true} />} /> */}
                    {/* <Route path={URL_FARMS_VENDORS} element={<CommercialVendor farms={true} />} /> */}
                    {/* <Route path={URL_COMMERCIAL_COMMERCIALS} element={<CommercialCommercials farms={false} />} /> */}
                    {/* <Route path={URL_COMMERCIAL_DELIVERIES} element={<CommercialDeliveries />} /> */}
                    {/* <Route path={URL_COMMERCIAL_VENDORS} element={<CommercialVendor />} /> */}
                    {/* <Route path={URL_COMMERCIAL_PRODUCTS} element={<CommercialProducts />} /> */}
                    {/* <Route path={URL_COMMERCIAL_PRODUCT} element={<ProductDetails />} /> */}
                    {/* <Route path={URL_COMMERCIAL_CART} element={<CommercialCart />} /> */}
                    {/* <Route path={URL_COMMERCIAL_CHECKOUT} element={<CommercialCheckout />} /> */}
                    {/* <Route path={URL_COMMERCIAL_RESTAURANTS} element={<CommercialRestaurants />} /> */}
                    {/* <Route path={URL_COMMERCIAL_COUPONS} element={<CommercialCoupons />} /> */}
                    {/* <Route path={URL_COMMERCIAL_NEWSLETTERS} element={<CommercialNewsletters />} /> */}
                    {/* <Route path={URL_STRATA_SUMMARY} element={loggedIn ? <StrataSummary /> : <Navigate to={URL_LOGIN} />} /> */}
                    {/* <Route path={URL_STRATA_CONTACTS} element={loggedIn ? <StrataContacts /> : <Navigate to={URL_LOGIN} />} /> */}
                    {/* <Route path={URL_STRATA_NOTIFICATIONS} element={loggedIn ? <StrataNotifications /> : <Navigate to={URL_LOGIN} />} /> */}
                    {/* <Route path={URL_STRATA_DOCUMENTS} element={loggedIn ? <StrataDocuments /> : <Navigate to={URL_LOGIN} />} /> */}
                    {/* <Route path={URL_STRATA_ISSUES} element={loggedIn ? <StrataIssues /> : <Navigate to={URL_LOGIN} />} /> */}
                    {/* <Route path={URL_COMMUNITY_ISSUES} element={loggedIn ? <CommunityIssues /> : <Navigate to={URL_LOGIN} />} /> */}
                    {/* <Route path={URL_COMMUNITY_REQUESTS} element={loggedIn ? <CommunityRequests /> : <Navigate to={URL_LOGIN} />} /> */}
                    {/* <Route path={URL_COMMUNITY_ADS} element={loggedIn ? <CommunityAds /> : <Navigate to={URL_LOGIN} />} /> */}
                    {/* <Route path={URL_COMMUNITY_ACTIVITIES} element={loggedIn ? <CommunityActivities /> : <Navigate to={URL_LOGIN} />} /> */}
                    {/* <Route path={URL_CONTACT_US} element={<ContactUs />} /> */}
                    <Route path={URL_NOTIFICATIONS} element={<Notifications />} />
                    {/* <Route path={URL_FAQS} element={<Faqs />} /> */}
                    
                  </Route>
                </Routes>
              </Router>
            </UIProvider>
        </IntlProvider>
      </ThemeProvider>
  );
}

export default App;
