import { api } from './api'

const postApi = api.injectEndpoints({
  endpoints: (build) => ({
      getPosts: build.query({
          query: ({ page, pageSize, sort, category, status, userStatus, user, building, community, search, token, inc }) => ({
            url: "api/posts",
            method: "GET",
            params: { page, pageSize, sort, category, status, userStatus, user, building, community, search },
            headers: { Authorization: `Bearer ${token}` },
          }),
          providesTags: (result) =>
            result?.posts
            ? [
                ...result.posts.map(({ _id }) => ({ type: 'Posts', id: _id })),
                { type: 'Posts', id: 'LIST' },
              ]
            : [{ type: 'Posts', id: 'LIST' }],
      }),
      getMyPosts: build.query({
        query: ({ page, pageSize, sort, category, status, user, building, community, search, token, inc }) => ({
          url: "api/myPosts",
          method: "GET",
          params: search ? { page, pageSize, sort, category, status, user, building, community, search } : { page, pageSize, sort, category, status, user, building, community, },
          headers: { Authorization: `Bearer ${token}` },
        }),
        providesTags: (result) =>
          result?.posts
          ? [
              ...result.posts.map(({ _id }) => ({ type: 'Posts', id: _id })),
              { type: 'Posts', id: 'LIST' },
            ]
          : [{ type: 'Posts', id: 'LIST' }],
    }),
      addPost: build.mutation({
        query: ({token, body}) => ({
          url: `api/addPost/`,
          method: 'POST',
          body,
          headers: { Authorization: `Bearer ${token}` },
        }),
        invalidatesTags: ['Posts'],
      }),
      updatePost: build.mutation({
        query: ({id, token, body}) => ({
          url: `api/post/${id}`,
          method: 'PUT',
          body,
          headers: { Authorization: `Bearer ${token}` },
        }),
        invalidatesTags: ['Posts'],
      }),
      addPostComment: build.mutation({
        query: ({id, token, body}) => ({
          url: `api/addPostComment/${id}`,
          method: 'PUT',
          body,
          headers: { Authorization: `Bearer ${token}` },
        }),
        // invalidatesTags: ['Posts'],
      }),
      addPostMessage: build.mutation({
        query: ({id, token, body}) => ({
          url: `api/addPostMessage/${id}`,
          method: 'PUT',
          body,
          headers: { Authorization: `Bearer ${token}` },
        }),
        // invalidatesTags: ['Posts'],
      }),
      getUnreadMessageCount: build.query({
          query: ({ user, token }) => ({
            url: "api/unreadMessageCount",
            method: "GET",
            params: { user },
            headers: { Authorization: `Bearer ${token}` },
          }),
          providesTags: ['Posts']
      }),
      updatePostMessagesRead: build.mutation({
        query: ({token, body}) => ({
          url: `api/postMessagesRead`,
          method: 'PUT',
          body,
          headers: { Authorization: `Bearer ${token}` },
        }),
        // invalidatesTags: ['Posts'],
      }),
      likePost: build.mutation({
        query: ({id, token, body}) => ({
          url: `api/likePost/${id}`,
          method: 'PUT',
          body,
          headers: { Authorization: `Bearer ${token}` },
        }),
        // invalidatesTags: ['Posts'],
      }),
      dislikePost: build.mutation({
        query: ({id, token, body}) => ({
          url: `api/dislikePost/${id}`,
          method: 'PUT',
          body,
          headers: { Authorization: `Bearer ${token}` },
        }),
        // invalidatesTags: ['Posts'],
      }),
    }),
    overrideExisting: false,
})

export const { useGetPostsQuery, useGetMyPostsQuery, useGetUnreadMessageCountQuery, useAddPostMutation, useUpdatePostMutation, useAddPostCommentMutation, useAddPostMessageMutation, useLikePostMutation, useDislikePostMutation, useUpdatePostMessagesReadMutation } = postApi