import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { ImageCovered } from "../../components/imageCover/ImageCover";
import { setConversationUploads } from "../../redux/chatroom";
import { Close, FilePresent } from "@mui/icons-material";

const UploadSection = ({ conversation, files }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  return (
    <Stack
      width="100%"
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      px={2}
      py={1}
      bgcolor={theme.palette.amber["100"]}
      borderRadius={"10px 10px 0 0"}
    >
      <Stack
        direction={"row"}
        justifyContent={"start"}
        alignItems={"center"}
        spacing={2}
        sx={{
          width: "90%",
          overflow: "auto",
        }}
      >
        {files?.map((file) => {
          if (file.type.indexOf("image") >= 0) {
            return (
              <ImageCovered src={file.url} height="80px" key={file?.url} />
            );
          } else {
            return (
              <Stack
                key={file?.url}
                direction={"row"}
                justifyContent={"start"}
                alignItems={"center"}
                spacing={1}
                height="80px"
              >
                <FilePresent />
                <Typography variant="body">{file.url}</Typography>
              </Stack>
            );
          }
        })}
      </Stack>
      <IconButton
        onClick={() => {
          dispatch(
            setConversationUploads({
              conversationId: null,
              files: [],
            })
          );
        }}
      >
        <Close />
      </IconButton>
    </Stack>
  );
};

export default UploadSection;
