import React, { useEffect, useMemo, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import Badge from "@mui/material/Badge";
import { useUIContext } from "../../context/ui";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton, Stack, useTheme, Typography } from "@mui/material";
import TopbarDrawer from "../drawer/Drawer";
import {
  LOGO_HEADER_CN,
  LOGO_HEADER_EN,
  URL_CHATROOM,
  URL_HOME,
  URL_NOTIFICATIONS,
} from "../../constants";
import { LOCALES } from "../../i18n/locales";
import { ImageCovered } from "../imageCover/ImageCover";
import { ArrowBack, Notifications } from "@mui/icons-material";
import { setShowChatroom } from "../../redux/chatroom";
import moment from "moment";

const TopbarMobile = ({
  user,
  tablet,
  mobile,
  unreadMessages,
  unreadNotifications,
  showLanguageSelectDialog,
}) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { setDrawerOpen, setShowSearchBox } = useUIContext();
  const navigate = useNavigate();

  const locale =
    useSelector((state) => state.persisted.global.locale)?.value ||
    LOCALES.ENGLISH;
  const { show_chatroom } = useSelector((state) => state.chatroom);

  const showBackContacts = useMemo(() => {
    return pathname?.indexOf(URL_CHATROOM) >= 0 && show_chatroom;
  }, [pathname, show_chatroom]);

  // const carts = useSelector((state) => state.persisted.global.carts);
  // const [cart, setCart] = useState(null);
  // useEffect(() => {
  //   if (carts) {
  //     if (!user && carts[NULL_USER]) {
  //       setCart(carts[NULL_USER]);
  //     } else if (user && carts[user?._id]) {
  //       setCart(carts[user?._id]);
  //     }
  //   }
  // }, [carts, user]);
  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          padding: "5px 10px",
          minHeight: "60px",
          // backgroundColor: theme.palette.primary.main,
          backgroundColor: "#FFF",
          zIndex: 100,
          py: 1.5,
        }}
      >
        {showBackContacts && (
          <IconButton
            sx={{ color: theme.palette.primary.main }}
            onClick={() => {
              dispatch(
                setShowChatroom({
                  showChatroom: false,
                })
              );
            }}
          >
            <ArrowBack fontSize="large" />
          </IconButton>
        )}
        {!showBackContacts && (
          <Badge badgeContent={unreadMessages} color="error">
            <IconButton
              sx={{ color: theme.palette.primary.main }}
              onClick={() => setDrawerOpen(true)}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          </Badge>
        )}

        <ImageCovered
          src={locale === LOCALES.ENGLISH ? LOGO_HEADER_EN : LOGO_HEADER_CN}
          alt={"LOGO"}
          // style={{ maxHeight: 60 }}
          onClick={() => navigate(URL_HOME)}
          sx={{
            mb: -1,
            maxHeight: 50,
            color: theme.palette.common.white,
            "&:hover": {
              cursor: "pointer",
            },
          }}
        />
        <IconButton
          sx={{ color: theme.palette.primary.main }}
          onClick={() => navigate(URL_NOTIFICATIONS)}
        >
          <Badge badgeContent={unreadNotifications} color="error">
            <Notifications fontSize="large" />
          </Badge>
          {/* <Notifications fontSize="large" /> */}
        </IconButton>
      </Stack>
      <TopbarDrawer
        user={user}
        unreadMessages={unreadMessages}
        showLanguageSelectDialog={showLanguageSelectDialog}
      />
    </>
  );
};

export default TopbarMobile;
