import { Box, Slide, Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ChatSidebar from "../../sections/chat/ChatSidebar";
import ChatHeader from "../../components/chat/ChatHeader";
import ChatFooter from "../../components/chat/ChatFooter";
import { useDispatch, useSelector } from "react-redux";
import ChatRoom from "../../sections/chat/ChatRoom";
import { LOCALES } from "../../i18n/locales";
import { useGetConversationsQuery } from "../../apis/conversation";
import { useUIContext } from "../../context/ui";
import {
  addDirectConversation,
  setMessagesViewed,
  updateDirectConversation,
} from "../../redux/conversation";
import { setConversationViewed } from "../../redux/chatroom";
import SearchedMessages from "../../sections/chat/SearchedMessages";

const ChatRoomPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const user = useSelector((state) => state.user.currentUser)?.data;
  const userToken = useSelector((state) => state.persisted.global.userToken);
  const locale =
    useSelector((state) => state.persisted.global.locale)?.value ||
    LOCALES.ENGLISH;
  const config = useSelector((state) => state.config)?.config;
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { room_id, conversation_viewed, show_chatroom, show_search } =
    useSelector((state) => state.chatroom);
  const directChats = useSelector(
    (state) => state.persisted.conversation.direct_chats
  );

  const current_conversation = useMemo(() => {
    return directChats && user?._id?.length > 0
      ? directChats[user?._id]?.current_conversation
      : null;
  }, [directChats, user?._id]);

  const conversations = useMemo(() => {
    return directChats && user?._id?.length > 0
      ? directChats[user?._id]?.conversations
      : [];
  }, [directChats, user?._id]);

  // const { setShowBackdropCallback } = useUIContext();
  // const {
  //   data: dataConversations,
  //   isLoading: isLoadingConversations,
  //   isFetchingConversations,
  // } = useGetConversationsQuery(
  //   { userId: user?._id, token: userToken.token },
  //   { skip: !user?._id || !userToken }
  // );

  // useEffect(() => {
  //   setShowBackdropCallback(isLoadingConversations | isFetchingConversations);
  // }, [isLoadingConversations, isFetchingConversations]);

  // useEffect(() => {
  //   if (
  //     dataConversations?.status === "success" &&
  //     dataConversations?.data?.conversations?.length > 0 &&
  //     initialLoading === true
  //   ) {
  //     // Update redux conversations
  //     const latestConversations = dataConversations?.data?.conversations;
  //     latestConversations?.map((conversation) => {
  //       const existing_conversation = conversations
  //         ? conversations.find((item) => item?._id === conversation._id)
  //         : null;
  //       if (existing_conversation) {
  //         // update direct conversation

  //         dispatch(
  //           updateDirectConversation({
  //             userId: user?._id,
  //             conversation: conversation,
  //             updateMessage: true,
  //             span: config["defaultMessageDividerSpan"]
  //               ? parseFloat(config["defaultMessageDividerSpan"])
  //               : undefined,
  //           })
  //         );
  //       } else {
  //         // add direct conversation
  //         dispatch(
  //           addDirectConversation({
  //             userId: user?._id,
  //             conversation: conversation,
  //             updateMessage: true,
  //           })
  //         );
  //       }
  //     });
  //     setInitialLoading(false);
  //   }

  //   if (initialLoading === false && conversations?.length > 0) {
  //     const filtered = conversations?.filter((item) => item.active === true);
  //     if (filtered?.length > 0) {
  //       dispatch(
  //         setConversationViewed({
  //           conversationId: filtered[0]._id,
  //         })
  //       );
  //     }
  //   }
  // }, [dataConversations, dispatch, initialLoading]);

  useEffect(() => {
    if (!conversation_viewed) {
      const filtered = conversations?.filter((item) => item.active === true);
      if (filtered?.length > 0) {
        dispatch(
          setConversationViewed({
            conversationId: filtered[0]._id,
          })
        );
      }
    }
  }, [conversations, dispatch, conversation_viewed]);

  useEffect(() => {
    if (conversation_viewed?.length > 0) {
      dispatch(
        setMessagesViewed({
          conversationId: conversation_viewed,
          userId: user?._id,
        })
      );
    }
  }, [conversation_viewed, user?._id]);

  // useEffect(() => {
  //   console.log(
  //     "AAAAAAAAAAAAAAAa",
  //     show_chatroom,
  //     show_search,
  //     conversation_viewed
  //   );
  // }, [show_chatroom, show_search, conversation_viewed]);
  return (
    <Stack
      direction={mobile ? "column" : "row"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
      height="100%"
      minHeight={"650px"}
      py={2}
    >
      {mobile ? (
        <Slide
          direction="right"
          in={!show_chatroom && !show_search}
          mountOnEnter
          unmountOnExit
        >
          <Stack width={"100%"} height={"100%"}>
            <ChatSidebar
              user={user}
              userToken={userToken}
              mobile={mobile}
              locale={locale}
              current_conversation={current_conversation}
              conversations={conversations}
            />
          </Stack>
        </Slide>
      ) : (
        <Stack
          width={"25%"}
          minWidth="300px"
          height={"100%"}
          border={`1px solid ${theme.palette.grey["300"]}`}
        >
          <ChatSidebar
            user={user}
            userToken={userToken}
            mobile={false}
            locale={locale}
            current_conversation={current_conversation}
            conversations={conversations}
          />
        </Stack>
      )}

      {mobile ? (
        <Slide
          direction="left"
          in={show_chatroom && !show_search}
          mountOnEnter
          unmountOnExit
        >
          <Stack
            width={"100%"}
            height={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <ChatRoom
              user={user}
              userToken={userToken}
              mobile={mobile}
              current_conversation={current_conversation}
            />
          </Stack>
        </Slide>
      ) : (
        <Stack
          width={"60%"}
          minWidth={"400px"}
          height={"100%"}
          border={`1px solid ${theme.palette.grey["300"]}`}
        >
          <ChatRoom
            width={"100%"}
            user={user}
            userToken={userToken}
            mobile={mobile}
            current_conversation={current_conversation}
          />
        </Stack>
      )}

      {mobile ? (
        <Slide
          direction="right"
          in={show_search && !show_chatroom}
          mountOnEnter
          unmountOnExit
        >
          <Box width="100%" height="100%">
            <SearchedMessages
              mobile={mobile}
              current_conversation={current_conversation}
            />
          </Box>
        </Slide>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default ChatRoomPage;
