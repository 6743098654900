import React, { useState, useEffect, useMemo } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import PublicSharpIcon from "@mui/icons-material/PublicSharp";
import { FormattedMessage, useIntl } from "react-intl";
import { useUIContext } from "../../context/ui";
import { lighten } from "polished";
import { useDispatch, useSelector } from "react-redux";
import { TITLES } from "../../i18n/titles";
import { useLocation, useNavigate } from "react-router-dom";
import {
  URL_HOME,
  URL_PRODUCTS,
  URL_LOGIN,
  URL_CONTACT,
  URL_PICKUP,
  URL_NOTIFICATIONS,
  URL_COMMUNITY_REQUESTS,
  SEVERITY_WARNING,
  LOCALE_ITEMS,
  URL_CHATROOM,
} from "../../constants";
import { MENU_TOPBAR, getSidebarItemsProfile } from "../../data/menu";
import {
  DrawerCloseButton,
  DrawerItemButton,
  DrawerList,
} from "../../styles/bars";
import { setLocale, setUserToken } from "../../redux/global";
import {
  ChevronRightOutlined,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { setUser } from "../../redux/user";
import { LOCALES } from "../../i18n/locales";
import { socket } from "../../socket";
import useAvatar from "../../hooks/useAvatar";
import { StyledBadge } from "../styledBadge/StyledBadge";

const MiddleDivider = styled((props) => (
  <Divider variant="middle" {...props} />
))``;

const TopbarDrawer = ({ user, unreadMessages }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { drawerOpen, setDrawerOpen } = useUIContext();
  const locale =
    useSelector((state) => state.persisted.global.locale)?.value ||
    LOCALES.ENGLISH;

  const [langTitle, setLangTitle] = useState(LOCALES.ENGLISH);
  const [menuList, setMenuList] = useState([]);
  const [activeLink, setActiveLink] = useState(location?.pathname);
  const [userExpanded, setUserExpanded] = React.useState(false);
  const [localeExpanded, setLocaleExpanded] = React.useState(false);
  // const [postsExpanded, setPostsExpanded] = React.useState(false);
  // const [strataExpanded, setStrataExpanded] = React.useState(false);
  // const [communityExpanded, setCommunityExpanded] = React.useState(false);
  // const [commercialExpanded, setCommercialExpanded] = React.useState(false);

  const avatarFunc = useAvatar();

  const loggedIn = useMemo(() => {
    return Boolean(user);
    // &&
    // (user?.firstName !== process.env.REACT_APP_GUEST_PREFIX ||
    //   user?.lastName !== process.env.REACT_APP_GUEST_PREFIX)
  }, [user]);

  const showName = useMemo(() => {
    return user
      ? user?.firstName?.length > 0
        ? user?.firstName
        : user?.username
      : "";
  }, [user]);

  useEffect(() => {
    if (MENU_TOPBAR) {
      setMenuList([
        ...MENU_TOPBAR.map((item) => {
          return {
            ...item,
            expanded: item.children?.length > 0 ? false : undefined,
          };
        }),
      ]);
    }
  }, [MENU_TOPBAR]);

  // 点击 profile
  const handleProfileLinkSelected = (item) => {
    if (item.children && item.children.length > 0) {
      // setPostsExpanded(!postsExpanded);
    } else if (item.link.indexOf("logout") >= 0) {
      if (
        window.confirm(intl.formatMessage({ id: "window.sure.logout" })) ===
        true
      ) {
        if (socket) {
          socket.emit("end", {
            user_id: user?._id,
          });
        }
        dispatch(setUser(null));
        dispatch(setUserToken(null));
        setActiveLink(URL_HOME);
        navigate(URL_HOME);
        setDrawerOpen(false);
        
      }
    } else {
      navigate(item.link ? item.link : "/");
      setActiveLink(item.link);
      // navigate(item.link);
      setDrawerOpen(false);
    }
  };
  const handleUsernameClick = () => {
    setUserExpanded(!userExpanded);
  };

  const handleLocaleClick = () => {
    setLocaleExpanded(!localeExpanded);
  };

  const handleLocaleSelect = (value) => {
    setLocaleExpanded(!localeExpanded);
    dispatch(
      setLocale({
        value: value,
        saved: true,
      })
    );
  };

  const handleLinkClick = (url) => {
    navigate(url);
    setActiveLink(url);
    setDrawerOpen(false);
  };

  const handleTopMenuItemSelected = (item) => {
    if (item.children.length === 0) {
      navigate(item.url);
      setActiveLink(item.url);
      setDrawerOpen(false);
    } else {
      setMenuList([
        ...menuList.map((item) => {
          return {
            ...item,
            expanded: item.children?.length > 0 ? !item.expanded : undefined,
          };
        }),
      ]);
    }
    // else if (item.category === "strata") {
    //   setStrataExpanded(!strataExpanded);
    //   setCommunityExpanded(false);
    //   setCommercialExpanded(false);
    // } else if (item.category === "community") {
    //   setCommunityExpanded(!communityExpanded);
    //   setStrataExpanded(false);
    //   setCommercialExpanded(false);
    // } else if (item.category === "commercial") {
    //   setCommercialExpanded(!commercialExpanded);
    //   setStrataExpanded(false);
    //   setCommunityExpanded(false);
    // }
  };

  const handleChildMenuItem = (item) => {
    setActiveLink(item.url);
    navigate(item.url);
    setDrawerOpen(false);
    // if (
    //   item.link.indexOf("community") >= 0 ||
    //   item.link.indexOf("strata") >= 0
    // ) {
    //   if (!user) {
    //     setAlertObj({
    //       severity: SEVERITY_WARNING,
    //       content: intl.formatMessage({ id: "warning.not.login" }),
    //       duration: null,
    //     });
    //     setShowAlert(true);
    //     return;
    //   } else if (!activeBuilding) {
    //     setAlertObj({
    //       severity: SEVERITY_WARNING,
    //       content: intl.formatMessage({ id: "warning.community.not.set" }),
    //       duration: null,
    //     });
    //     setShowAlert(true);
    //     return;
    //   } else if (user.verified === false) {
    //     setAlertObj({
    //       severity: SEVERITY_WARNING,
    //       content: intl.formatMessage({ id: "warning.user.not.verified" }),
    //       duration: null,
    //     });
    //     setShowAlert(true);
    //     return;
    //   }
    // }

    // if (item.state) {
    //   setTimeout(() => {
    //     navigate(item.link, { state: item.state });
    //   }, 100);
    // } else {
    //   setTimeout(() => {
    //     navigate(item.link);
    //   }, 100);
    // }
  };

  useEffect(() => {
    setLangTitle(TITLES[locale]);

    if (activeLink.indexOf("profile") >= 0) {
      setUserExpanded(true);
    }
    // else if (activeLink.indexOf("commercial") >= 0) {
    //   setCommercialExpanded(true);
    // }
  }, [locale, activeLink]);

  console.log("menuList", menuList);
  return (
    <>
      <Drawer
        open={drawerOpen}
        sx={{ height: "auto" }}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: { width: "220px" },
        }}
      >
        <Stack
          direction={"column"}
          width="100%"
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              backgroundColor: theme.palette.grey["100"],
              color: theme.palette.primary.main,
              px: "10px",
              height: "64px",
              borderBottom: `1px solid ${theme.palette.grey["300"]}`,
              width: "100%",
            }}
          >
            {!loggedIn && (
              <Box px={2}>
                <Typography variant="h6" color={theme.palette.primary.main}>
                  <FormattedMessage id={"app.title"} />
                </Typography>
              </Box>
            )}
            {loggedIn && (
              <Stack
                direction={"row"}
                justifyContent={"start"}
                alignItems={"center"}
                spacing={1}
              >
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  variant="dot"
                >
                  <Avatar
                    src={avatarFunc({ image: user?.avatar })}
                    // alt={showName}
                    sx={{
                      mr: 0.5,
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </StyledBadge>

                <Typography variant="body1" color={theme.palette.text.primary}>
                  {showName}
                </Typography>
              </Stack>
            )}
            <DrawerCloseButton onClick={() => setDrawerOpen(false)}>
              <CloseIcon
                sx={{
                  fontSize: "0.8em",
                  color: lighten(0.09, theme.palette.primary.main),
                }}
              />
            </DrawerCloseButton>
          </Stack>
          <DrawerList>
            {menuList.map((item) => (
              <Box key={item.id}>
                <DrawerItemButton
                  selected={activeLink === item.url}
                  key={item.id}
                  onClick={() => handleTopMenuItemSelected(item)}
                >
                  {item.url !== URL_CHATROOM && (
                    <ListItemText>
                      <Typography variant="body1">
                        <FormattedMessage id={item.title} />
                      </Typography>
                    </ListItemText>
                  )}
                  {item.url === URL_CHATROOM && (
                    <Badge badgeContent={unreadMessages} color="error">
                      <ListItemText>
                        <Typography variant="body1">
                          <FormattedMessage id={item.title} />
                        </Typography>
                      </ListItemText>
                    </Badge>
                  )}

                  {item.children.length > 0 && item.expanded === true && (
                    <ExpandLess />
                  )}
                  {item.children.length > 0 && item.expanded === false && (
                    <ExpandMore />
                  )}
                </DrawerItemButton>
                {item.expanded && (
                  <Collapse in={item.expanded} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.children.map((child) => (
                        <ListItemButton
                          selected={activeLink.indexOf(child?.url) >= 0}
                          key={child.title}
                          sx={{ pl: 5 }}
                          onClick={() => {
                            handleChildMenuItem(child);
                          }}
                        >
                          <ListItemText>
                            <Typography variant="body1">
                              <FormattedMessage id={child.title} />
                            </Typography>
                          </ListItemText>
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                )}
              </Box>
            ))}
            <MiddleDivider />
            {/* <DrawerItemButton
              selected={activeLink === URL_NOTIFICATIONS}
              onClick={() => handleLinkClick(URL_NOTIFICATIONS)}
            >
              <Badge badgeContent={unreadNotifications} color="warning">
                <Typography variant="body1">
                  <FormattedMessage id="general.notifications" />
                </Typography>
              </Badge>
            </DrawerItemButton> */}
            {/* {unreadMessageCount > 0 && (
              <DrawerItemButton
                selected={activeLink === URL_COMMUNITY_REQUESTS}
                onClick={() => handleLinkClick(URL_COMMUNITY_REQUESTS)}
              >
                <Badge badgeContent={unreadMessageCount} color="error">
                  <Typography variant="body1">
                    <FormattedMessage id="community.messages" />
                  </Typography>
                </Badge>
              </DrawerItemButton>
            )} */}
            <DrawerItemButton onClick={() => handleLocaleClick()}>
              <ListItemIcon>
                <PublicSharpIcon />
              </ListItemIcon>
              <ListItemText>{langTitle}</ListItemText>
              {localeExpanded ? <ExpandLess /> : <ExpandMore />}
            </DrawerItemButton>
            <Collapse in={localeExpanded} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {LOCALE_ITEMS.map((el) => (
                  <Box key={el.value}>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        handleLocaleSelect(el.value);
                      }}
                    >
                      <ListItemText
                        sx={{
                          color: theme.palette.text.primary,
                        }}
                      >
                        {el.title}
                      </ListItemText>
                    </ListItemButton>
                  </Box>
                ))}
              </List>
            </Collapse>
            {!loggedIn && (
              <DrawerItemButton
                selected={activeLink === URL_LOGIN}
                onClick={() => handleLinkClick(URL_LOGIN)}
              >
                <Typography variant="body1">
                  <FormattedMessage id="app.login" />
                </Typography>
              </DrawerItemButton>
            )}
            {loggedIn && (
              <>
                <DrawerItemButton
                  selected={activeLink === URL_LOGIN}
                  onClick={() => handleUsernameClick()}
                >
                  <ListItemText>
                    <Typography variant="body1">
                      <FormattedMessage id="general.hello" />, {showName}
                    </Typography>
                  </ListItemText>
                  {userExpanded ? <ExpandLess /> : <ExpandMore />}
                </DrawerItemButton>
                <Collapse in={userExpanded} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {getSidebarItemsProfile(true).map((item) => (
                      <Box
                        key={item.link}
                        mt={item.link?.indexOf("logout") >= 0 ? 2 : 0}
                      >
                        <ListItemButton
                          key={item.link}
                          selected={activeLink.indexOf(item.link) >= 0}
                          sx={{ pl: 4 }}
                          onClick={() => handleProfileLinkSelected(item)}
                        >
                          <ListItemText
                            sx={{
                              color:
                                item.link?.indexOf("logout") >= 0
                                  ? theme.palette.error.main
                                  : theme.palette.blueGrey["800"],
                            }}
                          >
                            <Typography
                              variant={
                                item.link?.indexOf("logout") >= 0
                                  ? "h6"
                                  : "body1"
                              }
                            >
                              <FormattedMessage id={item.text} />
                            </Typography>
                          </ListItemText>
                          {(!item.children || item.children.length === 0) &&
                            activeLink.indexOf(item.link) >= 0 && (
                              <ChevronRightOutlined sx={{ ml: "auto" }} />
                            )}
                          {/* {item.children &&
                            item.children.length > 0 &&
                            (postsExpanded ? <ExpandLess /> : <ExpandMore />)} */}
                        </ListItemButton>
                        {/* {postsExpanded && item.children?.length > 0 && (
                          <Collapse
                            in={postsExpanded}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              {item.children.map((child) => (
                                <ListItemButton
                                  selected={
                                    activeLink.indexOf(child?.link) >= 0
                                  }
                                  key={child.text}
                                  sx={{ pl: 5 }}
                                  onClick={() => {
                                    handleLinkClick(child?.link);
                                  }}
                                >
                                  <ListItemText>
                                    <Typography variant="body1">
                                      <FormattedMessage id={child.text} />
                                    </Typography>
                                  </ListItemText>
                                </ListItemButton>
                              ))}
                            </List>
                          </Collapse>
                        )} */}
                      </Box>
                    ))}
                  </List>
                </Collapse>
              </>
            )}
          </DrawerList>
        </Stack>
      </Drawer>
    </>
  );
};

export default TopbarDrawer;
