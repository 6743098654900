import { Box, Button, Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "../../components/carousel/Carousel";
import Service from "./Service";
import Commercials from "./Commercials";
import Communities from "./Communities";
import { useGetConfigsQuery } from "../../apis/config";
import { CONFIG_KEY_HOME_CAROUSELS, URL_CHATROOM } from "../../constants";
import { LOCALES } from "../../i18n/locales";
import { setConfig } from "../../redux/config";
import Steps from "./Steps";
import { Link, useNavigate } from "react-router-dom";
import { ChatBubble, Email } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import useImage from "../../hooks/useImage";

const Home = () => {
  const theme = useTheme();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useSelector((state) => state.user.currentUser)?.data;
  const locale =
    useSelector((state) => state.persisted.global.locale)?.value ||
    LOCALES.ENGLISH;
  const imageFunc = useImage()
  // const activeBuilding = useSelector(
  //   (state) => state.persisted.global.activeBuilding
  // );
  const config = useSelector((state) => state.config)?.config;
  const [carousels, setCarousels] = useState([]);

  useEffect(() => {
    if (config && config[CONFIG_KEY_HOME_CAROUSELS]) {
      // console.log("config", config);
      const configSlides = config[CONFIG_KEY_HOME_CAROUSELS];
      // console.log("configSlides", configSlides);
      let slides = JSON.parse(configSlides);
      slides = slides.filter(
        (slide) => slide.lang.length === 0 || slide.lang.indexOf(locale) >= 0
      );
      slides.sort((a, b) => parseInt(a.sort) - parseInt(b.sort));
      // console.log("slides", slides);
      setCarousels([
        ...slides?.map((slide) => {
          return {
            ...slide,
            url: imageFunc({image: slide["url"]}),
          };
        }),
      ]);
    }
  }, [config, imageFunc]);

  // console.log("carousels", carousels);
  return (
    <>
      <Box width="100%">
        <Box width={mobile ? "100%" : "100%"}>
          {carousels?.length > 0 && (
            <Carousel
              slides={carousels}
              tablet={tablet}
              mobile={mobile}
              locale={locale}
            />
          )}
        </Box>
      </Box>
      <Stack
        width={"100%"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: theme.palette.background.paper,
          // padding: '50px',
        }}
      >
        <Service
          mobile={mobile}
          tablet={tablet}
          user={user}
          // activeBuilding={activeBuilding}
        />
        <Steps
          mobile={mobile}
          tablet={tablet}
          user={user}
          // activeBuilding={activeBuilding}
        />
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={3}
          mb={5}
        >
          <Button
            variant="contained"
            href={`mailto: ${process.env.REACT_APP_EMAIL_SUPPORT}`}
            sx={{ width: "150px", py: 1, fontSize: "16px", fontWeight: 500, borderRadius: 0, }}
            startIcon={<Email fontSize="medium" />}
          >
            <FormattedMessage id='home.email.us' />
          </Button>
          <Button
            variant="contained"
            sx={{ width: "150px", py: 1, fontSize: "16px", fontWeight: 500, borderRadius: 0,  }}
            startIcon={<ChatBubble fontSize="medium" />}
            onClick={() => {
              navigate(URL_CHATROOM)
            }}
          >
            <FormattedMessage id='home.message.us' />
          </Button>
        </Stack>
        {/* <Commercials mobile={mobile} tablet={tablet} /> */}
        {/* <Communities mobile={mobile} tablet={tablet}/> */}
      </Stack>
    </>
  );
};

export default Home;
